import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faSquareCheck } from "@fortawesome/pro-regular-svg-icons";
import { t } from "i18next";
import CheckboxTree from "react-checkbox-tree";
import CheckboxTreeNodeDto from "../../../../../types/dtos/generic/CheckboxTreeNodeDto";
import SmallLoader from "../../../../loaders/SmallLoader";
import { faUser as fasUser } from "@fortawesome/pro-solid-svg-icons";
import { Label } from "../../../../common";

interface JobTitleSelectionProps {
  nodes?: CheckboxTreeNodeDto[];
  treeChecked: string[];
  treeExpanded: string[];
  onTreeChecked(newState: string[]): void;
  onTreeExpanded(newState: string[]): void;
  isLoading: boolean;
  labelTranslationKey?: string | undefined;
}

const JobTitleSelection = ({
  nodes,
  treeChecked,
  treeExpanded,
  onTreeChecked,
  onTreeExpanded,
  isLoading,
  labelTranslationKey = undefined,
}: JobTitleSelectionProps) => {
  return (
    <div className="pt-2">
      {isLoading && (
        <div className="pt-2">
          <SmallLoader />
          <p className="text-center pt-1 text-[#959595] text-sm">
            {t("Loading job titles")}...
          </p>
        </div>
      )}
      {!isLoading && nodes && (
        <div>
          {labelTranslationKey && <Label text={t(labelTranslationKey)} />}
          <CheckboxTree
            iconsClass="fa5"
            icons={{
              check: (
                <FontAwesomeIcon className="text-black" icon={faSquareCheck} />
              ),
              uncheck: (
                <FontAwesomeIcon className="text-black" icon={faSquare} />
              ),
              leaf: (
                <FontAwesomeIcon
                  className="client-colour-icon"
                  icon={fasUser}
                />
              ),
            }}
            nodes={nodes}
            checked={treeChecked}
            expanded={treeExpanded}
            onCheck={onTreeChecked}
            onExpand={onTreeExpanded}
          />
        </div>
      )}
    </div>
  );
};

export default JobTitleSelection;
