import { t } from "i18next";
import InfoAlert from "../../../../alerts/InfoAlert";
import { SwitchAlternative, TextArea } from "../../../../common";

interface UserIdsSelectionProps {
  /** False means system user ids, true means employee numbers */
  checked: boolean;
  onSwitchChange(newState: boolean): void;
  textAreaValue: string;
  onTextAreaValueChange(newState: string): void;
}

const UserIdsSelection = ({
  checked,
  onSwitchChange,
  textAreaValue,
  onTextAreaValueChange,
}: UserIdsSelectionProps) => {
  return (
    <div>
      <div className="pt-2 text-center">
        <SwitchAlternative
          uncheckedText={t("Common.UserIds")}
          checkedText={t("Common.EmployeeReference")}
          checked={checked}
          onChange={onSwitchChange}
        />
      </div>
      {!checked && (
        <div className="pt-2">
          <InfoAlert
            prefix={""}
            message={t("Pages.SendNow.Warnings.OnlyNumericalValues")}
          />
        </div>
      )}
      <div>
        <TextArea
          inputId="user-ids-selection"
          value={textAreaValue}
          onChange={onTextAreaValueChange}
          className="mt-2 p-2 w-full rounded-md border border-gray-400"
          minRows={6}
          maxRows={20}
        />
      </div>
    </div>
  );
};

export default UserIdsSelection;
