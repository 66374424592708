import { t } from "i18next";
import PeoplePickerUserDto from "../../../../../types/dtos/generic/PeoplePickerUserDto";
import { ValidationResult } from "../../../../../types/forms";
import { Label } from "../../../../common";
import PeoplePicker from "../../../../common/PeoplePicker";

interface SpecificUserSelectionProps {
  /** The onChange event, for handling state changes */
  onPeoplePickerChange(newValue: PeoplePickerUserDto[] | null): void;
  peoplePickerSelectedUsers: PeoplePickerUserDto[];
}

const SpecificUserSelection = ({
  onPeoplePickerChange,
  peoplePickerSelectedUsers,
}: SpecificUserSelectionProps) => {
  return (
    <div className="pt-2">
      <Label text={t("Pages.SendNow.Fields.SelectSpecificUser")} />
      <PeoplePicker
        inputId="input-id"
        className="block mt-2 p-2 w-full bg-gray-100 border-0"
        allowMultipleSelection={true}
        selectedUsers={peoplePickerSelectedUsers}
        onPeoplePickerChange={onPeoplePickerChange}
      />
    </div>
  );
};

export default SpecificUserSelection;
