import { t } from "i18next";
import { Badge } from "../../common";
import ToDoListTaskTypeDto from "../../../types/dtos/dashboards/right-bar/to-do-list/ToDoListTaskTypeDto";

interface TaskTypeSelectorProps {
  toDoListTaskTypes: ToDoListTaskTypeDto[];
  selectedTaskTypeId: string | number;
  showCount: boolean;
  onSelectedTaskTypeIdChange(
    standardTaskTypeId?: number | null,
    advancedTaskTypeId?: string | null
  ): void;
}

function TaskTypeSelector({
  toDoListTaskTypes,
  selectedTaskTypeId,
  showCount,
  onSelectedTaskTypeIdChange,
}: TaskTypeSelectorProps) {
  return (
    <div>
      {toDoListTaskTypes.map((taskType) => {
        const isSelected =
          selectedTaskTypeId === taskType.standardTaskTypeId ||
          selectedTaskTypeId === taskType.advancedTaskTypeId;
        return (
          <button
            key={taskType.pluralTranslationKeyIdentifier}
            onClick={() =>
              onSelectedTaskTypeIdChange(
                taskType.standardTaskTypeId,
                taskType.advancedTaskTypeId
              )
            }
            className={`${
              isSelected
                ? "border border-gray-400 hover:cursor-default bg-gray-200"
                : "bg-gray-200/60 hover:cursor-pointer"
            }  text-gray-600 p-2 mr-1 mb-1 rounded-md text-sm`}
          >
            {t(taskType.pluralTranslationKeyIdentifier)}
            {showCount && (
              <Badge
                text={taskType.count.toString()}
                backgroundColourClassName="accent-bg"
                textColourClassName="text-white"
                classNames={
                  isSelected ? "hover:cursor-default" : "hover:cursor-pointer"
                }
              />
            )}
          </button>
        );
      })}
    </div>
  );
}

export default TaskTypeSelector;
