import { MainContainer } from "../../components/layout";
import {
  Reducer,
  useReducer,
  useContext,
  useEffect,
  useState,
  Fragment,
} from "react";
import AppContext from "../../state/AppContext";
import { useTranslation } from "react-i18next";
import SmallLoader from "../../components/loaders/SmallLoader";
import { Avatar } from "../../components/common";
import { useAuth } from "react-oidc-context";
import UserContext from "../../state/UserContext";
import SearchField from "../../components/common/SearchField";
import managerDashboardApi from "../../api/dashboard/managerDashboardApi";
import YourPeopleFormSectionUserDto from "../../types/dtos/dashboards/YourPeopleFormSectionUserDto";
import UserTagList from "../../components/manager-dashboard/Tags/UserTagList";
import JourneyFormSection from "../../components/shared/dashboard-journeys/JourneyFormSection";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

interface YourPeopleFormState {
  isLoading: boolean;
  users: YourPeopleFormSectionUserDto[] | null;
  filteredUsers: YourPeopleFormSectionUserDto[] | null;
}

function YourPeopleForm() {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const appContext = useContext(AppContext);
  const auth = useAuth();
  const managerApi = new managerDashboardApi(auth.user?.access_token);

  const { preselectedEmployeeId } = useParams();
  const [searchTerm, setSearchTerm] = useState<string | null>(null);

  const [state, setState] = useReducer<
    Reducer<YourPeopleFormState, Partial<YourPeopleFormState>>
  >((state, newState) => ({ ...state, ...newState }), {
    isLoading: false,
    users: null,
    filteredUsers: null,
  });

  const populateSearchBarWithPreselectedEmployee = (
    users: YourPeopleFormSectionUserDto[]
  ) => {
    const preselectedEmployeeName = users.find(
      (x) => x.employeeId == parseInt(preselectedEmployeeId!)
    )?.employeeFullName;
    if (preselectedEmployeeName != null) {
      setSearchTerm(preselectedEmployeeName);
    }
  };

  useEffectOnce(() => {
    setState({ isLoading: true });

    appContext.setPageTitle(
      t("Pages.ManagerDashboard.PageTitle") +
        ": " +
        t("Nav.LargeScreen.MyDashboard.Forms")
    );

    managerApi.getJourneyFormDataForPeopleManagedByLoggedInUser(
      (data) => {
        setState({
          isLoading: false,
          users: data.users,
          filteredUsers: data.users,
        });

        if (preselectedEmployeeId && data.users.length > 0) {
          populateSearchBarWithPreselectedEmployee(data.users);
        }
      },
      (error) => {
        console.error("Error loading manager data", error);
      }
    );
  });

  useEffect(() => {
    // If the users haven't loaded yet, return
    if (!state.users) {
      return;
    }

    // Filter the users based on the search terms
    if (searchTerm) {
      let users = [...state.users];
      users = users.filter((x) =>
        x.employeeFullName.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setState({ filteredUsers: users });
    } else {
      setState({ filteredUsers: state.users });
    }
  }, [searchTerm]);

  const handleSearchTermChange = (newsearchTerm: string | null) => {
    setSearchTerm(newsearchTerm);
  };

  return (
    <MainContainer>
      {state.isLoading && (
        <div className="pt-5">
          <SmallLoader />
          <p className="text-center pt-1 text-[#959595] text-sm">
            {t("Common.Loading")}...
          </p>
        </div>
      )}
      {!state.isLoading && (
        <>
          <div className="mt-2 lg:mt-4 lg:flex lg:flex-row lg:justify-end">
            <SearchField
              onSearch={handleSearchTermChange}
              isLoading={state.isLoading}
              loaderColor={userContext.user.client.accentHexColour}
              searchTerm={searchTerm}
              includeSetWidth={true}
              outerContainerClassName=""
            />
          </div>
          {state.filteredUsers &&
            state.filteredUsers.map((user) => (
              <Fragment key={user.employeeId}>
                <div className="mt-4 px-4">
                  <div className="grid grid-cols-1 gap-1 md:mb-1">
                    <div className="flex flex-row">
                      <div className="hidden md:block md:mr-5 md:ml-1 md:mt-1">
                        <Avatar
                          userInitials={user.initials}
                          userFullName={user.employeeFullName}
                          activeStatus="DO-NOT-SHOW"
                          imageUrl={user.profileImageUrl}
                          size={12}
                        />
                      </div>
                      <div className="flex flex-col grow">
                        <h4 className="coloured-heading !mb-0 !mt-0 flex-none text-xl">
                          {user.employeeFullName}
                        </h4>
                        {/* Tags */}
                        <div className="mt-1">
                          <UserTagList
                            jobTitle={user.jobTitleTranslationKeyIdentifier}
                            jobTitleTooltip={t("Pages.Admin.Common.JobTitle")}
                            appraisalLevel={
                              user.appraisalLevelTranslationKeyIdentifier
                            }
                            appraisalLevelTooltip={t("Common.AppraisalLevel")}
                            location={user.locationName}
                            locationTooltip={t("Pages.Admin.Common.Location")}
                            viewingActor={
                              userContext.user.id === user.managerId
                                ? "MANAGER"
                                : "DELEGATED-MANAGER"
                            }
                            managerOrDelegatedName={
                              userContext.user.id === user.managerId
                                ? user.journeyManagerFullName
                                : user.managerFullName
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <JourneyFormSection
                    userContext={userContext}
                    activeForms={user.activeForms}
                    completedForms={user.completedForms}
                    isYourPeoplePage={true}
                    managedUser={user}
                  />
                </div>
                <hr className="mt-4" />
              </Fragment>
            ))}
        </>
      )}
    </MainContainer>
  );
}

export default YourPeopleForm;
