import { ToWords } from "to-words";

const toWords = new ToWords();

const typeConversionHelper = {
  /** Attempts to convert a string to a number. Returns undefined if not a valid number */
  stringToNumber: function (input: string | undefined): number | undefined {
    const converted = parseInt(input ? input : "");
    if (isNaN(converted)) {
      return undefined;
    }

    return converted;
  },

  /** Converts a number to a string, e.g. 2 to "two" */
  numberToWordsString: function (input: number | undefined): string {
    if (input === undefined) {
      return "";
    }

    return toWords.convert(input).toLowerCase();
  },
};

export default typeConversionHelper;
