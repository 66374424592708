import { Reducer, useReducer, useState } from "react";
import { useAuth } from "react-oidc-context";
import { JourneyConstants } from "../../../config/journeys/JourneyConstants";
import { ValidationResult } from "../../../types/forms";
import DangerAlert from "../../alerts/DangerAlert";
import { Label, Sidebar, TextArea, TextInput } from "../../common";
import journeyAdminApi from "../../../api/admin/journeyAdminApi";
import {
  AdminNewJourney,
  AdminNewJourneyInfo,
  JourneyCreationApiResponse,
} from "../../../types/admin/journeys";

interface CreateJourneyModalProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  categoryId: string;
  onJourneyCreatedCallback: (newJourneyInfo: AdminNewJourneyInfo) => void;
}

export const CreateJourneyModal = ({
  isOpen,
  onOpenChange,
  categoryId,
  onJourneyCreatedCallback,
}: CreateJourneyModalProps) => {
  // Constants
  const auth = useAuth();
  const api = new journeyAdminApi(auth.user?.access_token);

  // State
  const [popupState, setPopupState] = useReducer<
    Reducer<NewJourneyPopupState, Partial<NewJourneyPopupState>>
  >((state, newState) => ({ ...state, ...newState }), getDefaultPopupState());

  // Functions
  function getDefaultPopupState(): NewJourneyPopupState {
    return {
      name: "",
      description: "",
      showValidationErrors: false,
      saveErrored: false,
      isCallingApi: false,
      validationErrors: [],
    };
  }

  const validateForm = (): boolean => {
    let isValid = true;
    if (popupState.name.trim() === "") {
      setPopupState({
        showValidationErrors: true,
        validationErrors: [
          {
            field: "NAME",
            validationResult: {
              isValid: false,
              errors: [{ errorType: "REQUIRED" }],
            },
          },
        ],
      });
      isValid = false;
    } else {
      setPopupState({ showValidationErrors: false, validationErrors: [] });
    }
    return isValid;
  };

  // Events
  const handleNameChange = (newName: string) => {
    setPopupState({ name: newName });
  };

  const handleDescriptionChange = (newDescription: string) => {
    setPopupState({ description: newDescription });
  };

  const handleCreateJourney = () => {
    // Hide any previous errors
    setPopupState({ saveErrored: false });

    // Check the form is valid
    if (!validateForm()) {
      return;
    }

    // Disable the "Create" button to avoid double clicks
    setPopupState({ isCallingApi: true });

    const newJourneyDetails: AdminNewJourney = {
      categoryId: categoryId,
      displayName: popupState.name.trim(),
      description: popupState.description.trim(),
    };

    const successCallback = (apiResponse: JourneyCreationApiResponse) => {
      if (!apiResponse.success || !apiResponse.data) {
        setPopupState({ saveErrored: true, isCallingApi: false });
        console.error("Failed to create journey", apiResponse.errorCode);
        return;
      }

      // Reset the form
      setPopupState(getDefaultPopupState());

      // Close the modal
      onOpenChange(false);

      // Call the callback
      onJourneyCreatedCallback(apiResponse.data);
    };

    const errorCallback = () => {
      setPopupState({ saveErrored: true, isCallingApi: false });
    };

    // Call the API
    api.addJourney(newJourneyDetails, successCallback, errorCallback);
  };

  const handleCancel = () => {
    // Logic for canceling the creation
    onOpenChange(false);
  };

  return (
    <Sidebar
      title="Create a new journey"
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      onPrimaryButtonClick={handleCreateJourney}
      primaryButtonText="Create"
      onSecondaryButtonClick={handleCancel}
      secondaryButtonText="Cancel"
      isPrimaryButtonDisabled={popupState.isCallingApi}
      width="NARROW"
    >
      {popupState.saveErrored && (
        <DangerAlert
          message="There was a problem creating your new journey. Please try again"
          prefix="Oops!"
        />
      )}
      <p className="mb-2">Give your new journey a unique name:</p>
      <form className="grid grid-cols-1 gap-2">
        <div>
          <Label htmlFor="journeyName">Journey name</Label>
          <TextInput
            inputId="journeyName"
            placeholder="Required"
            value={popupState.name}
            onChange={handleNameChange}
            className="w-full border-gray-200"
            maxLength={JourneyConstants.Admin.MaxLengths.JourneyDisplayName}
            showValidationErrors={popupState.showValidationErrors}
            validationResult={
              popupState.validationErrors.find((x) => x.field === "NAME")
                ?.validationResult
            }
          />
        </div>
        <div>
          <Label htmlFor="journeyDesc">Description</Label>
          <TextArea
            inputId="journeyDesc"
            placeholder="Optional, but recommended"
            value={popupState.description}
            onChange={handleDescriptionChange}
            className="w-full border-gray-200"
            minRows={3}
            maxLength={JourneyConstants.Admin.MaxLengths.JourneyDescription}
          />
        </div>
      </form>
    </Sidebar>
  );
};

interface NewJourneyPopupState {
  name: string;
  description: string;
  showValidationErrors: boolean;
  saveErrored: boolean;
  isCallingApi: boolean;
  validationErrors: JourneyModalValidationError[];
}

interface JourneyModalValidationError {
  field: "NAME" | "DESCRIPTION";
  validationResult: ValidationResult;
}
