import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import ToDoListEmptyState from "../shared/ToDoListEmptyState";
import ToDoListLockedState from "./AdvancedToDoListLockedState";
import ToDoListRestrictionBanner from "./AdvancedToDoListRestrictionBanner";
import myDashboardApi from "../../../../api/dashboard/myDashboardApi";
import taskRestrictionHelper from "../../../../helpers/taskRestrictionHelper";
import { JourneyFormGroupDto } from "../../../../types/dtos/journeys";
import { ClientTaskType } from "../../../../types/dtos/tasks/advanced-tasks/ClientTaskType";
import TaskRequirementBadge from "../../../task-management/TaskRequirementBadge";
import ToDoListDisplay from "../shared/ToDoListDisplay";
import { t } from "i18next";
import { advancedTaskHelper } from "../../../../helpers";
import AddAdvancedTaskPopup from "../../../tasks/advanced-tasks/AddAdvancedTaskPopup";
import { BaseUserDetailsDto } from "../../../../types/dtos/generic";
import { UserContextInterface } from "../../../../state/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import ToDoListTaskTypeDto from "../../../../types/dtos/dashboards/right-bar/to-do-list/ToDoListTaskTypeDto";
import ExistingAdvancedTaskPopup from "../../../tasks/advanced-tasks/ExistingAdvancedTaskPopup";
import AdvancedToDoListItemDto from "../../../../types/dtos/dashboards/right-bar/to-do-list/AdvancedToDoListItemDto";
import taskTypeIconHelper from "../../../../helpers/taskTypeIconHelper";
import { LoadingPlaceholderItem } from "../shared/LoadingPlaceholderItem";

interface AdvancedToDoListProps {
  userContext: UserContextInterface;
  initialToDoItems: AdvancedToDoListItemDto[];
  taskTypes: ClientTaskType[];
  toDoListTaskTypes: ToDoListTaskTypeDto[];
  selectedTaskTypeId: string;
  formDetails: JourneyFormGroupDto | undefined | null;
  hasJourneyBeenSubmitted: boolean;
  updateTaskTypeCount(taskTypeId: string | number, count: number): void;
}

function AdvancedToDoList({
  userContext,
  initialToDoItems,
  taskTypes,
  toDoListTaskTypes,
  selectedTaskTypeId,
  formDetails,
  hasJourneyBeenSubmitted,
  updateTaskTypeCount
}: AdvancedToDoListProps) {
  const auth = useAuth();
  const dashboardApi = new myDashboardApi(auth.user?.access_token);

  // Flag used to avoid hitting the API on the first selectedTaskTypeId change to load data as 
  // the initial data comes from the dashboard load API
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tasks, setTasks] = useState<AdvancedToDoListItemDto[]>([]);
  const [displayTasks, setDisplayTasks] = useState<AdvancedToDoListItemDto[]>([]);
  const [isLockedDueToFeaturedJourney, setIsLockedDueToFeaturedJourney] = useState<boolean>(false);
  const [isAddTaskModalOpen, setIsAddTaskModalOpen] = useState<boolean>(false);
  const [isExistingTaskModalOpen, setIsExistingTaskModalOpen] = useState<boolean>(false);
  const [selectedUserTaskId, setSelectedUserTaskId] = useState<string | null>(null);
  const [taskCountExceedsMaxBy, setTaskCountExceedsMaxBy] = useState<number>(0);
  const [hasShowAllBeenClicked, setHasShowAllBeenClicked] = useState<boolean>(false);

  const maxItemsToDisplay = 4;
  const selectedTaskType = taskTypes.find((x) => x.id === selectedTaskTypeId);
  const selectedToDoTaskType = toDoListTaskTypes.find((x) => x.advancedTaskTypeId === selectedTaskTypeId);
  const isActivelyInReview = selectedToDoTaskType?.isActivelyInReview ?? false;
  const reachedMaximumTaskLimit = selectedTaskType?.maxTaskCount != null && tasks.length >= selectedTaskType?.maxTaskCount;
  const showRestrictionBanner =
    selectedTaskType != undefined && selectedTaskType.suppressDashboardCreateAndValidate
      ? false
      : selectedTaskType != null && !taskRestrictionHelper.doesUserHaveTasksThatSatisfyRestrictions(
        tasks,
        selectedTaskType.minTaskCount,
        selectedTaskType.maxTaskCount,
        selectedTaskType.countRestriction,
        selectedTaskType.categories
      );

  // Hide the add new button if the task type is set to suppress the dashboard create and validate button
  // or if the task type is set to hide the add button when in review
  const hideAddNewButton = selectedTaskType != undefined && (selectedTaskType.suppressDashboardCreateAndValidate || isActivelyInReview && selectedTaskType.hideAddButtonWhenInReview);

  const taskSubjectOwner: BaseUserDetailsDto = {
    firstName: userContext.user.firstName,
    fullName: userContext.user.fullName,
    userId: userContext.user.id,
  };

  useEffect(() => {
    // Load the tasks for the selected task type (Only if it has changed)
    if (selectedTaskType != undefined) {
      if (isInitialLoad) {
        setIsInitialLoad(false);
        setTasks(initialToDoItems);
        updateDisplayTasks(hasShowAllBeenClicked, initialToDoItems);
        setHasShowAllBeenClicked(false);
      } else {
        setIsLoading(true);

        dashboardApi.getAdvancedToDoListItems(
          selectedTaskTypeId,
          (data: AdvancedToDoListItemDto[]) => {
            setIsLoading(false);
            setTasks(data);
            updateDisplayTasks(hasShowAllBeenClicked, data);
            setHasShowAllBeenClicked(false);
          },
          (error: any) => {
            console.error(error.message);
            setIsLoading(false);
          }
        );
      }
    }
  }, [selectedTaskType]);

  useEffect(() => {
    if (formDetails && selectedTaskType) {
      let currentlyFeaturedInJourney = formDetails.forms.some(
        (form) => form.questions.some(
          (question) => question.questionType === "ADVANCED-TASK-MANAGEMENT"
            && question.taskManagementConfig?.advancedTaskTypeId === selectedTaskType?.id
        )
      );

      // If currently in journey, but the journey has been submitted... unlock it and reload items
      if (currentlyFeaturedInJourney == true && hasJourneyBeenSubmitted) {
        currentlyFeaturedInJourney = false;
        dashboardApi.getAdvancedToDoListItems(
          selectedTaskTypeId,
          (data: AdvancedToDoListItemDto[]) => {
            setIsLoading(false);
            setTasks(data);
            updateDisplayTasks(hasShowAllBeenClicked, data);
            setIsLockedDueToFeaturedJourney(currentlyFeaturedInJourney);
          },
          (error: any) => {
            console.error(error.message);
            setIsLoading(false);
          }
        );
      } else {
        setIsLockedDueToFeaturedJourney(currentlyFeaturedInJourney);
      }
    }
  }, [formDetails, selectedTaskType, hasJourneyBeenSubmitted]);

  // Update the exceeded count when the tasks change
  useEffect(() => {
    if (tasks.length > maxItemsToDisplay) {
      setTaskCountExceedsMaxBy(tasks.length - maxItemsToDisplay);
    } else {
      setTaskCountExceedsMaxBy(0);
    }
  }, [tasks]);

  const handleShowAllAvailableTaskClick = (showAll: boolean) => {
    setHasShowAllBeenClicked(showAll);
    updateDisplayTasks(showAll, tasks);
  };

  const toggleShowAllAvailableTasks = () => {
    handleShowAllAvailableTaskClick(!hasShowAllBeenClicked);
  }

  const onSuccessfulTaskCreation = (userTaskId: string | null) => {
    refreshTaskData(userTaskId, (newItemCount: number) => {
      updateTaskTypeCount(selectedTaskTypeId, newItemCount);
    });
    setIsAddTaskModalOpen(false);
  };

  const handleItemClick = (id: string) => {
    setSelectedUserTaskId(id);
    setIsExistingTaskModalOpen(true);
  }

  const handleExistingModalOpenChange = (isOpen: boolean) => {
    setIsExistingTaskModalOpen(isOpen);
    if (!isOpen) {
      refreshTaskData(null, (newItemCount: number) => {
        setSelectedUserTaskId(null);
        updateTaskTypeCount(selectedTaskTypeId, newItemCount);
      });
    }
  };

  const refreshTaskData = (userTaskId: string | null, onSuccess: (newItemCount: number) => void) => {
    dashboardApi.getAdvancedToDoListItems(
      selectedTaskTypeId,
      (data: AdvancedToDoListItemDto[]) => {
        data = updateDataWithRelevantFlags(userTaskId, data);
        setIsLoading(false);
        setTasks(data);
        updateDisplayTasks(hasShowAllBeenClicked, data);
        onSuccess(data.length);
      },
      (error: any) => {
        console.error(error.message);
        setIsLoading(false);
      }
    );
  }

  const updateDataWithRelevantFlags = (userTaskId: string | null, data: AdvancedToDoListItemDto[]) => {
    let hasBeenAddedFlagSet = false;
    let hasUpdatedFlagBeenSet = false;

    // Attempt to find a matching UserTaskId that was recently added and set the relevant flag
    if (userTaskId != null) {
      hasBeenAddedFlagSet = true;
      data = data.map((x) => {
        if (x.id === userTaskId) {
          x.wasAdded = true;
        }
        return x;
      });
    }

    // Attempt to find a matching SelectedUserTaskId that was recently updated and set the relevant flag
    if (selectedUserTaskId != null) {
      hasUpdatedFlagBeenSet = true;
      data = data.map((x) => {
        if (x.id === selectedUserTaskId) {
          x.wasUpdated = true;
        }
        return x;
      });
    }

    // If either flag was set then create a timeout to revert the flag
    if (hasBeenAddedFlagSet || hasUpdatedFlagBeenSet) {
      setTimeout(() => {
        data = data.map((x) => {
          if (x.id === userTaskId) {
            x.wasAdded = false;
          }
          if (x.id === selectedUserTaskId) {
            x.wasUpdated = false;
          }
          return x;
        });
        updateDisplayTasks(hasShowAllBeenClicked, data);
      }, 2000);
    }
    return data;
  }

  const updateDisplayTasks = (showAll: boolean, items: AdvancedToDoListItemDto[]) => {
    let itemsForDisplay: AdvancedToDoListItemDto[] = items;
    if (!showAll) {
      itemsForDisplay = itemsForDisplay.slice(0, maxItemsToDisplay);
    }
    setDisplayTasks(itemsForDisplay);
  }

  return (
    <>
      <div className="flex flex-col h-full">
        {isLoading && (
          <>
            <LoadingPlaceholderItem />
            <LoadingPlaceholderItem />
            <LoadingPlaceholderItem />
            <LoadingPlaceholderItem />
          </>
        )}

        {/* On TaskType selection (not journey or catch up) if there is a min/max task count set we need to show that restriction */}
        {!isLoading &&
          !isLockedDueToFeaturedJourney &&
          showRestrictionBanner &&
          selectedTaskType &&
          (selectedTaskType.minTaskCount != null ||
            selectedTaskType.maxTaskCount != null) && (
            <ToDoListRestrictionBanner
              selectedTaskType={selectedTaskType}
              hasActiveTasks={tasks.length > 0}
            />
          )}

        {!isLoading &&
          selectedTaskType != undefined &&
          !isLockedDueToFeaturedJourney && (
            <>
              {(selectedTaskType.minTaskCount != null ||
                selectedTaskType.maxTaskCount != null ||
                selectedTaskType.categories?.find(
                  (x) => x.minTaskCount || x.maxTaskCount
                ) != null) && (
                  <div className="mb-2">
                    <TaskRequirementBadge
                      tasks={tasks}
                      minTasks={selectedTaskType.minTaskCount}
                      maxTasks={selectedTaskType.maxTaskCount}
                      countRestriction={selectedTaskType.countRestriction}
                      activeCategories={selectedTaskType.categories}
                      taskType={selectedTaskType}
                      isNonEmployeeViewer={false}
                      origin="OUTSIDE-JOURNEY"
                      fullLengthMode={true}
                    />
                  </div>
                )}
            </>
          )}

        <div>
          {!isLoading && isLockedDueToFeaturedJourney && (
            <ToDoListLockedState
              selectedTaskType={selectedTaskType}
              journeyTitle={formDetails?.title}
            />
          )}
          {!isLoading && !isLockedDueToFeaturedJourney && selectedTaskType && (
            <>
              {displayTasks.length === 0 && (
                <ToDoListEmptyState
                  iconType={taskTypeIconHelper.getTaskTypeIcon(
                    selectedTaskType.iconType
                  )}
                  pluralNameTranslationKeyIdentifier={
                    selectedTaskType.pluralNameTranslationKeyIdentifier
                  }
                />
              )}
              {displayTasks.length > 0 && (
                <ToDoListDisplay
                  items={displayTasks}
                  iconType={taskTypeIconHelper.getTaskTypeIcon(
                    selectedTaskType.iconType
                  )}
                  singularNameTranslationKeyIdentifier={
                    selectedTaskType.singularNameTranslationKeyIdentifier
                  }
                  onItemClick={handleItemClick}
                />
              )}
              {/* Show X more, if the task count exceeds the max */}
              {taskCountExceedsMaxBy > 0 && (
                <div className="text-sm mt-2 cursor-pointer font-normal primary-text text-center">
                  <button onClick={toggleShowAllAvailableTasks} className="hover:underline">
                    <span>{hasShowAllBeenClicked ? t("Common.ShowLessTasks", {
                        tasks: t(selectedTaskType.pluralNameTranslationKeyIdentifier).toLowerCase()
                      }) : t("Common.ShowXMore", {
                        x: taskCountExceedsMaxBy
                      })}</span>
                    <FontAwesomeIcon icon={hasShowAllBeenClicked ? faChevronUp : faChevronDown} className="ml-1" />
                  </button>
                </div>
              )}
              {selectedTaskType && !hideAddNewButton && (
                <button
                  disabled={reachedMaximumTaskLimit}
                  className="btn-primary w-full !mt-2 !px-3 !py-2 disabled:cursor-not-allowed"
                  onClick={() => setIsAddTaskModalOpen(true)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  <span className="ml-2">
                    {t("TaskType.Button.AddNewX").replace(
                      "#TASK_TYPE#",
                      advancedTaskHelper.ToLowerCase(
                        selectedTaskType.singularNameTranslationKeyIdentifier
                      )
                    )}
                  </span>
                </button>
              )}
            </>
          )}
        </div>
      </div>

      {selectedTaskType && (
        <>
          <AddAdvancedTaskPopup
            usageScenario="DASHBOARD-TASKS"
            taskType={selectedTaskType}
            activeTasks={tasks}
            maxOverallTaskRestriction={selectedTaskType.maxTaskCount ?? null}
            categories={selectedTaskType.categories ?? []}
            ownerEmployee={taskSubjectOwner}
            isOpen={isAddTaskModalOpen}
            onOpenChange={setIsAddTaskModalOpen}
            onSuccessfulCreation={onSuccessfulTaskCreation}
          />
          <ExistingAdvancedTaskPopup
            usageScenario="DASHBOARD-TASKS"
            taskType={selectedTaskType}
            userTaskId={selectedUserTaskId}
            categories={selectedTaskType.categories ?? []}
            isOpen={isExistingTaskModalOpen}
            onOpenChange={handleExistingModalOpenChange}
            onSuccessfulUpdate={() => handleExistingModalOpenChange(false)}
            onSuccessfulCancel={() => handleExistingModalOpenChange(false)}
            onSuccessfulClose={() => handleExistingModalOpenChange(false)}
            onSuccessfulReOpen={() => handleExistingModalOpenChange(false)}
            onSuccessfulSavedForLater={() => handleExistingModalOpenChange(false)}
            onCommentCountChanged={() => handleExistingModalOpenChange(false)}
            isLocked={isActivelyInReview}
          />
        </>
      )}
    </>
  );
}

export default AdvancedToDoList;
