import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faPencilAlt,
} from "@fortawesome/pro-regular-svg-icons";
import {
  AdminEditedJourney,
  JourneyOverview,
  JourneyOverviewApiResponse,
} from "../../../types/admin/journeys";
import {
  DropDownMenu,
  GenericDropDownList,
  Label,
  TextArea,
  TextInput,
  Tooltip,
} from "../../common";
import { JourneyConstants } from "../../../config/journeys/JourneyConstants";
import journeyAdminApi from "../../../api/admin/journeyAdminApi";
import { ValidationResult } from "../../../types/forms";
import DangerBanner from "../../common/DangerBanner";
import { KeyValuePair } from "../../../types/generic";

interface EditableJourneyHeaderProps {
  isLoading: boolean;
  journey: JourneyOverview | null;
  onJourneyUpdated: (journey: JourneyOverview) => void;
}

interface JourneyHeaderFormValidationError {
  field: "NAME" | "DESCRIPTION" | "CATEGORY-ID";
  result: ValidationResult;
}

export const EditableJourneyHeader = ({
  isLoading,
  journey,
  onJourneyUpdated,
}: EditableJourneyHeaderProps) => {
  // Constants
  const auth = useAuth();
  const api = new journeyAdminApi(auth.user?.access_token);

  // State
  const [inEditMode, setInEditMode] = useState(false);
  const [nameEditedValue, setNameEditedValue] = useState<string | null>(null);
  const [descriptionEditedValue, setDescriptionEditedValue] = useState<
    string | null
  >(null);
  const [categoryIdEditedValue, setCategoryIdEditedValue] = useState<
    string | null
  >(null);
  const [formErrors, setFormErrors] = useState<
    JourneyHeaderFormValidationError[]
  >([]);
  const [showFormErrors, setShowFormErrors] = useState<boolean>(false);
  const [isCallingApi, setIsCallingApi] = useState(false);
  const [erroredSaving, setErroredSaving] = useState<boolean>(false);

  // Effects
  useEffect(() => {
    setDescriptionEditedValue(journey ? journey.description : null);
    setNameEditedValue(journey ? journey.displayName : null);
    setCategoryIdEditedValue(journey ? journey.categoryId : null);
  }, [journey]);

  // Functions
  const validateForm = (): boolean => {
    // Reset
    setShowFormErrors(false);

    let isValid = true;
    const errors: JourneyHeaderFormValidationError[] = [];

    if (!nameEditedValue || nameEditedValue.trim().length === 0) {
      isValid = false;
      errors.push({
        field: "NAME",
        result: {
          isValid: false,
          errors: [{ errorType: "REQUIRED" }],
        },
      });
    }

    if (!categoryIdEditedValue || categoryIdEditedValue.trim().length === 0) {
      isValid = false;
      errors.push({
        field: "CATEGORY-ID",
        result: {
          isValid: false,
          errors: [{ errorType: "REQUIRED" }],
        },
      });
    }

    setFormErrors(errors);

    if (!isValid) {
      setShowFormErrors(true);
    }

    return isValid;
  };

  // Events
  const onDropMenuChange = () => {
    // If the edit mode is changing from true to false, it's a reset
    if (inEditMode === true) {
      // Reset the edit form
      setNameEditedValue(journey ? journey.displayName : "");
      setDescriptionEditedValue(journey ? journey.description : "");
    }
    setInEditMode(!inEditMode);
  };

  const handleSaveClick = () => {
    if (!journey) return;

    if (!validateForm()) return;

    const updatedJourney: AdminEditedJourney = {
      id: journey.id,
      categoryId: categoryIdEditedValue!,
      displayName: nameEditedValue!,
      description: descriptionEditedValue,
    };

    setIsCallingApi(true);
    api.updateJourney(
      updatedJourney,
      (response: JourneyOverviewApiResponse) => {
        // Reload the journey
        setInEditMode(false);
        setIsCallingApi(false);
        setErroredSaving(false);
        onJourneyUpdated(response.data);
      },
      () => {
        // Error
        setErroredSaving(true);
        setIsCallingApi(false);
      }
    );
  };

  // Render values
  const selectedCategoryName =
    journey?.categoryDropDownOptions &&
    journey.categoryDropDownOptions.length > 0
      ? journey.categoryDropDownOptions.find(
          (o) => o.key === journey.categoryId
        )!.value
      : "No category selected";

  const categoryDropDownItems: KeyValuePair<string, string>[] =
    journey?.categoryDropDownOptions || [];

  return (
    <div className="bg-slate-100 rounded-md px-4 pt-1 pb-4 my-2 border border-gray-200">
      <div className="flex flex-row mb-2 border-b border-dotted border-gray-300">
        <div className="flex-grow">
          <h4 className="font-semibold pb-1">Journey details</h4>
        </div>
        <div className="flex-initial">
          <DropDownMenu
            eventType="EVENT"
            items={[
              {
                text: inEditMode ? "Stop editing details" : "Edit details",
                customArg: "EDIT",
                customIcon: <FontAwesomeIcon icon={faPencilAlt} />,
              },
            ]}
            showMenuEvenIfOnlyOneItem
            onItemClick={onDropMenuChange}
            menuButtonClassName="block w-full lg:inline-block lg:w-auto"
            customButtonContents={
              <>
                <Tooltip
                  content="Options"
                  triggerElement={
                    <button className="block w-6 text-center border border-transparent rounded-md hover:bg-slate-50 hover:border-slate-200">
                      <FontAwesomeIcon icon={faEllipsisVertical} />
                    </button>
                  }
                />
              </>
            }
          />
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
        {(isLoading || !journey) && (
          <>
            <div className="h-10 bg-gray-200 rounded animate-pulse"></div>
            <div className="h-10 bg-gray-200 rounded animate-pulse"></div>
            <div className="h-16 bg-gray-200 rounded animate-pulse"></div>
          </>
        )}
        {!isLoading && journey && (
          <>
            {erroredSaving && (
              <DangerBanner text="There was a problem saving your changes. Please try again" />
            )}
            <div>
              <Label
                text="Name"
                htmlFor="journeyName"
                className="uppercase text-sm text-gray-500"
              />
              {inEditMode ? (
                <TextInput
                  inputId="journeyName"
                  value={nameEditedValue || ""}
                  onChange={setNameEditedValue}
                  placeholder="Required"
                  className="w-full border-gray-400"
                  maxLength={
                    JourneyConstants.Admin.MaxLengths.CategoryDisplayName
                  }
                  showValidationErrors={showFormErrors}
                  validationResult={
                    formErrors &&
                    formErrors.find((e) => e.field === "NAME")?.result
                  }
                />
              ) : (
                <div>
                  <button
                    className="block truncate hover:cursor-pointer"
                    onClick={() => setInEditMode(true)}
                  >
                    {journey.displayName}
                  </button>
                </div>
              )}
            </div>

            <div>
              <Label
                text="Category"
                htmlFor="categoryId"
                className="uppercase text-sm text-gray-500"
              />
              {inEditMode ? (
                <GenericDropDownList
                  inputId="categoryId"
                  items={categoryDropDownItems}
                  currentValue={categoryIdEditedValue || ""}
                  onChange={setCategoryIdEditedValue}
                  className="w-full border-gray-400"
                  applyBorder
                  showValidationErrors={showFormErrors}
                  validationResult={
                    formErrors &&
                    formErrors.find((e) => e.field === "CATEGORY-ID")?.result
                  }
                />
              ) : (
                <div>
                  <button
                    className="block truncate hover:cursor-pointer"
                    onClick={() => setInEditMode(true)}
                  >
                    {selectedCategoryName}
                  </button>
                </div>
              )}
            </div>

            <div>
              <Label
                text="Description"
                htmlFor="journeyDesc"
                className="uppercase text-sm text-gray-500"
              />
              {inEditMode ? (
                <TextArea
                  inputId="journeyDesc"
                  value={descriptionEditedValue || ""}
                  onChange={setDescriptionEditedValue}
                  placeholder="Optional, but recommended"
                  className="w-full rounded-md border-gray-400"
                  minRows={3}
                  maxLength={
                    JourneyConstants.Admin.MaxLengths.JourneyDescription
                  }
                />
              ) : (
                <div>
                  <button
                    className="inline-block max-w-full truncate hover:cursor-pointer"
                    onClick={() => setInEditMode(true)}
                  >
                    {journey.description}
                  </button>
                </div>
              )}
            </div>
            {inEditMode && (
              <div className="lg:col-span-3 text-right">
                <button
                  className="btn-primary disabled:cursor-not-allowed"
                  onClick={handleSaveClick}
                  disabled={isCallingApi}
                >
                  Save
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
