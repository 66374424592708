import { useEffect, useState } from "react";
import { t } from "i18next";
//@ts-ignore
import Pagination from "react-js-pagination";
import { ReactComponent as EmptyStateImage } from "../../../images/noCalendar.svg";
import JourneyCatchUpItem from "./JourneyCatchUpItem";
import { DashboardJourneyTabType } from "../../../types/dtos/dashboards/DashboardJourneyTabType";
import YourJourneyCatchUpDto from "../../../types/dtos/catch-ups/YourJourneyCatchUpDto";

interface JourneyCatchUpTabProps {
  currentUserId: number;
  catchUps: YourJourneyCatchUpDto[];
  selectedTab: DashboardJourneyTabType;
}

export const JourneyCatchUpTab = ({
  currentUserId,
  catchUps,
  selectedTab,
}: JourneyCatchUpTabProps) => {
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalItemCount, setTotalItemCount] = useState<number>(catchUps.length);
  const [itemsOnDisplay, setItemsOnDisplay] = useState<YourJourneyCatchUpDto[]>(
    []
  );

  const isCompletedTab = selectedTab === "COMPLETED";

  useEffect(() => {
    // Set the page number to 1 when the forms change
    const pageNum = 1;
    setPageNumber(pageNum);
    calculateItemsToDisplay(pageNum);
    setTotalItemCount(catchUps.length);
  }, [catchUps]);

  const handlePageNumberChange = (pageNumber: number) => {
    setPageNumber(pageNumber);
    calculateItemsToDisplay(pageNumber);
  };

  const calculateItemsToDisplay = (newPageNumber: number) => {
    var startIndex = (newPageNumber - 1) * itemsPerPage;
    let displayItems = [...catchUps].splice(startIndex, itemsPerPage);
    setItemsOnDisplay(displayItems);
  };

  return (
    <>
      {catchUps.length === 0 && (
        <div className="pt-4 lg:pt-16 text-center text-gray-400">
          <div className="flex justify-center">
            <EmptyStateImage className="max-w-1/2" />
          </div>
          <p className="pt-2 lg:pt-4 text-sm">
            {t("Pages.MyDashboard.CatchUps.EmptyState")}
          </p>
        </div>
      )}

      {catchUps.length > 0 && (
        <>
          {itemsOnDisplay.map((catchUp) => (
            <JourneyCatchUpItem
              key={`form_item_${catchUp.answerSetGuidId}`}
              catchUp={catchUp}
              isCompletedTab={isCompletedTab}
              currentUserId={currentUserId}
            />
          ))}
          {totalItemCount && totalItemCount > itemsPerPage && (
            <div className="grid grid-cols-1 pt-1 md:grid-cols-5 md:text-gray-500">
              <div className="col-span-3">
                {/* Only show the pagination if there is more than one page (aka if the totalItemCount is greater than itemsPerPage) */}
                <div className="mt-2 text-xs">
                  <Pagination
                    activePage={pageNumber}
                    itemsCountPerPage={itemsPerPage}
                    totalItemsCount={totalItemCount}
                    pageRangeDisplayed={5}
                    onChange={handlePageNumberChange}
                    activeClass="pagination-active-list-item"
                    itemClass="pagination-list-item"
                    itemClassFirst="pagination-first-item"
                    itemClassLast="pagination-last-item"
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
