import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouseUser,
  faUserFriends,
  faTools,
  faAnalytics,
  faEllipsisH,
  faTimes,
  faQuestionCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { faThLarge } from "@fortawesome/pro-duotone-svg-icons";
import { useTranslation } from "react-i18next";
import AppRoutes from "../../routes/AppRoutes";
import UserContext from "../../state/UserContext";
import NonPrimaryNavItems from "../../routes/NonPrimaryNavItems";
import { Badge } from "../common";
import { AnalyticsSubNavItems } from "../../routes/analytics";
import ttModulesList from "../../config/modules/ttModulesList";
import { moduleAccessHelper, userDetailsHelper } from "../../helpers";
import { AdminSubNavItems } from "../../routes/admin";
import { TouchNavItem } from "../../state/admin/TouchNavItem";
import adminHelper from "../../helpers/adminHelper";
import YourJourneySubNavItems from "../../routes/your-journey/YourJourneySubNavItems";
import YourPeopleSubNavItems from "../../routes/your-people/YourPeopleSubNavItems";

const TouchNav = () => {
  const [displayFullNav, setDisplayFullNav] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  let displayedNavItems = 1; // MyDashboard always displays

  /** Keeps a count of the currently displayed nav items, and
   * returns true or false depending on whether the number
   * of nav items displayed exceeds the maximum number allowed
   */
  const canDisplayNavItem = (boolValueToCheck: boolean | null) => {
    const maxNavItems = 4;
    const anotherNavItemWouldNotExceedMax =
      displayedNavItems + 1 <= maxNavItems;

    if (boolValueToCheck === null) {
      return anotherNavItemWouldNotExceedMax;
    }

    if (boolValueToCheck && anotherNavItemWouldNotExceedMax) {
      displayedNavItems += 1;
      return true;
    }
    return false;
  };

  const navigateAndCloseFullNav = (url: string) => {
    setDisplayFullNav(false);
    navigate(url);
  };

  const isInExitMode = userContext.user.myDashboardMode === "EXIT";

  const shouldShowAnalyticsNavItem =
    userContext.user.hasAnalyticsAccess || userContext.user.isManager;

  const shouldShowCompanyDashboardNavItem = moduleAccessHelper.userHasModule(
    userContext.user,
    ttModulesList.CompanyDash
  );

  const shouldShowClassicAnalyticsNavItem =
    shouldShowAnalyticsNavItem &&
    moduleAccessHelper.userHasModule(userContext.user, ttModulesList.Reviews);

  const analyticsSubNavItems = AnalyticsSubNavItems(
    userContext.user.analyticsNavItems,
    shouldShowClassicAnalyticsNavItem,
    "TOUCH"
  );

  const adminSubNavItems = AdminSubNavItems("TOUCH");
  const showAdminNavForManagersThatAdminTheirPeople =
    adminHelper.doesUserHaveAdminModuleAccess(userContext, "USER-JOURNEY");

  let shouldShowAdminNavItem =
    userContext.user.isAdmin || showAdminNavForManagersThatAdminTheirPeople;

  const yourJourneySubNavItems = YourJourneySubNavItems(userContext.user);
  const yourPeopleSubNavItems = YourPeopleSubNavItems(userContext.user);

  const managerBadgeNumber = userDetailsHelper.getManagerUpdateCount(
    userContext.user
  );

  const myDashboardBadgeNumber = userDetailsHelper.getOwnUpdateCount(
    userContext.user
  );

  let shouldShowYourPeopleSubNavItems =
    userContext.user.isManager || userContext.user.isJourneyManager;

  let otherNavItems: TouchNavItem[] = [
    {
      text: "Nav.Touch.Help",
      href: AppRoutes.help,
    },
  ];

  if (yourJourneySubNavItems.length > 0) {
    otherNavItems = otherNavItems.concat(
      yourJourneySubNavItems.map((x) => {
        return { prefixText: "Your Journey", text: x.title, href: x.url };
      })
    );
  }

  if (
    shouldShowYourPeopleSubNavItems &&
    userContext.user.client.taskTypes &&
    userContext.user.client.taskTypes.length > 0
  ) {
    otherNavItems = otherNavItems.concat(
      yourPeopleSubNavItems.map((x) => {
        return { prefixText: "Team", text: x.title, href: x.url };
      })
    );
  }

  if (shouldShowAdminNavItem) {
    otherNavItems = otherNavItems.concat(
      adminSubNavItems.map((x) => {
        return { prefixText: "Admin", text: x.title, href: x.url };
      })
    );
  }

  if (shouldShowAnalyticsNavItem) {
    otherNavItems = otherNavItems.concat(
      analyticsSubNavItems.map((x) => {
        return { prefixText: "Analytics", text: x.title, href: x.url };
      })
    );
  }

  otherNavItems = otherNavItems.concat(NonPrimaryNavItems);

  return (
    <>
      <div className="flex flex-row items-stretch justify-around border-t border-gray-300 pb-2">
        <div className="touch-nav-item">
          <Link to={AppRoutes.yourJourney.root}>
            <FontAwesomeIcon icon={faHouseUser} size="lg" />
            <span>{t("Nav.Touch.MyDashboard")}</span>
            {myDashboardBadgeNumber > 0 && (
              <Badge
                text={myDashboardBadgeNumber.toString()}
                backgroundColourClassName="accent-bg"
                textColourClassName="text-white"
                marginClassName="ml-2"
              />
            )}
          </Link>
        </div>
        <>
          {canDisplayNavItem(
            userContext.user.isManager || userContext.user.isJourneyManager
          ) && (
            <div className="touch-nav-item with-badge">
              <Link to={AppRoutes.yourPeople.root}>
                <FontAwesomeIcon icon={faUserFriends} size="lg" />
                <span>{t("Nav.Touch.ManagerDashboard")}</span>
                {managerBadgeNumber > 0 && (
                  <Badge
                    text={managerBadgeNumber.toString()}
                    backgroundColourClassName="accent-bg"
                    textColourClassName="text-white"
                    marginClassName="ml-2"
                  />
                )}
              </Link>
            </div>
          )}
          {canDisplayNavItem(shouldShowCompanyDashboardNavItem) && (
            <div className="touch-nav-item">
              <Link to={AppRoutes.companyDashboard}>
                <FontAwesomeIcon icon={faThLarge} size="lg" />
                <span>{t("Nav.Touch.CompanyDashboard")}</span>
              </Link>
            </div>
          )}

          {!isInExitMode && canDisplayNavItem(shouldShowAdminNavItem) && (
            <div className="touch-nav-item">
              <Link to={AppRoutes.admin.clientJourneys.path}>
                <FontAwesomeIcon icon={faTools} size="lg" />
                <span>{t("Nav.Touch.Admin.Root")}</span>
              </Link>
            </div>
          )}
          {canDisplayNavItem(shouldShowAnalyticsNavItem) && (
            <div className="touch-nav-item">
              <Link to={AppRoutes.analytics.usage}>
                <FontAwesomeIcon icon={faAnalytics} size="lg" />
                <span>{t("Nav.Touch.Analytics.Root")}</span>
              </Link>
            </div>
          )}
        </>
        {canDisplayNavItem(null) && (
          <div className="touch-nav-item">
            <a href={AppRoutes.help} target="_blank">
              <FontAwesomeIcon icon={faQuestionCircle} size="lg" />
              <span>{t("Nav.Touch.Help")}</span>
            </a>
          </div>
        )}
        <div className="touch-nav-item">
          <button onClick={() => setDisplayFullNav(true)}>
            <FontAwesomeIcon icon={faEllipsisH} size="lg" />
            <span>{t("Nav.Touch.ExpandNav")}</span>
          </button>
        </div>
      </div>
      {displayFullNav && (
        <div className="h-screen w-screen fixed top-0 left-0 bg-white">
          <div className="flex flex-col h-full">
            <div className="flex-none h-12">
              <div className="relative">
                <button
                  className="absolute top-3 right-3 modal-close-icon"
                  onClick={() => setDisplayFullNav(false)}
                >
                  <FontAwesomeIcon icon={faTimes} size="2x" />
                </button>
              </div>
            </div>
            <div className="flex-grow text-center overflow-y-auto">
              <div className="h-full w-full flex flex-col justify-center items-center">
                {otherNavItems.map((otherNavItem) => (
                  <div key={otherNavItem.text}>
                    <button
                      onClick={() => navigateAndCloseFullNav(otherNavItem.href)}
                    >
                      <span>
                        {otherNavItem.prefixText != undefined
                          ? otherNavItem.prefixText +
                            ": " +
                            t(otherNavItem.text)
                          : t(otherNavItem.text)}
                      </span>
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex-none h-20 bg-white">
              <div className="flex flex-cols h-full items-end">
                <div className="flex-1 text-center bg-gray-100/60 h-16">
                  <div className="h-full w-full flex flex-col justify-center items-center">
                    &copy; {userContext.user.client.productName}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TouchNav;
