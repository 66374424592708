import { useTranslation } from "react-i18next";
import cx from "classnames";
import { useState } from "react";
import { useAuth } from "react-oidc-context";
import { UserContextInterface } from "../../../state/UserContext";
import Tabs, { TabDetails } from "../../common/Tabs";
import { JourneyFormTab } from "./JourneyFormTab";
import { DashboardJourneyTabType } from "../../../types/dtos/dashboards/DashboardJourneyTabType";
import { useNavigate } from "react-router-dom";
import AppRoutes from "../../../routes/AppRoutes";
import { JourneyFormButtonArg } from "../../../types/dashboard/JourneyFormDropDownItems";
import UserJourneyDetailDto from "../../../types/dtos/shared/UserJourneyDetailDto";
import myDashboardApi from "../../../api/dashboard/myDashboardApi";
import managerDashboardApi from "../../../api/dashboard/managerDashboardApi";
import dualPrepJourneyHelper from "../../../helpers/dualPrepJourneyHelper";
import YourPeopleFormSectionUserDto from "../../../types/dtos/dashboards/YourPeopleFormSectionUserDto";

interface JourneyFormSectionProps {
  userContext: UserContextInterface;
  activeForms: UserJourneyDetailDto[];
  completedForms: UserJourneyDetailDto[];
  isYourPeoplePage: boolean;
  searchComponent?: React.ReactNode | undefined;
  // Properties only set from places IsYourPeoplePage is true
  managedUser?: YourPeopleFormSectionUserDto | null;
}

const JourneyFormSection = ({
  userContext,
  activeForms,
  completedForms,
  isYourPeoplePage,
  managedUser,
  searchComponent = undefined,
}: JourneyFormSectionProps) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const navigate = useNavigate();
  const dashboardApi = new myDashboardApi(auth.user?.access_token);
  const manDashboardApi = new managerDashboardApi(auth.user?.access_token);
  const clientColour = userContext.user.client.primaryHexColour;

  const [activeTab, setActiveTab] = useState<DashboardJourneyTabType>("ACTIVE");

  const onActiveTabChange = (tabName: string) => {
    let tab: DashboardJourneyTabType = "ACTIVE";
    switch (tabName) {
      case t("Common.Active"):
        tab = "ACTIVE";
        break;
      case t("Common.Completed"):
        tab = "COMPLETED";
        break;
    }
    setActiveTab(tab);
  };

  const handleUpdateClick = (form: UserJourneyDetailDto) => {
    const goToDashboard =
      form.latestAnswerSet?.guidId == null ||
      form.latestAnswerSet?.statusEnum === "PARTIALLY-SAVED-JOURNEY";
    if (goToDashboard && !isYourPeoplePage) {
      setCurrentJourneyAndRedirectToDashboard(form.journeyReference);
    } else {
      navigateToCollabDoc(form.latestAnswerSet.guidId);
    }
  };

  const handleViewClick = (form: UserJourneyDetailDto) => {
    navigateToCollabDoc(form.latestAnswerSet?.guidId);
  };

  const handleDualPrepItemClick = (
    form: UserJourneyDetailDto,
    arg: JourneyFormButtonArg
  ) => {
    switch (arg) {
      case "COMPLETE-EMP-PLANNING":
        setCurrentJourneyAndRedirectToDashboard(form.journeyReference);
        break;
      case "COMPLETE-MAN-PLANNING":
        // If no managedUser is provided, do nothing
        if (!managedUser) {
          return;
        }

        // Check whether the manager planning exists
        const doesManPlanningExist = form.answerSets.some(
          (x) => x.isDualPrepOtherAnswerSet
        );
        if (!doesManPlanningExist) {
          // If it doesn't exist then we need to create it and then navigate to it
          const dualPrepHelper = new dualPrepJourneyHelper(
            manDashboardApi,
            userContext
          );
          dualPrepHelper.openNewCollabDocByJourneyRef(
            managedUser.employeeId,
            form.journeyReference,
            (destinationUrl: string) => {
              navigate(destinationUrl);
            }
          );
        } else {
          // Navigate to the manager planning document
          const manPlanning = form.answerSets.find(
            (x) => x.isDualPrepOtherAnswerSet
          );
          navigateToCollabDoc(manPlanning?.guidId);
        }
        break;
      case "VIEW-EMP-PLANNING":
        const empPlanning = form.answerSets.find(
          (x) => x.isDualPrepSubjectAnswerSet
        );
        navigateToCollabDoc(empPlanning?.guidId);
        break;
      case "VIEW-MAN-PLANNING":
        const manPlanning = form.answerSets.find(
          (x) => x.isDualPrepOtherAnswerSet
        );
        navigateToCollabDoc(manPlanning?.guidId);
        break;
      case "UPDATE-MEETING":
      case "VIEW-MEETING":
        const meetingDoc = form.answerSets.find(
          (x) => x.isDualPrepMeetingDocAnswerSet
        );
        navigateToCollabDoc(meetingDoc?.guidId);
        break;
    }
  };

  const navigateToCollabDoc = (guidId?: string) => {
    if (!guidId) return null;

    const redirectUrl = AppRoutes.collaborativeDocument.generateRoute(
      guidId,
      false,
      null
    );
    navigate(redirectUrl);
  };

  const setCurrentJourneyAndRedirectToDashboard = (journeyRef: string) => {
    dashboardApi.assignDifferentJourneyToUser(
      null,
      journeyRef,
      (data) => {
        navigate(AppRoutes.yourJourney.root);
      },
      (error) => {
        console.error("Error setting current journey", error);
      }
    );
  };

  let tabs: Array<TabDetails> = [
    {
      title: t("Common.Active"),
      content: (
        <JourneyFormTab
          userContext={userContext}
          forms={activeForms}
          selectedTab={activeTab}
          isYourPeoplePage={isYourPeoplePage}
          onUpdateClick={handleUpdateClick}
          onViewClick={handleViewClick}
          onDualPrepItemClick={handleDualPrepItemClick}
        />
      ),
      displayAlertIcon: false,
      itemCount: activeForms.length,
      itemCountDisplayMode: "BRACKETS",
    },
    {
      title: t("Common.Completed"),
      content: (
        <JourneyFormTab
          userContext={userContext}
          forms={completedForms}
          selectedTab={activeTab}
          isYourPeoplePage={isYourPeoplePage}
          onUpdateClick={handleUpdateClick}
          onViewClick={handleViewClick}
          onDualPrepItemClick={handleDualPrepItemClick}
        />
      ),
      displayAlertIcon: false,
      itemCount: completedForms.length,
      itemCountDisplayMode: "BRACKETS",
    },
  ];

  return (
    <div>
      <Tabs
        tabs={tabs}
        selectedTabClassNames={cx(
          "radix-state-inactive:border-0 radix-state-inactive:hover:border-b-2 radix-state-inactive:hover:!border-b-gray-200",
          "radix-state-active:border-b-2",
          clientColour
            ? `radix-state-active:border-b-[#${clientColour}]`
            : "radix-state-active:border-b-gray-400",
          clientColour ? `radix-state-active:text-[#${clientColour}]` : ""
        )}
        maxTabsOnSmallScreens={3}
        contextClassNames="!py-0"
        tabListWidth="w-1/3"
        onActiveTabChangeEvent={onActiveTabChange}
        customComponentOnRightOfTabs={searchComponent}
      />
    </div>
  );
};

export default JourneyFormSection;
