import { t } from "i18next";
import {
  CheckBox,
  GenericDropDownList,
  InfoBanner,
  ModalPopup,
} from "../../../common";
import { UserDetailDropdownOptionAction } from "../../../../types/admin/client-journeys/UserDetailDropdownOptionAction";
import { KeyValuePair } from "../../../../types/generic";
import DangerBanner from "../../../common/DangerBanner";
import SmallLoader from "../../../loaders/SmallLoader";
import React, { useState } from "react";
import UserContext from "../../../../state/UserContext";
import { ClientSentJourneyApprovalFlow } from "../../../../types/admin/client-journeys/ClientSentJourneyApprovalFlow";

interface UserDetailJourneyOptionPopupProps {
  isOpen: boolean;
  option: UserDetailDropdownOptionAction;
  titleText: string;
  confirmButtonText: string;
  cancelButtonText: string;
  onOpenChange(open: boolean): void;
  onConfirmButtonClick(): void;
  onCancelButtonClick(): void;
  selectedViewDropdownOption?: KeyValuePair<string, string> | null;
  viewDropdownOptions?: KeyValuePair<string, string>[] | null;
  onViewOptionDropdownChange?(
    selectedValue: KeyValuePair<string, string>
  ): void;
  userName?: string | null;
  managerRoleName?: string | null;
  journeyName?: string | null;
  isJourneyDualPrep: boolean | null;
  selectedSendReminderDropdownOption: KeyValuePair<string, string> | null;
  sendReminderDropdownOptions: KeyValuePair<string, string>[] | null;
  onSendReminderOptionDropdownChange?(
    selectedValue: KeyValuePair<string, string>
  ): void;
  reopenShowMeetingFormWarning?: boolean | null;
  selectedReopenDropdownOption: KeyValuePair<string, string> | null;
  reopenDropdownOptions: KeyValuePair<string, string>[] | null;
  onReopenOptionDropdownChange?(
    selectedValue: KeyValuePair<string, string>
  ): void;
  currentJourneyReference?: string | null;
  currentJourneyName?: string | null;
  isProcessingSendJourneyRequest?: boolean;
  enforceUserConfirmation: boolean;
  approvalFlow: ClientSentJourneyApprovalFlow | null;
}

export const UserDetailJourneyOptionPopup = ({
  isOpen,
  option,
  titleText,
  confirmButtonText,
  cancelButtonText,
  onOpenChange,
  onConfirmButtonClick,
  onCancelButtonClick,
  selectedViewDropdownOption,
  viewDropdownOptions,
  onViewOptionDropdownChange,
  userName,
  managerRoleName,
  journeyName,
  isJourneyDualPrep,
  selectedSendReminderDropdownOption,
  sendReminderDropdownOptions,
  onSendReminderOptionDropdownChange,
  reopenShowMeetingFormWarning,
  selectedReopenDropdownOption,
  reopenDropdownOptions,
  onReopenOptionDropdownChange,
  currentJourneyReference = undefined,
  currentJourneyName = undefined,
  isProcessingSendJourneyRequest = false,
  enforceUserConfirmation,
  approvalFlow,
}: UserDetailJourneyOptionPopupProps) => {
  const userContext = React.useContext(UserContext);

  // If user confirmation is enforced and it's not ticked then disable the confirm button
  const [isProceedTicked, setIsProceedTicked] = useState<boolean>(false);
  let isPrimaryButtonDisabled = false;
  if (enforceUserConfirmation && !isProceedTicked) {
    isPrimaryButtonDisabled = true;
  }

  // There is a scenario with 'Reopen' in dual prep where there is potentially no answer sets completed yet
  // (e.g. when manager starts prep before employee and doesn't complete it). Therefore in this scenario we
  // need to hit the confirm button and flag this to the user.
  const isReopenOptionWithNoCompletedAnswerSets =
    option == "REOPEN" &&
    isJourneyDualPrep &&
    reopenDropdownOptions &&
    reopenDropdownOptions.length == 0;

  let primaryButtonText = isReopenOptionWithNoCompletedAnswerSets
    ? undefined
    : t(confirmButtonText);
  let secondaryButtonText: string | undefined = t(cancelButtonText);

  // If we are processing then hide the buttons
  if (isProcessingSendJourneyRequest) {
    primaryButtonText = undefined;
    secondaryButtonText = undefined;
  }

  const handleSendReminderOptionDropdownChange = (newValue: string) => {
    if (onSendReminderOptionDropdownChange && sendReminderDropdownOptions) {
      const selectedValue = sendReminderDropdownOptions.find(
        (item) => item.key == newValue
      );
      onSendReminderOptionDropdownChange(selectedValue!);
    }
  };

  const handleViewOptionDropdownChange = (newValue: string) => {
    if (onViewOptionDropdownChange && viewDropdownOptions) {
      const selectedValue = viewDropdownOptions.find(
        (item) => item.key == newValue
      );
      onViewOptionDropdownChange(selectedValue!);
    }
  };

  const handleReopenOptionDropdownChange = (newValue: string) => {
    if (onReopenOptionDropdownChange && reopenDropdownOptions) {
      const selectedValue = reopenDropdownOptions.find(
        (item) => item.key == newValue
      );
      onReopenOptionDropdownChange(selectedValue!);
      setIsProceedTicked(false);
    }
  };

  const sendReminderInfo = (
    <div>
      <p>
        {t("Pages.Admin.Popups.SendReminderInfo1")}{" "}
        {selectedSendReminderDropdownOption?.key != "BOTH" && (
          <strong>{selectedSendReminderDropdownOption?.value}</strong>
        )}
        {selectedSendReminderDropdownOption?.key == "BOTH" && (
          <span>
            <strong>{userName}</strong> {t("Common.And_LowerCase")}{" "}
            <strong>{managerRoleName}</strong>
          </span>
        )}{" "}
        {t("Pages.Admin.Popups.SendReminderInfo2")}:{" "}
        <strong>{journeyName}</strong>
      </p>
    </div>
  );

  const reopenJourneyWarningMessage = (
    <div className="pl-2">
      <p>{t("Pages.Admin.Popups.ReopenJourneyWarning")}</p>
      <div className="mt-2">
        <CheckBox
          labelText={t("Pages.Admin.Common.AreYouSureYouWantToProceed")}
          onChange={() => setIsProceedTicked(!isProceedTicked)}
          selected={isProceedTicked}
          labelClassNames="text-sm pt-1"
          checkboxClassNames="bg-white border border-rose-200"
          checkboxPosition="LEFT"
          includeCheckboxContainerClass={false}
          controlId="reopenJourneyCheckbox"
        />
        <p className="text-xs italic">
          ({t("Pages.Admin.Common.PleaseTickAbove")})
        </p>
      </div>
    </div>
  );

  const reopenJourneyInfoMessage = (
    <div>
      <p>{t("Pages.Admin.Popups.ReopenJourneyInfo")}</p>
      <div className="mt-2">
        <CheckBox
          labelText={t("Pages.Admin.Common.AreYouSureYouWantToProceed")}
          onChange={() => setIsProceedTicked(!isProceedTicked)}
          selected={isProceedTicked}
          labelClassNames="text-sm pt-1"
          checkboxClassNames="bg-white border border-blue-200"
          checkboxPosition="LEFT"
          includeCheckboxContainerClass={false}
        />
        <p className="text-xs italic">
          ({t("Pages.Admin.Common.PleaseTickAbove")})
        </p>
      </div>
    </div>
  );

  const reopenJourneyNoCompletedFormsMessage = (
    <div>
      <p>{t("Pages.Admin.Popups.ReopenJourneyNoCompletedForms")}</p>
    </div>
  );

  const cancelJourneyInfo = (
    <div>
      <p>{t("Pages.Admin.Popups.CancelJourneyInfo")}</p>
      <div className="mt-2">
        <CheckBox
          labelText={t("Pages.Admin.Common.AreYouSureYouWantToProceed")}
          onChange={() => setIsProceedTicked(!isProceedTicked)}
          selected={isProceedTicked}
          labelClassNames="text-sm pt-1"
          checkboxClassNames="bg-white border border-rose-200"
          checkboxPosition="LEFT"
          includeCheckboxContainerClass={false}
          controlId="cancelJourneyCheckbox"
        />
        <p className="text-xs italic">
          ({t("Pages.Admin.Common.PleaseTickAbove")})
        </p>
      </div>
    </div>
  );

  const onOpenChangeHandler = (open: boolean) => {
    setIsProceedTicked(false);
    onOpenChange(open);
  };

  const onConfirmButtonClickHandler = () => {
    setIsProceedTicked(false);
    onConfirmButtonClick();
  };

  const onCancelButtonClickHandler = () => {
    setIsProceedTicked(false);
    onCancelButtonClick();
  };

  return (
    <ModalPopup
      isOpen={isOpen}
      onOpenChange={onOpenChangeHandler}
      onPrimaryButtonClick={onConfirmButtonClickHandler}
      onSecondaryButtonClick={onCancelButtonClickHandler}
      primaryButtonText={primaryButtonText}
      secondaryButtonText={secondaryButtonText}
      title={t(titleText)}
      isPrimaryButtonDisabled={isPrimaryButtonDisabled}
      preventInitialAutoFocus={true}
      width="SMALL"
    >
      {isProcessingSendJourneyRequest && (
        <div className="text-center pt-4">
          <SmallLoader colour={userContext.user.client.accentHexColour} />
          <p className="mt-4 mb-2">
            {t("Pages.Admin.Common.PleaseWaitProcessingRequest")}...
          </p>
        </div>
      )}
      {!isProcessingSendJourneyRequest && (
        <>
          {option == "SEND-REMINDER" &&
            userName &&
            journeyName &&
            sendReminderDropdownOptions &&
            selectedSendReminderDropdownOption && (
              <>
                <p>{t("Pages.Admin.Popups.Content.SendReminder")}</p>
                <GenericDropDownList
                  className="w-full bg-gray-100 border-0 text-sm py-1"
                  items={sendReminderDropdownOptions}
                  currentValue={selectedSendReminderDropdownOption.key}
                  includeSelectOption={false}
                  onChange={handleSendReminderOptionDropdownChange}
                />
                <InfoBanner children={sendReminderInfo} />
              </>
            )}
          {option == "VIEW" &&
            viewDropdownOptions &&
            selectedViewDropdownOption && (
              <>
                <p>{t("Pages.Admin.Popups.Content.ViewAnswers")}</p>
                <GenericDropDownList
                  className="w-full bg-gray-100 border-0 text-sm py-1"
                  items={viewDropdownOptions}
                  currentValue={selectedViewDropdownOption.key}
                  includeSelectOption={false}
                  onChange={handleViewOptionDropdownChange}
                />
              </>
            )}
          {option == "REOPEN" && userName && journeyName && (
            <>
              <p>
                {t("Pages.Admin.Popups.Content.ReopenJourney1")}{" "}
                <strong>{journeyName}</strong>{" "}
                {t("Pages.Admin.Popups.Content.ReopenJourney2")}{" "}
                <strong>{userName}</strong>.
              </p>
              {isJourneyDualPrep &&
                reopenDropdownOptions &&
                selectedReopenDropdownOption && (
                  <>
                    <p className="mt-2">
                      {t("Pages.Admin.Popups.Content.ReopenJourney3")}
                    </p>
                    <GenericDropDownList
                      className="w-full bg-gray-100 border-0 text-sm py-1"
                      items={reopenDropdownOptions}
                      currentValue={selectedReopenDropdownOption.key}
                      includeSelectOption={false}
                      onChange={handleReopenOptionDropdownChange}
                    />
                    <span className="text-sm">
                      <span className="font-bold">
                        {t("Pages.Admin.Common.Note")}:{" "}
                      </span>
                      {t(
                        "Pages.Admin.Popups.Content.ReopenPreviousAnswersWillStay"
                      )}
                    </span>
                  </>
                )}
              {isReopenOptionWithNoCompletedAnswerSets && (
                <InfoBanner children={reopenJourneyNoCompletedFormsMessage} />
              )}
              {!isJourneyDualPrep && (
                <InfoBanner children={reopenJourneyInfoMessage} />
              )}
              {reopenShowMeetingFormWarning && (
                <DangerBanner children={reopenJourneyWarningMessage} />
              )}
            </>
          )}
          {option == "CANCEL" && userName && journeyName && (
            <>
              <p>
                {t("Pages.Admin.Popups.Content.CancelJourney1")}{" "}
                <strong>{journeyName}</strong>{" "}
                {t("Pages.Admin.Popups.Content.CancelJourney2")}{" "}
                <strong>{userName}</strong>.
              </p>
              <DangerBanner children={cancelJourneyInfo} />
            </>
          )}

          {option == "SEND-JOURNEY" && journeyName && userName && (
            <>
              <p>
                {t("Pages.Admin.Popups.Content.SendJourney1")}{" "}
                <strong>{journeyName}</strong>{" "}
                {t("Pages.Admin.Popups.Content.SendJourney2")}{" "}
                <strong>{userName}</strong>.
              </p>
              <p>{t("Pages.Admin.Popups.Content.SendJourney3")}</p>
            </>
          )}
        </>
      )}
    </ModalPopup>
  );
};

export default UserDetailJourneyOptionPopup;
