import { t } from "i18next";
import { ModalPopup } from "../common";
import SuccessAlert from "../alerts/SuccessAlert";

interface ManagerUnableToUpdatePopupProps {
  isOpen: boolean;
  subjectEmployeeName: string;
  subjectEmployeeHasLockedJourney: boolean;
  hasClickedSendReminder: boolean;
  isJourneyPrefillAnswerEnabled: boolean;
  onOpenChange(open: boolean): void;
  onSendReminderClick(): void;
  onStartYourselfClick(): void;
  onCancelClick(): void;
}

/** Gets the correct translated translation key identifier based on the scenario */
const getPopupText = (
  suffix: string,
  isJourneyPrefillAnswerEnabled: boolean,
  subjectEmployeeHasLockedJourney: boolean
) => {
  const prefix = "Pages.ManagerDashboard.Popup.";
  if (subjectEmployeeHasLockedJourney) {
    return t(prefix + "UserJourneyIsLocked." + suffix);
  } else if (!isJourneyPrefillAnswerEnabled) {
    return t(prefix + "ManagerUnableToUpdate." + suffix);
  } else {
    return t(prefix + "LiveJourney." + suffix);
  }
};

export const ManagerUnableToUpdatePopup = ({
  isOpen,
  subjectEmployeeName,
  subjectEmployeeHasLockedJourney,
  hasClickedSendReminder = false,
  isJourneyPrefillAnswerEnabled = false,
  onOpenChange,
  onSendReminderClick,
  onStartYourselfClick,
  onCancelClick,
}: ManagerUnableToUpdatePopupProps) => {
  const success = t("Reminder.TriggedByManager.Successful").replaceAll(
    "#SUBJECT_EMPLOYEE_NAME#",
    subjectEmployeeName
  );
  const titleText = getPopupText(
    "Title",
    isJourneyPrefillAnswerEnabled,
    subjectEmployeeHasLockedJourney
  ).replaceAll("#SUBJECT_EMPLOYEE_NAME#", subjectEmployeeName);
  const bodyText = getPopupText(
    "Body",
    isJourneyPrefillAnswerEnabled,
    subjectEmployeeHasLockedJourney
  ).replaceAll("#SUBJECT_EMPLOYEE_NAME#", subjectEmployeeName);

  const primaryButtonText = getPopupText(
    "SendReminderButton",
    isJourneyPrefillAnswerEnabled,
    subjectEmployeeHasLockedJourney
  );

  const allowStartThisAsManager =
    isJourneyPrefillAnswerEnabled && !subjectEmployeeHasLockedJourney;

  const secondaryButtonText = allowStartThisAsManager
    ? t("Pages.ManagerDashboard.Popup.LiveJourney.StartThisYourself")
    : t("Close");
  const secondaryButtonClick = allowStartThisAsManager
    ? onStartYourselfClick
    : onCancelClick;

  return (
    <ModalPopup
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      onPrimaryButtonClick={onSendReminderClick}
      onSecondaryButtonClick={secondaryButtonClick}
      isPrimaryButtonDisabled={hasClickedSendReminder}
      primaryButtonText={primaryButtonText}
      secondaryButtonText={secondaryButtonText}
      title={titleText}
      width="SMALL"
    >
      {hasClickedSendReminder && (
        <SuccessAlert prefix={t("Common.Success")} message={success} />
      )}
      <p>{bodyText}</p>
    </ModalPopup>
  );
};

export default ManagerUnableToUpdatePopup;
