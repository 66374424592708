import { useContext, useEffect, useState } from "react";
import UserContext from "../../../state/UserContext";
import AdvancedToDoList from "./advanced-to-do-list/AdvancedToDoList";
import { JourneyFormGroupDto } from "../../../types/dtos/journeys";
import DashboardRightBarDto from "../../../types/dtos/dashboards/right-bar/DashboardRightBarDto";
import TaskTypeSelector from "./TaskTypeSelector";
import ToDoListTaskTypeDto from "../../../types/dtos/dashboards/right-bar/to-do-list/ToDoListTaskTypeDto";
import StandardToDoList from "./standard-to-do-list/StandardToDoList";
import ModifyTaskResponseDto from "../../../types/dtos/tasks/ModifyTaskResponseDto";
import { t } from "i18next";
import DashboardCatchUps from "./catch-ups/DashboardCatchUps";

interface DashboardRightBarProps {
  details: DashboardRightBarDto;
  formDetails: JourneyFormGroupDto | undefined | null;
  hasJourneyBeenSubmitted: boolean;
  modifyStandardTaskFromDashboard(
    task: any,
    onSuccess: (taskData: ModifyTaskResponseDto<number>) => void
  ): void;
  onLoadStandardTaskToEdit(
    taskId: number,
    taskType: string,
    successCallback: (responseData: any) => void
  ): void;
}

function DashboardRightBar({
  details,
  formDetails,
  hasJourneyBeenSubmitted,
  modifyStandardTaskFromDashboard,
  onLoadStandardTaskToEdit,
}: DashboardRightBarProps) {
  const userContext = useContext(UserContext);

  const [selectedTaskTypeId, setSelectedTaskTypeId] = useState<
    string | number
  >();
  const [toDoListTaskTypes, setToDoListTaskTypes] = useState<
    ToDoListTaskTypeDto[]
  >(details.toDoListTaskTypes);

  // Set the selected task type id to the first task type id in the list
  useEffect(() => {
    if (details.toDoListTaskTypes.some((x) => x.standardTaskTypeId != null)) {
      // @ts-ignore
      setSelectedTaskTypeId(details.toDoListTaskTypes[0].standardTaskTypeId);
    } else {
      // @ts-ignore
      setSelectedTaskTypeId(details.toDoListTaskTypes[0].advancedTaskTypeId);
    }
  }, []);

  const onSelectedTaskTypeIdChange = (
    standardTaskTypeId?: number | null,
    advancedTaskTypeId?: string | null
  ) => {
    if (standardTaskTypeId != null) {
      setSelectedTaskTypeId(standardTaskTypeId);
    } else if (advancedTaskTypeId != null) {
      setSelectedTaskTypeId(advancedTaskTypeId);
    }
  };

  const updateTaskTypeCount = (taskTypeId: string | number, count: number) => {
    setToDoListTaskTypes((prev) =>
      prev.map((taskType) => {
        if (
          taskType.standardTaskTypeId === taskTypeId ||
          taskType.advancedTaskTypeId === taskTypeId
        ) {
          return { ...taskType, count };
        }
        return taskType;
      })
    );
  };

  const isValidGUID = (val: string) => {
    const regex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return regex.test(val);
  };

  return (
    <div
      id="scrollable-dashboard-right-bar"
      className="snap-y overflow-y-auto overflow-x-hidden grow ml-1"
    >
      <div id="to-do-list" className="bg-white rounded-lg p-4">
        <header className="text-lg font-semibold mt-0 mb-2">
          {t("DashboardRightBar.YourToDoList")}
        </header>
        {/* Task Type Selection */}
        {selectedTaskTypeId && (
          <TaskTypeSelector
            toDoListTaskTypes={toDoListTaskTypes}
            selectedTaskTypeId={selectedTaskTypeId}
            showCount={true}
            onSelectedTaskTypeIdChange={onSelectedTaskTypeIdChange}
          />
        )}

        {/* To Do List */}
        <div className="pt-2">
          {/* Standard Tasks */}
          {!userContext.user.client.usesTaskTypes && (
            <StandardToDoList
              initialToDoItems={details.standardListItems}
              toDoListTaskTypes={toDoListTaskTypes}
              // @ts-ignore
              selectedTaskTypeId={selectedTaskTypeId}
              updateTaskTypeCount={updateTaskTypeCount}
              modifyTaskFromDashboard={modifyStandardTaskFromDashboard}
              onLoadTaskToEdit={onLoadStandardTaskToEdit}
            />
          )}
          {/* Advanced Tasks */}
          {userContext.user.client.usesTaskTypes && (
            <>
              {/* @ts-ignore */}
              {isValidGUID(selectedTaskTypeId) && (
                <AdvancedToDoList
                  userContext={userContext}
                  initialToDoItems={details.advancedListItems}
                  taskTypes={userContext.user.client.taskTypes}
                  toDoListTaskTypes={toDoListTaskTypes}
                  // @ts-ignore
                  selectedTaskTypeId={selectedTaskTypeId}
                  formDetails={formDetails}
                  hasJourneyBeenSubmitted={hasJourneyBeenSubmitted}
                  updateTaskTypeCount={updateTaskTypeCount}
                />
              )}
            </>
          )}
        </div>
      </div>
      <div id="catch-up-list" className="bg-white rounded-lg p-4 mt-4">
        <DashboardCatchUps initialCatchUps={details.catchUps} />
      </div>
    </div>
  );
}

export default DashboardRightBar;
