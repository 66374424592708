import cx from "classnames";
import Tooltip from "./Tooltip";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

interface BadgeProps {
  text: string;
  icon?: FontAwesomeIconProps["icon"] | undefined;
  iconPlacement?: "left" | "right" | undefined;
  tooltip?: string | undefined;
  backgroundColourClassName?: string;
  textColourClassName?: string;
  borderRadiusClassName?: string;
  marginClassName?: string;
  cursorClassName?: string;
  /** Any other class names where you don't want to override the default value like you do for margin, border etc */
  classNames?: string;
  textSize?: string;
  hyperlink?: string | undefined;
}

/** A simple badge component which renders text within a small, coloured block */
function Badge({
  text,
  icon = undefined,
  iconPlacement = "left",
  tooltip,
  backgroundColourClassName = "bg-gray-200/50",
  textColourClassName = "text-gray-200",
  borderRadiusClassName = "rounded-sm",
  marginClassName = "mx-2",
  cursorClassName = "cursor-default",
  classNames = "",
  textSize = "text-xs",
  hyperlink = undefined,
}: BadgeProps) {
  const sharedClasses = cx(
    "badge-label px-2 py-0.5 leading-tight select-none whitespace-nowrap",
    backgroundColourClassName,
    textColourClassName,
    borderRadiusClassName,
    marginClassName,
    cursorClassName,
    classNames,
    textSize
  );

  const badgeElement = (
    <BadgeElement hyperlink={hyperlink} classNames={sharedClasses}>
      <>
        {icon && iconPlacement === "left" && (
          <span className="mr-1">
            <FontAwesomeIcon icon={icon} size="xs" />
          </span>
        )}
        {text}
        {icon && iconPlacement === "right" && (
          <span className="ml-1">
            <FontAwesomeIcon icon={icon} size="xs" />
          </span>
        )}
      </>
    </BadgeElement>
  );

  return tooltip === undefined || tooltip.length === 0 ? (
    badgeElement
  ) : (
    <Tooltip content={tooltip} triggerElement={badgeElement} />
  );
}

const BadgeElement = ({
  hyperlink,
  classNames,
  children,
}: {
  hyperlink: string | undefined;
  classNames: string;
  children: JSX.Element;
}) => {
  const hasValidHyperlink = hyperlink !== undefined && hyperlink.length > 0;

  if (hasValidHyperlink) {
    return (
      <Link
        to={hyperlink}
        className={cx(classNames, "hover:cursor-pointer hover:underline")}
      >
        {children}
      </Link>
    );
  } else {
    return <span className={classNames}>{children}</span>;
  }
};

export default Badge;
