import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faCalendarClock } from "@fortawesome/pro-regular-svg-icons";
import { faPenToSquare as faPenToSquareSolid } from "@fortawesome/pro-solid-svg-icons";
import { FormattedDate, Tooltip, Avatar } from "../../../common";
import { useNavigate } from "react-router-dom";
import DashboardCatchUpDto from "../../../../types/dtos/dashboards/right-bar/catch-ups/DashboardCatchUpDto";
import AppRoutes from "../../../../routes/AppRoutes";

interface CatchUpListItemProps {
  catchUp: DashboardCatchUpDto;
}

const CatchUpListItem = ({
  catchUp
}: CatchUpListItemProps) => {
  const navigate = useNavigate();

  const navigateToCollabDoc = (guidId: string) => {
    const redirectUrl = AppRoutes.collaborativeDocument.generateRoute(
      guidId,
      false,
      null
    );
    navigate(redirectUrl);
  }
  return (
    <Tooltip
      content={catchUp.title}
      triggerElement={
        <div
          onClick={() => navigateToCollabDoc(catchUp.answerSetGuidId)}
          className="to-do-list-item bg-white cursor-pointer rounded-md border border-gray-300 hover:drop-shadow-md"
        >
          <div className="flex flex-row p-3">
            <div className="basis-3/12 pl-2">
              <Avatar
                imageUrl={catchUp.participantProfilePic}
                userFullName={catchUp.participantFullName}
                userInitials={catchUp.participantInitials}
                size={10}
                initialsTextClassName="text-sm"
              />
            </div>
            <div className="basis-8/12">
              <div className="flex flex-col text-sm text text-gray-600">
                <div className="font-medium">{catchUp.participantFullName}</div>
                <div>
                  <FontAwesomeIcon icon={faCalendarClock} className="pr-1" />
                  <FormattedDate date={catchUp.dateScheduled} displayMode="DATE-AND-TIME" useTwentyFourHourTimeFormat={true} />
                </div>
              </div>
            </div>
            <div className="basis-1/12 pt-2 text-primary">
              <FontAwesomeIcon className="icon-unhover" icon={faPenToSquare} />
              <FontAwesomeIcon className="icon-hover" icon={faPenToSquareSolid} />
            </div>
          </div>
        </div>
      }
    />
  );
};

export default CatchUpListItem;
