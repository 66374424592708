import React, { useEffect, useReducer, Reducer } from "react";
import { useTranslation } from "react-i18next";
import { MainContainer } from "../../../components/layout";
import AppContext from "../../../state/AppContext";
import UserContext from "../../../state/UserContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppRoutes from "../../AppRoutes";
import SendNowApiResponseDto from "../../../types/dtos/admin/SendNowApiResponseDto";
import { ClientFormDto } from "../../../types/dtos/admin/ClientFormDto";
import SendNowForm from "../../../components/admin/client-journeys/SendNow/SendNowForm";
import { useAuth } from "react-oidc-context";
import adminApi from "../../../api/dashboard/adminApi";
import adminHelper from "../../../helpers/adminHelper";
import { ApprovalFlowDto } from "../../../types/dtos/admin/ApprovalFlowDto";

interface SendPageState {
  waitingForApiResult: boolean;
  defaultIntroMessage: string;
  defaultThemeColour: string;
  availableSections: ClientFormDto[];
  selectedSections: ClientFormDto[];
  displayApprovalFlows: boolean;
  availableApprovalFlows: ApprovalFlowDto[];
}

function SendPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const admApi = new adminApi(auth.user?.access_token);
  const { clientFormId } = useParams();
  const appContext = React.useContext(AppContext);
  const userContext = React.useContext(UserContext);

  const [state, setState] = useReducer<
    Reducer<SendPageState, Partial<SendPageState>>
  >((state, newState) => ({ ...state, ...newState }), {
    waitingForApiResult: true,
    defaultIntroMessage: "",
    defaultThemeColour: "",
    availableSections: [],
    selectedSections: [],
    displayApprovalFlows: false,
    availableApprovalFlows: [],
  });

  useEffect(() => {
    adminHelper.checkAdminAccessAndRedirectWhenNoAccess(
      userContext,
      "JOURNEY-CONFIG",
      navigate
    );

    if (clientFormId !== undefined) {
      admApi.getDetailsForSendPage(
        parseInt(clientFormId),
        (json: SendNowApiResponseDto) => {
          setState({
            waitingForApiResult: false,
            defaultIntroMessage: json.defaultIntroMessage,
            defaultThemeColour: json.defaultThemeColour,
            availableSections: json.availableSections,
            selectedSections: json.selectedSections,
            displayApprovalFlows: json.displayAvailableApprovalFlows,
            availableApprovalFlows: json.availableApprovalFlows,
          });
        },
        (error: any) => {
          console.error(error);
        }
      );
    }

    appContext.setPageTitle(
      t("Pages.Admin.PageTitle.Journeys") +
        " - " +
        t("Pages.Admin.PageTitle.Send")
    );
    appContext.setShowPageTitleAccent(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onUpdateAvailableSections = (newState: any) => {
    setState({ availableSections: newState });
  };

  const onUpdateSelectedSections = (newState: any) => {
    setState({ selectedSections: newState });
  };

  return (
    <MainContainer>
      <div className="pt-2">
        <Link
          to={AppRoutes.admin.clientJourneys.pathOpenWithConfigTabSelected}
          className="btn-primary"
        >
          {t("Common.Back")}
        </Link>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-0.5">
        <div className="mt-4 col-span-1 md:col-span-10 md:col-start-2">
          <SendNowForm
            defaultIntroMessage={state.defaultIntroMessage}
            defaultThemeColour={state.defaultThemeColour}
            availableSections={state.availableSections}
            selectedSections={state.selectedSections}
            displayApprovalFlows={state.displayApprovalFlows}
            availableApprovalFlows={state.availableApprovalFlows}
            onUpdateAvailableSections={onUpdateAvailableSections}
            onUpdateSelectedSections={onUpdateSelectedSections}
          />
        </div>
      </div>
    </MainContainer>
  );
}

export default SendPage;
