import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition, faArrowsRotate, faClock } from "@fortawesome/pro-regular-svg-icons";
import { faPenToSquare, faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import { faPenToSquare as faPenToSquareSolid, faMagnifyingGlass as faMagnifyingGlassSolid, faStar } from "@fortawesome/pro-solid-svg-icons";
import { t } from "i18next";
import { FormattedDate, Badge } from "../../../common";

interface ToDoListItemProps {
  id: string | number;
  title: string;
  dueDate: Date;
  isOverdue: boolean;
  isCompleted: boolean;
  iconType: IconDefinition;
  singularNameTranslationKeyIdentifier: string;
  wasAdded: boolean;
  wasUpdated: boolean;
  onItemClick(id: string | number): void;
}

const ToDoListItem = ({
  id,
  title,
  dueDate,
  isOverdue,
  isCompleted,
  iconType,
  singularNameTranslationKeyIdentifier,
  wasAdded,
  wasUpdated,
  onItemClick,
}: ToDoListItemProps) => {
  return (
    <div
      onClick={() => onItemClick(id)}
      className="to-do-list-item bg-white cursor-pointer rounded-md border border-gray-300 hover:drop-shadow-md"
    >
      <div className="flex flex-col p-3">
        <div className="flex flex-row">
          <div className="basis-11/12">
            {wasAdded && (
              <Badge
                text={t(singularNameTranslationKeyIdentifier) + " " + t("Common.Added").toLowerCase()}
                backgroundColourClassName="bg-blue-200/75"
                textColourClassName="text-blue-600"
                icon={faStar}
                marginClassName="mr-2"
              />
            )}
            {wasUpdated && (
              <Badge
                text={t(singularNameTranslationKeyIdentifier) + " " + t("Common.UpdatedBySentence.LastUpdated").toLowerCase()}
                backgroundColourClassName="bg-blue-200/75"
                textColourClassName="text-blue-600"
                icon={faArrowsRotate}
                marginClassName="mr-2"
              />
            )}
            {!wasAdded && !wasUpdated && (
              <Badge
                text={t(singularNameTranslationKeyIdentifier)}
                textColourClassName="text-gray-400"
                icon={iconType}
                marginClassName="mr-2"
              />
            )}
          </div>
          <div className="basis-1/12 text-right text-primary">
            {!isCompleted && (
              <>
                <FontAwesomeIcon className="icon-unhover" icon={faPenToSquare} />
                <FontAwesomeIcon className="icon-hover" icon={faPenToSquareSolid} />
              </>
            )}
            {isCompleted && (
              <>
                <FontAwesomeIcon className="icon-unhover" icon={faMagnifyingGlass} />
                <FontAwesomeIcon className="icon-hover" icon={faMagnifyingGlassSolid} />
              </>
            )}
          </div>
        </div>
        <div className="flex flex-row pt-1">
          <div className="line-clamp-2 text-sm text-gray-600">
            <div className="font-medium">{title}</div>
          </div>
        </div>
        <div className="flex flex-row pt-2">
          {isCompleted && (
            <Badge
              text={t("Pages.Admin.Common.Completed")}
              backgroundColourClassName="bg-green-200/75 rounded-full"
              textColourClassName="text-green-600"
              borderRadiusClassName="rounded-sm"
              marginClassName="mr-1"
            />
          )}

          {!isCompleted && !isOverdue && (
            <Badge
              text={t('Common.NotCompleted')}
              backgroundColourClassName="bg-yellow-200/75 rounded-full"
              textColourClassName="text-yellow-600"
              borderRadiusClassName="rounded-sm"
              marginClassName="mr-1"
            />
          )}

          {!isCompleted && isOverdue && (
            <Badge
              text={t('Common.Overdue')}
              backgroundColourClassName="bg-rose-200/75 rounded-full"
              textColourClassName="text-rose-600"
              borderRadiusClassName="rounded-sm"
              marginClassName="mr-1"
            />
          )}

          <div className="badge-label px-2 py-0.5 leading-tight select-none whitespace-nowrap rounded-sm bg-white text-gray-500 mx-1 border border-gray-400 text-xs">
            <FontAwesomeIcon icon={faClock} className="pr-1" />
            <FormattedDate date={dueDate} displayMode="FROM-NOW" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToDoListItem;
