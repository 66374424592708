import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripDotsVertical } from "@fortawesome/pro-solid-svg-icons";
import cx from "classnames";
import { ClientFormDto } from "../../../../../types/dtos/admin/ClientFormDto";
import React from "react";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { t } from "i18next";

interface SectionItemProps {
  item: ClientFormDto;
  itemSelected: number;
  dragHandleProps: object;
  commonProps(item: ClientFormDto): void;
}

class SectionItem extends React.Component<SectionItemProps> {
  constructor(props: any) {
    super(props);
  }
  render() {
    const scale = this.props.itemSelected * 0.05 + 1;
    const shadow = this.props.itemSelected * 15 + 1;
    const dragged = this.props.itemSelected !== 0;

    return (
      <div
        className={cx("item", { dragged })}
        style={{
          transform: `scale(${scale})`,
          boxShadow: `rgba(0, 0, 0, 0.3) 0px ${shadow}px ${2 * shadow}px 0px`,
        }}
      >
        <div className="p-2 border grid grid-cols-12">
          <div className="col-span-1 text-center">
            <FontAwesomeIcon
              icon={faGripDotsVertical}
              className="dragHandle cursor-pointer"
              {...this.props.dragHandleProps}
            ></FontAwesomeIcon>
          </div>
          <p className="col-span-10">
            {t(this.props.item.titleTranslationKey)}
          </p>
          <div>
            <FontAwesomeIcon
              icon={faTrashAlt}
              onClick={() => this.props.commonProps(this.props.item)}
              className="text-red-700 cursor-pointer col-span-1"
            ></FontAwesomeIcon>
          </div>
        </div>
      </div>
    );
  }
}

export default SectionItem;
