import { useAuth } from "react-oidc-context";
import appraisalLevelApi from "../../../api/dashboard/appraisalLevelApi";
import jobTitleApi from "../../../api/dashboard/jobTitleApi";
import locationApi from "../../../api/dashboard/locationApi";
import { useEffect, useState } from "react";
import CheckboxTreeNodeDto from "../../../types/dtos/generic/CheckboxTreeNodeDto";
import FilterCheckboxTreeApiResponseDto from "../../../types/analytics/FilterCheckboxTreeApiResponseDto";
import AdvancedFilterModal from "../../analytics/filters/AdvancedFilterModal";
import FilterEditIcon from "../../analytics/filters/FilterEditIcon";
import { Badge, CheckBox, ModalPopup } from "../../common";
import SearchFilterInputs from "../../../types/admin/client-journeys/SearchFilterInputs";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import employeeStatusApi from "../../../api/dashboard/employeeStatusApi ";
import { useTranslation } from "react-i18next";

interface AdminFiltersProps {
  selectedValues: SearchFilterInputs;
  onLocationPickerChange: (selectedLocationIds: number[]) => void;
  onJobTitlePickerChange: (selectedJobtitleIds: number[]) => void;
  onAppraisalLevelPickerChange: (selectedAppraisalLevelIds: number[]) => void;
  onEmployeeStatusPickerChange: (selectedEmployeeStatusIds: number[]) => void;
  onActiveOnlyChange: (newValue: boolean) => void;
  onApplyFilters(): void;
  onResetFilters(): void;
}

type SearchFilterType =
  | "JOB-TITLE"
  | "APPRAISAL-LEVEL"
  | "LOCATION"
  | "EMPLOYEE-STATUS";

function AdminFilters({
  selectedValues,
  onLocationPickerChange,
  onJobTitlePickerChange,
  onAppraisalLevelPickerChange,
  onEmployeeStatusPickerChange,
  onActiveOnlyChange,
  onApplyFilters,
  onResetFilters,
}: AdminFiltersProps) {
  const { t } = useTranslation();

  const searchFilterItems: Array<{
    type: SearchFilterType;
    text: string;
  }> = [
    { type: "JOB-TITLE", text: t("Pages.Admin.Common.JobTitle") },
    { type: "APPRAISAL-LEVEL", text: t("Pages.Admin.Common.Level") },
    { type: "LOCATION", text: t("Pages.Admin.Common.Location") },
    { type: "EMPLOYEE-STATUS", text: t("Pages.Admin.Common.Status") },
  ];

  const auth = useAuth();
  const appLevelApi = new appraisalLevelApi(auth.user?.access_token);
  const locApi = new locationApi(auth.user?.access_token);
  const jTitleApi = new jobTitleApi(auth.user?.access_token);
  const empStatusApi = new employeeStatusApi(auth.user?.access_token);

  // State
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [filterType, setFilterType] = useState<SearchFilterType | null>(null);
  const [activeOnlyTicked, setActiveOnlyTicked] = useState<boolean>(
    selectedValues.activeUsersOnly === true
  );

  const [locations, setLocations] = useState<CheckboxTreeNodeDto[]>();
  const [isLocationsLoading, setIsLocationsLoading] = useState<boolean>(false);
  const [locationTreeChecked, setLocationTreeChecked] = useState<string[]>([]);
  const [locationTreeExpanded, setLocationTreeExpanded] = useState<string[]>(
    []
  );
  const [selectedLocationCount, setSelectedLocationCount] = useState<number>(
    selectedValues.locationIds?.length ?? 0
  );

  const [jobTitles, setJobTitles] = useState<CheckboxTreeNodeDto[]>();
  const [isJobTitlesLoading, setIsJobTitlesLoading] = useState<boolean>(false);
  const [jobTitleTreeChecked, setJobTitleTreeChecked] = useState<string[]>([]);
  const [jobTitleTreeExpanded, setJobTitleTreeExpanded] = useState<string[]>(
    []
  );
  const [selectedJobTitleCount, setSelectedJobTitleCount] = useState<number>(
    selectedValues.jobTitleIds?.length ?? 0
  );

  const [appraisalLevels, setAppraisalLevels] =
    useState<CheckboxTreeNodeDto[]>();
  const [isAppraisalLevelsLoading, setIsAppraisalLevelsLoading] =
    useState<boolean>(false);
  const [appraisalLevelTreeChecked, setAppraisalLevelTreeChecked] = useState<
    string[]
  >([]);
  const [appraisalLevelTreeExpanded, setAppraisalLevelTreeExpanded] = useState<
    string[]
  >([]);
  const [selectedAppraisalLevelCount, setSelectedAppraisalLevelCount] =
    useState<number>(selectedValues.appraisalLevelIds?.length ?? 0);

  const [employeeStatuses, setEmployeeStatuses] =
    useState<CheckboxTreeNodeDto[]>();
  const [isEmployeeStatusesLoading, setIsEmployeeStatusesLoading] =
    useState<boolean>(false);
  const [employeeStatusTreeChecked, setEmployeeStatusTreeChecked] = useState<
    string[]
  >([]);
  const [employeeStatusTreeExpanded, setEmployeeStatusTreeExpanded] = useState<
    string[]
  >([]);
  const [selectedEmployeeStatusCount, setSelectedEmployeeStatusCount] =
    useState<number>(selectedValues.employeeStatusIds?.length ?? 0);

  // Hooks
  useEffect(() => {
    // As the applied filters change in the parent state, ensure the modals launch with a matching state,
    // e.g. so the ticked appraisal levels remain ticked when the modal is re-opened
    setSelectedAppraisalLevelCount(
      selectedValues.appraisalLevelIds?.length ?? 0
    );
    setAppraisalLevelTreeChecked(
      selectedValues.appraisalLevelIds?.map((x) => x.toString()) ?? []
    );

    setSelectedLocationCount(selectedValues.locationIds?.length ?? 0);
    setLocationTreeChecked(
      selectedValues.locationIds?.map((x) => x.toString()) ?? []
    );

    setSelectedJobTitleCount(selectedValues.jobTitleIds?.length ?? 0);
    setJobTitleTreeChecked(
      selectedValues.jobTitleIds?.map((x) => x.toString()) ?? []
    );

    setSelectedEmployeeStatusCount(
      selectedValues.employeeStatusIds?.length ?? 0
    );
    setEmployeeStatusTreeChecked(
      selectedValues.employeeStatusIds?.map((x) => x.toString()) ?? []
    );
  }, [
    selectedValues.jobTitleIds,
    selectedValues.appraisalLevelIds,
    selectedValues.locationIds,
    selectedValues.employeeStatusIds,
  ]);

  /** When the user clicks the primary button from inside an advanced filter modal */
  const onAddFiltersClick = () => {
    setModalIsOpen(false);
    switch (filterType) {
      case "JOB-TITLE":
        setSelectedJobTitleCount(jobTitleTreeChecked.length);
        onJobTitlePickerChange(jobTitleTreeChecked.map((x) => parseInt(x)));
        break;
      case "APPRAISAL-LEVEL":
        setSelectedAppraisalLevelCount(appraisalLevelTreeChecked.length);
        onAppraisalLevelPickerChange(
          appraisalLevelTreeChecked.map((x) => parseInt(x))
        );
        break;
      case "LOCATION":
        setSelectedLocationCount(locationTreeChecked.length);
        onLocationPickerChange(locationTreeChecked.map((x) => parseInt(x)));
        break;
      case "EMPLOYEE-STATUS":
        setSelectedEmployeeStatusCount(employeeStatusTreeChecked.length);
        onEmployeeStatusPickerChange(
          employeeStatusTreeChecked.map((x) => parseInt(x))
        );
        break;
      default:
        break;
    }
  };

  const onLocationFilterClick = (): void => {
    setFilterType("LOCATION");
    if (locations === undefined) {
      setIsLocationsLoading(true);

      // Call the API to load the necessary state
      const successCallback = (data: FilterCheckboxTreeApiResponseDto) => {
        setLocations(data.nodes);
        setIsLocationsLoading(false);
      };

      const errorCallback = (error: any) => {
        console.error(error);
      };

      locApi.getLocationCheckboxTreeItemsByClientId(
        successCallback,
        errorCallback
      );
    }
  };

  const onJobTitleFilterClick = (): void => {
    setFilterType("JOB-TITLE");
    if (jobTitles === undefined) {
      setIsJobTitlesLoading(true);

      // Call the API to load the necessary state
      const successCallback = (data: FilterCheckboxTreeApiResponseDto) => {
        setJobTitles(data.nodes);
        setIsJobTitlesLoading(false);
      };

      const errorCallback = (error: any) => {
        console.error(error);
      };

      jTitleApi.getJobTitleCheckboxTreeItemsByClientId(
        successCallback,
        errorCallback
      );
    }
  };

  const onAppraisalFilterClick = (): void => {
    setFilterType("APPRAISAL-LEVEL");
    if (appraisalLevels === undefined) {
      setIsAppraisalLevelsLoading(true);

      // Call the API to load the necessary state
      const successCallback = (data: FilterCheckboxTreeApiResponseDto) => {
        setAppraisalLevels(data.nodes);
        setIsAppraisalLevelsLoading(false);
      };

      const errorCallback = (error: any) => {
        console.error(error);
      };

      appLevelApi.getAppraisalLevelCheckboxTreeItemsByClientId(
        successCallback,
        errorCallback
      );
    }
  };

  const onEmployeeStatusFilterClick = (): void => {
    setFilterType("EMPLOYEE-STATUS");
    setIsEmployeeStatusesLoading(true);

    // Call the API to load the necessary state
    const successCallback = (data: FilterCheckboxTreeApiResponseDto) => {
      setEmployeeStatuses(data.nodes);
      setIsEmployeeStatusesLoading(false);
    };

    const errorCallback = (error: any) => {
      console.error(error);
    };

    empStatusApi.getEmployeeStatusCheckboxTreeItems(
      activeOnlyTicked,
      successCallback,
      errorCallback
    );
  };

  const clearSelections = (filterType: SearchFilterType) => {
    if (filterType === "JOB-TITLE") {
      setJobTitleTreeChecked([]);
      setJobTitleTreeExpanded([]);
      setSelectedJobTitleCount(0);
    } else if (filterType === "APPRAISAL-LEVEL") {
      setAppraisalLevelTreeChecked([]);
      setAppraisalLevelTreeExpanded([]);
      setSelectedAppraisalLevelCount(0);
    } else if (filterType === "LOCATION") {
      setLocationTreeChecked([]);
      setLocationTreeExpanded([]);
      setSelectedLocationCount(0);
    } else if (filterType === "EMPLOYEE-STATUS") {
      setEmployeeStatusTreeChecked([]);
      setEmployeeStatusTreeExpanded([]);
      setSelectedEmployeeStatusCount(0);
    }
  };

  const onOpenFilterModal = (filterType: SearchFilterType) => {
    switch (filterType) {
      case "JOB-TITLE":
        setModalTitle("Filter by Job Title");
        onJobTitleFilterClick();
        break;
      case "APPRAISAL-LEVEL":
        setModalTitle("Filter by Level");
        onAppraisalFilterClick();
        break;
      case "LOCATION":
        setModalTitle("Filter by Location");
        onLocationFilterClick();
        break;
      case "EMPLOYEE-STATUS":
        setModalTitle("Filter by Status");
        onEmployeeStatusFilterClick();
        break;
      default:
        setFilterType(null);
    }
    setModalIsOpen(true);
  };

  const onActiveOnlyTickChange = (isChecked: boolean) => {
    setActiveOnlyTicked(isChecked);
    onActiveOnlyChange(isChecked);
  };

  return (
    <div>
      <div className="flex flex-col gap-x-4 md:flex-row">
        {searchFilterItems.map((filterItem) => {
          const selectedItemCount =
            filterItem.type === "JOB-TITLE"
              ? selectedJobTitleCount
              : filterItem.type === "APPRAISAL-LEVEL"
              ? selectedAppraisalLevelCount
              : filterItem.type === "LOCATION"
              ? selectedLocationCount
              : filterItem.type === "EMPLOYEE-STATUS"
              ? selectedEmployeeStatusCount
              : 0;
          const selectedItemBadgeText =
            selectedItemCount >= 100 ? "99+" : selectedItemCount.toString();
          return (
            <div
              key={filterItem.type}
              className="w-full lg:w-32 text-sm mb-2 bg-gray-200 py-2 px-3 cursor-pointer group rounded-sm hover:bg-gray-300"
              onClick={() => onOpenFilterModal(filterItem.type)}
            >
              <div className="flex flex-row">
                <div className="grow">
                  <span>{filterItem.text}</span>
                  {selectedItemCount > 0 && (
                    <Badge
                      text={selectedItemBadgeText}
                      backgroundColourClassName="accent-bg"
                      textColourClassName="text-white"
                    />
                  )}
                </div>
                <div className="grow-0 text-right w-2">
                  <FilterEditIcon />
                </div>
              </div>
            </div>
          );
        })}
        <div className="mt-1">
          <CheckBox
            labelText="Active only"
            onChange={onActiveOnlyTickChange}
            selected={activeOnlyTicked}
            labelClassNames="text-sm pt-1"
            checkboxClassNames="bg-white"
            controlId="adv-filters-include-leavers"
          />
        </div>
      </div>
      <div>
        <div className="text-left">
          <hr className="my-2" />
          <button className="btn-primary text-sm" onClick={onApplyFilters}>
            <FontAwesomeIcon icon={faCheck} />
            <span className="ml-1">{t("Pages.Admin.Common.ApplyFilters")}</span>
          </button>
          <button className="ml-4 text-sm" onClick={onResetFilters}>
            {t("Pages.Admin.Common.Reset")}
          </button>
        </div>
      </div>
      {filterType !== null && (
        <ModalPopup
          isOpen={modalIsOpen}
          onOpenChange={setModalIsOpen}
          onPrimaryButtonClick={onAddFiltersClick}
          title={modalTitle}
          showCloseIcon={true}
          width="SMALL"
          primaryButtonText="Done"
        >
          <AdvancedFilterModal
            filterType={filterType}
            onClearSelections={() => clearSelections(filterType)}
            locations={locations}
            isLocationsLoading={isLocationsLoading}
            locationTreeChecked={locationTreeChecked}
            locationTreeExpanded={locationTreeExpanded}
            onLocationTreeChecked={setLocationTreeChecked}
            onLocationTreeExpanded={setLocationTreeExpanded}
            appraisalLevels={appraisalLevels}
            isAppraisalLevelsLoading={isAppraisalLevelsLoading}
            appraisalLevelTreeChecked={appraisalLevelTreeChecked}
            appraisalLevelTreeExpanded={appraisalLevelTreeExpanded}
            onAppraisalLevelTreeChecked={setAppraisalLevelTreeChecked}
            onAppraisalLevelTreeExpanded={setAppraisalLevelTreeExpanded}
            jobTitles={jobTitles}
            isJobTitlesLoading={isJobTitlesLoading}
            jobTitleTreeChecked={jobTitleTreeChecked}
            jobTitleTreeExpanded={jobTitleTreeExpanded}
            onJobTitleTreeChecked={setJobTitleTreeChecked}
            onJobTitleTreeExpanded={setJobTitleTreeExpanded}
            employeeStatuses={employeeStatuses}
            isEmployeeStatusesLoading={isEmployeeStatusesLoading}
            employeeStatusTreeChecked={employeeStatusTreeChecked}
            employeeStatusTreeExpanded={employeeStatusTreeExpanded}
            onEmployeeStatusTreeChecked={setEmployeeStatusTreeChecked}
            onEmployeeStatusTreeExpanded={setEmployeeStatusTreeExpanded}
          />
        </ModalPopup>
      )}
    </div>
  );
}

export default AdminFilters;
