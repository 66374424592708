import taskTypeHelper from "../../helpers/taskTypeHelper";
import ModifyTaskResponseDto from "../../types/dtos/tasks/ModifyTaskResponseDto";
import MyDashboardApiResponseDto from "../../types/dtos/dashboards/MyDashboardApiResponseDto";
import {
  JourneyAssignmentResponseDto,
} from "../../types/dtos/journeys";
import { TaskType } from "../../types/tasks";
import {
  EditableTask,
} from "../../types/tasks/EditableTasks";
import { apiClient } from "../apiClient";
import { DashboardSummaryTabDto } from "../../types/dtos/dashboards";
import GenericApiResult from "../../types/dtos/generic/GenericApiResult";
import MyDashboardFormsApiResponseDto from "../../types/dtos/dashboards/MyDashboardFormsApiResponseDto";
import MyDashboardCatchUpsApiResponseDto from "../../types/dtos/dashboards/MyDashboardCatchUpsApiResponseDto";
import StandardToDoListItemDto from "../../types/dtos/dashboards/right-bar/to-do-list/StandardToDoListItemDto";
import AdvancedToDoListItemDto from "../../types/dtos/dashboards/right-bar/to-do-list/AdvancedToDoListItemDto";
import DashboardCatchUpDto from "../../types/dtos/dashboards/right-bar/catch-ups/DashboardCatchUpDto";

class myDashboardApi {
  constructor(authToken: string | undefined) {
    this.accessToken = authToken;
  }

  accessToken: string | undefined;

  /** Loads the employee dashboard */
  getFullDashboard(
    onSuccess: (dashboardData: MyDashboardApiResponseDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(this.accessToken, "dashboards/my-dashboard/get-full").then(
      (data) => {
        const dashboardData = data as MyDashboardApiResponseDto;
        onSuccess(dashboardData);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Loads the summaries for the employee dashboard */
  getSummaries(
    onSuccess: (summaries: DashboardSummaryTabDto[]) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/my-dashboard/get-summaries"
    ).then(
      (data) => {
        const summaries = data as DashboardSummaryTabDto[];
        onSuccess(summaries);
      },
      (error) => {
        onError(error);
      }
    );
  }

  // Gets the ToDoListItems (standard/non-advanced tasks) for the given employee and taskTypeId
  getStandardToDoListItems(
    taskTypeId: number,
    onSuccess: (data: StandardToDoListItemDto[]) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      `dashboards/my-dashboard/get-standard-to-do-list-items?taskTypeId=${taskTypeId}`
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  // Gets the ToDoListItems (advanced tasks) for the given employee and taskTypeId
  getAdvancedToDoListItems(
    taskTypeId: string,
    onSuccess: (data: AdvancedToDoListItemDto[]) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      `dashboards/my-dashboard/get-advanced-to-do-list-items?taskTypeId=${taskTypeId}`
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  // Gets the active catch ups for the given employee to be displayed on the dashboard
  getDashboardCatchUps(
    onSuccess: (data: DashboardCatchUpDto[]) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      `dashboards/my-dashboard/get-dashboard-catch-ups`
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Load the full timeline, rather than just the most relevant items loaded in `getFullDashboard` */
  assignDifferentJourneyToUser(
    clientFormId: number | null,
    exactJourneyRef: string | null,
    onSuccess: (assignmentResult: JourneyAssignmentResponseDto) => void,
    onError: (error: any) => void
  ) {
    const querystring = new URLSearchParams();

    if (clientFormId && clientFormId > 0) {
      querystring.append("clientFormId", clientFormId.toString());
    }

    if (exactJourneyRef && exactJourneyRef.length > 0) {
      querystring.append("journeyRef", exactJourneyRef);
    }

    const apiUrl =
      "dashboards/my-dashboard/assign-journey?" + querystring.toString();

    return apiClient(this.accessToken, apiUrl).then(
      (data) => {
        const newJourneyData = data as JourneyAssignmentResponseDto;
        onSuccess(newJourneyData);
      },
      (error) => {
        onError(error);
      }
    );
  }

  modifyTaskFromDashboard(
    taskDto: EditableTask<number>,
    modifyTaskType: string,
    onSuccess: (data: ModifyTaskResponseDto<number>) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/my-dashboard/modify-simple-task-from-dashboard?modificationAction=" +
      modifyTaskType,
      {
        body: JSON.stringify(taskDto),
      }
    ).then(
      (data) => {
        const responseData = data as ModifyTaskResponseDto<number>;

        // If the response has a TaskDto then we need to be sure to convert that into its relevant EditableTask object
        if (responseData.doesResponseContainsNewTaskDto) {
          responseData.task = taskTypeHelper.getTaskObject<number>(
            responseData.taskDbObject
          );
        }

        onSuccess(responseData);
      },
      (error) => {
        onError(error);
      }
    );
  }

  DeleteTaskFromDashboard(
    taskId: number,
    onSuccess: (data: boolean | null) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/my-dashboard/delete-simple-task-from-dashboard?taskId=" +
      taskId
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  toggleCompleteTaskFromDashboard(
    taskId: number,
    taskIsComplete: boolean,
    onSuccess: (data: boolean | null) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/my-dashboard/toggle-complete-simple-task?taskId=" +
      taskId +
      "&isComplete=" +
      taskIsComplete
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  loadEditableTaskDetails(
    taskId: number,
    taskType: TaskType,
    onSuccess: (data: ModifyTaskResponseDto<number>) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/my-dashboard/load-editable-simple-task-details?taskId=" +
      taskId +
      "&tasktype=" +
      taskType
    ).then(
      (data) => {
        const responseData = data as ModifyTaskResponseDto<number>;

        // If the response has a TaskDto then we need to be sure to convert that into its relevant EditableTask object
        if (responseData.doesResponseContainsNewTaskDto) {
          responseData.task = taskTypeHelper.getTaskObject<number>(
            responseData.taskDbObject
          );
        }

        onSuccess(responseData);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Enter edit mode to create a clone of a collab doc */
  createAndPrefillDoc(
    subjectEmployeeId: number,
    journeyReference: string,
    onSuccess: (data: GenericApiResult) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/collab-doc/create-and-prefill-doc?subjectEmployeeId=" +
      subjectEmployeeId +
      "&journeyRef=" +
      journeyReference
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  getJourneyFormsData(
    onSuccess: (data: MyDashboardFormsApiResponseDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/my-dashboard/get-journey-forms-data"
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  getJourneyCatchUpsData(
    onSuccess: (data: MyDashboardCatchUpsApiResponseDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/my-dashboard/get-journey-catch-ups-data"
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }
}

export default myDashboardApi;
