import { useEffect, useState } from "react";
import JourneyFormItem from "./JourneyFormItem";
//@ts-ignore
import Pagination from "react-js-pagination";
import { ReactComponent as EmptyStateImage } from "../../../images/noGeneralContent.svg";
import { t } from "i18next";
import UserJourneyDetailDto from "../../../types/dtos/shared/UserJourneyDetailDto";
import { JourneyFormButtonArg } from "../../../types/dashboard/JourneyFormDropDownItems";
import { DashboardJourneyTabType } from "../../../types/dtos/dashboards/DashboardJourneyTabType";
import { UserContextInterface } from "../../../state/UserContext";

interface JourneyFormTabProps {
  userContext: UserContextInterface;
  forms: UserJourneyDetailDto[];
  selectedTab: DashboardJourneyTabType;
  isYourPeoplePage: boolean;
  onUpdateClick(form: UserJourneyDetailDto): void;
  onViewClick(form: UserJourneyDetailDto): void;
  onDualPrepItemClick(
    form: UserJourneyDetailDto,
    arg: JourneyFormButtonArg
  ): void;
}

export const JourneyFormTab = ({
  userContext,
  forms,
  selectedTab,
  isYourPeoplePage,
  onUpdateClick,
  onViewClick,
  onDualPrepItemClick,
}: JourneyFormTabProps) => {
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalItemCount, setTotalItemCount] = useState<number>(forms.length);
  const [itemsOnDisplay, setItemsOnDisplay] = useState<UserJourneyDetailDto[]>(
    []
  );

  const isCompletedTab = selectedTab === "COMPLETED";

  useEffect(() => {
    // Set the page number to 1 when the forms change
    const pageNum = 1;
    setPageNumber(pageNum);
    calculateItemsToDisplay(pageNum);
    setTotalItemCount(forms.length);
  }, [forms]);

  const handlePageNumberChange = (pageNumber: number) => {
    setPageNumber(pageNumber);
    calculateItemsToDisplay(pageNumber);
  };

  const calculateItemsToDisplay = (newPageNumber: number) => {
    var startIndex = (newPageNumber - 1) * itemsPerPage;
    let displayItems = [...forms].splice(startIndex, itemsPerPage);
    setItemsOnDisplay(displayItems);
  };

  return (
    <>
      {forms.length === 0 && (
        <div
          className={`text-center text-gray-400 ${
            isYourPeoplePage ? "py-4 lg:py-6" : "pt-4 lg:pt-16"
          }`}
        >
          {!isYourPeoplePage && (
            <div className="flex justify-center">
              <EmptyStateImage className="max-w-1/2" />
            </div>
          )}
          <p className={`text-sm ${isYourPeoplePage ? "" : "pt-2 lg:pt-4"}`}>
            {t("Pages.MyDashboard.Forms.EmptyState")}
          </p>
        </div>
      )}

      {forms.length > 0 && (
        <>
          {itemsOnDisplay.map((form, index) => {
            return (
              <JourneyFormItem
                userContext={userContext}
                key={`form_item_${form.journeyReference}_${index}`}
                form={form}
                isCompletedTab={isCompletedTab}
                isYourPeoplePage={isYourPeoplePage}
                onUpdateClick={onUpdateClick}
                onViewClick={onViewClick}
                onDualPrepItemClick={onDualPrepItemClick}
              />
            );
          })}
          {totalItemCount && totalItemCount > itemsPerPage && (
            <div className="grid grid-cols-1 pt-1 md:grid-cols-5 md:text-gray-500">
              <div className="col-span-3">
                {/* Only show the pagination if there is more than one page (aka if the totalItemCount is greater than itemsPerPage) */}
                <div className="mt-2 text-xs">
                  <Pagination
                    activePage={pageNumber}
                    itemsCountPerPage={itemsPerPage}
                    totalItemsCount={totalItemCount}
                    pageRangeDisplayed={5}
                    onChange={handlePageNumberChange}
                    activeClass="pagination-active-list-item"
                    itemClass="pagination-list-item"
                    itemClassFirst="pagination-first-item"
                    itemClassLast="pagination-last-item"
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
