import React, { useEffect, useState } from "react";
//@ts-ignore
import Pagination from "react-js-pagination";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { MainContainer } from "../../../components/layout";
import AppContext from "../../../state/AppContext";
import UserContext from "../../../state/UserContext";
import adminHelper from "../../../helpers/adminHelper";
import AppRoutes from "../../AppRoutes";
import adminApi from "../../../api/dashboard/adminApi";
import UserDetailDto from "../../../types/dtos/admin/UserDetailDto";
import { Avatar } from "../../../components/common";
import {
  faUser,
  faMapMarkerAlt,
  faChevronLeft,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserAdminHistoryItem from "../../../types/dtos/admin/UserAdminHistoryItem";
import UserDetailHistoryPageApiResponseDto from "../../../types/dtos/admin/UserDetailHistoryPageApiResponseDto";
import PaginationAndSearchCriteria from "../../../types/admin/client-journeys/PaginationAndSearchCriteria";
import { dateHelper } from "../../../helpers";
import EmptyFilteredState from "../../../images/EmptyFilteredState";

function UserAdminHistory() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const appContext = React.useContext(AppContext);
  const userContext = React.useContext(UserContext);
  const auth = useAuth();
  const admApi = new adminApi(auth.user?.access_token);

  const { userId } = useParams();
  let selectedUserId = 0;
  if (userId != null) {
    var parsedUserId = parseInt(userId);
    if (!isNaN(parsedUserId)) {
      selectedUserId = parseInt(userId);
    }
  }

  const managerAdminsTheirPeopleConfig =
    userContext.user.client.moduleConfigs.find((config) => {
      return config.key === "ManagersAdministerTheirEmployees";
    });

  const isNonAdminUserWithManagerConfigAccess =
    !userContext.user.isAdmin &&
    managerAdminsTheirPeopleConfig != undefined &&
    managerAdminsTheirPeopleConfig.value === "true" &&
    userContext.user.isManager;

  const [userDetails, setUserDetails] = useState<UserDetailDto | null>(null);
  const [adminHistory, setAdminHistory] = useState<
    UserAdminHistoryItem[] | undefined
  >(undefined);
  const [adminHistoryTotalCount, setAdminHistoryTotalCount] = useState<
    number | undefined
  >(undefined);
  const [criteria, setCriteria] = useState<PaginationAndSearchCriteria>({
    pageNumber: 1,
    pageSize: 15,
    searchTerm: null,
  });

  useEffect(() => {
    adminHelper.checkAdminAccessAndRedirectWhenNoAccess(
      userContext,
      "USER-JOURNEY",
      navigate
    );
    appContext.setPageTitle(t("Pages.Admin.PageTitle.Journeys"));
    appContext.setShowPageTitleAccent(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Trigger a reload of data if the pageNumber or searchTerm changes
  useEffect(() => {
    admApi.getUserAdminHistoryPage(
      selectedUserId,
      isNonAdminUserWithManagerConfigAccess,
      criteria.pageSize,
      criteria.pageNumber,
      (data: UserDetailHistoryPageApiResponseDto) => {
        setUserDetails(data.userDetails);
        setAdminHistory(data.adminHistory.pagedItems);
        setAdminHistoryTotalCount(data.adminHistory.totalItemCount);
      },
      (error: any) => {
        console.error(error);
      }
    );
  }, [criteria]);

  const handlePageNumberChange = (pageNumber: number) => {
    setCriteria({ ...criteria, pageNumber });
  };

  return (
    <MainContainer>
      <div className="w-full border rounded p-6 mt-6">
        <div className="hover:underline">
          <Link
            to={AppRoutes.admin.clientJourneys.userDetail.generateRoute(
              userId!
            )}
          >
            <FontAwesomeIcon size="xs" icon={faChevronLeft} />
            <span className="pl-1.5">{t("Common.Back")}</span>
          </Link>
        </div>
        {userDetails && (
          <div className="md:px-14">
            <div className="grid grid-cols-1 gap-1 mt-2">
              <div className="flex flex-row">
                <div className="hidden md:block md:mr-4">
                  <Avatar
                    key={userId}
                    userFullName={userDetails.fullName}
                    userInitials={userDetails.initials}
                    initialsTextClassName="text-xl"
                    size={16}
                    imageUrl={
                      userDetails.profileImageUrl
                        ? userDetails.profileImageUrl
                        : undefined
                    }
                  />
                </div>
                <div className="grow pt-2">
                  <div className="flex flex-row">
                    <h3 className="coloured-heading !mb-0 !mt-0 basis-4/5">
                      {userDetails.fullName}
                    </h3>
                  </div>
                  <div>
                    <div className="flex gap-5 text-sm">
                      <p title="Job title">
                        <FontAwesomeIcon
                          icon={faUser}
                          size="1x"
                          className="text-[#C4C4C4]"
                        />
                        <span className="pl-1">{t(userDetails.jobTitle)}</span>
                      </p>
                      <p title="Location">
                        <FontAwesomeIcon
                          icon={faMapMarkerAlt}
                          size="1x"
                          className="text-[#C4C4C4]"
                        />
                        <span className="pl-1">{userDetails.locationName}</span>
                      </p>
                      <p title="Level">
                        <FontAwesomeIcon
                          icon={faUser}
                          size="1x"
                          className="text-[#C4C4C4]"
                        />
                        <span className="pl-1">
                          {t(userDetails.appraisalLevel)}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="mt-5" />
            {adminHistoryTotalCount === 0 && (
              <>
                <em className="block text-center italic py-4">
                  <div className="mb-4 flex justify-center">
                    <EmptyFilteredState />
                  </div>
                  {t("Pages.Admin.History.NoHistory")}
                </em>
              </>
            )}
            {adminHistoryTotalCount != undefined &&
              adminHistoryTotalCount > 0 && (
                <>
                  <div className="w-full overflow-x-auto text-sm">
                    <div className="mt-2 text-gray-500 text-base">
                      <small>
                        {t("Pages.Admin.UserDetails.AdminHistory_Upper")}
                      </small>
                    </div>
                    <table className="datatable mt-1 mb-4">
                      <thead>
                        <tr className="text-left" key="Date">
                          <th className="!py-3 w-36">
                            <div className="flex">
                              <span className="grow">
                                {t("Pages.Admin.History.Date")}
                              </span>
                            </div>
                          </th>
                          <th className="!py-3 w-56" key="PerformedBy">
                            <div className="flex">
                              <span className="grow">
                                {t("Pages.Admin.History.PerformedBy")}
                              </span>
                            </div>
                          </th>
                          <th className="!py-3" key="Description">
                            <div className="flex">
                              <span className="grow">
                                {t("Pages.Admin.History.Description")}
                              </span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {adminHistory != undefined &&
                          adminHistory.map((log) => (
                            <tr key={log.id}>
                              <td className="w-36">
                                {dateHelper
                                  .convertUtcDateToLocal(log.datePerformed)
                                  .toLocaleDateString(undefined) +
                                  " - " +
                                  dateHelper
                                    .convertUtcDateToLocal(log.datePerformed)
                                    .toLocaleTimeString(undefined, {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })}
                              </td>
                              <td className="w-56">
                                {log.performedUserFullName}
                              </td>
                              <td>{log.description}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="text-xs">
                    <Pagination
                      activePage={criteria.pageNumber}
                      itemsCountPerPage={criteria.pageSize}
                      totalItemsCount={adminHistoryTotalCount}
                      pageRangeDisplayed={5}
                      onChange={handlePageNumberChange}
                      activeClass="pagination-active-list-item"
                      itemClass="pagination-list-item"
                      itemClassFirst="pagination-first-item"
                      itemClassLast="pagination-last-item"
                    />
                  </div>
                </>
              )}
          </div>
        )}
      </div>
    </MainContainer>
  );
}

export default UserAdminHistory;
