import { useState } from "react";
import { faBolt } from "@fortawesome/pro-regular-svg-icons";
import {
  ConfigurationBlockCard,
  ConfigurationBlockCardEmptyState,
} from "../ConfigurationBlocks";

interface TriggersConfigurationBlockProps {
  journeyVersionId: string;
  isInEditMode: boolean;
  onTriggerEditMode: () => void;
}

export const TriggersConfigurationBlock = ({
  journeyVersionId,
  isInEditMode,
  onTriggerEditMode,
}: TriggersConfigurationBlockProps) => {
  const [triggerCount, setTriggerCount] = useState<number>(0);

  const emptyStateAdditionalContent = isInEditMode ? (
    <p className="text-sm text-gray-400">
      Triggers are required - without any triggers, no users will get this
      journey
    </p>
  ) : null;

  return (
    <ConfigurationBlockCard
      title="Triggers"
      helpTooltip="'Triggers' are the events which assign this journey to users. There must be at least one trigger."
      icon={faBolt}
      isInEditMode={isInEditMode}
    >
      <div className="text-gray-500 italic">
        {triggerCount > 0 ? (
          <div>
            <div>TODO: Listing/summary</div>
            <button onClick={onTriggerEditMode}>Edit</button>
          </div>
        ) : (
          <ConfigurationBlockCardEmptyState
            itemName="trigger"
            icon={faBolt}
            onAddClick={() => {}}
            additionalChildren={emptyStateAdditionalContent}
          />
        )}
      </div>
    </ConfigurationBlockCard>
  );
};
