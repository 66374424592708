import { useTranslation } from "react-i18next";
import ClientFormAutomationUnitType from "../../../../types/dtos/admin/ClientFormAutomationUnitType";
import DangerAlert from "../../../alerts/DangerAlert";
import { journeyAutomationHelper } from "../../../../helpers";

interface MinimumAutomationValidationMessageProps {
  automationNumber: number | null | undefined;
  automationUnit: ClientFormAutomationUnitType | null | undefined;
}

/** A validation message which will display when the automation settings are invalid */
const MinimumAutomationValidationMessage = ({
  automationNumber,
  automationUnit,
}: MinimumAutomationValidationMessageProps) => {
  const { t } = useTranslation();
  const validationResult = journeyAutomationHelper.isValid(
    automationNumber,
    automationUnit
  );

  // Show the validation warning if the automation number is less than or equal to 0 or if the automation unit
  // is DAYS and the automation number is less than the minimum allowed days
  if (validationResult === "INVALID") {
    return (
      <DangerAlert
        prefix={t("Common.Validation.Oops")}
        message={`An automated journey cannot be more frequent than every ${journeyAutomationHelper._minAutomationDaysAllowed} days`}
      />
    );
  }

  return null;
};

export default MinimumAutomationValidationMessage;
