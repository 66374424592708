import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import {
  faBell,
  faBellExclamation,
  faEllipsisVertical,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ManagerDashboardPersonDto from "../../../types/dtos/dashboards/ManagerDashboardPersonDto";
import { Avatar, DropDownMenu } from "../../common";
import UserContext from "../../../state/UserContext";
import AppRoutes from "../../../routes/AppRoutes";
import ManageCatchUpPopup from "../../catch-ups/ManageCatchUpPopup";
import { AppraisalLevelClientTaskTypeDto } from "../../../types/dtos/users/AppraisalLevelClientTaskTypeDto";
import UserTagList from "../Tags/UserTagList";
import AssignDelegatedManagerPopup from "../AssignDelegatedManagerPopup";
import UserFurtherDetailsDto from "../../../types/dtos/generic/UserWithManagerDetailsDto";

interface DropDownMenuItemDto {
  text: string;
  customArg: string;
  disabled: boolean;
  disabledMsg?: string;
}

const DropDownMenuArgs = {
  advancedTasksPrefix: "ADVANCED-TASKS-PAGE_",
  history: "HISTORY",
  requestCatchUp: "REQUEST-CATCH-UP",
  legacyTasks: "LEGACY-TASKS",
  changeJourneyManager: "CHANGE-JOURNEY-MANAGER",
};

interface PeopleCardHeaderProps {
  managedUser: ManagerDashboardPersonDto;
  appraisalLevelTaskTypes: AppraisalLevelClientTaskTypeDto[];
  isLegacyClient: boolean;
  /**If this is undefined then the user doesn't have any history to show */
  onOpenUserHistory?(userId: number): void | undefined;
  onLiveJourneyClick?(userId: number): void | undefined;
  onUpdateUser(user: ManagerDashboardPersonDto): void | undefined;
}

function PeopleHeader({
  managedUser,
  appraisalLevelTaskTypes,
  isLegacyClient,
  onOpenUserHistory,
  onLiveJourneyClick,
  onUpdateUser,
}: PeopleCardHeaderProps) {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const isTheManagerLoggedIn = userContext.user.id === managedUser.managerId;
  const navigate = useNavigate();
  const handleOpenHistoryClick = () => {
    onOpenUserHistory && onOpenUserHistory(managedUser.userId);
  };
  const [catchUpModalIsOpen, setCatchUpModalIsOpen] = useState<boolean>(false);
  const [changeJourneyManagerModalIsOpen, setChangeJourneyManagerModalIsOpen] =
    useState<boolean>(false);

  const requestCatchUpDto: UserFurtherDetailsDto = {
    userId: managedUser.userId,
    firstName: managedUser.firstName,
    fullName: managedUser.fullName,
    managerFullName: managedUser.managerName,
    isUserCurrentlyDelegated: managedUser.delegatedManagerId != null,
  };

  const journeyManagerTitle =
    managedUser.delegatedManagerId != null
      ? t("Pages.ManagerDashboard.Actions.ChangeJourneyManager")
      : t("Pages.ManagerDashboard.Actions.AssignJourneyManager");

  const getDropDownMenuOptions = (): DropDownMenuItemDto[] => {
    const menuItems: DropDownMenuItemDto[] = [];

    const userTaskTypes =
      appraisalLevelTaskTypes.find(
        (x) => x.appraisalLevelId === managedUser.appraisalLevelId
      )?.taskTypes ?? [];

    if (userTaskTypes.length > 0) {
      userTaskTypes.map((tt) => {
        const taskTypeName = t(tt.pluralNameTranslationKeyIdentifier);
        menuItems.push({
          text: `View ${taskTypeName}`,
          customArg: DropDownMenuArgs.advancedTasksPrefix + tt.id,
          disabled: false,
        });
      });
    } else {
      // If there are no task types and its a legacy client, show link to legacy tasks
      if (isLegacyClient) {
        menuItems.push({
          text: t("Pages.ManagerDashboard.Actions.ViewTasks"),
          customArg: DropDownMenuArgs.legacyTasks,
          disabled: false,
        });
      }
    }

    menuItems.push({
      text: t("Pages.ManagerDashboard.Actions.ViewJourneyHistory"),
      customArg: DropDownMenuArgs.history,
      disabled: onOpenUserHistory == null ? true : false,
      disabledMsg:
        onOpenUserHistory == null
          ? t("Pages.ManagerDashboard.Actions.NoJourneyHistory")
          : undefined,
    });

    menuItems.push({
      text: t("Pages.ManagerDashboard.Actions.RequestACatchUp"),
      customArg: "REQUEST-CATCH-UP",
      disabled: false,
    });

    menuItems.push({
      text: journeyManagerTitle,
      customArg: "CHANGE-JOURNEY-MANAGER",
      disabled: false,
    });

    return menuItems;
  };

  const handleAdvancedTasksRedirect = (taskTypeId: string) => {
    const redirectUrl =
      AppRoutes.yourPeople.tasks.generatePathWithPreselectedEmployeeIdRoute(
        taskTypeId,
        managedUser.userId
      );
    navigate(redirectUrl);
  };

  const handleLegacyTasksRedirect = () => {
    const redirectUrl = AppRoutes.legacyTaskList.path;
    navigate(redirectUrl);
  };

  const btnDropDownContents = (
    <>
      {" "}
      <div className="mt-4">
        <button
          id="manager-dashboard-option"
          className="text-[#5d5d5d] bg-[#F5F5F5] hover:bg-gray-200 font-medium rounded-sm py-1 px-4 border border-gray-300 text-xs w-full lg:inline-block lg:w-auto hidden md:visible"
        >
          {t("ManagerDashboard.Common.Actions")}
          <FontAwesomeIcon icon={faChevronDown} className="pl-2" />
        </button>
        <button
          id="manager-dashboard-option"
          className="!px-4 block w-full visible md:hidden"
        >
          <FontAwesomeIcon icon={faEllipsisVertical} className="pl-2" />
        </button>
      </div>
    </>
  );
  const handleCatchUpItemClick = () => {
    setCatchUpModalIsOpen(true);
  };
  const handleChangeJourneyManagerItemClick = () => {
    setChangeJourneyManagerModalIsOpen(true);
  };
  const handleDropDownItemClick = (index: number, arg: string) => {
    switch (arg) {
      case DropDownMenuArgs.history:
        handleOpenHistoryClick();
        break;
      case DropDownMenuArgs.requestCatchUp:
        handleCatchUpItemClick();
        break;
      case DropDownMenuArgs.changeJourneyManager:
        handleChangeJourneyManagerItemClick();
        break;
      case DropDownMenuArgs.legacyTasks:
        handleLegacyTasksRedirect();
        break;
      default:
        if (arg.startsWith(DropDownMenuArgs.advancedTasksPrefix)) {
          const taskTypeId = arg.replace(
            DropDownMenuArgs.advancedTasksPrefix,
            ""
          );
          handleAdvancedTasksRedirect(taskTypeId);
        } else {
          console.error("Unknown manager page drop down menu arg: " + arg);
        }
        break;
    }
  };

  const handleLiveJourneyClick = () => {
    onLiveJourneyClick && onLiveJourneyClick(managedUser.userId);
  };

  return (
    <>
      {managedUser && (
        <div className="grid grid-cols-1 gap-1 md:mb-1">
          <div className="flex flex-row">
            <div className="hidden md:block md:mr-5 md:ml-1 md:mt-1">
              <Avatar
                userInitials={managedUser.initials}
                userFullName={managedUser.fullName}
                activeStatus="DO-NOT-SHOW"
                imageUrl={managedUser.profileImageUrl}
                size={12}
              />
            </div>
            <div className="flex flex-col grow">
              <h5 className="coloured-heading !mb-0 !mt-0 flex-none text-xl">
                {managedUser.fullName}
              </h5>
              {/* Tags */}
              <div className="mt-1">
                <UserTagList
                  jobTitle={managedUser.jobTitle}
                  jobTitleTooltip={t("Pages.Admin.Common.JobTitle")}
                  appraisalLevel={managedUser.appraisalLevelTransKeyIdentifier}
                  appraisalLevelTooltip={t("Common.AppraisalLevel")}
                  location={managedUser.locationName}
                  locationTooltip={t("Pages.Admin.Common.Location")}
                  viewingActor={
                    isTheManagerLoggedIn ? "MANAGER" : "DELEGATED-MANAGER"
                  }
                  managerOrDelegatedName={
                    isTheManagerLoggedIn
                      ? managedUser.delegatedManagerName
                      : managedUser.managerName
                  }
                />
              </div>
              {/* Current journey */}
              <div className="mt-3 mb-1">
                <p className="text-sm text-[#5d5d5d]">
                  <span>{t("Dashboards.LiveJourney")}:</span>
                  {managedUser.currentJourneyTitle && (
                    <>
                      <span className="pl-1 coloured-text">
                        {managedUser.currentJourneyTitle}
                      </span>
                      <button
                        onClick={handleLiveJourneyClick}
                        className="ml-2 group bg-gray-200 hover:bg-gray-300 rounded-sm py-1 px-2 border border-gray-300 text-xs"
                      >
                        <FontAwesomeIcon
                          icon={faBell}
                          className="group-hover:hidden"
                        />
                        <FontAwesomeIcon
                          icon={faBellExclamation}
                          className="hidden group-hover:inline-block"
                        />
                        <span className="pl-1">
                          {t(
                            "Pages.ManagerDashboard.Popup.LiveJourney.SendReminderButton"
                          )}
                        </span>
                      </button>
                    </>
                  )}
                  {!managedUser.currentJourneyTitle && (
                    <span className="pl-1">{t("Dashboards.None")}</span>
                  )}
                </p>
              </div>
            </div>
            <div className="shrink-0 justify-self-end">
              <DropDownMenu
                eventType="EVENT"
                items={getDropDownMenuOptions()}
                onItemClick={handleDropDownItemClick}
                customButtonContents={btnDropDownContents}
                menuButtonClassName="cursor-pointer"
              />
            </div>
          </div>
        </div>
      )}
      <ManageCatchUpPopup
        isOpen={catchUpModalIsOpen}
        onOpenChange={setCatchUpModalIsOpen}
        userId={requestCatchUpDto.userId}
        userDetails={requestCatchUpDto}
      />
      <AssignDelegatedManagerPopup
        title={journeyManagerTitle}
        isUserCurrentlyDelegated={requestCatchUpDto.isUserCurrentlyDelegated}
        isOpen={changeJourneyManagerModalIsOpen}
        onOpenChange={setChangeJourneyManagerModalIsOpen}
        managerFullName={requestCatchUpDto.managerFullName}
        delegatedManagerId={managedUser.delegatedManagerId}
        delegatedManagerName={managedUser.delegatedManagerName}
        managedUser={managedUser}
        onUpdateUser={onUpdateUser}
      />
    </>
  );
}

export default PeopleHeader;
