import SignedInUserDto from "../../types/dtos/users/SignedInUserDto";
import SubNavItem from "../../types/nav/SubNavItem";
import AppRoutes from "../AppRoutes";

const YourJourneySubNavItems = (
  loggedInUser: SignedInUserDto
): SubNavItem[] => {
  let output: SubNavItem[] = [];

  // Addition of 'Forms'
  output.push({
    title: "Nav.LargeScreen.MyDashboard.Forms",
    translateTitle: true,
    url: AppRoutes.yourJourney.forms,
    badgeCount: undefined
  });

  // Addition of 'Catch Ups'
  output.push({
    title: "Nav.LargeScreen.MyDashboard.CatchUps",
    translateTitle: true,
    url: AppRoutes.yourJourney.catchUps,
    badgeCount: undefined
  });

  // Addition of AdvancedTaskTypes
  loggedInUser.client.taskTypes.forEach((clientTaskType) => {
    const taskTypeHighlights = loggedInUser.highlightedTasks.find(
      (x) => x.taskTypeId === clientTaskType.id
    );
    output.push({
      title: clientTaskType.shortTitleTranslationKeyIdentifier,
      translateTitle: true,
      url: AppRoutes.yourJourney.tasks.generatePathRoute(clientTaskType.id),
      badgeCount: taskTypeHighlights?.ownTaskIds
        ? taskTypeHighlights.ownTaskIds.length
        : undefined,
    });
  });
  return output;
};

export default YourJourneySubNavItems;
