import { useEffect, useState } from "react";
import cx from "classnames";
import {
  faPlus,
  faBolt,
  faArrowProgress,
} from "@fortawesome/pro-regular-svg-icons";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { JourneyOverview } from "../../../types/admin/journeys";
import { Badge, PrimaryButton, Sidebar } from "../../common";
import { JourneyVersionSummary } from "../../../types/admin/journeys/api/JourneyOverviewApiResponse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ConfigurationBlockCard,
  ConfigurationBlockCardEmptyState,
} from "./ConfigurationBlocks";
import { ConditionsConfigurationBlock } from "./conditions/ConditionsConfigurationBlock";
import { TriggersConfigurationBlock } from "./triggers/TriggersConfigurationBlock";
import { StepsConfigurationBlock } from "./steps/StepsConfigurationBlock";

interface JourneyVersionEditorContainerProps {
  isLoading: boolean;
  journey: JourneyOverview | null;
}

export const JourneyVersionEditorContainer = ({
  isLoading,
  journey,
}: JourneyVersionEditorContainerProps) => {
  // State
  const [selectedVersionId, setSelectedVersionId] = useState<string | null>(
    null
  );
  const [blocksInEditMode, setBlocksInEditMode] = useState<boolean>(false);

  // Effects
  useEffect(() => {
    // Get the active version id
    /*if (journey && journey.versions && journey.versions.length > 0) {
      const activeVersion = journey.versions.find((v) => v.isActiveVersion);
      if (activeVersion) {
        setSelectedVersionId(activeVersion.id);
      }
    }*/
    setSelectedVersionId("1");
  }, [journey]);

  // Events
  const onVersionClicked = (versionId: string) => {
    setSelectedVersionId(versionId);

    // Reset to summary mode instead of edit mode
    setBlocksInEditMode(false);
  };

  const onTriggerEditMode = () => {
    setBlocksInEditMode(true);
  };

  return (
    <>
      <div className="bg-white border border-gray-200 rounded-md px-4 pt-1 pb-4 mt-2">
        <h4 className="font-semibold border-b border-dotted border-gray-300 pb-1">
          Configuration
        </h4>
        <div className="pt-1 pb-2">
          <JourneyVersionPicker
            selectedVersionId={selectedVersionId}
            onVersionClicked={onVersionClicked}
          />
        </div>
        {selectedVersionId && (
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
            <ConditionsConfigurationBlock
              journeyVersionId={selectedVersionId}
              isInEditMode={blocksInEditMode}
              onTriggerEditMode={onTriggerEditMode}
            />
            <TriggersConfigurationBlock
              journeyVersionId={selectedVersionId}
              isInEditMode={blocksInEditMode}
              onTriggerEditMode={onTriggerEditMode}
            />
            <StepsConfigurationBlock
              journeyVersionId={selectedVersionId}
              isInEditMode={blocksInEditMode}
              onTriggerEditMode={onTriggerEditMode}
            />
          </div>
        )}
      </div>
    </>
  );
};

const JourneyVersionPicker = ({
  selectedVersionId,
  onVersionClicked,
}: {
  selectedVersionId: string | null;
  onVersionClicked: (versionId: string) => void;
}) => {
  // State
  const [listIsExpanded, setListIsExpanded] = useState<boolean>(false);

  // Events
  const toggleListExpansion = () => {
    setListIsExpanded(!listIsExpanded);
  };

  const handleItemClick = (versionId: string) => {
    // Expand the list if it's not expanded already,
    // and if it is already expanded, trigger the version click event
    if (listIsExpanded) {
      onVersionClicked(versionId);
    }
    toggleListExpansion();
  };

  const versions: JourneyVersionSummary[] = [
    {
      id: "1",
      name: "Version 1",
      isActiveVersion: true,
    },
    {
      id: "2",
      name: "Version 2",
      isActiveVersion: false,
    },
    {
      id: "3",
      name: "Version 3",
      isActiveVersion: false,
    },
    {
      id: "4",
      name: "Version 4",
      isActiveVersion: false,
    },
    {
      id: "5",
      name: "Version 5",
      isActiveVersion: false,
    },
    {
      id: "6",
      name: "Version 6",
      isActiveVersion: false,
    },
    {
      id: "7",
      name: "Version 7",
      isActiveVersion: false,
    },
    {
      id: "8",
      name: "Version 8",
      isActiveVersion: false,
    },
    {
      id: "9",
      name: "Version 9",
      isActiveVersion: false,
    },
    {
      id: "10",
      name: "Version 10",
      isActiveVersion: false,
    },
  ];

  return (
    <div className="mb-2">
      <div className="mb-1">
        <span className="uppercase text-sm text-gray-500">Version to edit</span>
      </div>
      <div
        className={cx("flex flex-row", listIsExpanded ? "" : "items-center")}
      >
        <div className="flex-grow">
          {versions && versions.length > 0 && (
            <div
              className={cx(
                "max-h-64 overflow-y-auto pr-2 mr-2",
                listIsExpanded ? "bg-slate-50 p-1" : ""
              )}
            >
              <div className="grid grid-cols-1 gap-y-1">
                {versions.map((version) => (
                  <JourneyVersionListItem
                    key={version.id}
                    name={version.name}
                    id={version.id}
                    isActiveVersion={version.isActiveVersion}
                    isSelected={
                      selectedVersionId !== null &&
                      version.id === selectedVersionId
                    }
                    listIsExpanded={listIsExpanded}
                    onClick={handleItemClick}
                    onExpandList={() => setListIsExpanded(true)}
                  />
                ))}
              </div>
            </div>
          )}
          {!versions ||
            (versions.length === 0 && (
              <div className="text-gray-500 italic">No versions found</div>
            ))}
        </div>
        <div className="flex-initial">
          <PrimaryButton
            onClick={() => {}}
            text="New version"
            icon={faPlus}
            iconPlacement="LEFT"
            size="SMALL"
          />
        </div>
      </div>
    </div>
  );
};

const JourneyVersionListItem = ({
  id,
  name,
  isActiveVersion,
  isSelected,
  listIsExpanded,
  onClick,
  onExpandList,
}: JourneyVersionListItemProps) => {
  if (!listIsExpanded && !isSelected) {
    // Don't render
    return null;
  }

  const handleClick = () => {
    onClick(id);
  };

  let bgColourClassName = "bg-white hover:bg-slate-100";
  if (listIsExpanded && isSelected) {
    bgColourClassName = "bg-slate-200";
  } else if (isSelected) {
    bgColourClassName = "bg-slate-100 hover:bg-slate-200";
  }

  /* TODO
    Signal whether the active version or not
    Is there a badge for the status of the version?
    Show counts of steps, conditions, triggers (icons with numbers in grey)
    Three dot menu (horizontal?) to show options (Clone, Delete etc), incl on active one when list expanded
    -- Though clone could be done only via the "Add new version" popup for now, "Start blank" or "Clone existing"
    Handle "Add new version" click
    Handle version drop down change
  */

  const activeBadge = isActiveVersion ? (
    <Badge
      text="Live"
      backgroundColourClassName="bg-green-500"
      marginClassName="ml-2"
    />
  ) : null;

  const collapsedElements = (
    <>
      <button className="flex-grow" onClick={onExpandList}>
        <span
          className="block text-left py-1 pr-2 text-ellipsis text-gray-500 group-hover:text-gray-600"
          onClick={handleClick}
        >
          {name}
          {activeBadge}
        </span>
      </button>
      <div className="flex-initial">
        <button
          onClick={onExpandList}
          className="px-1 text-gray-400 group-hover:text-gray-600 leading-relaxed"
        >
          <FontAwesomeIcon icon={faCaretDown} size="1x" />
        </button>
      </div>
    </>
  );

  const expandedElements = (
    <>
      <button className="flex-grow" onClick={handleClick}>
        <div className="block text-left py-1 pr-2 text-ellipsis text-gray-500 group-hover:text-inherit">
          {name}
          {activeBadge}
        </div>
      </button>
    </>
  );

  return (
    <div
      className={cx(
        "px-2 py-1 border border-gray-200 rounded-sm group",
        bgColourClassName
      )}
    >
      <div className="flex flex-row gap-x-2">
        {listIsExpanded ? expandedElements : collapsedElements}
      </div>
    </div>
  );
};

interface JourneyVersionListItemProps {
  id: string;
  name: string;
  isActiveVersion: boolean;
  isSelected: boolean;
  listIsExpanded: boolean;
  onClick: (versionId: string) => void;
  onExpandList: () => void;
}
