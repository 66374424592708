import { faCircleNotch } from "@fortawesome/pro-duotone-svg-icons";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faCheckCircle } from "@fortawesome/pro-regular-svg-icons";
import {
  faCheck,
  faExclamationCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import PeoplePicker from "../../../common/PeoplePicker";
import { EditableFieldType } from "../../../../types/admin/client-journeys/EditableFieldType";
import { GenericDropDownList, Tooltip } from "../../../common";
import { KeyValuePair } from "../../../../types/generic";

interface EditableFieldProps {
  initialValue?: string;
  label: string;
  displayValue: string;
  inEditMode: boolean;
  isSaving: boolean;
  hasSaved: boolean;
  hasErrored?: boolean;
  errorMessage?: string;
  fieldType: EditableFieldType;
  userIdToExcludeFromResults?: number | null;
  dropdownItems?: KeyValuePair<number, string>[] | null;
  dropdownCurrentItem?: string;
  tooltip?: string;
  autoSuggestModifyWidth?: boolean;
  setInEditMode: (inEditMode: boolean) => void;
  onPeoplePickerChange?(selectedUserId: number): void;
  onPeoplePickerClearField?(): void;
  onDropdownChange?(newValue: string): void;
  onSaveIconClick(): void;
  onErrorTryAgain?(): void;
}

function EditableField({
  initialValue = undefined,
  label,
  displayValue,
  inEditMode,
  isSaving,
  hasSaved,
  hasErrored,
  errorMessage,
  fieldType,
  userIdToExcludeFromResults = null,
  dropdownItems,
  dropdownCurrentItem,
  tooltip,
  autoSuggestModifyWidth,
  setInEditMode,
  onPeoplePickerChange,
  onPeoplePickerClearField,
  onDropdownChange,
  onSaveIconClick,
  onErrorTryAgain,
}: EditableFieldProps) {
  const { t } = useTranslation();

  // If no displayValue was given show "None assigned"
  const inNonEditModeDisplayValue = displayValue
    ? displayValue
    : "None assigned";

  return (
    <div>
      <div className="mb-1 text-gray-500">
        <small>{label}</small>
        {tooltip && (
          <Tooltip
            content={tooltip}
            appearAt="bottom"
            align="start"
            triggerElement={
              <button className="px-1">
                <FontAwesomeIcon icon={faInfoCircle} size="xs" />
              </button>
            }
          />
        )}
      </div>
      {!inEditMode && !isSaving && !hasSaved && !hasErrored && (
        <div
          onClick={() => setInEditMode(true)}
          className="text-sm mb-2 bg-gray-100 py-2 px-3 cursor-pointer group rounded-sm hover:bg-gray-200"
        >
          <div className="flex flex-row">
            <div className="grow">
              <span>{inNonEditModeDisplayValue}</span>
            </div>
            <div className="grow-0 text-right w-2">
              <FontAwesomeIcon
                icon={faPencilAlt}
                size="xs"
                className="text-gray-400"
              />
            </div>
          </div>
        </div>
      )}
      {inEditMode &&
        !isSaving &&
        !hasSaved &&
        !hasErrored &&
        fieldType === "PEOPLE-PICKER" &&
        onPeoplePickerChange && (
          <div className="flex">
            <div className="w-11/12">
              <PeoplePicker
                initialInput={initialValue}
                inputId="input-id"
                className="block p-2 w-full bg-gray-100 border-0"
                onPeoplePickerChange={onPeoplePickerChange}
                userIdToExcludeFromResults={userIdToExcludeFromResults}
                autoSuggestModifyWidth={autoSuggestModifyWidth}
                allowClearField={true}
                onClearFieldClick={onPeoplePickerClearField}
              />
            </div>
            <div className="w-1/12 text-right mt-2 hover:cursor-pointer">
              <FontAwesomeIcon
                icon={faCheckCircle}
                size="1x"
                className="text-gray-400 text-green-800"
                onClick={onSaveIconClick}
              />
            </div>
          </div>
        )}
      {inEditMode &&
        !isSaving &&
        !hasSaved &&
        !hasErrored &&
        fieldType === "DROPDOWN" &&
        dropdownItems &&
        dropdownCurrentItem &&
        onDropdownChange && (
          <div className="flex">
            <div className="w-11/12">
              <GenericDropDownList
                className="w-full bg-gray-100 border-0"
                items={dropdownItems}
                currentValue={dropdownCurrentItem}
                onChange={onDropdownChange}
                includeSelectOption={false}
              />
            </div>
            <div className="w-1/12 text-right mt-2 hover:cursor-pointer">
              <FontAwesomeIcon
                icon={faCheckCircle}
                size="1x"
                className="text-gray-400 text-green-800"
                onClick={onSaveIconClick}
              />
            </div>
          </div>
        )}
      {isSaving && (
        <div className="text-sm mb-2 bg-gray-100 py-2 px-3 rounded-sm">
          <FontAwesomeIcon
            icon={faCircleNotch}
            size="sm"
            className="mr-2 animate-spin"
          />
          {t("Common.Saving")}...
        </div>
      )}
      {!isSaving && hasSaved && (
        <div className="text-sm mb-2 bg-green-200 text-green-700 py-2 px-3 rounded-sm">
          <FontAwesomeIcon icon={faCheck} size="sm" className="mr-2" />
          {t("Common.Saved")}!
        </div>
      )}
      {hasErrored && !isSaving && onErrorTryAgain && (
        <div className="text-sm mb-2 bg-red-200 text-red-700 py-2 px-3 rounded-sm">
          {errorMessage && (
            <Tooltip
              content={errorMessage}
              appearAt="bottom"
              align="start"
              triggerElement={
                <button className="px-1">
                  <FontAwesomeIcon icon={faExclamationCircle} size="sm" />
                </button>
              }
            />
          )}
          Failed to save{" "}
          <button
            className="underline cursor-pointer"
            onClick={onErrorTryAgain}
          >
            Try again
          </button>
        </div>
      )}
    </div>
  );
}

export default EditableField;
