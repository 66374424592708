import { useTranslation } from "react-i18next";
import { DefaultSettingDto } from "../../../../types/dtos/admin/DefaultSettingDto";
import {
  ColourPicker,
  HtmlEditor,
  Label,
  ModeDropDownList,
} from "../../../common";
import { useEffect, useState } from "react";
import {
  colourHelper,
  dateHelper,
  journeyAutomationHelper,
} from "../../../../helpers";
import { ValidationResult } from "../../../../types/forms";
import SuccessAlert from "../../../alerts/SuccessAlert";
import WindowPicker from "../../../common/WindowPicker";
import { WindowDto } from "../../../../types/dtos/admin/WindowDto";
import InductionDateRange from "../../../common/InductionDateRange";
import ClientFormModeType from "../../../../types/dtos/admin/ClientFormModeType";
import ClientFormAutomationUnitType from "../../../../types/dtos/admin/ClientFormAutomationUnitType";
import AutomationPicker from "../../../common/AutomationPicker";
import ClientFormModeEnum from "../../../../types/admin/client-journeys/ClientFormModeEnum";
import ClientFormAutomationUnitEnum from "../../../../types/admin/client-journeys/ClientFormAutomationUnitEnum";
import { useAuth } from "react-oidc-context";
import adminApi from "../../../../api/dashboard/adminApi";
import WindowDatabaseStatusEnum from "../../../../types/admin/client-journeys/WindowDatabaseStatus";
import { DefaultSettings } from "../../../../state/admin/config/DefaultSettings";
import SmallLoader from "../../../loaders/SmallLoader";
import MinimumAutomationValidationMessage from "./MinimumAutomationValidationMessage";
import AutomationWarningMessage from "./AutomationWarningMessage";

interface DefaultSettingsTabProps {
  clientFormId: string | undefined;
  settings: DefaultSettingDto;
  defaultSettings: DefaultSettings;
  onDefaultSettingsChange(newState: DefaultSettings): void;
}

function DefaultSettingsTab({
  clientFormId,
  settings,
  defaultSettings,
  onDefaultSettingsChange,
}: DefaultSettingsTabProps) {
  const { t } = useTranslation();
  const maxWordCount = 500;
  const contrastCheckerFontHexCode = colourHelper.getDefaultTextColour();
  const auth = useAuth();
  const admApi = new adminApi(auth.user?.access_token);
  const modeInputId = "mode-select";
  const introMsgInputId = "intro-mesage-editor";
  const themeColourInputId = "theme-colour-selector";
  const automationAmountInputId = "automation-amount-input";
  const inductionDateRangeInputId = "induction-date-range-input";
  const windowsInputId = "default-windows";

  const [isFormSaving, setIsFormSaving] = useState<boolean>(false);
  const [currentSavedMode, setCurrentSavedMode] = useState<
    ClientFormModeType | undefined
  >(undefined);

  useEffect(() => {
    // Set the mode on page load, so we can track when it's changed from its original value
    // and warn the user about the effects of setting a form up as automated
    setCurrentSavedMode(defaultSettings.selectedMode);
  }, []);

  useEffect(() => {
    if (defaultSettings.automationAmount && defaultSettings.automationUnit) {
      onDefaultSettingsChange({
        ...defaultSettings,
        nextDate: dateHelper.getFromCurrentDateUtcs(
          defaultSettings.automationAmount,
          defaultSettings.automationUnit
        ),
      });
    }
  }, [defaultSettings.automationAmount, defaultSettings.automationUnit]);

  useEffect(() => {
    if (defaultSettings.inductionDaysFrom) {
      onDefaultSettingsChange({
        ...defaultSettings,
        nextDate: dateHelper.getFromCurrentDateUtcs(
          defaultSettings.inductionDaysFrom,
          "DAYS"
        ),
      });
    }
  }, [defaultSettings.inductionDaysFrom]);

  //  |------------- EVENTS -------------|
  const handleModeChange = (newMode: ClientFormModeType) => {
    if (newMode !== "AUTOMATED") {
      onDefaultSettingsChange({
        ...defaultSettings,
        formIsDirty: true,
        selectedMode: newMode,
        automationAmount: undefined,
        automationUnit: undefined,
      });
    } else {
      onDefaultSettingsChange({
        ...defaultSettings,
        formIsDirty: true,
        selectedMode: newMode,
        automationAmount: settings.automationAmount
          ? settings.automationAmount
          : 3,
        automationUnit: settings.automationUnit
          ? settings.automationUnit
          : "MONTHS",
      });
    }
  };

  const handleHtmlEditorChange = (value: string, wordCount: number) => {
    let validationResult;
    if (defaultSettings.showValidationErrors) {
      // Only perform validation if the user has tried to save already
      validationResult = validateIntroMessage(wordCount);
    }

    onDefaultSettingsChange({
      ...defaultSettings,
      formIsDirty: true,
      introMessage: value,
      introMessageValidation:
        validationResult != undefined ? validationResult : null,
      introMessageWordCount: wordCount,
    });
  };

  const handleThemeColourChange = (newThemeColour: string) => {
    let validationResult;
    if (defaultSettings.showValidationErrors) {
      // Only perform validation if the user has tried to save already
      validationResult = validateThemeColour(newThemeColour);
    }

    onDefaultSettingsChange({
      ...defaultSettings,
      formIsDirty: true,
      themeColour: newThemeColour,
      themeColourValidation:
        validationResult != undefined ? validationResult : null,
    });
  };

  const handleAutomationAmountChange = (newAutomationAmount: number) => {
    if (newAutomationAmount !== null) {
      onDefaultSettingsChange({
        ...defaultSettings,
        formIsDirty: true,
        automationAmount: newAutomationAmount,
      });
    }
  };

  const handleAutomationUnitChange = (
    newAutomationUnitString: string | null
  ) => {
    const newAutomationUnit =
      newAutomationUnitString !== null
        ? (newAutomationUnitString as ClientFormAutomationUnitType)
        : undefined;

    onDefaultSettingsChange({
      ...defaultSettings,
      formIsDirty: true,
      automationUnit: newAutomationUnit,
    });
  };

  const handleInductionDaysToChange = (newInductionDaysTo: number) => {
    if (newInductionDaysTo !== null) {
      onDefaultSettingsChange({
        ...defaultSettings,
        formIsDirty: true,
        inductionDaysTo: newInductionDaysTo,
      });
    }
  };

  const handleInductionDaysFromChange = (newInductionDaysFrom: number) => {
    if (newInductionDaysFrom !== null) {
      onDefaultSettingsChange({
        ...defaultSettings,
        formIsDirty: true,
        inductionDaysFrom: newInductionDaysFrom,
      });
    }
  };

  const onAddWindow = (startDate: Date | null, endDate: Date | null) => {
    if (defaultSettings.windows && startDate && endDate) {
      const newWindowDto: WindowDto = {
        id: 0,
        startDate: startDate,
        endDate: endDate,
        isActive: false,
        status: WindowDatabaseStatusEnum.ToAdd,
      };

      const sorted = [...defaultSettings.windows, newWindowDto].sort(
        // @ts-ignore
        (a, b) => a.startDate - b.startDate
      );
      onDefaultSettingsChange({
        ...defaultSettings,
        formIsDirty: true,
        windows: sorted,
      });
    }
  };

  const onEditWindow = (window: WindowDto) => {
    if (defaultSettings.windows) {
      // 'Clone'
      let newState = [...defaultSettings.windows];

      // Create window object to represent the edited window
      // Note: If the EditedWindow is yet to be added to the database we need to keep the status as ToAdd
      const editedWindow: WindowDto = {
        id: window.id,
        startDate: window.startDate,
        endDate: window.endDate,
        isActive: window.isActive,
        status:
          window.status == WindowDatabaseStatusEnum.ToAdd
            ? window.status
            : WindowDatabaseStatusEnum.ToUpdate,
      };

      // Find index in the cloned list and replace it, before updating the defaultSettings.
      let index = newState.findIndex((x) => x.id == window.id);
      newState.splice(index, 1, editedWindow);

      onDefaultSettingsChange({
        ...defaultSettings,
        formIsDirty: true,
        windows: newState,
      });
    }
  };

  const onDeleteWindow = (window: WindowDto) => {
    if (window.status == WindowDatabaseStatusEnum.Exists) {
      // Flag the window as needing as 'ToDelete' from the database when saved.
      window.status = WindowDatabaseStatusEnum.ToDelete;
    } else {
      // If the window being deleted doesn't already exist in terms of the database status
      // then it can be removed from the windows completely.
      var newState = defaultSettings.windows?.filter(function (w) {
        return w !== window;
      });

      if (newState && newState?.length > 0) {
        onDefaultSettingsChange({
          ...defaultSettings,
          formIsDirty: true,
          windows: newState,
        });
      } else {
        onDefaultSettingsChange({
          ...defaultSettings,
          formIsDirty: true,
          windows: [],
        });
      }
    }
  };

  const onSaveChanges = () => {
    if (settingsAreValid()) {
      setIsFormSaving(true);
      const defaultSettingsDto: DefaultSettingDto = {
        mode: defaultSettings.selectedMode,
        modeEnum: settings.modeEnum,
        introMessageTranslationKeyId: settings.introMessageTranslationKeyId,
        introMessage: defaultSettings.introMessage,
        themeColour: defaultSettings.themeColour,
      };

      if (defaultSettings.selectedMode === "AUTOMATED") {
        defaultSettingsDto.modeEnum = ClientFormModeEnum.Automated;
        defaultSettingsDto.automationAmount = defaultSettings.automationAmount;
        defaultSettingsDto.automationUnit = defaultSettings.automationUnit;

        switch (defaultSettingsDto.automationUnit) {
          case "YEARS":
            defaultSettingsDto.automationUnitEnum =
              ClientFormAutomationUnitEnum.Years;
            break;
          case "MONTHS":
            defaultSettingsDto.automationUnitEnum =
              ClientFormAutomationUnitEnum.Months;
            break;
          case "WEEKS":
            defaultSettingsDto.automationUnitEnum =
              ClientFormAutomationUnitEnum.Weeks;
            break;
          case "DAYS":
            defaultSettingsDto.automationUnitEnum =
              ClientFormAutomationUnitEnum.Days;
            break;
        }
      } else if (defaultSettings.selectedMode === "WINDOWED") {
        defaultSettingsDto.modeEnum = ClientFormModeEnum.Windowed;
        defaultSettingsDto.windows = defaultSettings.windows;
      } else if (defaultSettings.selectedMode === "INDUCTION") {
        defaultSettingsDto.modeEnum = ClientFormModeEnum.Induction;
        defaultSettingsDto.inductionDaysFrom =
          defaultSettings.inductionDaysFrom;
        defaultSettingsDto.inductionDaysTo = defaultSettings.inductionDaysTo;
      } else if (defaultSettings.selectedMode === "HIDDEN-ON-DASHBOARD") {
        defaultSettingsDto.modeEnum = ClientFormModeEnum.HiddenOnDashboard;
      } else if (defaultSettings.selectedMode === "MANUAL-ONLY") {
        defaultSettingsDto.modeEnum = ClientFormModeEnum.ManualOnly;
      } else if (defaultSettings.selectedMode === "EXIT") {
        defaultSettingsDto.modeEnum = ClientFormModeEnum.Exit;
      }

      if (clientFormId != undefined) {
        admApi.saveDefaultSettings(
          parseInt(clientFormId),
          defaultSettingsDto,
          (data: WindowDto[]) => {
            // Only if its a 'Windowed' form do we set this window prop.
            // We can use settings.modeEnum instead of selectedMode here as the 'Windowed' forms aren't
            // ever going to change.
            if (
              settings.modeEnum == ClientFormModeEnum.Windowed &&
              data != null
            ) {
              // Turn dates into JS Date objects so they can be sorted.
              data.forEach((window) => {
                window.startDate = new Date(window.startDate);
                window.endDate = new Date(window.endDate);
              });

              onDefaultSettingsChange({
                ...defaultSettings,
                windows: data,
                formIsDirty: false,
                showSuccessAlert: true,
                showValidationErrors: false,
              });
            } else {
              onDefaultSettingsChange({
                ...defaultSettings,
                formIsDirty: false,
                showSuccessAlert: true,
                showValidationErrors: false,
              });
            }
            setIsFormSaving(false);
            setCurrentSavedMode(defaultSettings.selectedMode);
          },
          (error: any) => {
            console.error(error);
          }
        );
      }
    } else {
      // Show errors
      setIsFormSaving(false);
      onDefaultSettingsChange({
        ...defaultSettings,
        showSuccessAlert: false,
        showValidationErrors: true,
        introMessageValidation: validateIntroMessage(
          defaultSettings.introMessageWordCount
        ),
        themeColourValidation: validateThemeColour(defaultSettings.themeColour),
      });
    }
  };

  const onResetChanges = () => {
    onDefaultSettingsChange({
      ...defaultSettings,
      selectedMode: settings.mode,
      introMessage: settings.introMessage,
      introMessageValidation: null,
      themeColour: settings.themeColour,
      automationAmount: settings.automationAmount,
      automationUnit: settings.automationUnit,
      windows: settings.windows,
      inductionDaysFrom: settings.inductionDaysFrom,
      inductionDaysTo: settings.inductionDaysTo,
      showSuccessAlert: false,
      showValidationErrors: false,
      formIsDirty: false,
    });
  };

  //  |------------- VALIDATION -------------|
  const settingsAreValid = (): boolean => {
    const introMessageValidationResult = validateIntroMessage(
      defaultSettings.introMessageWordCount
    );
    const themeColourValidationResult = validateThemeColour(
      defaultSettings.themeColour
    );
    const automationUnitsAreValid = journeyAutomationHelper.isValid(
      defaultSettings.automationAmount,
      defaultSettings.automationUnit
    );

    return (
      introMessageValidationResult.isValid &&
      themeColourValidationResult.isValid &&
      automationUnitsAreValid !== "INVALID"
    );
  };

  const validateIntroMessage = (wordCount: number): ValidationResult => {
    let validationResult: ValidationResult;

    if (wordCount === 0) {
      validationResult = new ValidationResult(false, [
        { errorType: "REQUIRED" },
      ]);
    } else if (wordCount > maxWordCount) {
      validationResult = new ValidationResult(false, [
        { errorType: "TOO-LONG", max: maxWordCount, current: wordCount },
      ]);
    } else {
      validationResult = new ValidationResult(true);
    }

    return validationResult;
  };

  const validateThemeColour = (themeColour: string): ValidationResult => {
    let validationResult: ValidationResult;

    if (themeColour === "#" || themeColour.length === 0) {
      validationResult = new ValidationResult(false, [
        { errorType: "REQUIRED" },
      ]);
    } else if (!colourHelper.isValidHex(themeColour)) {
      validationResult = new ValidationResult(false, [
        { errorType: "INVALID-HEX" },
      ]);
    } else if (
      !colourHelper.doesColourPassContrastCheck(
        contrastCheckerFontHexCode,
        themeColour
      )
    ) {
      validationResult = new ValidationResult(false, [
        { errorType: "ACCESSIBILITY-ISSUE" },
      ]);
    } else {
      validationResult = new ValidationResult(true);
    }

    return validationResult;
  };

  return (
    <>
      <div>
        {defaultSettings.showSuccessAlert && (
          <SuccessAlert
            prefix={t("Common.Success")}
            message={t("Common.Validation.Successful.SavedSettings")}
          />
        )}
        {defaultSettings.selectedMode !== "WINDOWED" &&
          defaultSettings.selectedMode !== "INDUCTION" &&
          defaultSettings.selectedMode !== "EXIT" &&
          settings.modes && (
            <div className="mb-2">
              <ModeDropDownList
                inputId={modeInputId}
                value={defaultSettings.selectedMode}
                onChange={handleModeChange}
                modes={settings.modes}
              />
              <AutomationWarningMessage
                originalMode={currentSavedMode}
                selectedMode={defaultSettings.selectedMode}
                automationAmount={defaultSettings.automationAmount}
                automationUnit={defaultSettings.automationUnit}
              />
            </div>
          )}
        <div className="mb-2 pt-4">
          <Label
            htmlFor={introMsgInputId}
            text={t("Pages.Admin.Common.IntroMessage")}
          />
          <HtmlEditor
            inputId={introMsgInputId}
            toolbarMode="ADVANCED"
            currentValue={defaultSettings.introMessage}
            onValueChangeWithWordCount={handleHtmlEditorChange}
            showValidationErrors={defaultSettings.showValidationErrors}
            validationResult={defaultSettings.introMessageValidation}
            currentWordCount={defaultSettings.introMessageWordCount}
            maxWordCount={maxWordCount}
          />
        </div>
        <div className="mb-2">
          <Label
            htmlFor={themeColourInputId}
            text={t("Pages.Admin.Common.ThemeColour")}
          />
          <ColourPicker
            inputId={themeColourInputId}
            value={defaultSettings.themeColour}
            fontColour={contrastCheckerFontHexCode}
            onChange={handleThemeColourChange}
            showValidationErrors={defaultSettings.showValidationErrors}
            validationResult={defaultSettings.themeColourValidation}
          />
        </div>
        {defaultSettings.selectedMode === "AUTOMATED" &&
          settings.automationUnits &&
          defaultSettings.automationAmount &&
          defaultSettings.automationUnit && (
            <div className="mb-2 mt-6">
              <Label
                htmlFor={automationAmountInputId}
                text={t("Pages.Admin.Common.SendSectionToUsersEveryX")}
              />
              <MinimumAutomationValidationMessage
                automationNumber={defaultSettings.automationAmount}
                automationUnit={defaultSettings.automationUnit}
              />
              <AutomationPicker
                numberInputId={automationAmountInputId}
                dropdownInputId={automationAmountInputId}
                onAutomationAmountChange={handleAutomationAmountChange}
                onAutomationUnitChange={handleAutomationUnitChange}
                value={defaultSettings.automationAmount}
                selectedAutomationUnit={defaultSettings.automationUnit}
                automationUnits={settings.automationUnits}
                nextDate={defaultSettings.nextDate}
              />
            </div>
          )}
        {defaultSettings.selectedMode === "WINDOWED" && (
          <WindowPicker
            inputId={windowsInputId}
            onAdd={onAddWindow}
            onEdit={onEditWindow}
            onDelete={onDeleteWindow}
            windows={defaultSettings.windows}
          />
        )}
        {defaultSettings.selectedMode === "INDUCTION" &&
          defaultSettings.inductionDaysTo &&
          defaultSettings.inductionDaysFrom && (
            <InductionDateRange
              inputId={inductionDateRangeInputId}
              onDaysToChange={handleInductionDaysToChange}
              onDaysFromChange={handleInductionDaysFromChange}
              daysTo={defaultSettings.inductionDaysTo}
              daysFrom={defaultSettings.inductionDaysFrom}
              nextDate={defaultSettings.nextDate}
            />
          )}
        <div className="flex flex-row py-3">
          {!isFormSaving && (
            <>
              <div className="grow">
                <button
                  className="btn-primary"
                  disabled={!defaultSettings.formIsDirty}
                  onClick={onSaveChanges}
                >
                  {t("Pages.Admin.Buttons.SaveChanges")}
                </button>
              </div>
              {defaultSettings.formIsDirty && (
                <div>
                  <button className="btn-secondary" onClick={onResetChanges}>
                    {t("Pages.Admin.Buttons.ResetChanges")}
                  </button>
                </div>
              )}
            </>
          )}

          {isFormSaving && (
            <div className="grow">
              <div className="pt-2">
                <SmallLoader />
                <p className="text-center pt-1 text-[#959595] text-sm">
                  {t("Common.SavingYourSettings")}...
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default DefaultSettingsTab;
