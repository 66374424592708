import React, { useEffect, useReducer, Reducer } from "react";
import { useTranslation } from "react-i18next";
import { MainContainer } from "../../../components/layout";
import AppContext from "../../../state/AppContext";
import UserContext from "../../../state/UserContext";
import { Link, useNavigate } from "react-router-dom";
import AppRoutes from "../../AppRoutes";
import { SentJourneyDto } from "../../../types/dtos/admin/SentJourneyDto";
import { useAuth } from "react-oidc-context";
import adminApi from "../../../api/dashboard/adminApi";
import SentJourneyApiResponseDto from "../../../types/dtos/admin/SentJourneyApiResponseDto";
import ClientSentJourneyStatusDisplay from "../../../components/common/ClientSentJourneyStatusDisplay";
import {
  Badge,
  EditableDate,
  FormattedDate,
  ModalPopup,
  Switch,
  Tooltip,
} from "../../../components/common";
//@ts-ignore
import Pagination from "react-js-pagination";
import { parseISO } from "date-fns";
import FullScreenLoaderWithMessage from "../../../components/loaders/FullScreenLoaderWithMessage";
import { SentJourneyRecipientDto } from "../../../types/dtos/admin/SentJourneyRecipientDto";
import SmallLoader from "../../../components/loaders/SmallLoader";
import SentJourneyRecipientApiResponseDto from "../../../types/dtos/admin/SentJourneyRecipientApiResponseDto";
import adminHelper from "../../../helpers/adminHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faPencilAlt } from "@fortawesome/pro-solid-svg-icons";
import EditCriteriaPopupModal from "../../../components/admin/client-journeys/SendingHistory/EditCriteriaPopupModal";
import TaskManagementReviewDateType from "../../../types/admin/client-journeys/TaskManagementReviewDateType";

interface SendingHistoryState {
  waitingForApiResult: boolean;
  sentJourneys: SentJourneyDto[];
  activePageNumber: number;
  itemsPerPage: number;
  displaySentJourneys: SentJourneyDto[];
  totalItemCount: number;
  recipientModalIsOpen: boolean;
  isRecipientModalLoading: boolean;
  recipientModalUsers: SentJourneyRecipientDto[];

  taskManagementModalIsOpen: boolean;
  taskClientSentJourneyId?: number;
  taskMinimumDate?: Date | null;
  taskDateType?: TaskManagementReviewDateType | null;
}

function SendingHistory() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const appContext = React.useContext(AppContext);
  const userContext = React.useContext(UserContext);
  const auth = useAuth();
  const admApi = new adminApi(auth.user?.access_token);

  const [state, setState] = useReducer<
    Reducer<SendingHistoryState, Partial<SendingHistoryState>>
  >((state, newState) => ({ ...state, ...newState }), {
    waitingForApiResult: true,
    sentJourneys: [],
    activePageNumber: 1,
    itemsPerPage: 5,
    displaySentJourneys: [],
    totalItemCount: 0,
    recipientModalIsOpen: false,
    isRecipientModalLoading: true,
    recipientModalUsers: [],
    taskManagementModalIsOpen: false,
  });

  const prepareSentJourneysForDisplay = (
    sentJourneys: SentJourneyDto[],
    startIndex: number
  ): SentJourneyDto[] => {
    if (!sentJourneys || sentJourneys.length === 0) return [];

    const output = [...sentJourneys].splice(startIndex, state.itemsPerPage);

    // Parse the date strings into Date objects, if they are strings from the API, otherwise leave them as the date object/null that they are
    // (they can be date objects if set via the date picker)
    output.forEach((sj) => {
      sj.journeyDeadline =
        sj.journeyDeadline && typeof sj.journeyDeadline === "string"
          ? parseISO(sj.journeyDeadline)
          : sj.journeyDeadline;
    });

    return output;
  };

  useEffect(() => {
    adminHelper.checkAdminAccessAndRedirectWhenNoAccess(
      userContext,
      "JOURNEY-CONFIG",
      navigate
    );

    // Call the API to load the necessary state
    admApi.getSendingHistory(
      (data: SentJourneyApiResponseDto) => {
        setState({
          waitingForApiResult: false,
          sentJourneys: data.sentJourneys,
          displaySentJourneys: prepareSentJourneysForDisplay(
            data.sentJourneys,
            0
          ),
          totalItemCount: data.sentJourneys.length,
        });
      },
      (error: any) => {
        console.error(error);
      }
    );

    appContext.setPageTitle(
      t("Pages.Admin.PageTitle.Journeys") +
        " - " +
        t("Pages.Admin.Buttons.SendingHistory")
    );
    appContext.setShowPageTitleAccent(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePageChange = (pageNumber: number) => {
    const startIndex = (pageNumber - 1) * state.itemsPerPage;
    const displayItems = prepareSentJourneysForDisplay(
      state.sentJourneys,
      startIndex
    );

    setState({
      displaySentJourneys: displayItems,
      activePageNumber: pageNumber,
    });
  };

  const handleDeadlineChange = (
    newDeadlineDate: Date | null,
    sentJourneyId: number
  ) => {
    const updateStateDeadline = (sentJourneys: SentJourneyDto[]) => {
      if (!sentJourneys || sentJourneys.length === 0) return [];

      const updatedSentJourneys = [...sentJourneys];
      const sentJourneyToUpdate = updatedSentJourneys.find(
        (sj) => sj.clientSentJourneyId === sentJourneyId
      );

      if (sentJourneyToUpdate) {
        sentJourneyToUpdate.journeyDeadline = newDeadlineDate;
      }

      return updatedSentJourneys;
    };

    // Update the API
    admApi.updateSentJourneyDeadline(
      sentJourneyId,
      newDeadlineDate,
      (success: boolean) => {
        if (success) {
          // Update the page state
          setState({
            sentJourneys: updateStateDeadline(state.sentJourneys),
            displaySentJourneys: updateStateDeadline(state.displaySentJourneys),
          });
        } else {
          console.error("Error saving deadline");
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  };

  const handleAllowSendingChange = (
    newAllowSending: boolean,
    sentJourneyId: number
  ) => {
    const updateStateDeadline = (sentJourneys: SentJourneyDto[]) => {
      if (!sentJourneys || sentJourneys.length === 0) return [];

      const updatedSentJourneys = [...sentJourneys];
      const sentJourneyToUpdate = updatedSentJourneys.find(
        (sj) => sj.clientSentJourneyId === sentJourneyId
      );

      if (sentJourneyToUpdate) {
        sentJourneyToUpdate.allowAdminUsersToSend = newAllowSending;
      }

      return updatedSentJourneys;
    };

    // Update the API
    admApi.updateSentJourneyAllowAdminSending(
      sentJourneyId,
      newAllowSending,
      (success: boolean) => {
        if (success) {
          // Update the page state
          setState({
            sentJourneys: updateStateDeadline(state.sentJourneys),
            displaySentJourneys: updateStateDeadline(state.displaySentJourneys),
          });
        } else {
          console.error("Error saving deadline");
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  };

  const loadModalRecipientsClick = (clientSentJourneyId: number) => {
    setState({ recipientModalIsOpen: true });

    admApi.getClientSentJourneyRecipients(
      clientSentJourneyId,
      (json: SentJourneyRecipientApiResponseDto) => {
        setState({
          isRecipientModalLoading: false,
          recipientModalUsers: json.recipients,
        });
      },
      (error: any) => {
        console.error(error);
      }
    );
  };

  const onModalCancelClick = () => {
    setState({ recipientModalIsOpen: false, recipientModalUsers: [] });
  };

  const showTaskManagementModal = (
    clientSentJourneyId: number,
    minimumDate: Date | undefined | null,
    dateType: TaskManagementReviewDateType | undefined | null
  ) => {
    setState({
      taskManagementModalIsOpen: true,
      taskClientSentJourneyId: clientSentJourneyId,
      taskMinimumDate: minimumDate,
      taskDateType: dateType,
    });
  };

  const onTaskManagementUpdate = (
    sentJourneyId: number,
    taskMinimumDate: Date | null,
    taskDateType: TaskManagementReviewDateType | null
  ) => {
    const updateStateDeadline = (sentJourneys: SentJourneyDto[]) => {
      if (!sentJourneys || sentJourneys.length === 0) return [];

      const updatedSentJourneys = [...sentJourneys];
      const sentJourneyToUpdate = updatedSentJourneys.find(
        (sj) => sj.clientSentJourneyId === sentJourneyId
      );

      if (sentJourneyToUpdate) {
        sentJourneyToUpdate.minAdvancedTaskReviewDate = taskMinimumDate;
        sentJourneyToUpdate.advancedTaskReviewDateType = taskDateType;
      }

      return updatedSentJourneys;
    };

    // Update the API
    admApi.updateSentJourneyTaskManagementConfigs(
      sentJourneyId,
      taskMinimumDate,
      taskDateType,
      (success: boolean) => {
        if (success) {
          // Update the page state
          setState({
            taskManagementModalIsOpen: false,
            sentJourneys: updateStateDeadline(state.sentJourneys),
            displaySentJourneys: updateStateDeadline(state.displaySentJourneys),
          });
        } else {
          console.error("Error updating task management configs");
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  };

  const onTaskManagementModalCancelClick = () => {
    setState({
      taskManagementModalIsOpen: false,
      taskClientSentJourneyId: undefined,
      taskMinimumDate: undefined,
      taskDateType: undefined,
    });
  };

  return (
    <MainContainer>
      <div className="pt-2 hover:underline">
        <Link to={AppRoutes.admin.clientJourneys.pathOpenWithConfigTabSelected}>
          <FontAwesomeIcon size="xs" icon={faChevronLeft} />
          <span className="pl-1.5">{t("Common.Back")}</span>
        </Link>
      </div>
      {state.waitingForApiResult && (
        <FullScreenLoaderWithMessage message={t("Common.Loading")} />
      )}
      {!state.waitingForApiResult && (
        <>
          <div className="mt-4">
            {state.displaySentJourneys.map((sj) => {
              const deadlineDateEditControl = (
                <EditableDate
                  currentValue={sj.journeyDeadline}
                  dateDisplayMode={"DATE-ONLY"}
                  dateIsRequired={false}
                  onChangeSubmitted={(newDate) =>
                    handleDeadlineChange(newDate, sj.clientSentJourneyId)
                  }
                  infoMessageText={t("Pages.Admin.Common.DeadlineInfoWarning")}
                />
              );

              const allowSendingControl = (
                <>
                  <Switch
                    checked={sj.allowAdminUsersToSend}
                    onChange={(newValue) =>
                      handleAllowSendingChange(newValue, sj.clientSentJourneyId)
                    }
                  />
                </>
              );

              return (
                <div
                  key={"sent_journey_for_" + sj.clientJourneyId}
                  className="flex flex-col px-4 mb-4 border-2 border-[#E5E7EB] rounded p-2 bg-white"
                >
                  <div className="flex flex-row">
                    <div className="w-full block sm:hidden">
                      <h3 className="coloured-heading">
                        {i18n.exists(sj.journeyTitleTranslationKeyIdentifier)
                          ? t(sj.journeyTitleTranslationKeyIdentifier)
                          : sj.defaultJourneyTitle}
                      </h3>
                      <hr />
                      <div className="mt-2">
                        {sj.forms.map((form, i) => {
                          const formName = i18n.exists(
                            form.formNameTranslationKeyIdentifier
                          )
                            ? t(form.formNameTranslationKeyIdentifier)
                            : form.defaultTranslatedFormName;
                          return (
                            <Badge
                              key={"badge_" + sj.clientJourneyId + "_" + i}
                              text={formName}
                              textColourClassName="text-gray-400"
                              marginClassName="mr-2"
                            />
                          );
                        })}
                      </div>
                    </div>
                    {/* Hide for mobile, show on smaller+ screens */}
                    <div className="hidden sm:contents">
                      <div className="w-5/6">
                        <h3 className="coloured-heading">
                          {i18n.exists(sj.journeyTitleTranslationKeyIdentifier)
                            ? t(sj.journeyTitleTranslationKeyIdentifier)
                            : sj.defaultJourneyTitle}
                        </h3>
                        <hr />
                        <div className="mt-2">
                          {sj.forms.map((form, i) => {
                            const formName = i18n.exists(
                              form.formNameTranslationKeyIdentifier
                            )
                              ? t(form.formNameTranslationKeyIdentifier)
                              : form.defaultTranslatedFormName;
                            return (
                              <Badge
                                key={
                                  "badge_screen_" + sj.clientJourneyId + "_" + i
                                }
                                text={formName}
                                textColourClassName="text-gray-400"
                                marginClassName="mr-2"
                              />
                            );
                          })}
                        </div>
                      </div>
                      <div
                        className="w-1/6 sm:cursor-pointer"
                        onClick={() =>
                          loadModalRecipientsClick(sj.clientSentJourneyId)
                        }
                      >
                        <div className="flex flex-col text-center pt-3">
                          <div className="border rounded-lg">
                            <h3 className="coloured-heading !mb-0">
                              {sj.recipientCount}
                            </h3>
                            <h4 className="coloured-heading !text-sm !mt-0">
                              {t("Pages.Admin.Common.Recipients")}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pt-3">
                    {/* Show for mobile, hide on smaller+ screens */}
                    <div className="block sm:hidden">
                      <div>
                        <label className="font-semibold">
                          {t("Common.Status")}:{" "}
                        </label>
                        <span>
                          <ClientSentJourneyStatusDisplay value={sj.status} />
                        </span>
                      </div>
                      <div>
                        <label className="font-semibold">
                          {t("Pages.Admin.Common.Recipients")}:{" "}
                        </label>
                        <span>{sj.recipientCount}</span>
                      </div>
                      <div>
                        <label className="font-semibold">
                          {t("Common.Deadline")}:{" "}
                        </label>
                        <span>{deadlineDateEditControl}</span>
                      </div>
                      <div>
                        <label className="font-semibold">
                          Allow admins to send:{" "}
                        </label>
                        <span>{allowSendingControl}</span>
                      </div>
                      <div>
                        <label className="font-semibold">
                          {t("Pages.Admin.Common.DateSent")}:{" "}
                        </label>
                        <span>
                          <FormattedDate
                            displayMode="DATE-AND-TIME"
                            date={sj.dateSent}
                            convertFromUtc={true}
                          />
                        </span>
                      </div>
                      <div>
                        <label className="font-semibold">
                          {t("Pages.Admin.Common.SentBy")}:{" "}
                        </label>
                        <span>{sj.sentBy}</span>
                      </div>
                    </div>

                    {/* Hide for mobile, show on smaller+ screens */}
                    <div className="hidden sm:flex sm:flex-row sm:text-sm">
                      <div className="basis-3/12">
                        <label className="font-semibold">
                          {t("Common.Status")}:{" "}
                        </label>
                        <span>
                          <ClientSentJourneyStatusDisplay value={sj.status} />
                        </span>
                      </div>
                      <div className="basis-3/12">
                        <label className="font-semibold">
                          {t("Common.Deadline")}:{" "}
                        </label>
                        {deadlineDateEditControl}
                      </div>
                      <div className="basis-2/12">
                        <label className="font-semibold">
                          {t("Pages.Admin.SendingHistory.AllowAdminSending")}:{" "}
                        </label>
                        <span>{allowSendingControl}</span>
                      </div>
                      <div className="basis-4/12 text-right">
                        <label className="font-semibold">
                          {t("Pages.Admin.Common.SentBy")}:{" "}
                        </label>
                        <span>{sj.sentBy}</span>{" "}
                        <span>
                          (
                          <FormattedDate
                            displayMode="DATE-AND-TIME"
                            date={sj.dateSent}
                            convertFromUtc={true}
                          />
                          )
                        </span>
                      </div>
                    </div>
                    {sj.hasAdvancedTaskManagementQuestion && (
                      <div className="pt-3">
                        <div className="border border-[#E5E7EB] rounded p-2 text-sm">
                          <div>
                            <strong>
                              {t(
                                "Pages.Admin.SendingHistory.TaskManagementQuestion"
                              )}
                            </strong>
                            <FontAwesomeIcon
                              onClick={() =>
                                showTaskManagementModal(
                                  sj.clientSentJourneyId,
                                  sj.minAdvancedTaskReviewDate,
                                  sj.advancedTaskReviewDateType
                                )
                              }
                              size="xs"
                              icon={faPencilAlt}
                              className="ml-2 hover:cursor-pointer"
                            />
                          </div>
                          <div>
                            <p>
                              {t(
                                "Pages.Admin.SendingHistory.TheFollowingCriteria"
                              )}
                              :
                            </p>
                            {sj.minAdvancedTaskReviewDate &&
                              sj.advancedTaskReviewDateType && (
                                <div>
                                  <p>
                                    - {t("Pages.Admin.SendingHistory.From")} '
                                    <FormattedDate
                                      displayMode="DATE-ONLY"
                                      date={sj.minAdvancedTaskReviewDate}
                                    />
                                    ' {t("Pages.Admin.SendingHistory.By")}{" "}
                                    {sj.advancedTaskReviewDateType ===
                                      "DATE-CREATED" && (
                                      <span>'{t("Common.DateCreated")}'</span>
                                    )}
                                    {sj.advancedTaskReviewDateType ===
                                      "DUE-DATE" && (
                                      <span>'{t("Common.DueDate")}'</span>
                                    )}
                                  </p>
                                </div>
                              )}
                            {sj.minAdvancedTaskReviewDate == null &&
                              sj.advancedTaskReviewDateType == null && (
                                <div>
                                  <p>
                                    - {t("Pages.Admin.SendingHistory.NoneSet")}
                                  </p>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          {state.displaySentJourneys.length > 0 && (
            <div>
              {/* https://www.npmjs.com/package/react-js-pagination */}
              <Pagination
                activePage={state.activePageNumber}
                itemsCountPerPage={state.itemsPerPage}
                totalItemsCount={state.totalItemCount}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                activeClass="pagination-active-list-item"
                itemClass="pagination-list-item"
                itemClassFirst="pagination-first-item"
                itemClassLast="pagination-last-item"
              />
            </div>
          )}
        </>
      )}
      <ModalPopup
        isOpen={state.recipientModalIsOpen}
        onOpenChange={() => console.log("Test")}
        onPrimaryButtonClick={onModalCancelClick}
        primaryButtonText={t("Common.Close")}
        title={t("Pages.Admin.Common.Recipients")}
        showCloseIcon={false}
      >
        {state.isRecipientModalLoading && (
          <div className="pt-2">
            <SmallLoader />
            <p className="text-center pt-1 text-[#959595] text-sm">
              {t("Common.LoadingUsers")}...
            </p>
          </div>
        )}
        {!state.isRecipientModalLoading &&
          state.recipientModalUsers !== undefined && (
            <div className="snap-y overflow-scroll overflow-x-hidden mb-4 max-h-60 border border-gray-200">
              {state.recipientModalUsers.map((user, ix) => (
                <div className="pl-10 py-1.5 border-b border-b-gray-200 flex flex-row">
                  <p className="w-2/4">{user.name}</p>
                  <p className="w-2/4 text-sm text-right pr-6">
                    ({t(user.jobTitleTranslationKeyIdentifier)})
                  </p>
                </div>
              ))}
            </div>
          )}
      </ModalPopup>

      <EditCriteriaPopupModal
        isModalOpen={state.taskManagementModalIsOpen}
        clientSentJourneyId={state.taskClientSentJourneyId}
        taskMinimumDate={state.taskMinimumDate}
        taskDateType={state.taskDateType}
        onUpdate={onTaskManagementUpdate}
        onModalClose={onTaskManagementModalCancelClick}
      />
    </MainContainer>
  );
}

export default SendingHistory;
