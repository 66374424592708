import produce from "immer";
import { useContext, useEffect, useReducer, Reducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import managerDashboardApi from "../../api/dashboard/managerDashboardApi";
import AppContext from "../../state/AppContext";
import { MainContainer } from "../../components/layout";
import {
  PeopleWidget,
  UpdatesWidget,
} from "../../components/manager-dashboard";
import {
  CompletedFormSummaryResponseDto,
  DashboardSummaryTabDto,
  ManagerDashboardApiResponseDto,
  ManagerDashboardPersonDto,
  ManagerDashboardUpdateDto,
} from "../../types/dtos/dashboards";
import AppRoutes from "../AppRoutes";
import userApi from "../../api/user/userApi";
import UserContext from "../../state/UserContext";
import FormHistoryPopup from "../../components/form-summaries/FormHistoryPopup";
import { DashboardSummaryTabHistoryDto } from "../../types/dtos/dashboards/DashboardSummaryTabHistoryDto";
import { NewOrExistingCollabDocDetails } from "../../types/collab-docs";
import ManagerDashWalkthrough from "../../components/user-walkthrough/ManagerDashWalkthrough";
import ManagerUnableToUpdatePopup from "../../components/form-summaries/ManagerUnableToUpdatePopup";
import { UnableToUpdateModal } from "../../state/popup/UnableToUpdateModal";
import CanManagerStartCollabDocResponse from "../../types/dtos/collab-docs/CanManagerStartCollabDocResponse";
import PreparingDocumentPopup from "../../components/manager-dashboard/PreparingDocumentPopup";
import GenericApiResult from "../../types/dtos/generic/GenericApiResult";
import { AppraisalLevelClientTaskTypeDto } from "../../types/dtos/users/AppraisalLevelClientTaskTypeDto";
import SearchField from "../../components/common/SearchField";

interface ManagerDashboardState {
  waitingForApiResult: boolean;
  users: ManagerDashboardPersonDto[];
  appraisalLevelTaskTypes: AppraisalLevelClientTaskTypeDto[];
  updates: ManagerDashboardUpdateDto[];
  firstPageLoadCompleted: boolean;
  allowWalkthrough: boolean;
  showPreparingDocumentModal: boolean;
}

function ManagerDashboard() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);
  const auth = useAuth();
  const dashboardApi = new managerDashboardApi(auth.user?.access_token);
  const usersApi = new userApi(auth.user?.access_token);

  // State
  const [searchTerm, setSearchTerm] = useState<string | null>(null);

  const [unableToUpdateModal, setUnableToUpdateModal] =
    useState<UnableToUpdateModal>({
      isOpen: false,
      hasClickedSendReminder: false,
      bypassableDueToPrefillEnabled: false,
      subjectEmployee: undefined,
      subjectEmployeeHasLockedJourney: false,
    });
  const [employeesHaveLegacyReviews, setEmployeesHaveLegacyReviews] =
    useState<boolean>(false);
  const [clientHasTaskTypes, setClientHasTaskTypes] = useState<boolean>(false);
  const [isLegacyClient, setIsLegacyClient] = useState<boolean>(false);
  const [state, setState] = useReducer<
    Reducer<ManagerDashboardState, Partial<ManagerDashboardState>>
  >((state, newState) => ({ ...state, ...newState }), {
    waitingForApiResult: true,
    users: [],
    updates: [],
    firstPageLoadCompleted: false,
    allowWalkthrough: true,
    showPreparingDocumentModal: false,
    appraisalLevelTaskTypes: [],
  });
  useEffect(() => {
    if (userContext.user.legacyDetails.teamReviews === true) {
      setEmployeesHaveLegacyReviews(true);
    }
    if (userContext.user.legacyDetails.isLegacyClient === true) {
      setIsLegacyClient(true);
    }
  }, [userContext.user.legacyDetails]);

  useEffect(() => {
    if (userContext.user.client.taskTypes.length > 0) {
      setClientHasTaskTypes(true);
    }
  }, [userContext.user.client.taskTypes]);

  const reloadDashboard = () => {
    // Call the API to load the necessary state
    const successCallback = (data: ManagerDashboardApiResponseDto) => {
      setState({
        users: data.users,
        updates: data.updates,
        waitingForApiResult: false,
        firstPageLoadCompleted: true,
        appraisalLevelTaskTypes: data.appraisalLevelTaskTypes,
      });
    };

    const errorCallback = (error: any) => {
      console.error(error);
    };

    dashboardApi.GetFullManagerDashboard(successCallback, errorCallback);
  };

  const reloadUpdates = () => {
    reloadDashboard();
    refreshLoggedInUserDetails();
  };

  const handleUpdateUser = (user: ManagerDashboardPersonDto) => {
    // Will remove user, and any relevant updates, from state
    // - If they are not the logged in user's manager or delegated manager
    const isUserBeingRemoved =
      user.managerId !== userContext.user.id &&
      user.delegatedManagerId !== userContext.user.id;

    // Will remove just the relevant updates for the user, from state
    // - If they have  been delegated and its not to this user
    const removeOnlyUpdatesForUser =
      user.delegatedManagerId && user.delegatedManagerId != userContext.user.id;

    // Find the user within the list of users and then replace it with the updated user
    const nextUserState = produce(state.users, (draft) => {
      const userMatch = draft.find((x) => x.userId === user.userId);
      if (userMatch !== undefined) {
        const userIndex = draft.findIndex((x) => x.userId === user.userId);
        if (isUserBeingRemoved) {
          draft.splice(userIndex, 1);
        } else {
          // If they are then update the user
          draft[userIndex] = user;
        }
      }
    });

    if (isUserBeingRemoved || removeOnlyUpdatesForUser) {
      // Need to remove all updates in the state that are for the user being removed
      const nextUpdateState = [...state.updates].filter(
        (x) => x.userId !== user.userId
      );
      setState({ users: nextUserState, updates: nextUpdateState });

      // As the updates count is effected we need to refresh the logged in user details so the one in
      // the nav gets updated too
      refreshLoggedInUserDetails();
    } else {
      setState({ users: nextUserState });
    }
  };

  /** Reload the user details, this refreshes things like manager update counts which can change after
   * collab doc submission (where the user then gets redirected to the manager dashboard) */
  const refreshLoggedInUserDetails = () => {
    usersApi.getRefreshedLoggedInUserDetails(
      userContext.setUpdatedUserDetails,
      () => {}
    );
  };

  useEffect(() => {
    reloadDashboard();
    refreshLoggedInUserDetails();

    appContext.setPageTitle(t("Pages.ManagerDashboard.PageTitle"));
    appContext.setActiveDashboardTypeForWalkthrough("MANAGER-DASHBOARD");
    appContext.setShowPageTitleAccent(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const openCollabDocForTab = (
    userId: number,
    summaryTab: DashboardSummaryTabDto,
    incompleteJourneyReference: string | null
  ) => {
    const successCallback = (data: CanManagerStartCollabDocResponse) => {
      const subjectEmployee = state.users.find((x) => x.userId === userId);

      if (data.userHasJourneyAssignedWithFutureDeadline) {
        setUnableToUpdateModal({
          isOpen: true,
          hasClickedSendReminder: false,
          bypassableDueToPrefillEnabled: false,
          subjectEmployee: subjectEmployee,
          subjectEmployeeHasLockedJourney: true,
        });
      } else if (UserIsNotAssignedThisJourney(data)) {
        PerformOpenCollabDoc(
          userId,
          summaryTab,
          incompleteJourneyReference,
          false
        ); //Perform function without recalculate journey flag
      } else if (UserIsAssignedThisNonClientSentJourneyAndNotStarted(data)) {
        PerformOpenCollabDoc(
          userId,
          summaryTab,
          incompleteJourneyReference,
          true
        ); // Perform function with recalculate journey flag
      } else if (UserNeedsToFinishBeforeManagerCanStart(data)) {
        setUnableToUpdateModal({
          isOpen: true,
          hasClickedSendReminder: false,
          bypassableDueToPrefillEnabled: false,
          subjectEmployee: subjectEmployee,

          subjectEmployeeHasLockedJourney: false,
        });
      }
    };

    const errorCallback = (error: any) => {
      console.error(error);
    };

    dashboardApi.CanManagerCurrentlyStartCollabDoc(
      userId,
      summaryTab.clientFormId,
      successCallback,
      errorCallback
    );
  };

  const PerformOpenCollabDoc = (
    userId: number,
    summaryTab: DashboardSummaryTabDto,
    incompleteJourneyReference: string | null,
    triggerRecalculateJourney: boolean
  ) => {
    const hasBeenUpdatedBefore = !!summaryTab.lastCompletedDate;
    if (hasBeenUpdatedBefore) {
      // Subject user has completed this form before, so take the logged in user to the most recent completed version
      // so they can use that as a base to edit
      if (summaryTab.lastCompletedAnswerSetId) {
        const redirectUrl = AppRoutes.collaborativeDocument.generateRoute(
          summaryTab.lastCompletedAnswerSetId,
          false,
          summaryTab.clientFormId
        );
        navigate(redirectUrl);
      } else {
        console.error("Logical flaw... completed, but no completed answer set");
      }
    } else {
      // User has never completed this form before, so
      // call the API to start a blank collab doc and redirect to it once created
      const successCallback = (data: NewOrExistingCollabDocDetails) => {
        const destinationUrl = AppRoutes.collaborativeDocument.generateRoute(
          data.answerSetUniqueId,
          false,
          null
        );
        navigate(destinationUrl);
      };

      const errorCallback = (error: any) => {
        console.error(error);
      };

      dashboardApi.OpenCollabDocByClientFormId(
        userId,
        summaryTab.clientFormId,
        incompleteJourneyReference,
        triggerRecalculateJourney,
        successCallback,
        errorCallback
      );
    }
  };

  const onUserTabChange = (
    userId: number,
    clientFormId: number,
    onSuccess: () => void,
    onError: () => void
  ) => {
    // Call the API
    // On successs, update the local state value for the user and tab
    const onSummaryLoaded = (
      updatedTabData: CompletedFormSummaryResponseDto | null
    ) => {
      if (updatedTabData) {
        const nextUserState = produce(state.users, (draft) => {
          const userMatch = draft.find((x) => x.userId === userId);
          if (userMatch !== undefined) {
            const clientFormIndex = userMatch.summaries?.findIndex(
              (x) => x.clientFormId === clientFormId
            );
            if (clientFormIndex >= 0) {
              userMatch.summaries[clientFormIndex].formData =
                updatedTabData.summary;
              userMatch.summaries[clientFormIndex].lastUpdated =
                updatedTabData.utcDate;
              onSuccess();
            }
          }
        });
        setState({ users: nextUserState });
      }
    };

    dashboardApi.GetSummary(userId, clientFormId, onSummaryLoaded, onError);
  };

  const onLiveJourneyClick = (userId: number) => {
    const user = state.users.find((x) => x.userId === userId);
    if (user != undefined) {
      if (user.currentJourneyIncompleteAnswerSetGuid != undefined) {
        const destinationUrl = AppRoutes.collaborativeDocument.generateRoute(
          user.currentJourneyIncompleteAnswerSetGuid as string,
          false,
          null
        );
        navigate(destinationUrl);
      } else {
        //Surface the modal.
        setUnableToUpdateModal({
          isOpen: true,
          hasClickedSendReminder: false,
          bypassableDueToPrefillEnabled:
            user.isCurrentJourneyPrefillAnswersEnabled,
          subjectEmployee: user,

          subjectEmployeeHasLockedJourney: false,
        });
      }
    }
  };

  const onUserHistory = (userId: number) => {
    const redirectUrl =
      AppRoutes.yourPeople.forms.generatePathWithPreselectedEmployeeIdRoute(
        userId
      );
    navigate(redirectUrl);  
  };

  const closeUnableToUpdatePopup = () => {
    setUnableToUpdateModal({
      isOpen: false,
      hasClickedSendReminder: false,
      bypassableDueToPrefillEnabled: false,
      subjectEmployee: undefined,
      subjectEmployeeHasLockedJourney: false,
    });
  };

  const sendReminderToEmployee = () => {
    if (unableToUpdateModal.subjectEmployee != undefined) {
      const successCallback = (data: boolean) => {
        setUnableToUpdateModal({
          ...unableToUpdateModal,
          hasClickedSendReminder: true,
        });
      };

      const errorCallback = (error: any) => {
        console.error(error);
      };

      dashboardApi.SendReminderToEmployee(
        unableToUpdateModal.subjectEmployee.userId,
        successCallback,
        errorCallback
      );
    }
  };

  const handleStartYourselfClick = () => {
    if (
      unableToUpdateModal.subjectEmployee != undefined &&
      unableToUpdateModal.subjectEmployee.currentJourneyReference != undefined
    ) {
      closeUnableToUpdatePopup();
      setShowPreparingDocumentModal(true);

      const successCallback = (data: GenericApiResult) => {
        if (data.successful) {
          const destinationUrl = AppRoutes.collaborativeDocument.generateRoute(
            data.data as string,
            false,
            null
          );
          navigate(destinationUrl);
        }
      };

      const errorCallback = (error: any) => {
        console.error(error);
      };

      dashboardApi.createAndPrefillDoc(
        unableToUpdateModal.subjectEmployee.userId,
        unableToUpdateModal.subjectEmployee.currentJourneyReference,
        successCallback,
        errorCallback
      );
    }
  };

  const handleWalkthroughFinished = () => {
    setState({ allowWalkthrough: false });
  };

  const setShowPreparingDocumentModal = (isVisible: boolean) => {
    setState({ showPreparingDocumentModal: isVisible });
  };

  function UserIsNotAssignedThisJourney(
    data: CanManagerStartCollabDocResponse
  ) {
    return !data.isFormCurrentlyAssignedToEmp;
  }

  function UserIsAssignedThisNonClientSentJourneyAndNotStarted(
    data: CanManagerStartCollabDocResponse
  ) {
    return (
      data.isFormCurrentlyAssignedToEmp &&
      !data.isFormInProgress &&
      !data.isAssignedJourneyPartOfClientSentJourney
    );
  }

  function UserNeedsToFinishBeforeManagerCanStart(
    data: CanManagerStartCollabDocResponse
  ) {
    // User has form currently assigned and has either not started yet and its part of a sent journey,
    // or has started it regardless fo in sent journey or not
    return (
      (data.isFormCurrentlyAssignedToEmp &&
        !data.isFormInProgress &&
        data.isAssignedJourneyPartOfClientSentJourney) ||
      (data.isFormCurrentlyAssignedToEmp && data.isFormInProgress)
    );
  }

  return (
    <MainContainer>
      <div className="flex mt-1 justify-end">
        <SearchField
          id="manager-dashboard-search"
          onSearch={setSearchTerm}
          isLoading={state.waitingForApiResult}
          loaderColor={"#e5e7eb"}
          inputFieldClassName="text-xs pr-8 rounded-xl shadow-md"
          className="p-1 lg:w-96"
          includeSetWidth={false}
        />
      </div>

      <UpdatesWidget
        users={state.users}
        managerUpdates={state.updates}
        isLoading={state.waitingForApiResult}
        taskTypes={state.appraisalLevelTaskTypes}
        reloadUpdates={reloadUpdates}
        searchTerm={searchTerm}
      />

      <PeopleWidget
        users={state.users}
        appraisalLevelTaskTypes={state.appraisalLevelTaskTypes}
        isLoading={state.waitingForApiResult}
        isLegacyClient={isLegacyClient}
        clientHasTaskTypes={clientHasTaskTypes}
        employeesHaveLegacyReviews={employeesHaveLegacyReviews}
        searchTerm={searchTerm}
        onTriggerSectionUpdate={openCollabDocForTab}
        onUserTabChange={onUserTabChange}
        onOpenUserHistory={onUserHistory}
        onLiveJourneyClick={onLiveJourneyClick}
        onUpdateUser={handleUpdateUser}
      />

      <ManagerUnableToUpdatePopup
        isOpen={unableToUpdateModal.isOpen}
        subjectEmployeeName={
          unableToUpdateModal.subjectEmployee != null
            ? unableToUpdateModal.subjectEmployee.firstName
            : ""
        }
        subjectEmployeeHasLockedJourney={
          unableToUpdateModal.subjectEmployeeHasLockedJourney
        }
        hasClickedSendReminder={unableToUpdateModal.hasClickedSendReminder}
        isJourneyPrefillAnswerEnabled={
          unableToUpdateModal.bypassableDueToPrefillEnabled
        }
        onOpenChange={closeUnableToUpdatePopup}
        onCancelClick={closeUnableToUpdatePopup}
        onSendReminderClick={sendReminderToEmployee}
        onStartYourselfClick={handleStartYourselfClick}
      />

      <PreparingDocumentPopup
        isOpen={state.showPreparingDocumentModal}
        userContext={userContext}
        onOpenChange={setShowPreparingDocumentModal}
      />

      {state.firstPageLoadCompleted &&
        state.allowWalkthrough &&
        userContext.user.walkthroughSeen != null &&
        !userContext.user.walkthroughSeen.includes("MANAGER-DASHBOARD") && (
          <ManagerDashWalkthrough onFinished={handleWalkthroughFinished} />
        )}
    </MainContainer>
  );
}

export default ManagerDashboard;
