export const JourneyConstants = {
  Admin: {
    MaxLengths: {
      CategoryDisplayName: 250,
      CategoryDescription: 1000,
      JourneyDisplayName: 250,
      JourneyDescription: 1000,
    },
  },
};
