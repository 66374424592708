import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
interface PageHeaderIntroProps {
  body: string;
  title?: string | undefined;
  colourClassNames?: string;
  marginClassNames?: string;
  iconType?: "INFO" | undefined;
}

export const PageHeaderIntro = ({
  body,
  title = undefined,
  colourClassNames = "bg-slate-200/70 text-gray-600",
  marginClassNames = "",
  iconType = undefined,
}: PageHeaderIntroProps) => {
  return (
    <div
      className={cx("px-4 py-2 rounded-md", colourClassNames, marginClassNames)}
    >
      <div className="flex flex-row gap-2">
        {iconType !== undefined && (
          <div className="flex-initial">
            <FontAwesomeIcon size="sm" icon={faInfoCircle} />
          </div>
        )}
        <div className="flex-grow">
          {title && (
            <header className="mb-2 font-semibold text-lg">{title}</header>
          )}
          <p>{body}</p>
        </div>
      </div>
    </div>
  );
};
