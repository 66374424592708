import { t } from "i18next";
import { LoadingPlaceholderItem } from "./shared/LoadingPlaceholderItem";

function LoadingDashboardRightBarPlaceholder() {
  return (
    <div>
      <div id="loading-to-do-list" className="bg-white rounded-lg p-4">
        <header className="text-lg font-semibold mt-0 mb-2">
          {t("DashboardRightBar.YourToDoList")}
        </header>
        <LoadingPlaceholderItem />
        <LoadingPlaceholderItem />
        <LoadingPlaceholderItem />
        <LoadingPlaceholderItem />
      </div>
      <div id="loading-catch-up-list" className="bg-white rounded-lg p-4 mt-4">
        <header className="text-lg font-semibold mt-0 mb-2">
          {t("DashboardRightBar.YourCatchUps")}
        </header>
        <LoadingPlaceholderItem />
        <LoadingPlaceholderItem />
        <LoadingPlaceholderItem />
        <LoadingPlaceholderItem />
      </div>
    </div>
  );
}

export default LoadingDashboardRightBarPlaceholder;
