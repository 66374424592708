import { t } from "i18next";
import { JourneyFormButtonItemDto } from "../types/dashboard/JourneyFormDropDownItems";
import UserJourneyDetailDto from "../types/dtos/shared/UserJourneyDetailDto";

export const journeyFormHelper = {
  getDualPrepDropdownButtonItems: (
    form: UserJourneyDetailDto,
    isCompletedTab: boolean,
    isYourPeoplePage: boolean,
    hasPreventJourneySwitchConfigEnabled: boolean
  ): JourneyFormButtonItemDto[] => {
    function getItems(): JourneyFormButtonItemDto[] {
      if (isCompletedTab) {
        return [
          {
            text: t("Common.ViewMyPlanning"),
            // Switch customArg based on whether this is the manager's page or the employee's page
            customArg: isYourPeoplePage
              ? "VIEW-MAN-PLANNING"
              : "VIEW-EMP-PLANNING",
            disabled: false,
            isPrimary: false,
          },
          {
            text: t("Common.ViewMeeting"),
            customArg: "VIEW-MEETING",
            disabled: false,
            isPrimary: true,
          },
        ];
      } else {
        const employeeNeedsToCompletePrep = !form.answerSets.some(
          (x) =>
            x.isDualPrepSubjectAnswerSet &&
            x.statusEnum === "DUAL-PREP-SUBMITTED"
        );
        const managerNeedsToCompletePrep = !form.answerSets.some(
          (x) =>
            x.isDualPrepOtherAnswerSet && x.statusEnum === "DUAL-PREP-SUBMITTED"
        );
        const hasMeetingAnswerSet = form.answerSets.some(
          (x) => x.isDualPrepMeetingDocAnswerSet
        );

        if (isYourPeoplePage) {
          if (managerNeedsToCompletePrep) {
            return [
              {
                text: t("Common.CompletePlanning"),
                customArg: "COMPLETE-MAN-PLANNING",
                disabled: false,
                isPrimary: true,
              },
            ];
          } else if (employeeNeedsToCompletePrep) {
            return [
              {
                text: t("Common.ViewMyPlanning"),
                customArg: "VIEW-MAN-PLANNING",
                disabled: false,
                isPrimary: false,
              },
              {
                text: t("Common.UpdateMeeting"),
                customArg: "UPDATE-MEETING",
                disabled: true,
                isPrimary: true,
              },
            ];
          } else if (hasMeetingAnswerSet) {
            return [
              {
                text: t("Common.ViewMyPlanning"),
                customArg: "VIEW-MAN-PLANNING",
                disabled: false,
                isPrimary: false,
              },
              {
                text: t("Common.UpdateMeeting"),
                customArg: "UPDATE-MEETING",
                disabled: false,
                isPrimary: true,
              },
            ];
          }
        } else {
          if (employeeNeedsToCompletePrep) {
            var output: JourneyFormButtonItemDto[] = [];
            if (!hasPreventJourneySwitchConfigEnabled) {
              output.push({
                text: t("Common.CompletePlanning"),
                customArg: "COMPLETE-EMP-PLANNING",
                disabled: false,
                isPrimary: true,
              });
            }
            return output;
          } else if (managerNeedsToCompletePrep) {
            return [
              {
                text: t("Common.ViewMyPlanning"),
                customArg: "VIEW-EMP-PLANNING",
                disabled: false,
                isPrimary: false,
              },
              {
                text: t("Common.UpdateMeeting"),
                customArg: "UPDATE-MEETING",
                disabled: true,
                isPrimary: true,
              },
            ];
          } else if (hasMeetingAnswerSet) {
            return [
              {
                text: t("Common.ViewMyPlanning"),
                customArg: "VIEW-EMP-PLANNING",
                disabled: false,
                isPrimary: false,
              },
              {
                text: t("Common.UpdateMeeting"),
                customArg: "UPDATE-MEETING",
                disabled: false,
                isPrimary: true,
              },
            ];
          }
        }
      }

      // Shouldn't get to this point
      return [];
    }

    const output = getItems();

    // Sort the items so that the primary item is first
    return output.sort((a, b) =>
      a.isPrimary === b.isPrimary ? 0 : a.isPrimary ? -1 : 1
    );
  },
};

export default journeyFormHelper;
