import { wcagContrastChecker } from "@mdhnpm/wcag-contrast-checker";
import tinycolor from "tinycolor2";

const colourHelper = {
  getDefaultTextColour: function (): string {
    return "#FFFFFF";
  },
  /** Compares the given colour (background) against white text to see if it is accessible */
  doesColourPassContrastCheck: function (
    foregroundColour: string,
    backgroundColour: string
  ): boolean {
    try {
      let contrastChecker = wcagContrastChecker(
        foregroundColour,
        backgroundColour
      );
      return contrastChecker.regularText.aa;
    } catch (e) {
      return false;
    }
  },
  /** Checks whether the hex colour provided is a valid hex */
  isValidHex: function (colour: string): boolean {
    if (!colour || typeof colour !== "string") return false;

    // Validate hex values
    if (colour.substring(0, 1) === "#") colour = colour.substring(1);

    switch (colour.length) {
      case 3:
        return /^[0-9A-F]{3}$/i.test(colour);
      case 6:
        return /^[0-9A-F]{6}$/i.test(colour);
      case 8:
        return /^[0-9A-F]{8}$/i.test(colour);
      default:
        return false;
    }
  },

  /** Takes a hex colour and lightens/darkens it (positive number lightens, negative darkens).
   * Returns the new hex colour with the # at the start
   */
  shadeColour: function (colour: string, percent: number): string {
    if (!colour || typeof colour !== "string") return colour;

    const colourObj = tinycolor(colour);
    if (percent > 0) {
      return colourObj.lighten(percent).toHexString();
    }
    return colourObj.darken(Math.abs(percent)).toHexString();
  },

  /** Takes a hex colour and returns a numeric csv for the RGB, e.g. 123, 32, 32 */
  convertHexToRgbNumbersString: function (hex: string): string {
    if (!hex || typeof hex !== "string") return "";
    const colourObj = tinycolor(hex);
    const rgb = colourObj.toRgb();
    return `${rgb.r},${rgb.g},${rgb.b}`;
  },
};

export default colourHelper;
