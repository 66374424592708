import { useContext, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeftLong } from "@fortawesome/pro-regular-svg-icons";
import AppContext from "../../../state/AppContext";
import { MainContainer } from "../../../components/layout";
import AppRoutes from "../../AppRoutes";
import journeyAdminApi from "../../../api/admin/journeyAdminApi";
import {
  AdminNewJourneyInfo,
  JourneyCategoryOverview,
  JourneyCategoryOverviewApiResponse,
} from "../../../types/admin/journeys";
import {
  JourneyListing,
  CreateJourneyModal,
  EditableJourneyCategoryHeader,
} from "../../../components/admin/journeys";

const AdminJourneyCategory = () => {
  // Constants
  const appContext = useContext(AppContext);
  const { categoryId } = useParams();
  const navigate = useNavigate();
  const auth = useAuth();
  const api = new journeyAdminApi(auth.user?.access_token);

  // State
  const [isLoadingCategory, setIsLoadingCategory] = useState<boolean>(true);
  const [category, setCategory] = useState<JourneyCategoryOverview | null>(
    null
  );
  const [isCreateJourneyModalOpen, setIsCreateJourneyModalOpen] =
    useState<boolean>(false);

  // Effects
  useEffect(() => {
    loadData();
  }, [categoryId]); // eslint-disable-line react-hooks/exhaustive-deps

  // Functions
  function loadData() {
    if (!categoryId || categoryId.length === 0) return;

    const successCallback = (response: JourneyCategoryOverviewApiResponse) => {
      if (!response.success) {
        errorCallback();
      }

      onJourneyCategoryLoaded(response.data);
    };

    const errorCallback = () => {
      setCategory(null);
      setIsLoadingCategory(false);
      console.error("Problem loading the category overview");
    };

    setIsLoadingCategory(true);
    api.loadCategoryOverview(categoryId, successCallback, errorCallback);
  }

  const onJourneyCategoryLoaded = (
    updatedCategoryInfo: JourneyCategoryOverview
  ) => {
    setIsLoadingCategory(false);
    setCategory(updatedCategoryInfo);

    appContext.setPageTitle(
      `Journey management: ${updatedCategoryInfo.displayName}`
    );
    appContext.setShowPageTitleAccent(true);
  };

  const handleJourneyAdded = (newJourneyInfo: AdminNewJourneyInfo) => {
    navigate(
      AppRoutes.admin.journeys.journey.generateRoute(
        categoryId!,
        newJourneyInfo.journeyId
      )
    );
  };

  return (
    <MainContainer>
      <div className="mt-2 text-gray-600">
        <Link
          to={AppRoutes.admin.journeys.root}
          className="hover:underline hover:cursor-pointer"
        >
          <FontAwesomeIcon icon={faLeftLong} size="xs" className="mr-1" />
          Categories
        </Link>
      </div>
      <EditableJourneyCategoryHeader
        isLoading={isLoadingCategory}
        category={category}
        onCategoryUpdated={onJourneyCategoryLoaded}
      />
      <JourneyListing
        isLoading={isLoadingCategory}
        category={category}
        onClickJourneyAdd={() => setIsCreateJourneyModalOpen(true)}
      />
      {categoryId && (
        <CreateJourneyModal
          isOpen={isCreateJourneyModalOpen}
          onOpenChange={setIsCreateJourneyModalOpen}
          categoryId={categoryId}
          onJourneyCreatedCallback={handleJourneyAdded}
        />
      )}
    </MainContainer>
  );
};

export default AdminJourneyCategory;
