import React from "react";
import cx from "classnames";

interface MainContainerType {
  children: React.ReactNode;
  rightColumnChildren?: React.ReactNode;
  applySidePadding?: boolean;
}

const InnerContainerId = "main-container-inner";

export const ScrollPageMainContainerToTop = () => {
  const container = document.getElementById(InnerContainerId);
  if (container) {
    container.scrollTo({ top: 0, behavior: "smooth" });
  }
};

const MainContainer = ({
  children,
  rightColumnChildren,
  applySidePadding = true,
}: MainContainerType) => {
  return (
    <div
      id="main-container"
      className={cx(
        "flex flex-col overflow-auto overflow-x-hidden lg:overflow-hidden max-h-screen lg:max-h-full h-full",
        rightColumnChildren ? "lg:flex-row" : "",
        "print:overflow-visible print:max-h-none print:h-auto"
      )}
    >
      <div
        id={InnerContainerId}
        className={cx(
          "lg:overflow-auto lg:overflow-x-hidden lg:max-h-screen lg:pb-24",
          rightColumnChildren ? "" : "pb-36",
          rightColumnChildren ? "lg:w-3/4" : "lg:w-full",
          applySidePadding ? "px-2 md:px-4 lg:px-6" : "",
          "print:overflow-visible"
        )}
      >
        {children}
      </div>
      {rightColumnChildren && (
        <div
          id="right-bar-container"
          className="lg:w-1/3 p-4 bg-gray-100/60 lg:max-h-screen lg:overflow-y-auto"
        >
          {/* Using CSS calc for height to use 100% of the viewport height minus the height of the top bar */}
          {rightColumnChildren}
        </div>
      )}
    </div>
  );
};

export default MainContainer;
