import React from "react";
//@ts-ignore
import Pagination from "react-js-pagination";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSortUp, faSortDown } from "@fortawesome/pro-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import UserContext from "../../../state/UserContext";
import AppRoutes from "../../AppRoutes";
import { UserDetailSearchDto } from "../../../types/dtos/admin/UserJourneysTabApiResponseDto";
import TinyLoader from "../../../components/loaders/TinyLoader";
import { EmployeeSearchSortColumn } from "../../../types/admin/client-journeys/EmployeeSearchSortColumn";
import { EmployeeSearchSortDirection } from "../../../types/admin/client-journeys/EmployeeSearchSortDirection";
import EmptyFilteredState from "../../../images/EmptyFilteredState";
import SearchFilterInputs from "../../../types/admin/client-journeys/SearchFilterInputs";
import SearchAndFilters from "../../../components/admin/client-journeys/SearchAndFilters";

interface UserJourneysTabState {
  isLoading: boolean;
  users: UserDetailSearchDto[] | undefined;
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  sortColumn: EmployeeSearchSortColumn;
  sortDirection: EmployeeSearchSortDirection;
  searchTerm?: string | null;
  inEditMode: boolean;
  selectedValues: SearchFilterInputs;
  handlePageNumberChange(pageNumber: number): void;
  handlePageSizeChange(pageNumber: number): void;
  handleSortColumnChange(sortColumn: EmployeeSearchSortColumn): void;
  handleSearchTermChange(searchTerm: string | null): void;
  onBeginEditMode(): void;
  onLocationPickerChange: (selectedLocationIds: number[]) => void;
  onJobTitlePickerChange: (selectedJobtitleIds: number[]) => void;
  onAppraisalLevelPickerChange: (selectedAppraisalLevelIds: number[]) => void;
  onEmployeeStatusPickerChange: (selectedEmployeeStatusIds: number[]) => void;
  onActiveOnlyChange: (newValue: boolean) => void;
  onApplyFilters(): void;
  onResetFilters(): void;
}

function UserJourneyTab({
  isLoading,
  users,
  pageNumber,
  pageSize,
  totalItems,
  sortColumn,
  sortDirection,
  searchTerm,
  inEditMode,
  selectedValues,
  handlePageNumberChange,
  handlePageSizeChange,
  handleSortColumnChange,
  handleSearchTermChange,
  onBeginEditMode,
  onLocationPickerChange,
  onJobTitlePickerChange,
  onAppraisalLevelPickerChange,
  onEmployeeStatusPickerChange,
  onActiveOnlyChange,
  onApplyFilters,
  onResetFilters,
}: UserJourneysTabState) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userContext = React.useContext(UserContext);

  const onPageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newFilterValue = Number.parseInt(e.target.value);
    handlePageSizeChange(newFilterValue);
  };

  const getSortIcon = (
    column: EmployeeSearchSortColumn,
    sortColumn: EmployeeSearchSortColumn,
    sortDirection: EmployeeSearchSortDirection
  ) => {
    return (
      <>
        {column !== sortColumn && (
          <FontAwesomeIcon
            icon={faSort}
            className="text-gray-300 pt-1"
          ></FontAwesomeIcon>
        )}
        {column === sortColumn && sortDirection === "ASC" && (
          <FontAwesomeIcon
            icon={faSortUp}
            className="text-gray-500 pt-1"
          ></FontAwesomeIcon>
        )}
        {column === sortColumn && sortDirection === "DESC" && (
          <FontAwesomeIcon
            icon={faSortDown}
            className="text-gray-500 pt-1"
          ></FontAwesomeIcon>
        )}
      </>
    );
  };

  const getPaginationDisplayCount = () => {
    const reductionAmount =
      pageSize === 10
        ? 9
        : pageSize === 25
        ? 24
        : pageSize === 50
        ? 49
        : pageSize === 100
        ? 99
        : 0;

    const lowerCount = pageNumber * pageSize - reductionAmount;
    let upperCount = pageNumber * pageSize;
    if (upperCount > totalItems) {
      upperCount = totalItems;
    }

    const paginationTextDisplay = t("Pages.Admin.Journeys.PaginationDisplay")
      .replace("#LOWER_COUNT#", lowerCount.toString())
      .replace("#UPPER_COUNT#", upperCount.toString())
      .replace("#TOTAL_ITEMS#", totalItems.toString());

    return (
      <h4 className="pt-2 mb-2 text-gray-600 text-sm md:pl-4">
        {paginationTextDisplay}
      </h4>
    );
  };

  return (
    <>
      <SearchAndFilters
        isLoading={isLoading}
        searchTerm={searchTerm}
        inEditMode={inEditMode}
        selectedValues={selectedValues}
        onBeginEditMode={onBeginEditMode}
        handleSearchTermChange={handleSearchTermChange}
        onLocationPickerChange={onLocationPickerChange}
        onJobTitlePickerChange={onJobTitlePickerChange}
        onAppraisalLevelPickerChange={onAppraisalLevelPickerChange}
        onEmployeeStatusPickerChange={onEmployeeStatusPickerChange}
        onActiveOnlyChange={onActiveOnlyChange}
        onApplyFilters={onApplyFilters}
        onResetFilters={onResetFilters}
      />
      {!isLoading && totalItems === 0 && (
        <em className="block text-center italic py-4">
          <div className="mb-4 flex justify-center">
            <EmptyFilteredState />
          </div>
          {t("Pages.Admin.Common.NoUsersMatchingCriteria")}
        </em>
      )}
      {totalItems > 0 && (
        <>
          <div className="w-full overflow-x-auto">
            <table className="datatable mt-4 mb-4 md:table-fixed">
              <thead>
                <tr className="text-left">
                  <th
                    className="!py-3 cursor-pointer"
                    key="Name"
                    onClick={() => handleSortColumnChange("NAME")}
                  >
                    <div className="flex">
                      <span className="grow">
                        {t("Pages.Admin.Common.Name")}
                      </span>
                      {getSortIcon("NAME", sortColumn, sortDirection)}
                    </div>
                  </th>
                  <th
                    className="!py-3 cursor-pointer"
                    key="JobTitle"
                    onClick={() => handleSortColumnChange("JOB-TITLE")}
                  >
                    <div className="flex">
                      <span className="grow">
                        {t("Pages.Admin.Common.JobTitle")}
                      </span>
                      {getSortIcon("JOB-TITLE", sortColumn, sortDirection)}
                    </div>
                  </th>
                  <th
                    className="!py-3 cursor-pointer"
                    key="Level"
                    onClick={() => handleSortColumnChange("LEVEL")}
                  >
                    <div className="flex">
                      <span className="grow">
                        {t("Pages.Admin.Common.Level")}
                      </span>
                      {getSortIcon("LEVEL", sortColumn, sortDirection)}
                    </div>
                  </th>
                  <th
                    className="!py-3 cursor-pointer"
                    key="Manager"
                    onClick={() => handleSortColumnChange("MANAGER")}
                  >
                    <div className="flex">
                      <span className="grow">
                        {t("Pages.Admin.Common.Manager")}
                      </span>
                      {getSortIcon("MANAGER", sortColumn, sortDirection)}
                    </div>
                  </th>
                  <th
                    className="!py-3 w-36 cursor-pointer"
                    key="Status"
                    onClick={() => handleSortColumnChange("STATUS")}
                  >
                    <div className="flex">
                      <span className="grow">
                        {t("Pages.Admin.Common.Status")}
                      </span>
                      {getSortIcon("STATUS", sortColumn, sortDirection)}
                    </div>
                  </th>
                  <th className="w-24" key="View"></th>
                </tr>
              </thead>
              <tbody>
                {users != undefined &&
                  users.map((user) => (
                    <tr key={user.id}>
                      <td
                        className="!py-2 cursor-pointer hover:underline"
                        key={user.id + "_Name"}
                        onClick={() =>
                          navigate(
                            AppRoutes.admin.clientJourneys.userDetail.generateRoute(
                              user.id.toString()
                            )
                          )
                        }
                      >
                        {user.name}
                      </td>
                      <td className="!py-4" key={user.id + "_JobTitle"}>
                        {t(user.jobTitle)}
                      </td>
                      <td className="!py-4" key={user.id + "_Level"}>
                        {t(user.level)}
                      </td>
                      <td className="!py-4" key={user.id + "_Manager"}>
                        {user.managerName}
                      </td>
                      <td className="!py-4" key={user.id + "_Status"}>
                        {user.status}
                      </td>
                      <td className="!py-4" key={user.id + "View"}>
                        <Link
                          to={AppRoutes.admin.clientJourneys.userDetail.generateRoute(
                            user.id.toString()
                          )}
                        >
                          <span className="py-1 px-4 text-gray-600 bg-white border rounded hover:bg-gray-50">
                            {t("Common.View")}
                          </span>
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="flex flex-col gap-y-2 mb-4 md:flex-row md:gap-y-0">
            <div className="flex bg-gray-50 mt-2 border border-gray-300 text-gray-700 text-sm rounded-lg mr-4 md:mt-0">
              <span className="basis-3/4 pt-2 pl-4">
                {t("Pages.Admin.Common.PageSize")}:
              </span>
              <select
                className="basis-1/4 bg-gray-50 border-none text-sm rounded-lg"
                value={pageSize}
                onChange={onPageSizeChange}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            <div>
              <Pagination
                activePage={pageNumber}
                itemsCountPerPage={pageSize}
                totalItemsCount={totalItems}
                pageRangeDisplayed={5}
                onChange={handlePageNumberChange}
                activeClass="pagination-active-list-item"
                itemClass="pagination-list-item"
                itemClassFirst="pagination-first-item"
                itemClassLast="pagination-last-item"
              />
            </div>
            {getPaginationDisplayCount()}
            {isLoading && (
              <div className="pl-4">
                <TinyLoader colour={userContext.user.client.accentHexColour} />
                <span className="ml-1 text-gray-500 text-sm">
                  {t("Common.Loading")}...
                </span>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default UserJourneyTab;
