import { useState } from "react";
import { useAuth } from "react-oidc-context";
import myDashboardApi from "../../../../api/dashboard/myDashboardApi";
import { LoadingPlaceholderItem } from "../shared/LoadingPlaceholderItem";
import DashboardCatchUpDto from "../../../../types/dtos/dashboards/right-bar/catch-ups/DashboardCatchUpDto";
import CatchUpEmptyState from "./CatchUpEmptyState";
import CatchUpListItem from "./CatchUpListItem";
import { useNavigate } from "react-router-dom";
import AppRoutes from "../../../../routes/AppRoutes";
import { t } from "i18next";
import {
  faCalendarPlus,
  faArrowRightLong,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ManageCatchUpPopup from "../../../catch-ups/ManageCatchUpPopup";
import { Badge } from "../../../common";

interface DashboardCatchUpsProps {
  initialCatchUps: DashboardCatchUpDto[];
}

const DashboardCatchUps = ({ initialCatchUps }: DashboardCatchUpsProps) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const dashboardApi = new myDashboardApi(auth.user?.access_token);
  const maxItemsToDisplay = 4;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [catchUps, setCatchUps] =
    useState<DashboardCatchUpDto[]>(initialCatchUps);
  const [displayCatchUps, setDisplayCatchUps] = useState<DashboardCatchUpDto[]>(
    initialCatchUps.slice(0, maxItemsToDisplay)
  );
  const [isAddCatchUpModalOpen, setIsAddCatchUpModalOpen] =
    useState<boolean>(false);

  const loadCatchUps = () => {
    setIsLoading(true);
    dashboardApi.getDashboardCatchUps(
      (data: DashboardCatchUpDto[]) => {
        setIsLoading(false);
        setCatchUps(data);
        setDisplayCatchUps(data.slice(0, maxItemsToDisplay));
      },
      (error: any) => {
        console.error(error.message);
        setIsLoading(false);
      }
    );
  };

  const navigateToCatchUpsPage = () => {
    navigate(AppRoutes.yourJourney.catchUps);
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-row">
        <header className="text-lg font-semibold mt-0 mb-2 basis-9/12">
          {t("DashboardRightBar.YourCatchUps")}
          <Badge
            text={catchUps.length.toString()}
            backgroundColourClassName="accent-bg"
            textColourClassName="text-white"
          />
        </header>
        <div className="basis-3/12 text-right">
          <button
            className="group hover:cursor-pointer"
            onClick={navigateToCatchUpsPage}
          >
            <span className="text-sm group-hover:underline">View all</span>
            <FontAwesomeIcon
              icon={faArrowRightLong}
              className="ml-1"
              size="xs"
            />
          </button>
        </div>
      </div>

      {isLoading && (
        <>
          <LoadingPlaceholderItem />
          <LoadingPlaceholderItem />
          <LoadingPlaceholderItem />
          <LoadingPlaceholderItem />
        </>
      )}

      {!isLoading && (
        <>
          {displayCatchUps.length === 0 && <CatchUpEmptyState />}
          {displayCatchUps.length > 0 && (
            <div id="advanced-catch-up-list-display">
              {displayCatchUps.map((catchUp, index) => (
                <div
                  className="snap-start my-2 first-of-type:mt-0 last-of-type:mb-0"
                  key={`tr-catch-up-list-item-${index}`}
                >
                  <CatchUpListItem catchUp={catchUp} />
                </div>
              ))}
            </div>
          )}

          {/* Add button */}
          <button
            className="btn-primary w-full !mt-2 !px-3 !py-2 disabled:cursor-not-allowed"
            onClick={() => setIsAddCatchUpModalOpen(true)}
          >
            <FontAwesomeIcon icon={faCalendarPlus} />
            <span className="ml-2">{t("DashboardRightBar.BookACatchUp")}</span>
          </button>
        </>
      )}

      {/* Modal */}
      <ManageCatchUpPopup
        isOpen={isAddCatchUpModalOpen}
        onOpenChange={() => setIsAddCatchUpModalOpen(false)}
        refreshParentTaskList={loadCatchUps}
      />
    </div>
  );
};

export default DashboardCatchUps;
