import { t } from "i18next";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faCommentQuestion,
  faPaperPlane,
} from "@fortawesome/pro-regular-svg-icons";
import { faUser, faTimer } from "@fortawesome/pro-solid-svg-icons";
import dateHelper from "../../../helpers/dateHelper";
import { Badge, FormattedDate, Tooltip } from "../../common";
import YourJourneyCatchUpDto from "../../../types/dtos/catch-ups/YourJourneyCatchUpDto";
import AppRoutes from "../../../routes/AppRoutes";

interface JourneyCatchUpItemProps {
  currentUserId: number;
  catchUp: YourJourneyCatchUpDto;
  isCompletedTab: boolean;
}

function JourneyCatchUpItem({
  currentUserId,
  catchUp,
  isCompletedTab,
}: JourneyCatchUpItemProps) {
  const redirectUrl = AppRoutes.collaborativeDocument.generateRoute(
    catchUp.answerSetGuidId,
    false,
    null
  );

  return (
    <div className="p-3 bg-white rounded-md mt-3 border border-gray-300">
      <div className="grid grid-cols-1 lg:grid-cols-6 gap-1">
        <div className="col-span-1 lg:col-span-3">
          <div className="flex flex-col items-start justify-center h-full gap-2">
            {/* Title */}
            <Link
              to={redirectUrl}
              className="font-bold text-gray-600 hover:cursor-pointer hover:underline disabled:cursor-not-allowed disabled:no-underline mr-2 leading-tight"
            >
              {catchUp.title}
            </Link>
            <CatchUpBasicInfo catchUp={catchUp} currentUserId={currentUserId} />
            <CatchUpStatusInfo
              catchUp={catchUp}
              isCompletedTab={isCompletedTab}
            />
          </div>
        </div>
        <div className="hidden md:block col-span-1 lg:col-span-2 mt-2 lg:mt-0">
          {/* Dates with icons here */}
          <CatchUpDocDates catchUp={catchUp} />
        </div>
        <div className="col-span-1 lg:col-span-1 mt-2 lg:mt-0">
          <div className="flex flex-col items-end justify-center h-full">
            <div className="text-center">
              <Link
                to={redirectUrl}
                className="btn-primary block lg:inline-block w-36 max-w-none cursor-pointer"
              >
                {isCompletedTab ? t("Common.View") : t("Common.Update")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const CatchUpBasicInfo = ({
  catchUp,
  currentUserId,
}: {
  catchUp: YourJourneyCatchUpDto;
  currentUserId: number;
}) => {
  const otherUserFirstName = catchUp.otherContributorName.split(" ")[0];
  return (
    <>
      <div className="flex flex-col lg:flex-row items-start gap-2 lg:gap-6">
        <div>
          <FontAwesomeIcon
            icon={faUser}
            className="text-gray-600 mr-2"
            size="sm"
          />
          <span>{catchUp.otherContributorName}</span>
        </div>
        <div>
          <FontAwesomeIcon
            icon={faCalendar}
            className="text-gray-600 mr-2"
            size="sm"
          />
          <span>
            <FormattedDate
              date={catchUp.dateScheduled}
              displayMode="DATE-AND-TIME"
              convertFromUtc
            />
          </span>
        </div>
        <div>
          <FontAwesomeIcon
            icon={faCommentQuestion}
            className="text-gray-600 mr-2"
            size="sm"
          />
          <span>
            {catchUp.subjectEmployeeId === currentUserId
              ? "About you"
              : "About " + otherUserFirstName}
          </span>
        </div>
      </div>
    </>
  );
};

const CatchUpStatusInfo = ({
  catchUp,
  isCompletedTab,
}: {
  catchUp: YourJourneyCatchUpDto;
  isCompletedTab: boolean;
}) => {
  return (
    <div>
      {/* Catch up hasn't been started yet */}
      {catchUp.hasNotBeenStarted && (
        <Badge
          text={t("Pages.Admin.Common.NotStarted")}
          backgroundColourClassName="bg-rose-200/75"
          textColourClassName="text-rose-600"
          borderRadiusClassName="rounded"
          marginClassName="m-0"
        />
      )}

      {/* Catch up has been started, but not completed */}
      {!catchUp.hasNotBeenStarted && !catchUp.isCompleted && (
        <Badge
          text={t("Pages.Admin.Common.InProgress")}
          backgroundColourClassName="bg-blue-200/75"
          textColourClassName="text-blue-600"
          borderRadiusClassName="rounded"
          marginClassName="m-0"
        />
      )}

      {/* Catch up is completed */}
      {catchUp.isCompleted && (
        <Badge
          text={t("Pages.Admin.Common.Completed")}
          backgroundColourClassName="bg-green-200/75"
          textColourClassName="text-green-600"
          borderRadiusClassName="rounded"
          marginClassName="m-0"
        />
      )}

      {/*!isCompletedTab && !catchUp.hasNotBeenStarted && (
        <span className="ml-4">
          {t(catchUp.statusTranslationKeyIdentifier)}
        </span>
      )*/}
    </div>
  );
};

const CatchUpDocDates = ({ catchUp }: { catchUp: YourJourneyCatchUpDto }) => {
  return (
    <div className="text-gray-500 text-sm flex flex-col items-start lg:items-end justify-center h-full gap-1">
      {catchUp.dateSent && (
        <div>
          <Tooltip
            triggerElement={
              <span>
                <FontAwesomeIcon
                  icon={faPaperPlane}
                  size="sm"
                  className="text-gray-600"
                />
                <span className="ml-2">
                  {dateHelper
                    .convertUtcDateToLocal(catchUp.dateSent)
                    .toLocaleDateString()}
                </span>
              </span>
            }
            content={t("Pages.Admin.Common.DateSent")}
          />
        </div>
      )}
      {catchUp.dateLastUpdated && (
        <div>
          <Tooltip
            triggerElement={
              <span>
                <FontAwesomeIcon
                  icon={faTimer}
                  size="sm"
                  className="text-gray-600"
                />
                <span className="ml-2">
                  {dateHelper
                    .convertUtcDateToLocal(catchUp.dateLastUpdated)
                    .toLocaleDateString()}
                </span>
              </span>
            }
            content={t("Pages.Admin.Common.LastUpdated")}
          />
        </div>
      )}
    </div>
  );
};

export default JourneyCatchUpItem;
