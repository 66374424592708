import { NewOrExistingCollabDocDetails } from "../../types/dtos/collab-docs";
import CanManagerStartCollabDocResponse from "../../types/dtos/collab-docs/CanManagerStartCollabDocResponse";
import {
  CompletedFormSummaryResponseDto,
  ManagerDashboardApiResponseDto,
} from "../../types/dtos/dashboards";
import { DashboardSummaryTabHistoryDto } from "../../types/dtos/dashboards/DashboardSummaryTabHistoryDto";
import YourPeopleFormSectionApiResponseDto from "../../types/dtos/dashboards/YourPeopleFormSectionApiResponseDto";
import GenericApiResult from "../../types/dtos/generic/GenericApiResult";
import ResultStatus from "../../types/dtos/generic/ResultStatus";
import { apiClient } from "../apiClient";

class managerDashboardApi {
  constructor(authToken: string | undefined) {
    this.accessToken = authToken;
  }

  accessToken: string | undefined;

  /** Loads the manager dashboard */
  GetFullManagerDashboard(
    onSuccess: (dashboardData: ManagerDashboardApiResponseDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/manager-dashboard/get-manager-dashboard"
    ).then(
      (data) => {
        const dashboardData = data as ManagerDashboardApiResponseDto;
        onSuccess(dashboardData);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /**Load the summary tabs for user */
  GetSummary(
    userId: number,
    clientFormId: number,
    onSuccess: (summaryData: CompletedFormSummaryResponseDto | null) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      `dashboards/manager-dashboard/get-summary?clientFormId=${clientFormId}&userId=${userId}`
    ).then(
      (data) => {
        const summaryData = data as CompletedFormSummaryResponseDto;
        onSuccess(summaryData);
      },
      (error) => {
        onError(error);
      }
    );
  }  

  OpenCollabDocByClientFormId(
    subjectUserId: number,
    clientFormId: number,
    incompleteJourneyReference: string | null,
    triggerRecalculateJourney: boolean,
    onSuccess: (summaryData: NewOrExistingCollabDocDetails) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      `dashboards/manager-dashboard/open-collab-doc?clientFormId=${clientFormId}&userId=${subjectUserId}&incompleteJourneyReference=${incompleteJourneyReference}&triggerRecalculateJourney=${triggerRecalculateJourney}`
    ).then(
      (data) => {
        const summaryData = data as NewOrExistingCollabDocDetails;
        onSuccess(summaryData);
      },
      (error) => {
        onError(error);
      }
    );
  }

  OpenNewCollabDocByJourneyRef(
    subjectUserId: number,
    journeyReference: string,
    onSuccess: (data: NewOrExistingCollabDocDetails) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      `dashboards/manager-dashboard/begin-collab-doc-by-journey-ref?userId=${subjectUserId}&journeyReference=${journeyReference}`
    ).then(
      (data) => {
        const summaryData = data as NewOrExistingCollabDocDetails;
        onSuccess(summaryData);
      },
      (error) => {
        onError(error);
      }
    );
  }

  CanManagerCurrentlyStartCollabDoc(
    subjectUserId: number,
    clientFormId: number,
    onSuccess: (data: CanManagerStartCollabDocResponse) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      `dashboards/manager-dashboard/can-manager-currently-start-collab-doc?clientFormId=${clientFormId}&userId=${subjectUserId}`
    ).then(
      (data) => {
        onSuccess(data as CanManagerStartCollabDocResponse);
      },
      (error) => {
        onError(error);
      }
    );
  }

  SendReminderToEmployee(
    subjectUserId: number,
    onSuccess: (data: boolean) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      `dashboards/manager-dashboard/send-reminder?userId=${subjectUserId}`
    ).then(
      (data) => {
        onSuccess(data as boolean);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Enter edit mode to create a clone of a collab doc */
  createAndPrefillDoc(
    subjectEmployeeId: number,
    journeyReference: string,
    onSuccess: (data: GenericApiResult) => void,
    onError: (error: any) => void
  ) {
    return apiClient(this.accessToken, "dashboards/collab-doc/create-and-prefill-doc?subjectEmployeeId=" +
      subjectEmployeeId + 
      "&journeyRef=" + 
      journeyReference).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  skipManagerPlanning(
    subjectEmployeeId: number,
    journeyRef: string,
    answerSetUniqueId: string | null,
    onSuccess: (data: boolean) => void,
    onError: (error: any) => void
  ) {
    return apiClient(this.accessToken, "dashboards/manager-dashboard/skip-manager-planning?subjectUserId=" +
    subjectEmployeeId +
    "&answerSetUniqueId=" + 
    answerSetUniqueId + 
    "&journeyRef=" + 
    journeyRef).then(
    (data) => {
      onSuccess(data);
    },
    (error) => {
      onError(error);
    }
  );
  }

  updateJourneyManagerForUserId(
    userId: number,
    proposedJourneyManagerId: number | undefined,
    onSuccess: (result: ResultStatus) => void,
    onError: (error: any) => void
  ) {
    let apiUrl = "dashboards/manager-dashboard/update-journey-manager-for-user-id?userId=" + userId;
    if (proposedJourneyManagerId != null && proposedJourneyManagerId != undefined) {
      apiUrl = apiUrl + "&proposedJourneyManagerId=" + proposedJourneyManagerId;
    }
    return apiClient(
      this.accessToken,
      apiUrl,
      ).then(
      (result) => {
        onSuccess(result);
      },
      (error) => {
        onError(error);
      }
    );
  }
  
  getJourneyFormDataForPeopleManagedByLoggedInUser(
    onSuccess: (data: YourPeopleFormSectionApiResponseDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/manager-dashboard/get-journey-form-data-for-people-managed-by-logged-in-user"
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }
}

export default managerDashboardApi;
