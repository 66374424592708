import { useContext, useEffect, useState } from "react";
import cx from "classnames";
import TaskManagementConfigDto from "../../../types/dtos/forms/TaskManagementConfigDto";
import {
  FormType,
  ValidationResult,
  ValidationSettings,
} from "../../../types/forms";
import ValidationWarning from "../../common/ValidationWarning";
import UserContext from "../../../state/UserContext";
import AdvancedTaskDto from "../../../types/dtos/forms/AdvancedTaskDto";
import { t } from "i18next";
import ExistingAdvancedTaskPopup from "../../tasks/advanced-tasks/ExistingAdvancedTaskPopup";
import advancedTasksApi from "../../../api/task/advancedTasksApi";
import { useAuth } from "react-oidc-context";
import UserTaskListingForJourney from "../../../types/dtos/tasks/advanced-tasks/UserTaskListingForJourney";
import AddAdvancedTaskPopup from "../../tasks/advanced-tasks/AddAdvancedTaskPopup";
import {
  BaseUserDetailsDto,
  UserBasicDetailsDto,
} from "../../../types/dtos/generic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import taskTypeIconHelper from "../../../helpers/taskTypeIconHelper";
import TaskRequirementBadge from "../../task-management/TaskRequirementBadge";
import { TaskManagementDropDownMenuArgs } from "../../../types/tasks/TaskManagementDropDownMenuArgs";
import { ExistingTaskManagementTabType } from "../../../types/tasks/ExistingTaskManagementTabType";
import { TaskPopupViewType } from "../../../types/tasks/TaskPopupViewType";
import AdvancedTaskDesktopItem from "./task-management/AdvancedTaskDesktopItem";
import AdvancedTaskMobileItem from "./task-management/AdvancedTaskMobileItem";
import { advancedTaskHelper, userDetailsHelper } from "../../../helpers";
import EnforcedCommentsValidityBanner from "./task-management/EnforcedCommentsValidityBanner";
import { TaskCreationMinMaxCountPrompt } from "./TaskCreationMinMaxCountPrompt";
import WarningAlert from "../../alerts/WarningAlert";

interface TaskManagementQuestionProps {
  tasks: AdvancedTaskDto[];
  /** The validation settings from the FormQuestion */
  questionValidations: ValidationSettings;
  cancelledTasks: AdvancedTaskDto[];
  newTasks: AdvancedTaskDto[];
  liveAndNewTasks: AdvancedTaskDto[];
  formType: FormType;
  formId: number;
  subjectUser: BaseUserDetailsDto;
  config: TaskManagementConfigDto;
  isReadOnly: boolean;
  /** Whether or not to display the validation warnings */
  showValidationErrors: boolean;
  /** If validation has been run, this is the validity plus any errors */
  validationResult?: ValidationResult | null;
  answerSetUniqueId: string | null | undefined;
  answerSetDateCreated: Date | null | undefined;
  participants: Array<UserBasicDetailsDto>;

  onPreTaskInteractionCheckForAnswerSet?(
    onSaveSuccess: (
      answerSetUniqueId: string | null,
      answerSetDateCreated: Date | null
    ) => void,
    onSaveError: () => void
  ): void;
  onChangeAdvancedTasks(
    tasks?: AdvancedTaskDto[],
    cancelledTasks?: AdvancedTaskDto[],
    newTasks?: AdvancedTaskDto[]
  ): void;
}

function TaskManagementQuestion({
  tasks,
  questionValidations,
  cancelledTasks,
  newTasks,
  liveAndNewTasks,
  formType,
  formId,
  subjectUser,
  config,
  isReadOnly,
  showValidationErrors,
  validationResult = null,
  answerSetUniqueId,
  answerSetDateCreated,
  participants,
  onPreTaskInteractionCheckForAnswerSet = undefined,
  onChangeAdvancedTasks,
}: TaskManagementQuestionProps) {
  // Context
  const userContext = useContext(UserContext);
  const auth = useAuth();
  const tasksApi = new advancedTasksApi(auth.user?.access_token);

  // State
  const [currentAnswerSetUniqueId, setCurrentAnswerSetUniqueId] = useState<
    string | null | undefined
  >(answerSetUniqueId);
  const [currentAnswerSetDateCreated, setCurrentAnswerSetDateCreated] =
    useState<Date | null | undefined>(answerSetDateCreated);

  const [activeTasks, setActiveTasks] = useState<AdvancedTaskDto[]>([]);

  const [isAddTaskModalOpen, setIsAddTaskModalOpen] = useState<boolean>(false);
  const [isExistingTaskModalOpen, setIsExistingTaskModalOpen] =
    useState<boolean>(false);
  const [selectedUserTaskId, setSelectedUserTaskId] = useState<string | null>(
    null
  );
  const [selectedTaskSnapshotDate, setSelectedTaskSnapshotDate] =
    useState<Date | null>(null);
  const [selectedUserTaskIsNewlyCreated, setSelectedUserTaskIsNewlyCreated] =
    useState<boolean>(false);
  const [selectedUserTaskTabToOpenTo, setSelectedUserTaskTabToOpenTo] =
    useState<ExistingTaskManagementTabType | null>(null);
  const [selectedFormToDisplay, setSelectedFormToDisplay] =
    useState<TaskPopupViewType | null>(null);

  // Only really to be set if the enforced commments are enabled and we are in a review question mode
  const [showAddCommentInFormLikeStyle, setShowAddCommentInFormLikeStyle] =
    useState<boolean>(false);

  const isLoggedInUserTheSubjectUser =
    subjectUser.userId == userContext.user.id;
  const nonSubjectUser = participants.find(
    (p) => p.userId != subjectUser.userId
  );
  const nonSubjectUserName = userDetailsHelper.getDisplayName(
    nonSubjectUser?.userId!,
    participants
  );
  const subjectUserName = userDetailsHelper.getDisplayName(
    subjectUser?.userId!,
    participants
  );

  useEffect(() => {
    setActiveTasks(liveAndNewTasks.filter((t) => t.status === "ACTIVE"));
  }, [liveAndNewTasks]);

  useEffect(() => {
    setCurrentAnswerSetUniqueId(answerSetUniqueId);
  }, [answerSetUniqueId]);

  useEffect(() => {
    setCurrentAnswerSetDateCreated(answerSetDateCreated);
  }, [answerSetDateCreated]);

  // Other
  const taskType = userContext.user.client.taskTypes.find(
    (t) => t.id === config.advancedTaskTypeId
  );

  const doesModeAllowForTaskCreation =
    config.questionMode === "TASK-CHECK-IN-AND-CREATION" ||
    config.questionMode === "TASK-REVIEW-AND-CREATION" ||
    config.questionMode === "TASK-CREATION-ONLY";
  const showCreateTaskButton = !isReadOnly && doesModeAllowForTaskCreation;

  const doesTaskTypeHaveRestrictions = config.countRestriction !== "NONE";
  const doesModeEnforceRestrictions =
    config.questionMode === "TASK-CHECK-IN-AND-CREATION" ||
    config.questionMode === "TASK-REVIEW-AND-CREATION" ||
    config.questionMode === "TASK-CREATION-ONLY";

  const userHasTasksToShow =
    config.questionMode != "TASK-CREATION-ONLY"
      ? tasks.length > 0 || cancelledTasks.length > 0 || newTasks.length > 0
      : newTasks.length > 0;

  let emptyStateMessageTranslationKey =
    subjectUser.userId == userContext.user.id
      ? "Pages.MyDashboard.TaskEmptyState"
      : "Pages.MyDashboard.TaskEmptyState_Manager";
  if (config.questionMode === "TASK-CREATION-ONLY") {
    emptyStateMessageTranslationKey += "_CreationOnly";
  }

  const emptyStateMessage = t(emptyStateMessageTranslationKey, {
    person: subjectUser.firstName,
    taskType: advancedTaskHelper.ToLowerCase(
      taskType?.pluralNameTranslationKeyIdentifier || "tasks"
    ),
  });

  // If config allows rating column to be shown AND we are not in the journey OR we
  // are in the journey but the tasktype has no ReviewForm
  const displayRatingColumn =
    config.showRatingColumn &&
    (formType !== "JOURNEY" ||
      (formType === "JOURNEY" && !taskType?.reviewFormId));
  const columnCount = displayRatingColumn ? 4 : 3;

  const onRefreshTasks = () => {
    tasksApi.getTasksForJourney(
      config.subjectEmployeeId,
      config.advancedTaskTypeId,
      config.minAdvancedTaskReviewDate,
      config.advancedTaskReviewDateTypeEnum,
      config.enforceComments,
      config.excludeTasksCompletedBeforeJourney,
      config.questionMode,
      config.answerSetId,
      currentAnswerSetUniqueId,
      currentAnswerSetDateCreated,
      config.isCompleted,
      config.isDualPrepJourney,
      (data: UserTaskListingForJourney) => {
        onChangeAdvancedTasks(data.tasks, data.cancelledTasks, data.newTasks);
      },
      (error: any) => {
        console.error("Error getting tasks on tab change", error);
      }
    );
  };

  const onAddNewTaskClick = () => {
    // Need an answer set to be able to store 'CreatedInAnswerSetUniqueId' against a task
    if (
      currentAnswerSetUniqueId == null ||
      currentAnswerSetUniqueId == undefined
    ) {
      requestAnswerSetCreation();
    }

    setIsAddTaskModalOpen(true);
  };

  const onAddNewTaskSuccessful = () => {
    setIsAddTaskModalOpen(false);
    onRefreshTasks();
  };

  const handleExistingModalOpenChange = (isOpen: boolean) => {
    setIsExistingTaskModalOpen(isOpen);
    if (!isOpen) {
      onRefreshTasks();
      setSelectedUserTaskTabToOpenTo(null);
      setSelectedFormToDisplay(null);
      setShowAddCommentInFormLikeStyle(false);
    }
  };

  const handleTaskDropDownItemClick = (userTaskId: string, action: string) => {
    handleExistingModalOpenChange(true);
    setSelectedUserTaskId(userTaskId);
    clearHighlight(userTaskId);

    const matchingTask = liveAndNewTasks.find((x) => x.taskId == userTaskId);
    if (matchingTask) {
      setSelectedUserTaskIsNewlyCreated(
        matchingTask.isNewlyCreatedInThisJourney
      );
    }

    // Any comments added need an answer set to be in place as we use the DateCreated on that
    // to include relevant comments for enforced comments logic.
    if (
      currentAnswerSetUniqueId == null ||
      currentAnswerSetUniqueId == undefined
    ) {
      requestAnswerSetCreation();
    }

    switch (action) {
      case TaskManagementDropDownMenuArgs.ViewDetails:
        setSelectedUserTaskTabToOpenTo("DETAILS");
        break;
      case TaskManagementDropDownMenuArgs.AddComment:
        setSelectedUserTaskTabToOpenTo("COMMENTS");
        
        // In this scenario we need to show the 'Add comment' form in the same style as the form
        if (formType === "COLLAB-DOC" 
          && config.enforceComments == true 
          && (config.questionMode == "TASK-REVIEW-AND-CREATION" 
          || config.questionMode == "TASK-REVIEW-ONLY")) {
            setShowAddCommentInFormLikeStyle(true);
        }
        break;
      case TaskManagementDropDownMenuArgs.CompleteTask:
        setSelectedUserTaskTabToOpenTo("DETAILS");
        setSelectedFormToDisplay("CLOSE-TASK-FORM");
        break;
      case TaskManagementDropDownMenuArgs.CancelTask:
        setSelectedUserTaskTabToOpenTo("DETAILS");
        setSelectedFormToDisplay("CANCEL-TASK-FORM");
        break;
      case TaskManagementDropDownMenuArgs.ReopenTask:
        setSelectedUserTaskTabToOpenTo("DETAILS");
        setSelectedFormToDisplay("REOPEN-TASK");
        break;
      case TaskManagementDropDownMenuArgs.ReviewTask:
        setSelectedUserTaskTabToOpenTo("DETAILS");
        setSelectedFormToDisplay("REVIEW-TASK-FORM");
        break;
    }
  };

  const handleTaskViewButtonClick = (
    userTaskId: string,
    snapshotDate: Date
  ) => {
    handleExistingModalOpenChange(true);
    setSelectedUserTaskId(userTaskId);
    setSelectedTaskSnapshotDate(snapshotDate);
    clearHighlight(userTaskId);

    const matchingTask = liveAndNewTasks.find((x) => x.taskId == userTaskId);
    if (matchingTask) {
      setSelectedUserTaskIsNewlyCreated(
        matchingTask.isNewlyCreatedInThisJourney
      );
    }

    // Any comments added need an answer set to be in place as we use the DateCreated on that
    // to include relevant comments for enforced comments logic.
    if (
      currentAnswerSetUniqueId == null ||
      currentAnswerSetUniqueId == undefined
    ) {
      requestAnswerSetCreation();
    }

    setSelectedUserTaskTabToOpenTo("DETAILS");
  };

  /** If the task is highlighted for the current user, clear the highlight and update the state to remove the HighlightDot */
  const clearHighlight = (userTaskId: string) => {
    let isCancelledTask =
      cancelledTasks.find((x) => x.taskId == userTaskId) != null ? true : false;
    clearTaskHighlight(userTaskId, isCancelledTask);
  };

  const clearTaskHighlight = (userTaskId: string, isCancelledTask: boolean) => {
    const matchingTask = !isCancelledTask
      ? liveAndNewTasks.find((x) => x.taskId == userTaskId)
      : cancelledTasks.find((x) => x.taskId == userTaskId);

    if (!matchingTask) return;

    const newTasksArray = !isCancelledTask
      ? [...liveAndNewTasks]
      : [...cancelledTasks];
    const taskIndexToUpdate = newTasksArray.findIndex(
      (x) => x.taskId == userTaskId
    );
    if (
      matchingTask.ownerEmployeeId == userContext.user.id &&
      matchingTask.highlightToOwner
    ) {
      newTasksArray[taskIndexToUpdate].highlightToOwner = false;
      if (!isCancelledTask) {
        onChangeAdvancedTasks(newTasksArray, undefined);
      } else {
        onChangeAdvancedTasks(undefined, newTasksArray);
      }
      userContext.removeTaskHighlight(
        matchingTask.ownerEmployeeId,
        config.advancedTaskTypeId,
        userTaskId
      );
    } else if (
      matchingTask.ownerEmployeeId != userContext.user.id &&
      matchingTask.highlightToManager
    ) {
      newTasksArray[taskIndexToUpdate].highlightToManager = false;
      if (!isCancelledTask) {
        onChangeAdvancedTasks(newTasksArray, undefined);
      } else {
        onChangeAdvancedTasks(undefined, newTasksArray);
      }
      userContext.removeTaskHighlight(
        matchingTask.ownerEmployeeId,
        config.advancedTaskTypeId,
        userTaskId
      );
    }
  };

  const handleExistingModalRemoveTaskSuccess = () => {
    setIsExistingTaskModalOpen(false);
    onRefreshTasks();
  };

  // In certain scenarios we need an answer set to be created. This will request one from the API.
  const requestAnswerSetCreation = () => {
    if (onPreTaskInteractionCheckForAnswerSet) {
      onPreTaskInteractionCheckForAnswerSet(
        (answerSetGuidId: string | null, answerSetDateCreated: Date | null) => {
          setCurrentAnswerSetUniqueId(answerSetGuidId);
          setCurrentAnswerSetDateCreated(answerSetDateCreated);
        },
        () => {}
      );
    }
  };

  const addButtonComponent = (
    <>
      {taskType && (
        <>
          <TaskCreationMinMaxCountPrompt
            validationSettings={questionValidations}
            taskType={taskType}
          />
          <button
            onClick={onAddNewTaskClick}
            disabled={
              taskType.maxTaskCount != null &&
              activeTasks &&
              activeTasks.length >= taskType.maxTaskCount
            }
            className={cx(
              "text-sm",
              formType === "JOURNEY" ? "journey-btn-primary" : "btn-primary"
            )}
          >
            {t("Common.Add")}{" "}
            {t(taskType.singularNameTranslationKeyIdentifier || "Task")}
          </button>
        </>
      )}
    </>
  );

  // Get the validation counts from either the TaskType or from the FormQuestion validation settings
  const taskValidationCounts = advancedTaskHelper.getValidationCounts(
    taskType?.minTaskCount || null,
    taskType?.maxTaskCount || null,
    questionValidations
  );

  return (
    <>
      {/* Validation errors */}
      {showValidationErrors && validationResult && (
        <ValidationWarning
          isValid={validationResult.isValid}
          errors={validationResult.errors}
        />
      )}
      {userHasTasksToShow && (
        <>
          {showCreateTaskButton && (
            <div
              className={cx(
                "text-right",
                formType === "COLLAB-DOC" ? "pb-2 pt-1" : "pb-1"
              )}
            >
              {doesModeEnforceRestrictions &&
                doesTaskTypeHaveRestrictions &&
                taskType && (
                  <TaskRequirementBadge
                    tasks={activeTasks}
                    minTasks={taskValidationCounts.min}
                    maxTasks={taskValidationCounts.max}
                    countRestriction={taskValidationCounts.restrictionType}
                    activeCategories={taskType.categories}
                    taskType={taskType}
                    isNonEmployeeViewer={false}
                    origin="JOURNEY"
                    journeySubjectFirstName={
                      // Only show the subject's name if the subject is not the current user
                      config.subjectEmployeeId == userContext.user.id
                        ? null
                        : subjectUser.firstName
                    }
                  />
                )}
              {addButtonComponent}
            </div>
          )}

          {/* If there are any active overdue tasks */}
          {config.liveAndNewTasks.some(
            (x) => x.status === "ACTIVE" && x.isOverdue === true
          ) && (
            <WarningAlert
              prefix=""
              message={t("Common.Validation.Errors.OverdueTasks")}
            />
          )}

          {/* Desktop Only */}
          <div className="hidden sm:contents">
            <table className="table-fixed w-full text-sm text-left text-gray-500 border">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100 border-b">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    {t("Tasks.Common.TaskName")}
                  </th>
                  <th scope="col" className="w-32 text-center px-6 py-3">
                    {t("Pages.Admin.Common.Status")}
                  </th>
                  {displayRatingColumn && (
                    <th scope="col" className="w-24 text-center px-6 py-3">
                      {t("Common.Rating")}
                    </th>
                  )}
                  <th scope="col" className="w-40 px-6 py-3"></th>
                </tr>
              </thead>
              <tbody>
                {config.questionMode != "TASK-CREATION-ONLY" &&
                  tasks.map((task) => (
                    <AdvancedTaskDesktopItem
                      key={task.taskId}
                      userContext={userContext}
                      task={task}
                      taskType={taskType}
                      config={config}
                      isReadOnly={isReadOnly}
                      formType={formType}
                      displayRatingColumn={displayRatingColumn}
                      columnCount={columnCount}
                      loggedInUserId={userContext.user.id}
                      isLoggedInUserTheSubjectUser={
                        isLoggedInUserTheSubjectUser
                      }
                      subjectUsersName={subjectUserName}
                      nonSubjectUsersName={nonSubjectUserName}
                      onDropdownMenuItemClick={handleTaskDropDownItemClick}
                      onViewButtonClick={handleTaskViewButtonClick}
                    />
                  ))}
                {doesModeAllowForTaskCreation &&
                  newTasks.map((task) => (
                    <AdvancedTaskDesktopItem
                      key={task.taskId}
                      userContext={userContext}
                      task={task}
                      taskType={taskType}
                      config={config}
                      isReadOnly={isReadOnly}
                      formType={formType}
                      displayRatingColumn={displayRatingColumn}
                      columnCount={columnCount}
                      loggedInUserId={userContext.user.id}
                      isLoggedInUserTheSubjectUser={
                        isLoggedInUserTheSubjectUser
                      }
                      subjectUsersName={subjectUserName}
                      nonSubjectUsersName={nonSubjectUserName}
                      onDropdownMenuItemClick={handleTaskDropDownItemClick}
                      onViewButtonClick={handleTaskViewButtonClick}
                    />
                  ))}
                {config.questionMode != "TASK-CREATION-ONLY" &&
                  cancelledTasks.map((cancelledTask) => (
                    <AdvancedTaskDesktopItem
                      key={cancelledTask.taskId}
                      userContext={userContext}
                      task={cancelledTask}
                      taskType={taskType}
                      config={config}
                      isCancelled={true}
                      isReadOnly={isReadOnly}
                      formType={formType}
                      displayRatingColumn={displayRatingColumn}
                      columnCount={columnCount}
                      loggedInUserId={userContext.user.id}
                      isLoggedInUserTheSubjectUser={
                        isLoggedInUserTheSubjectUser
                      }
                      subjectUsersName={subjectUserName}
                      nonSubjectUsersName={nonSubjectUserName}
                      onDropdownMenuItemClick={handleTaskDropDownItemClick}
                      onViewButtonClick={handleTaskViewButtonClick}
                    />
                  ))}
              </tbody>
            </table>
          </div>

          {/* Mobile Only */}
          <div className="block sm:hidden">
            <div className="border outline outline-1 outline-gray-400 rounded-sm">
              {config.questionMode != "TASK-CREATION-ONLY" &&
                tasks.map((task) => (
                  <AdvancedTaskMobileItem
                    key={task.taskId}
                    userContext={userContext}
                    task={task}
                    taskType={taskType}
                    config={config}
                    isReadOnly={isReadOnly}
                    formType={formType}
                    displayRatingColumn={displayRatingColumn}
                    columnCount={columnCount}
                    loggedInUserId={userContext.user.id}
                    isLoggedInUserTheSubjectUser={isLoggedInUserTheSubjectUser}
                    subjectUsersName={subjectUserName}
                    nonSubjectUsersName={nonSubjectUserName}
                    onDropdownMenuItemClick={handleTaskDropDownItemClick}
                    onViewButtonClick={handleTaskViewButtonClick}
                  />
                ))}
              {doesModeAllowForTaskCreation &&
                newTasks.map((task) => (
                  <AdvancedTaskMobileItem
                    key={task.taskId}
                    userContext={userContext}
                    task={task}
                    taskType={taskType}
                    config={config}
                    isReadOnly={isReadOnly}
                    formType={formType}
                    displayRatingColumn={displayRatingColumn}
                    columnCount={columnCount}
                    loggedInUserId={userContext.user.id}
                    isLoggedInUserTheSubjectUser={isLoggedInUserTheSubjectUser}
                    subjectUsersName={subjectUserName}
                    nonSubjectUsersName={nonSubjectUserName}
                    onDropdownMenuItemClick={handleTaskDropDownItemClick}
                    onViewButtonClick={handleTaskViewButtonClick}
                  />
                ))}
              {config.questionMode != "TASK-CREATION-ONLY" &&
                cancelledTasks.map((cancelledTask) => (
                  <AdvancedTaskMobileItem
                    key={cancelledTask.taskId}
                    userContext={userContext}
                    task={cancelledTask}
                    taskType={taskType}
                    config={config}
                    isCancelled={true}
                    isReadOnly={isReadOnly}
                    formType={formType}
                    displayRatingColumn={displayRatingColumn}
                    columnCount={columnCount}
                    loggedInUserId={userContext.user.id}
                    isLoggedInUserTheSubjectUser={isLoggedInUserTheSubjectUser}
                    subjectUsersName={subjectUserName}
                    nonSubjectUsersName={nonSubjectUserName}
                    onDropdownMenuItemClick={handleTaskDropDownItemClick}
                    onViewButtonClick={handleTaskViewButtonClick}
                  />
                ))}
            </div>
          </div>

          {/* Show validity of tasks based on enforced comments  */}
          {config.enforceComments &&
            config.liveAndNewTasks.some((x) => x.enforcedComments != null) &&
            taskType && (
              <EnforcedCommentsValidityBanner
                config={config}
                taskType={taskType}
                formType={formType}
              />
            )}
        </>
      )}
      {!userHasTasksToShow && taskType && (
        <div className="text-center text-gray-400 grow w-full">
          <div className="mt-3 lg:mt-5 ">
            <FontAwesomeIcon
              color={"#D1D5DB"}
              size="3x"
              icon={taskTypeIconHelper.getTaskTypeIcon(taskType.iconType)}
            />
          </div>
          <div
            className={cx(
              "mt-2 mb-2 !text-sm",
              formType === "COLLAB-DOC" ? "text-gray-700" : "text-white"
            )}
          >
            <p>{emptyStateMessage}</p>
            {showCreateTaskButton && (
              <div className="text-center pb-1 mt-1">{addButtonComponent}</div>
            )}
          </div>
        </div>
      )}
      {taskType && (
        <>
          <AddAdvancedTaskPopup
            usageScenario="JOURNEY-COLLAB-DOC"
            taskType={taskType}
            activeTasks={activeTasks ?? []}
            maxOverallTaskRestriction={taskValidationCounts.max}
            categories={taskType.categories ?? []}
            ownerEmployee={subjectUser}
            isOpen={isAddTaskModalOpen}
            createdInAnswerSetUniqueId={currentAnswerSetUniqueId}
            formId={formId}
            onOpenChange={setIsAddTaskModalOpen}
            onSuccessfulCreation={onAddNewTaskSuccessful}
          />
          <ExistingAdvancedTaskPopup
            usageScenario="JOURNEY-COLLAB-DOC"
            taskType={taskType}
            userTaskId={selectedUserTaskId}
            categories={taskType.categories ?? []}
            isOpen={isExistingTaskModalOpen}
            isTaskNewlyCreatedInJourney={selectedUserTaskIsNewlyCreated}
            initialTab={selectedUserTaskTabToOpenTo}
            initialFormToDisplay={selectedFormToDisplay}
            snapshotDate={selectedTaskSnapshotDate}
            isLocked={selectedTaskSnapshotDate != null}
            currentAnswerSetUniqueId={currentAnswerSetUniqueId}
            showAddCommentInFormLikeStyle={showAddCommentInFormLikeStyle}
            subjectUserName={subjectUserName}
            formId={formId}
            onOpenChange={handleExistingModalOpenChange}
            onSuccessfulUpdate={() => handleExistingModalOpenChange(false)}
            onSuccessfulCancel={() => handleExistingModalOpenChange(false)}
            onSuccessfulClose={() => handleExistingModalOpenChange(false)}
            onSuccessfulReOpen={() => handleExistingModalOpenChange(false)}
            onSuccessfulSavedForLater={() => handleExistingModalOpenChange(false)}
            onSuccessfulRemove={handleExistingModalRemoveTaskSuccess}
            onCommentCountChanged={() => console.log("Comment count changed")}
          />
        </>
      )}
    </>
  );
}

export default TaskManagementQuestion;
