import { Reducer, useReducer, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { MainContainer } from "../../components/layout";
import AppContext from "../../state/AppContext";
import SmallLoader from "../../components/loaders/SmallLoader";
import myDashboardApi from "../../api/dashboard/myDashboardApi";
import Tabs, { TabDetails } from "../../components/common/Tabs";
import UserContext from "../../state/UserContext";
import SearchField from "../../components/common/SearchField";
import YourJourneyCatchUpDto from "../../types/dtos/catch-ups/YourJourneyCatchUpDto";
import MyDashboardCatchUpsApiResponseDto from "../../types/dtos/dashboards/MyDashboardCatchUpsApiResponseDto";
import { DashboardJourneyTabType } from "../../types/dtos/dashboards/DashboardJourneyTabType";
import { JourneyCatchUpTab } from "../../components/dashboard/catch-ups/JourneyCatchUpTab";
import { PageHeaderIntro } from "../../components/common/PageHeaderIntro";

interface YourJourneyCatchUpsState {
  isLoading: boolean;
  activeCatchUps: YourJourneyCatchUpDto[] | null;
  completedCatchUps: YourJourneyCatchUpDto[] | null;
  filteredActiveCatchUps: YourJourneyCatchUpDto[] | null;
  filteredCompletedCatchUps: YourJourneyCatchUpDto[] | null;
}

function YourJourneyCatchUps() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const userContext = useContext(UserContext);
  const appContext = useContext(AppContext);
  const auth = useAuth();
  const dashboardApi = new myDashboardApi(auth.user?.access_token);
  const clientColour = userContext.user.client.primaryHexColour;

  const [activeTab, setActiveTab] = useState<DashboardJourneyTabType>("ACTIVE");
  const [searchTerm, setSearchTerm] = useState<string | null>(null);

  const [state, setState] = useReducer<
    Reducer<YourJourneyCatchUpsState, Partial<YourJourneyCatchUpsState>>
  >((state, newState) => ({ ...state, ...newState }), {
    isLoading: false,
    activeCatchUps: null,
    completedCatchUps: null,
    filteredActiveCatchUps: null,
    filteredCompletedCatchUps: null,
  });

  useEffect(() => {
    setState({ isLoading: true });

    appContext.setPageTitle(
      t("Pages.MyDashboard.PageTitle") +
        ": " +
        t("Nav.LargeScreen.MyDashboard.CatchUps")
    );

    // // Hit the API to load the forms
    dashboardApi.getJourneyCatchUpsData(
      (data: MyDashboardCatchUpsApiResponseDto) => {
        setState({
          isLoading: false,
          activeCatchUps: data.activeCatchUps,
          filteredActiveCatchUps: data.activeCatchUps,
          completedCatchUps: data.completedCatchUps,
          filteredCompletedCatchUps: data.completedCatchUps,
        });
      },
      (error: any) => {
        console.error("Error loading journey forms", error);
      }
    );
  }, []);

  useEffect(() => {
    // If the catch-ups are not loaded yet, return
    if (!state.activeCatchUps || !state.completedCatchUps) {
      return;
    }

    // Filter the catchups based on the search term
    if (searchTerm) {
      let activeCatchUps = state.activeCatchUps?.filter((catchUp) =>
        catchUp.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      let completedCatchUps = state.completedCatchUps?.filter((catchUp) =>
        catchUp.title.toLowerCase().includes(searchTerm.toLowerCase())
      );

      setState({
        filteredActiveCatchUps: activeCatchUps,
        filteredCompletedCatchUps: completedCatchUps,
      });
    } else {
      setState({
        filteredActiveCatchUps: state.activeCatchUps,
        filteredCompletedCatchUps: state.completedCatchUps,
      });
    }
  }, [searchTerm]);

  const onActiveTabChange = (tabName: string) => {
    let tab: DashboardJourneyTabType = "ACTIVE";
    switch (tabName) {
      case t("Common.Active"):
        tab = "ACTIVE";
        break;
      case t("Common.Completed"):
        tab = "COMPLETED";
        break;
    }
    setActiveTab(tab);
  };

  const handleSearchTermChange = (newsearchTerm: string | null) => {
    setSearchTerm(newsearchTerm);
  };

  let tabs: Array<TabDetails> = [
    {
      title: t("Common.Active"),
      content: (
        <JourneyCatchUpTab
          catchUps={state.filteredActiveCatchUps || []}
          selectedTab={activeTab}
          currentUserId={userContext.user.id}
        />
      ),
      displayAlertIcon: false,
      itemCount: state.filteredActiveCatchUps
        ? state.filteredActiveCatchUps.length
        : 0,
      itemCountDisplayMode: "BRACKETS",
    },
    {
      title: t("Common.Completed"),
      content: (
        <JourneyCatchUpTab
          catchUps={state.filteredCompletedCatchUps || []}
          selectedTab={activeTab}
          currentUserId={userContext.user.id}
        />
      ),
      displayAlertIcon: false,
      itemCount: state.filteredCompletedCatchUps
        ? state.filteredCompletedCatchUps.length
        : 0,
      itemCountDisplayMode: "BRACKETS",
    },
  ];

  const searchComponent = (
    <SearchField
      onSearch={handleSearchTermChange}
      isLoading={state.isLoading}
      loaderColor={userContext.user.client.accentHexColour}
      searchTerm={searchTerm}
      includeSetWidth={false}
      placeholderText="Search..."
    />
  );

  return (
    <MainContainer>
      {state.isLoading && (
        <div className="pt-2 lg:pt-12">
          <SmallLoader />
          <p className="text-center pt-1 text-[#959595] text-sm">
            {t("Common.Loading")}...
          </p>
        </div>
      )}
      {!state.isLoading && (
        <>
          <PageHeaderIntro
            iconType="INFO"
            body="Here, you'll find a list of all your recent and upcoming catch-ups and discussions. Some focus on you, while others involve colleagues or team members. Stay on top of your conversations and never miss a key update!"
            marginClassNames="my-2 lg:mt-4"
          />
          <div className="mt-2 lg:hidden">{searchComponent}</div>
          <div>
            <Tabs
              tabs={tabs}
              selectedTabClassNames={cx(
                "radix-state-inactive:border-0 radix-state-inactive:hover:border-b-2 radix-state-inactive:hover:!border-b-gray-200",
                "radix-state-active:border-b-2",
                clientColour
                  ? `radix-state-active:border-b-[#${clientColour}]`
                  : "radix-state-active:border-b-gray-400",
                clientColour ? `radix-state-active:text-[#${clientColour}]` : ""
              )}
              maxTabsOnSmallScreens={3}
              contextClassNames="!py-0"
              tabListWidth="w-1/3"
              onActiveTabChangeEvent={onActiveTabChange}
              customComponentOnRightOfTabs={searchComponent}
            />
          </div>
        </>
      )}
    </MainContainer>
  );
}

export default YourJourneyCatchUps;
