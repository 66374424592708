import { useState } from "react";
import { faListCheck } from "@fortawesome/pro-regular-svg-icons";
import {
  ConfigurationBlockCard,
  ConfigurationBlockCardEmptyState,
} from "../ConfigurationBlocks";
import { InfoBanner, Sidebar } from "../../../common";

interface ConditionsConfigurationBlockProps {
  journeyVersionId: string;
  isInEditMode: boolean;
  onTriggerEditMode: () => void;
}

export const ConditionsConfigurationBlock = ({
  journeyVersionId,
  isInEditMode,
  onTriggerEditMode,
}: ConditionsConfigurationBlockProps) => {
  // State
  const [isAddConditionSidebarOpen, setIsAddConditionSidebarOpen] =
    useState<boolean>(false);

  const [conditionCount, setConditionCount] = useState<number>(0);

  // Events
  const handleSaveNewCondition = () => {
    // Call the API, then update the state with the new conditions summary
    setIsAddConditionSidebarOpen(false);
  };

  const handleCancelAddNewCondition = () => {
    // Close the sidebar and reset the form
    setIsAddConditionSidebarOpen(false);
  };

  // Variables
  const emptyStateAdditionalContent = isInEditMode ? (
    <p className="text-sm text-gray-400">
      Conditions are optional - you don't have to have any
    </p>
  ) : null;

  const helpText =
    "'Conditions' control who can be assigned to this journey. They are optional.";

  return (
    <>
      <ConfigurationBlockCard
        title="Conditions"
        helpTooltip={helpText}
        icon={faListCheck}
        isInEditMode={isInEditMode}
      >
        <div className="text-gray-500 italic">
          {conditionCount > 0 ? (
            <div>
              <div>TODO: Listing/summary</div>
              <button onClick={onTriggerEditMode}>Edit</button>
            </div>
          ) : (
            <ConfigurationBlockCardEmptyState
              itemName="condition"
              icon={faListCheck}
              onAddClick={() => setIsAddConditionSidebarOpen(true)}
              additionalChildren={emptyStateAdditionalContent}
            />
          )}
        </div>
      </ConfigurationBlockCard>
      <Sidebar
        title="Add a new condition"
        isOpen={isAddConditionSidebarOpen}
        onOpenChange={setIsAddConditionSidebarOpen}
        width="NARROW"
        primaryButtonText="Save"
        onPrimaryButtonClick={handleSaveNewCondition}
        secondaryButtonText="Cancel"
        onSecondaryButtonClick={handleCancelAddNewCondition}
      >
        <InfoBanner text={helpText} colour="SUBTLE" className="my-2" />
        <p>
          TODO: Add condition form - though make a component that can be shared
          with editing
        </p>
        <p>
          Would be cool if we could do a "condition builder" here, e.g.
          "Appraisal Level" "is" "Senior", or "Job Title" "contains" "Manager"
          etc
        </p>
      </Sidebar>
    </>
  );
};
