import { useTranslation } from "react-i18next";
import { ClientTaskType } from "../../types/dtos/tasks/advanced-tasks/ClientTaskType";
import { advancedTaskHelper } from "../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { TaskReviewDetailDto } from "../../types/dtos/tasks/advanced-tasks/TaskReviewDetailDto";
import { useNavigate } from "react-router-dom";
import AppRoutes from "../../routes/AppRoutes";

interface TaskListLockedBannerProps {
  taskType: ClientTaskType;
  isNonEmployeeViewer: boolean;
  taskReviewDetails: TaskReviewDetailDto;
}

const TaskListLockedBanner = ({
  taskType,
  isNonEmployeeViewer,
  taskReviewDetails
}: TaskListLockedBannerProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToCollabDoc = () => {
    if (taskReviewDetails) {
      navigate(`/collab-doc/${taskReviewDetails.answerSetGuidId}`);
    }
  };

  const navigateToDashboard = () => {
    navigate(AppRoutes.yourJourney.root);
  };

  return (
    <div className="bg-orange-50 py-1 px-4 mb-2 text-orange-700 text-sm rounded-sm border-l-2 border-l-orange-800">
      <div>
        <div className="fa-layers fa-fw mr-0.5">
          <FontAwesomeIcon icon={faExclamationCircle}></FontAwesomeIcon>
        </div>
        {t("TaskType.Listing.LockedMessage", {
          taskTypes: advancedTaskHelper.ToLowerCase(taskType.pluralNameTranslationKeyIdentifier)
        })}
        {" "}
        {/* Employee - Journey Stage - Direct to dashboard */}
        {!isNonEmployeeViewer && !taskReviewDetails.isAtCollabDocStage && (
          <button className="underline hover:cursor-pointer font-bold" onClick={navigateToDashboard}>
            {taskReviewDetails.journeyName}
          </button>
        )}

        {/* Manager - Journey Stage - Show non-link  */}
        {isNonEmployeeViewer && !taskReviewDetails.isAtCollabDocStage && (
          <button className="font-bold cursor-default">
            {taskReviewDetails.journeyName}
          </button>
        )}

        {/* Employee or Manager - Collab Doc Stage - Show link to collab doc  */}
        {taskReviewDetails.isAtCollabDocStage && (
          <button className="underline hover:cursor-pointer font-bold" onClick={navigateToCollabDoc}>
            {taskReviewDetails.journeyName}
          </button>
        )}
      </div>
    </div>
  );
};

export default TaskListLockedBanner;
