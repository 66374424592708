import { Reducer, useEffect, useReducer, useRef, useState } from "react";
//@ts-ignore
import React from "react";
import UserContext from "../../../../state/UserContext";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import ToDoListTaskTypeDto from "../../../../types/dtos/dashboards/right-bar/to-do-list/ToDoListTaskTypeDto";
import TaskTypeSelector from "../../../dashboard/right-bar/TaskTypeSelector";
import { AdvancedTaskTabCounts } from "../../../../types/dtos/tasks/advanced-tasks/AdvancedTaskTabCounts";
import { ClientTaskType } from "../../../../types/dtos/tasks/advanced-tasks/ClientTaskType";
import UserTaskListItemDto from "../../../../types/dtos/tasks/advanced-tasks/UserTaskListItemDto";
import advancedTasksApi from "../../../../api/task/advancedTasksApi";
import TaskManagementSection from "../../../task-management/TaskManagementSection";
import SmallLoader from "../../../loaders/SmallLoader";
import UserTaskListingForAdminResponse from "../../../../types/dtos/tasks/advanced-tasks/UserTaskListingForAdminResponse";
import UserTaskListGroupDto from "../../../../types/dtos/tasks/advanced-tasks/UserTaskListGroupDto";

interface UserDetailTaskSectionProps {
  userId: number;
  taskTypeDtos: ToDoListTaskTypeDto[];
}

interface TaskManagementState {
  isPageLoading: boolean;
  tabCounts: AdvancedTaskTabCounts;
  taskType: ClientTaskType | null;
  headerTitleHtml: string | null;
  headerBodyHtml: string | null;
  user: UserTaskListGroupDto | null;
}

function UserDetailTaskSection({
  userId,
  taskTypeDtos,
}: UserDetailTaskSectionProps) {
  const { t } = useTranslation();
  const auth = useAuth();
  const tasksApi = new advancedTasksApi(auth.user?.access_token);
  const userContext = React.useContext(UserContext);

  const [selectedTaskTypeId, setSelectedTaskTypeId] = useState<string | number>();
  const componentRef = useRef(null);

  const [taskManagementState, setTaskManagementState] = useReducer<
    Reducer<
      TaskManagementState,
      Partial<TaskManagementState>
    >
  >((state, newState) => ({ ...state, ...newState }), {
    isPageLoading: false,
    taskType: null,
    headerTitleHtml: null,
    headerBodyHtml: null,
    tabCounts: {
      employeeId: 0,
      active: 0,
      cancelled: 0,
      completed: 0,
      historic: 0,
    },
    user: null
  });

  // Set the selected task type id to the first task type id in the list
  useEffect(() => {
    if (taskTypeDtos.some((x) => x.standardTaskTypeId != null)) {
      // @ts-ignore
      setSelectedTaskTypeId(taskTypeDtos[0].standardTaskTypeId);
    } else {
      // @ts-ignore
      setSelectedTaskTypeId(taskTypeDtos[0].advancedTaskTypeId);
    }
  }, []);

  useEffect(() => {
    setTaskManagementState({ isPageLoading: true });
    const clientTaskType = userContext.user.client.taskTypes.find(
      (x) => x.id === selectedTaskTypeId
    );
    if (clientTaskType) {
      tasksApi.listTasksForAdminByUser(
        userId,
        clientTaskType.id,
        (data: UserTaskListingForAdminResponse) => {
          setTaskManagementState({
            isPageLoading: false,
            taskType: clientTaskType,
            headerTitleHtml: data.headerTitleHtml,
            headerBodyHtml: data.headerBodyHtml,
            user: data.user
          });
        },
        (error: any) => {
          console.error("Error getting comments", error);
        }
      );
    }
  }, [selectedTaskTypeId]);

  const onSelectedTaskTypeIdChange = (standardTaskTypeId?: number | null, advancedTaskTypeId?: string | null) => {
    if (standardTaskTypeId != null) {
      setSelectedTaskTypeId(standardTaskTypeId);
    } else if (advancedTaskTypeId != null) {
      setSelectedTaskTypeId(advancedTaskTypeId);
    }
  };

  const updateTasks = (
    userId: number,
    tasks: UserTaskListItemDto[],
    tabCounts: AdvancedTaskTabCounts
  ) => {
    if (taskManagementState.user != null) {
      setTaskManagementState({ user: { ...taskManagementState.user, tasks: tasks, tabCounts: tabCounts } });
      scrollToCompontent();
    }
  };

  const scrollToCompontent = () => {
    if (componentRef.current) {
      // @ts-ignore
      componentRef.current.scrollIntoView();
    }
  }


  return (
    <>
      <div ref={componentRef} className="mt-1 text-gray-500">
        <h4 className="font-bold mt-2 mb-1">{t("Timeline.Filters.Tasks")}</h4>
        {/* Task Type Selection */}
        {selectedTaskTypeId && (
          <TaskTypeSelector
            toDoListTaskTypes={taskTypeDtos}
            selectedTaskTypeId={selectedTaskTypeId}
            showCount={false}
            onSelectedTaskTypeIdChange={onSelectedTaskTypeIdChange}
          />
        )}
        {taskManagementState.isPageLoading && (
          <div className="pt-2">
            <SmallLoader />
            <p className="text-center pt-1 text-[#959595] text-sm">
              {t("Common.Loading")}...
            </p>
          </div>
        )}
        {!taskManagementState.isPageLoading && taskManagementState.taskType && taskManagementState.user && (
          <TaskManagementSection
            tabCounts={taskManagementState.user.tabCounts}
            userContext={userContext}
            taskType={taskManagementState.taskType}
            tasks={taskManagementState.user.tasks}
            minTasks={taskManagementState.user.minTasks}
            maxTasks={taskManagementState.user.maxTasks}
            countRestriction={taskManagementState.user.countRestriction}
            activeCategories={taskManagementState.user.activeCategories}
            userGroup={taskManagementState.user}
            viewerType="ADMIN"
            setUserTasks={updateTasks}
            isActivelyInReview={taskManagementState.user.isActivelyInReview}
            taskReviewDetails={taskManagementState.user.taskReviewDetails}
          />
        )}
      </div>
    </>
  );
}

export default UserDetailTaskSection;
