import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faXmark } from "@fortawesome/pro-regular-svg-icons";
import TinyLoader from "../loaders/TinyLoader";
import cx from "classnames";

interface SearchFieldProps {
  id?: string;
  isLoading: boolean;
  onSearch: (searchValue: string | null) => void;
  loaderColor: string;
  searchTerm?: string | null;
  includeSetWidth?: boolean;
  placeholderText?: string;
  outerContainerClassName?: string;
  inputFieldClassName?: string;
  className?: string;
}

function SearchField({
  id = "search-field",
  isLoading,
  onSearch,
  loaderColor,
  searchTerm,
  includeSetWidth = true,
  placeholderText = "Search for a user...",
  outerContainerClassName = "flex items-center my-0.5",
  inputFieldClassName = undefined,
  className = undefined,
}: SearchFieldProps) {
  const [searchPhrase, setSearchPhrase] = useState<string>(searchTerm ?? "");

  useEffect(() => {
    setSearchPhrase(searchTerm ?? "");
  }, [searchTerm]);

  const debouncedPerformSearch = useCallback(
    debounce((searchValue: string) => {
      onSearch(searchValue);
    }, 300),
    []
  );

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    setSearchPhrase(searchValue);
    debouncedPerformSearch(searchValue.trim());
  };

  const onClearTextField = (e: React.MouseEvent) => {
    e.preventDefault();
    setSearchPhrase("");
    onSearch(null);
  };

  const onKeyDownHandler = (e: React.KeyboardEvent) => {
    // Prevent press on 'Enter' key to prevent search field from clearing
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
    }
  };
  return (
    <form id={id} className={outerContainerClassName}>
      <div
        className={cx(
          "relative w-full",
          includeSetWidth ? "lg:w-80" : "",
          className ? className : ""
        )}
      >
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-gray-500">
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </div>
        <input
          type="text"
          value={searchPhrase}
          onKeyDown={onKeyDownHandler}
          onChange={onTextChange}
          maxLength={250}
          className={cx(
            "bg-gray-50 border border-gray-300 text-gray-700 text-sm rounded-lg block w-full pl-10 py-2 pr-24",
            inputFieldClassName ? inputFieldClassName : ""
          )}
          placeholder={placeholderText}
        />
        <div className="absolute inset-y-0 right-4 flex items-center pr-3">
          <TinyLoader isLoading={isLoading} colour={loaderColor} />
        </div>
        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
          <button
            className="cursor-pointer px-1 text-gray-500"
            onClick={onClearTextField}
          >
            <FontAwesomeIcon icon={faXmark} size="xs" />
          </button>
        </div>
      </div>
    </form>
  );
}

export default SearchField;
