import {
  JourneyCategoryApiResponse,
  AdminNewJourneyCategory,
  JourneyCategoryOverviewApiResponse,
  AdminEditedJourneyCategory,
  AdminNewJourney as NewJourneyHeader,
  JourneyCreationApiResponse,
  AdminEditedJourney,
  JourneyOverviewApiResponse,
} from "../../types/admin/journeys";
import { apiClient } from "../apiClient";

class journeyAdminApi {
  constructor(authToken: string | undefined) {
    this.accessToken = authToken;
  }

  accessToken: string | undefined;

  /** Load the journey categories for the logged in user's client */
  loadCategories(
    onSuccess: (response: JourneyCategoryApiResponse) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/journey-admin/journey-categories"
    ).then(
      (response) => {
        const responseData = response as JourneyCategoryApiResponse;
        onSuccess(responseData);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Call the API to add a new Journey Category */
  addJourneyCategory(
    newCategory: AdminNewJourneyCategory,
    onSuccess: (response: JourneyCategoryApiResponse) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/journey-admin/add-journey-category",
      {
        body: JSON.stringify(newCategory),
      }
    ).then(
      (response) => {
        const responseData = response as JourneyCategoryApiResponse;
        onSuccess(responseData);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Call the API to update a Journey Category's header */
  updateJourneyCategory(
    category: AdminEditedJourneyCategory,
    onSuccess: (response: JourneyCategoryOverviewApiResponse) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/journey-admin/update-journey-category",
      {
        body: JSON.stringify(category),
      }
    ).then(
      (response) => {
        const responseData = response as JourneyCategoryOverviewApiResponse;
        onSuccess(responseData);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Load the journey categories for the logged in user's client */
  deleteCategory(
    categoryId: string,
    onSuccess: (response: JourneyCategoryApiResponse) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/journey-admin/delete-journey-category?id=" + categoryId,
      {
        method: "DELETE",
      }
    ).then(
      (response) => {
        const responseData = response as JourneyCategoryApiResponse;
        onSuccess(responseData);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Get the category header details and the list of journeys within it */
  loadCategoryOverview(
    categoryId: string,
    onSuccess: (response: JourneyCategoryOverviewApiResponse) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/journey-admin/category-overview?id=" + categoryId
    ).then(
      (response) => {
        const responseData = response as JourneyCategoryOverviewApiResponse;
        onSuccess(responseData);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Call the API to add a new Journey */
  addJourney(
    newJourney: NewJourneyHeader,
    onSuccess: (response: JourneyCreationApiResponse) => void,
    onError: (error: any) => void
  ) {
    return apiClient(this.accessToken, "dashboards/journey-admin/add-journey", {
      body: JSON.stringify(newJourney),
    }).then(
      (response) => {
        const responseData = response as JourneyCreationApiResponse;
        onSuccess(responseData);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Call the API to update a Journey's header */
  updateJourney(
    category: AdminEditedJourney,
    onSuccess: (response: JourneyOverviewApiResponse) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/journey-admin/update-journey",
      {
        body: JSON.stringify(category),
      }
    ).then(
      (response) => {
        const responseData = response as JourneyOverviewApiResponse;
        onSuccess(responseData);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Get the journey header details and overviews of the child collections within it */
  loadJourneyOverview(
    journeyId: string,
    onSuccess: (response: JourneyOverviewApiResponse) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/journey-admin/journey-overview?id=" + journeyId
    ).then(
      (response) => {
        const responseData = response as JourneyOverviewApiResponse;
        onSuccess(responseData);
      },
      (error) => {
        onError(error);
      }
    );
  }
}

export default journeyAdminApi;
