import { Reducer, useReducer } from "react";
import { useAuth } from "react-oidc-context";
import { Label, Sidebar, TextArea, TextInput } from "../../common";
import { JourneyConstants } from "../../../config/journeys/JourneyConstants";
import {
  JourneyCategoryApiResponse,
  AdminNewJourneyCategory,
} from "../../../types/admin/journeys";
import DangerAlert from "../../alerts/DangerAlert";
import journeyAdminApi from "../../../api/admin/journeyAdminApi";
import { ValidationResult } from "../../../types/forms";

interface CreateJourneyCategoryModalProps {
  modalIsVisible: boolean;
  onToggleModal: (isOpen: boolean) => void;
  onCreateCategorySuccessCallback: (
    apiResponse: JourneyCategoryApiResponse
  ) => void;
}

export const CreateJourneyCategoryModal = ({
  modalIsVisible,
  onToggleModal,
  onCreateCategorySuccessCallback,
}: CreateJourneyCategoryModalProps) => {
  // Constants
  const auth = useAuth();
  const api = new journeyAdminApi(auth.user?.access_token);

  // State
  const [popupState, setPopupState] = useReducer<
    Reducer<NewJourneyCategoryPopupState, Partial<NewJourneyCategoryPopupState>>
  >((state, newState) => ({ ...state, ...newState }), getDefaultPopupState());

  // Functions
  function getDefaultPopupState(): NewJourneyCategoryPopupState {
    return {
      name: "",
      description: "",
      showValidationErrors: false,
      saveErrored: false,
      isCallingApi: false,
      validationErrors: [],
    };
  }

  const validateForm = (): boolean => {
    let isValid = true;
    if (popupState.name.trim() === "") {
      setPopupState({
        showValidationErrors: true,
        validationErrors: [
          {
            field: "NAME",
            validationResult: {
              isValid: false,
              errors: [{ errorType: "REQUIRED" }],
            },
          },
        ],
      });
      isValid = false;
    } else {
      setPopupState({ showValidationErrors: false, validationErrors: [] });
    }
    return isValid;
  };

  // Events
  const handleNameChange = (newName: string) => {
    setPopupState({ name: newName });
  };

  const handleDescriptionChange = (newDescription: string) => {
    setPopupState({ description: newDescription });
  };

  const handleCreateCategory = () => {
    // Hide any previous errors
    setPopupState({ saveErrored: false });

    // Check the form is valid
    if (!validateForm()) {
      return;
    }

    // Disable the "Create" button to avoid double clicks
    setPopupState({ isCallingApi: true });

    const newCategoryDetails: AdminNewJourneyCategory = {
      displayName: popupState.name.trim(),
      description: popupState.description.trim(),
    };

    const successCallback = (apiResponse: JourneyCategoryApiResponse) => {
      if (!apiResponse.success) {
        setPopupState({ saveErrored: true, isCallingApi: false });
        console.error("Failed to create category", apiResponse.errorCode);
        return;
      }

      // Reset the form
      setPopupState(getDefaultPopupState());

      // Close the modal
      onToggleModal(false);

      // Call the callback
      onCreateCategorySuccessCallback(apiResponse);
    };

    const errorCallback = () => {
      setPopupState({ saveErrored: true, isCallingApi: false });
    };

    // Call the API
    api.addJourneyCategory(newCategoryDetails, successCallback, errorCallback);
  };

  const handleCancel = () => {
    // Logic for canceling the creation
    onToggleModal(false);
  };

  return (
    <Sidebar
      title="Create a new category"
      isOpen={modalIsVisible}
      onOpenChange={onToggleModal}
      onPrimaryButtonClick={handleCreateCategory}
      primaryButtonText="Create"
      onSecondaryButtonClick={handleCancel}
      secondaryButtonText="Cancel"
      isPrimaryButtonDisabled={popupState.isCallingApi}
      width="NARROW"
    >
      {popupState.saveErrored && (
        <DangerAlert
          message="There was a problem creating your new category. Please try again"
          prefix="Oops!"
        />
      )}
      <p className="mb-2">Give your new category a unique name:</p>
      <form className="grid grid-cols-1 gap-2">
        <div>
          <Label htmlFor="categoryName">Category name</Label>
          <TextInput
            inputId="categoryName"
            placeholder="Required"
            value={popupState.name}
            onChange={handleNameChange}
            className="w-full border-gray-200"
            maxLength={JourneyConstants.Admin.MaxLengths.CategoryDisplayName}
            showValidationErrors={popupState.showValidationErrors}
            validationResult={
              popupState.validationErrors.find((x) => x.field === "NAME")
                ?.validationResult
            }
          />
        </div>
        <div>
          <Label htmlFor="categoryDescription">Description</Label>
          <TextArea
            inputId="categoryDescription"
            placeholder="Optional, but recommended"
            value={popupState.description}
            onChange={handleDescriptionChange}
            className="w-full border-gray-200"
            minRows={3}
            maxLength={JourneyConstants.Admin.MaxLengths.CategoryDescription}
          />
        </div>
      </form>
    </Sidebar>
  );
};

interface NewJourneyCategoryPopupState {
  name: string;
  description: string;
  showValidationErrors: boolean;
  saveErrored: boolean;
  isCallingApi: boolean;
  validationErrors: CategoryModalValidationError[];
}

interface CategoryModalValidationError {
  field: "NAME" | "DESCRIPTION";
  validationResult: ValidationResult;
}
