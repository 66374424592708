import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";

interface PrimaryButtonProps {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  icon?: IconProp | undefined;
  iconPlacement?: "LEFT" | "RIGHT";
  size?: "NORMAL" | "SMALL";
}

const PrimaryButton = ({
  onClick,
  text,
  disabled = false,
  icon = undefined,
  iconPlacement = "LEFT",
  size = "NORMAL",
}: PrimaryButtonProps) => {
  return (
    <button
      className={cx(
        "btn-primary leading-loose font-semibold inline-flex items-center shadow",
        size === "SMALL" ? "btn-small" : ""
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && iconPlacement === "LEFT" && (
        <FontAwesomeIcon className="mr-2" icon={icon} size="xs" />
      )}
      <span>{text}</span>
      {icon && iconPlacement === "RIGHT" && (
        <FontAwesomeIcon className="ml-2" icon={icon} size="xs" />
      )}
    </button>
  );
};

export default PrimaryButton;
