import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeftLong } from "@fortawesome/pro-regular-svg-icons";
import AppContext from "../../../state/AppContext";
import AppRoutes from "../../AppRoutes";
import { MainContainer } from "../../../components/layout";
import {
  EditableJourneyHeader,
  JourneyVersionEditorContainer,
} from "../../../components/admin/journeys";
import { useAuth } from "react-oidc-context";
import journeyAdminApi from "../../../api/admin/journeyAdminApi";
import {
  JourneyOverview,
  JourneyOverviewApiResponse,
} from "../../../types/admin/journeys";

const AdminJourneyEditPage = () => {
  // Constants
  const appContext = useContext(AppContext);
  const { categoryId, journeyId } = useParams();
  const auth = useAuth();
  const api = new journeyAdminApi(auth.user?.access_token);

  // State
  const [isLoadingJourney, setIsLoadingJourney] = useState<boolean>(true);
  const [journey, setJourney] = useState<JourneyOverview | null>(null);

  // Effects
  useEffect(() => {
    loadData();
  }, [journeyId]); // eslint-disable-line react-hooks/exhaustive-deps

  // Functions
  function loadData() {
    if (!journeyId || journeyId.length === 0) return;

    const successCallback = (response: JourneyOverviewApiResponse) => {
      if (!response.success) {
        errorCallback();
      }

      onJourneyLoaded(response.data);
    };

    const errorCallback = () => {
      setJourney(null);
      setIsLoadingJourney(false);
      console.error("Problem loading the journey overview");
    };

    setIsLoadingJourney(true);
    api.loadJourneyOverview(journeyId, successCallback, errorCallback);
  }

  const onJourneyLoaded = (latestJourneyInfo: JourneyOverview) => {
    setIsLoadingJourney(false);
    setJourney(latestJourneyInfo);

    appContext.setPageTitle(
      `Journey management: ${latestJourneyInfo.displayName}`
    );
    appContext.setShowPageTitleAccent(true);
  };

  const categoryHref = categoryId
    ? AppRoutes.admin.journeys.category.generateRoute(categoryId)
    : "";

  return (
    <MainContainer>
      <div className="mt-2 text-gray-600">
        <Link
          to={categoryHref}
          className="hover:underline hover:cursor-pointer"
        >
          <FontAwesomeIcon icon={faLeftLong} size="xs" className="mr-1" />
          Back to category
        </Link>
      </div>
      <EditableJourneyHeader
        isLoading={isLoadingJourney}
        journey={journey}
        onJourneyUpdated={onJourneyLoaded}
      />
      <JourneyVersionEditorContainer
        journey={journey}
        isLoading={isLoadingJourney}
      />
    </MainContainer>
  );
};

export default AdminJourneyEditPage;
