import { useTranslation } from "react-i18next";
import { ReactComponent as EmptyStateImage } from "../../../../images/noCalendar.svg";

function CatchUpEmptyState() {
  const { t } = useTranslation();
  return (
    <div className="text-center text-gray-400 grow bg-white py-2 lg:py-3">
      <div className="flex justify-center">
        <EmptyStateImage className="max-w-1/2 h-16 my-4" />
      </div>
      <div className="mt-2 text-sm">
        <span>{t("DashboardTaskBar.CatchUps.YouHaventGotAnyActive")}</span>
      </div>
    </div>
  );
}

export default CatchUpEmptyState;
