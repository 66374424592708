import { Routes, Route } from "react-router-dom";
import AppRoutes from "../AppRoutes";
import Journeys from "./client-journeys/Journeys";
import UserDetail from "./users/UserDetail";
import SendingHistory from "./client-journeys/SendingHistory";
import EditConfiguration from "./client-journeys/EditConfiguration";
import SendPage from "./client-journeys/SendPage";
import UserAdminHistory from "./users/UserAdminHistory";
import {
  AdminJourneyCategory,
  AdminJourneyEditPage,
  AdminJourneyRoot,
} from "./journeys";

function AdminRoot() {
  return (
    <>
      <Routes>
        <Route
          path={AppRoutes.admin.sub.clientJourneys.path}
          element={<Journeys />}
        />
        <Route
          path={
            AppRoutes.admin.sub.clientJourneys.pathOpenWithConfigTabSelected
          }
          element={<Journeys />}
        />
        <Route
          path={
            AppRoutes.admin.sub.clientJourneys
              .pathRedirectFromSendNowAfterSuccess
          }
          element={<Journeys />}
        />
        <Route
          path={AppRoutes.admin.sub.clientJourneys.userDetail.path}
          element={<UserDetail />}
        />
        <Route
          path={AppRoutes.admin.sub.clientJourneys.userDetail.adminHistory}
          element={<UserAdminHistory />}
        />
        <Route
          path={AppRoutes.admin.sub.clientJourneys.sendingHistory}
          element={<SendingHistory />}
        />
        <Route
          path={AppRoutes.admin.sub.clientJourneys.config.path}
          element={<EditConfiguration />}
        />
        <Route
          path={AppRoutes.admin.sub.clientJourneys.send.path}
          element={<SendPage />}
        />
        <Route
          path={AppRoutes.admin.sub.journeys.root}
          element={<AdminJourneyRoot />}
        />
        <Route
          path={AppRoutes.admin.sub.journeys.category}
          element={<AdminJourneyCategory />}
        />
        <Route
          path={AppRoutes.admin.sub.journeys.journey}
          element={<AdminJourneyEditPage />}
        />
      </Routes>
    </>
  );
}

export default AdminRoot;
