import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faPencilAlt,
} from "@fortawesome/pro-regular-svg-icons";
import {
  AdminEditedJourneyCategory,
  JourneyCategoryOverview,
  JourneyCategoryOverviewApiResponse,
} from "../../../types/admin/journeys";
import {
  DropDownMenu,
  Label,
  TextArea,
  TextInput,
  Tooltip,
} from "../../common";
import { JourneyConstants } from "../../../config/journeys/JourneyConstants";
import journeyAdminApi from "../../../api/admin/journeyAdminApi";
import { ValidationResult } from "../../../types/forms";
import DangerBanner from "../../common/DangerBanner";

interface EditableJourneyCategoryHeaderProps {
  isLoading: boolean;
  category: JourneyCategoryOverview | null;
  onCategoryUpdated: (category: JourneyCategoryOverview) => void;
}

interface HeaderFormValidationError {
  field: "NAME" | "DESCRIPTION";
  result: ValidationResult;
}

export const EditableJourneyCategoryHeader = ({
  isLoading,
  category,
  onCategoryUpdated,
}: EditableJourneyCategoryHeaderProps) => {
  // Constants
  const auth = useAuth();
  const api = new journeyAdminApi(auth.user?.access_token);

  // State
  const [inEditMode, setInEditMode] = useState(false);
  const [nameEditedValue, setNameEditedValue] = useState<string | null>(null);
  const [descriptionEditedValue, setDescriptionEditedValue] = useState<
    string | null
  >(null);
  const [formErrors, setFormErrors] = useState<HeaderFormValidationError[]>([]);
  const [showFormErrors, setShowFormErrors] = useState<boolean>(false);
  const [isCallingApi, setIsCallingApi] = useState(false);
  const [erroredSaving, setErroredSaving] = useState<boolean>(false);

  // Effects
  useEffect(() => {
    setDescriptionEditedValue(category ? category.description : null);
    setNameEditedValue(category ? category.displayName : null);
  }, [category]);

  // Functions
  const validateHeaderForm = (): boolean => {
    // Reset
    setShowFormErrors(false);

    let isValid = true;
    const errors: HeaderFormValidationError[] = [];

    if (!nameEditedValue || nameEditedValue.trim().length === 0) {
      isValid = false;
      errors.push({
        field: "NAME",
        result: {
          isValid: false,
          errors: [{ errorType: "REQUIRED" }],
        },
      });
    }

    setFormErrors(errors);

    if (!isValid) {
      setShowFormErrors(true);
    }

    return isValid;
  };

  // Events
  const onDropMenuChange = () => {
    // If the edit mode is changing from true to false, it's a reset
    if (inEditMode === true) {
      // Reset the edit form
      setNameEditedValue(category ? category.displayName : "");
      setDescriptionEditedValue(category ? category.description : "");
    }
    setInEditMode(!inEditMode);
  };

  const handleCategoryHeaderSaveClick = () => {
    if (!category) return;

    if (!validateHeaderForm()) return;

    const updatedCategory: AdminEditedJourneyCategory = {
      id: category.id,
      displayName: nameEditedValue!,
      description: descriptionEditedValue,
    };

    setIsCallingApi(true);
    api.updateJourneyCategory(
      updatedCategory,
      (response: JourneyCategoryOverviewApiResponse) => {
        // Reload the category
        setInEditMode(false);
        setIsCallingApi(false);
        setErroredSaving(false);
        onCategoryUpdated(response.data);
      },
      () => {
        // Error
        setErroredSaving(true);
        setIsCallingApi(false);
      }
    );
  };

  return (
    <div className="bg-slate-100 rounded-md px-4 pt-1 pb-4 my-2 border border-gray-200">
      <div className="flex flex-row mb-2 border-b border-dotted border-gray-300">
        <div className="flex-grow">
          <h4 className="font-semibold pb-1">Category details</h4>
        </div>
        <div className="flex-initial">
          <DropDownMenu
            eventType="EVENT"
            items={[
              {
                text: inEditMode ? "Stop editing details" : "Edit details",
                customArg: "EDIT",
                customIcon: <FontAwesomeIcon icon={faPencilAlt} />,
              },
            ]}
            showMenuEvenIfOnlyOneItem
            onItemClick={onDropMenuChange}
            menuButtonClassName="block w-full lg:inline-block lg:w-auto"
            customButtonContents={
              <>
                <Tooltip
                  content="Options"
                  triggerElement={
                    <button className="block w-6 text-center border border-transparent rounded-md hover:bg-slate-50 hover:border-slate-200">
                      <FontAwesomeIcon icon={faEllipsisVertical} />
                    </button>
                  }
                />
              </>
            }
          />
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
        {(isLoading || !category) && (
          <>
            <div className="h-10 bg-gray-200 rounded animate-pulse"></div>
            <div className="h-16 bg-gray-200 rounded animate-pulse"></div>
          </>
        )}
        {!isLoading && category && (
          <>
            {erroredSaving && (
              <DangerBanner text="There was a problem saving your changes. Please try again" />
            )}
            <div>
              <Label
                text="Name"
                htmlFor="journeyCategoryName"
                className="uppercase text-sm text-gray-500"
              />
              {inEditMode ? (
                <TextInput
                  inputId="journeyCategoryName"
                  value={nameEditedValue || ""}
                  onChange={setNameEditedValue}
                  placeholder="Required"
                  className="w-full border-gray-400"
                  maxLength={
                    JourneyConstants.Admin.MaxLengths.CategoryDisplayName
                  }
                  showValidationErrors={showFormErrors}
                  validationResult={
                    formErrors &&
                    formErrors.find((e) => e.field === "NAME")?.result
                  }
                />
              ) : (
                <div>
                  <button
                    className="block truncate hover:cursor-pointer"
                    onClick={() => setInEditMode(true)}
                  >
                    {category.displayName}
                  </button>
                </div>
              )}
            </div>
            <div>
              <Label
                text="Description"
                htmlFor="journeyCategoryDesc"
                className="uppercase text-sm text-gray-500"
              />
              {inEditMode ? (
                <TextArea
                  inputId="journeyCategoryDesc"
                  value={descriptionEditedValue || ""}
                  onChange={setDescriptionEditedValue}
                  placeholder="Optional, but recommended"
                  className="w-full rounded-md border-gray-400"
                  minRows={3}
                  maxLength={
                    JourneyConstants.Admin.MaxLengths.CategoryDescription
                  }
                />
              ) : (
                <div>
                  <button
                    className="inline-block max-w-full truncate hover:cursor-pointer"
                    onClick={() => setInEditMode(true)}
                  >
                    {category.description}
                  </button>
                </div>
              )}
            </div>
            {inEditMode && (
              <div className="col-span-2 text-right">
                <button
                  className="btn-primary disabled:cursor-not-allowed"
                  onClick={handleCategoryHeaderSaveClick}
                  disabled={isCallingApi}
                >
                  Save
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
