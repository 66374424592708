import { useContext, useEffect } from "react";
import { JourneyCategoryList } from "../../../components/admin/journeys";
import AppContext from "../../../state/AppContext";
import { MainContainer } from "../../../components/layout";

const AdminJourneyRoot = () => {
  // Constants
  const appContext = useContext(AppContext);

  // Effects
  useEffect(() => {
    appContext.setPageTitle("Journey Management");
    appContext.setShowPageTitleAccent(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MainContainer>
      <div className="mt-4">
        <JourneyCategoryList />
      </div>
    </MainContainer>
  );
};

export default AdminJourneyRoot;
