import { MainContainer } from "../../components/layout";
import { Reducer, useReducer, useContext, useEffect, useState } from "react";
import AppContext from "../../state/AppContext";
import { useTranslation } from "react-i18next";
import SmallLoader from "../../components/loaders/SmallLoader";
import myDashboardApi from "../../api/dashboard/myDashboardApi";
import { useAuth } from "react-oidc-context";
import MyDashboardFormsApiResponseDto from "../../types/dtos/dashboards/MyDashboardFormsApiResponseDto";
import UserContext from "../../state/UserContext";
import SearchField from "../../components/common/SearchField";
import UserJourneyDetailDto from "../../types/dtos/shared/UserJourneyDetailDto";
import JourneyFormSection from "../../components/shared/dashboard-journeys/JourneyFormSection";
import { PageHeaderIntro } from "../../components/common/PageHeaderIntro";
import { useEffectOnce } from "react-use";

interface YourJourneyFormState {
  isLoading: boolean;
  activeForms: UserJourneyDetailDto[] | null;
  completedForms: UserJourneyDetailDto[] | null;
  filteredActiveForms: UserJourneyDetailDto[] | null;
  filteredCompletedForms: UserJourneyDetailDto[] | null;
}

function YourJourneyForms() {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const appContext = useContext(AppContext);
  const auth = useAuth();
  const dashboardApi = new myDashboardApi(auth.user?.access_token);

  const [searchTerm, setSearchTerm] = useState<string | null>(null);

  const [state, setState] = useReducer<
    Reducer<YourJourneyFormState, Partial<YourJourneyFormState>>
  >((state, newState) => ({ ...state, ...newState }), {
    isLoading: false,
    activeForms: null,
    completedForms: null,
    filteredActiveForms: null,
    filteredCompletedForms: null,
  });

  useEffectOnce(() => {
    setState({ isLoading: true });

    appContext.setPageTitle(
      t("Pages.MyDashboard.PageTitle") +
        ": " +
        t("Nav.LargeScreen.MyDashboard.Forms")
    );

    // // Hit the API to load the forms
    dashboardApi.getJourneyFormsData(
      (data: MyDashboardFormsApiResponseDto) => {
        setState({
          isLoading: false,
          activeForms: data.activeForms,
          filteredActiveForms: data.activeForms,
          completedForms: data.completedForms,
          filteredCompletedForms: data.completedForms,
        });
      },
      (error: any) => {
        console.error("Error loading journey forms", error);
      }
    );
  });

  useEffect(() => {
    // If the forms are not loaded yet, return
    if (!state.activeForms || !state.completedForms) {
      return;
    }

    // Filter the forms based on the search term
    if (searchTerm) {
      let activeForms = state.activeForms?.filter((form) =>
        form.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      let completedForms = state.completedForms?.filter((form) =>
        form.title.toLowerCase().includes(searchTerm.toLowerCase())
      );

      setState({
        filteredActiveForms: activeForms,
        filteredCompletedForms: completedForms,
      });
    } else {
      setState({
        filteredActiveForms: state.activeForms,
        filteredCompletedForms: state.completedForms,
      });
    }
  }, [searchTerm]);

  const handleSearchTermChange = (newsearchTerm: string | null) => {
    setSearchTerm(newsearchTerm);
  };

  const searchComponent = (
    <SearchField
      onSearch={handleSearchTermChange}
      isLoading={state.isLoading}
      loaderColor={userContext.user.client.accentHexColour}
      searchTerm={searchTerm}
      includeSetWidth={false}
      placeholderText="Search..."
      outerContainerClassName=""
    />
  );

  return (
    <MainContainer>
      {state.isLoading && (
        <div className="pt-2 lg:pt-12">
          <SmallLoader />
          <p className="text-center pt-1 text-[#959595] text-sm">
            {t("Common.Loading")}...
          </p>
        </div>
      )}
      {!state.isLoading && (
        <>
          <PageHeaderIntro
            iconType="INFO"
            body="View and complete the journeys that are focused on you, each designed to guide your progress and gather your insights."
            marginClassNames="my-2 lg:mt-4"
          />
          <div className="mt-2 lg:hidden">{searchComponent}</div>
          <JourneyFormSection
            userContext={userContext}
            activeForms={state.filteredActiveForms || []}
            completedForms={state.filteredCompletedForms || []}
            isYourPeoplePage={false}
            searchComponent={searchComponent}
          />
        </>
      )}
    </MainContainer>
  );
}

export default YourJourneyForms;
