import { faPlus, faLongArrowRight } from "@fortawesome/pro-regular-svg-icons";
import {
  AdminJourneyListItem,
  JourneyAdminStatus,
  JourneyCategoryOverview,
} from "../../../types/admin/journeys";
import { ReactComponent as EmptyStateImage } from "../../../images/journeysEmptyState.svg";
import { Badge, PrimaryButton, PrimaryLinkButton } from "../../common";
import { Link } from "react-router-dom";
import AppRoutes from "../../../routes/AppRoutes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface CategoryJourneysListProps {
  isLoading: boolean;
  category: JourneyCategoryOverview | null;
  /** A function to call when the user clicks to add a new journey */
  onClickJourneyAdd: () => void;
}

export const JourneyListing = ({
  isLoading,
  category,
  onClickJourneyAdd,
}: CategoryJourneysListProps) => {
  return (
    <div className="bg-white border border-gray-200 rounded-md px-4 pt-1 pb-4 mt-2">
      <h4 className="font-semibold border-b border-dotted border-gray-300 pb-1 mb-3">
        Journeys
      </h4>
      {/* Journey list or empty state */}
      {isLoading && <JourneyListLoadingPlaceholder />}
      {!isLoading &&
        (!category?.journeys || category.journeys.length === 0) && (
          <JourneyListEmptyState
            onCreateJourneyButtonClick={onClickJourneyAdd}
          />
        )}
      {!isLoading && category && category.journeys.length > 0 && (
        <>
          <div className="grid grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-2">
            {category.journeys.map((journey) => (
              <JourneyListItem
                key={journey.id}
                journey={journey}
                categoryId={category.id}
              />
            ))}
            <button
              className="bg-white hover:bg-slate-50 border border-dotted border-gray-300 rounded-sm leading-loose font-semibold text-center shadow hover:shadow-md py-8 px-4 group"
              onClick={onClickJourneyAdd}
            >
              <FontAwesomeIcon className="mr-2" icon={faPlus} size="xs" />
              <span className="group-hover:underline">New journey</span>
            </button>
          </div>
        </>
      )}
    </div>
  );
};

const JourneyListLoadingPlaceholder = () => {
  return (
    <div className="grid grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-2">
      {Array.from(Array(6).keys()).map((i) => (
        <div key={i} className="h-48 bg-gray-200 rounded animate-pulse"></div>
      ))}
    </div>
  );
};

const JourneyListEmptyState = ({
  onCreateJourneyButtonClick,
}: {
  onCreateJourneyButtonClick: () => void;
}) => {
  return (
    <div className="py-2">
      <div className="flex justify-center">
        <EmptyStateImage className="max-w-1/2" />
      </div>
      <p className="my-2 text-center">
        There aren't any journeys in this category yet
      </p>
      <div className="mt-2 text-center">
        <PrimaryButton
          text="New journey"
          onClick={onCreateJourneyButtonClick}
          icon={faPlus}
        />
      </div>
    </div>
  );
};

const JourneyListItem = ({
  journey,
  categoryId,
}: {
  journey: AdminJourneyListItem;
  categoryId: string;
}) => {
  const journeyLink = AppRoutes.admin.journeys.journey.generateRoute(
    categoryId,
    journey.id
  );

  return (
    <div className="bg-slate-50 py-2 border border-solid border-gray-300 rounded-sm shadow-md text-center">
      <div className="flex flex-col gap-y-2 h-full">
        <h3 className="font-semibold !m-0 flex-initial border-b border-gray-300 px-4 pb-1 text-ellipsis">
          <Link to={journeyLink} className="hover:underline">
            {journey.displayName}
          </Link>
        </h3>
        <div className="flex-initial px-4">
          <div className="text-sm text-gray-600 text-ellipsis overflow-hidden">
            Active version:{" "}
            {journey.activeVersionName
              ? `'${journey.activeVersionName}'`
              : "None"}
          </div>
        </div>
        <div className="flex-grow content-center px-4">
          {journey.description && (
            <p className="text-gray-500">{journey.description}</p>
          )}
        </div>

        <div className="my-2 flex-initial px-4">
          <div className="flex flex-row">
            <div className="flex-grow text-left">
              <JourneyListItemBadge status={journey.status} />
            </div>
            <PrimaryLinkButton
              href={journeyLink}
              text="Edit journey"
              icon={faLongArrowRight}
              iconPlacement="RIGHT"
              size="SMALL"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const JourneyListItemBadge = ({ status }: { status: JourneyAdminStatus }) => {
  let displayText: string = "";
  let bgColourClassName: string = "";
  let textColourClassName: string = "";

  switch (status) {
    case "DRAFT":
      displayText = "Draft";
      bgColourClassName = "bg-slate-500";
      textColourClassName = "text-white";
      break;
    case "ACTIVE":
      displayText = "Active";
      bgColourClassName = "bg-green-700";
      textColourClassName = "text-white";
      break;
    case "RETIRED":
      displayText = "Inactive";
      bgColourClassName = "bg-red-700";
      textColourClassName = "text-white";
      break;
  }

  return (
    <Badge
      text={displayText}
      backgroundColourClassName={bgColourClassName}
      textColourClassName={textColourClassName}
      marginClassName="ml-0"
      borderRadiusClassName="rounded-md"
      classNames="uppercase"
    />
  );
};
