import { t } from "i18next";
import { QuestionTasks } from "../types/forms";
import QuestionDbTasks from "../types/forms/QuestionDbTasks";
import { KeyValuePair } from "../types/generic";
import { TaskType } from "../types/tasks";
import EditableTaskDbObject from "../types/tasks/EditableTaskDbObject";
import {
  EditableAction,
  EditableGoal,
  EditableLearningAction,
  EditableTask,
  TaskIdentifierType,
} from "../types/tasks/EditableTasks";
import {
  faTrophyAlt,
  faGraduationCap,
  faClipboardListCheck,
} from "@fortawesome/pro-regular-svg-icons";

function convertTaskObject<TTaskIdentifierType extends TaskIdentifierType>(
  task: EditableTaskDbObject<TTaskIdentifierType>
): EditableTask<TTaskIdentifierType> {
  const targetDateValue = task.targetDate ? new Date(task.targetDate) : null;
  const dateCompletedValue = task.dateCompleted
    ? new Date(task.dateCompleted)
    : null;
  const dateCancelledValue = task.dateCancelled
    ? new Date(task.dateCancelled)
    : null;

  switch (task.taskType) {
    case "ACTION":
      return new EditableAction(
        "ORIGINAL",
        task.taskId ? task.taskId : null,
        task.title ? task.title : null,
        targetDateValue,
        task.field1 ? task.field1 : null,
        dateCompletedValue,
        dateCancelledValue,
        task.status
      );
    case "GOAL":
      return new EditableGoal(
        "ORIGINAL",
        task.taskId ? task.taskId : null,
        task.title ? task.title : null,
        targetDateValue,
        task.field1 ? task.field1 : null,
        task.field2 ? task.field2 : null,
        task.categoryId ? task.categoryId : null,
        dateCompletedValue,
        dateCancelledValue,
        task.status,
        task.rolloverFromTaskId
      );
    case "LEARNING":
      return new EditableLearningAction(
        "ORIGINAL",
        task.taskId ? task.taskId : null,
        task.title ? task.title : null,
        targetDateValue,
        task.field1 ? task.field1 : null,
        task.field2 ? task.field2 : null,
        task.field3 ? task.field3 : null,
        dateCompletedValue,
        dateCancelledValue,
        task.status
      );
  }
}

function convertTaskObjects<TTaskIdentifierType extends TaskIdentifierType>(
  tasks: EditableTaskDbObject<TTaskIdentifierType>[]
): EditableTask<TTaskIdentifierType>[] {
  if (tasks && tasks.length > 0) {
    return tasks.map((t) => convertTaskObject(t));
  } else {
    return [];
  }
}

function convertQuestionTaskObjects(
  questionTasks: QuestionDbTasks[]
): QuestionTasks[] {
  if (!questionTasks || questionTasks.length === 0) return [];

  const output = questionTasks.map((qt) => {
    return {
      ...qt,
      tasks: convertTaskObjects(qt.tasks),
    };
  });

  return output;
}

export const taskTypeHelper = {
  /** Get a new, blank task of the specified task type, optionally with the title pre-filled.
   * Useful when loading an add task modal but wanted to pre-fill some of the details
   */
  getTaskTemplate: function (
    taskType: TaskType,
    title: string | undefined = undefined
  ): EditableTask<number> {
    switch (taskType) {
      case "ACTION":
        return new EditableAction<number>(
          "NEW",
          null,
          title ? title : null,
          null,
          null,
          null,
          null,
          null
        );
      case "GOAL":
        return new EditableGoal<number>(
          "NEW",
          null,
          title ? title : null,
          null,
          null,
          null,
          null,
          null,
          null,
          null
        );
      case "LEARNING":
        return new EditableLearningAction<number>(
          "NEW",
          null,
          title ? title : null,
          null,
          null,
          null,
          null,
          null,
          null,
          null
        );
    }
  },
  /** Convert task js object from the database into the expected class object */
  getTaskObject: convertTaskObject,
  /** Convert task js objects from the database into the expected class objects */
  getTaskObjects: convertTaskObjects,

  getQuestionTaskObjects: convertQuestionTaskObjects,

  getTaskTypesAsKeyValuePairs: function (): KeyValuePair<TaskType, string>[] {
    const types: TaskType[] = ["ACTION", "GOAL", "LEARNING"];
    return types.map((type) => {
      return { key: type, value: this.getTranslationKeyForTaskType(type) };
    });
  },
  getTranslationKeyForTaskType: function (taskType: TaskType) {
    switch (taskType) {
      case "ACTION":
        return "Tasks.Types.Action";
      case "GOAL":
        return "Tasks.Types.Goal";
      case "LEARNING":
        return "Tasks.Types.Learning";
    }
  },
  getTaskQuestionInfoBannerText: function (
    taskType: TaskType | null,
    minimum: number | null,
    maximum: number | null,
    required: boolean
  ) {
    let text: string | null = null;

    switch (taskType) {
      case "ACTION":
        if (minimum && maximum) {
          text = t("Forms.Tasks.ActionCountInfo.Between")
            .replace("#MIN#", minimum.toString())
            .replace("#MAX#", maximum.toString());
        } else if (minimum) {
          const minTransKey =
            minimum === 1
              ? "Forms.Tasks.ActionCountInfo.Required"
              : "Forms.Tasks.ActionCountInfo.MinOnly";
          text = t(minTransKey).replace("#MIN#", minimum.toString());
        } else if (maximum) {
          text = t("Forms.Tasks.ActionCountInfo.MaxOnly").replace(
            "#MAX#",
            maximum.toString()
          );
        } else if (required) {
          text = t("Forms.Tasks.ActionCountInfo.Required");
        }
        break;
      case "GOAL":
        if (minimum && maximum) {
          text = t("Forms.Goals.Setting.GoalCountInfo.Between")
            .replace("#MIN#", minimum.toString())
            .replace("#MAX#", maximum.toString());
        } else if (minimum) {
          const minTransKey =
            minimum === 1
              ? "Forms.Goals.Setting.GoalCountInfo.Required"
              : "Forms.Goals.Setting.GoalCountInfo.MinOnly";
          text = t(minTransKey).replace("#MIN#", minimum.toString());
        } else if (maximum) {
          text = t("Forms.Goals.Setting.GoalCountInfo.MaxOnly").replace(
            "#MAX#",
            maximum.toString()
          );
        } else if (required) {
          text = t("Forms.Goals.Setting.GoalCountInfo.Required");
        }
        break;
      case "LEARNING":
        if (minimum && maximum) {
          text = t("Forms.Tasks.LearningCountInfo.Between")
            .replace("#MIN#", minimum.toString())
            .replace("#MAX#", maximum.toString());
        } else if (minimum) {
          const minTransKey =
            minimum === 1
              ? "Forms.Tasks.LearningCountInfo.Required"
              : "Forms.Tasks.LearningCountInfo.MinOnly";
          text = t(minTransKey).replace("#MIN#", minimum.toString());
        } else if (maximum) {
          text = t("Forms.Tasks.LearningCountInfo.MaxOnly").replace(
            "#MAX#",
            maximum.toString()
          );
        } else if (required) {
          text = t("Forms.Tasks.LearningCountInfo.Required");
        }
        break;
      default:
        if (minimum && maximum) {
          text = t("Forms.Tasks.GenericCountInfo.Between")
            .replace("#MIN#", minimum.toString())
            .replace("#MAX#", maximum.toString());
        } else if (minimum) {
          const minTransKey =
            minimum === 1
              ? "Forms.Tasks.GenericCountInfo.Required"
              : "Forms.Tasks.GenericCountInfo.MinOnly";
          text = t(minTransKey).replace("#MIN#", minimum.toString());
        } else if (maximum) {
          text = t("Forms.Tasks.GenericCountInfo.MaxOnly").replace(
            "#MAX#",
            maximum.toString()
          );
        } else if (required) {
          text = t("Forms.Tasks.GenericCountInfo.Required");
        }
        break;
    }

    return text;
  },
  getTaskTypeIcon: function (taskType: TaskType) {
    switch (taskType) {
      case "ACTION":
        return faClipboardListCheck;
      case "GOAL":
        return faTrophyAlt;
      case "LEARNING":
        return faGraduationCap;
      default:
        return faClipboardListCheck;
    }
  }
};

export default taskTypeHelper;
