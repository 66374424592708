import { useState } from "react";
import { useEffectOnce } from "react-use";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import {
  JourneyCategoryApiResponse,
  AdminJourneyCategoryListItem,
} from "../../../types/admin/journeys";
import AppRoutes from "../../../routes/AppRoutes";
import { CreateJourneyCategoryModal } from "./CreateJourneyCategoryModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faPencilAlt,
  faTrashAlt,
  faArrowProgress,
  faPlus,
  faArrowRightLong,
} from "@fortawesome/pro-regular-svg-icons";
import { faShuffle } from "@fortawesome/pro-duotone-svg-icons";
import {
  AlertPopup,
  Badge,
  DropDownMenu,
  PrimaryButton,
  PrimaryLinkButton,
  Tooltip,
} from "../../common";
import { DropDownMenuItem } from "../../common/DropDownMenu";
import { ReactComponent as EmptyStateImage } from "../../../images/emptyBox.svg";
import journeyAdminApi from "../../../api/admin/journeyAdminApi";
import FullScreenLoaderWithMessage from "../../loaders/FullScreenLoaderWithMessage";
import DangerAlert from "../../alerts/DangerAlert";

/** This is the list of categories rendered at the root journey management page */
const JourneyCategoryList = () => {
  // Constants
  const navigate = useNavigate();
  const auth = useAuth();
  const api = new journeyAdminApi(auth.user?.access_token);

  // State
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [erroredLoading, setErroredLoading] = useState(false);
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [categories, setCategories] = useState<AdminJourneyCategoryListItem[]>(
    []
  );
  const [deletingCategoryId, setDeletingCategoryId] = useState<string | null>(
    null
  );
  const [erroredDeleting, setErroredDeleting] = useState(false);

  const onTriggerShowAddCategoryModel = () => {
    setShowAddCategoryModal(true);
  };

  // Effects
  useEffectOnce(() => {
    // Load categories from API
    loadCategories();
  });

  // Functions
  function loadCategories() {
    // Reset the loader and the error state
    setErroredLoading(false);
    setIsLoadingCategories(true);

    api.loadCategories(
      (response) => {
        setIsLoadingCategories(false);
        setCategories(response.data);
      },
      (error) => {
        setIsLoadingCategories(false);
        setCategories([]);
        console.error(error);
        setErroredLoading(true);
      }
    );
  }

  // Events

  const onCreateCategorySuccessCallback = (
    apiResponse: JourneyCategoryApiResponse
  ) => {
    setCategories(apiResponse.data);
  };

  const onEditCategory = (categoryId: string) => {
    const categoryEditUrl =
      AppRoutes.admin.journeys.category.generateRoute(categoryId);
    navigate(categoryEditUrl);
  };

  const onConfirmDeleteCategory = () => {
    if (!deletingCategoryId) return;

    // Reset
    setErroredDeleting(false);

    const successCallback = (response: JourneyCategoryApiResponse) => {
      setDeletingCategoryId(null);
      setCategories(response.data);
    };

    const errorCallback = (error: any) => {
      setDeletingCategoryId(null);
      setErroredDeleting(true);
    };

    api.deleteCategory(deletingCategoryId, successCallback, errorCallback);
  };

  const onCancelDeleteCategory = () => {
    setDeletingCategoryId(null);
  };

  if (erroredLoading) {
    return (
      <DangerAlert
        message="Failed to load categories. Refresh the page to try again"
        prefix="Oops!"
      />
    );
  }

  if (isLoadingCategories) {
    return <FullScreenLoaderWithMessage message="Loading..." />;
  }

  return (
    <>
      <div className="bg-white border border-gray-200 rounded-md px-4 pt-1 pb-4 mt-2">
        <h4 className="font-semibold border-b border-dotted border-gray-300 pb-1 mb-2">
          Categories
        </h4>
        {categories.length > 0 ? (
          <div className="py-1">
            {erroredDeleting && (
              <DangerAlert
                message="Failed to delete category. Try again"
                prefix="Oops!"
              />
            )}
            <div className="grid grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-2">
              {categories.map((category) => (
                <CategoryItem
                  key={category.id}
                  category={category}
                  onDelete={setDeletingCategoryId}
                  onEdit={onEditCategory}
                />
              ))}
              <button
                className="bg-white hover:bg-slate-50 border border-dotted border-gray-300 rounded-sm leading-loose font-semibold text-center shadow hover:shadow-md py-8 px-4 group"
                onClick={onTriggerShowAddCategoryModel}
              >
                <FontAwesomeIcon className="mr-2" icon={faPlus} size="xs" />
                <span className="group-hover:underline">New category</span>
              </button>
            </div>
          </div>
        ) : (
          <EmptyState
            onCreateNewCategoryButtonClick={onTriggerShowAddCategoryModel}
          />
        )}
      </div>
      <CreateJourneyCategoryModal
        modalIsVisible={showAddCategoryModal}
        onToggleModal={setShowAddCategoryModal}
        onCreateCategorySuccessCallback={onCreateCategorySuccessCallback}
      />
      <AlertPopup
        isOpen={deletingCategoryId !== null}
        primaryButtonText="Yes, delete"
        bodyText={`Are you sure you want to delete the category "${
          categories.find((cat) => cat.id === deletingCategoryId)
            ?.displayName || "-"
        }"?`}
        onOpenChange={() => {}}
        onPrimaryButtonClick={onConfirmDeleteCategory}
        title="Are you sure?"
        onSecondaryButtonClick={onCancelDeleteCategory}
        secondaryButtonText="No, cancel"
      />
    </>
  );
};

const CategoryItem = ({
  category,
  onEdit,
  onDelete,
}: {
  category: AdminJourneyCategoryListItem;
  onEdit: (categoryId: string) => void;
  onDelete: (categoryId: string) => void;
}) => {
  let journeyCountText: string;
  switch (category.journeyCount) {
    case 0:
      journeyCountText = "No journeys";
      break;
    case 1:
      journeyCountText = "1 journey";
      break;
    default:
      journeyCountText = `${category.journeyCount} journeys`;
  }

  const categoryEditUrl = AppRoutes.admin.journeys.category.generateRoute(
    category.id
  );

  const optionsItems: DropDownMenuItem[] = [
    {
      text: "Edit",
      customArg: "EDIT",
      customIcon: <FontAwesomeIcon icon={faPencilAlt} />,
    },
    {
      text: "View journeys",
      customArg: "EDIT",
      customIcon: <FontAwesomeIcon icon={faShuffle} />,
    },
    {
      text: "Delete",
      disabledMsg: "Journeys within this category must be deleted/moved first",
      disabled: category.journeyCount > 0,
      customArg: "DELETE",
      customIcon: <FontAwesomeIcon icon={faTrashAlt} />,
    },
  ];

  const handleOptionsItemClick = (itemIndex: number, customArg: any) => {
    if (customArg === "EDIT") {
      onEdit(category.id);
    } else if (customArg === "DELETE") {
      onDelete(category.id);
    }
  };

  return (
    <div className="bg-slate-50 py-2 border border-solid border-gray-300 rounded-sm shadow-md">
      <div className="flex flex-col h-full">
        <div className="flex-initial">
          <div className="flex flex-row pl-4 pr-2 pb-2 border-b border-solid border-gray-300">
            <div className="flex-grow">
              <Link
                className="font-bold text-lg text-gray-600 hover:underline hover:cursor-pointer"
                to={categoryEditUrl}
              >
                {category.displayName}
              </Link>
            </div>
            <div className="flex-initial">
              <DropDownMenu
                eventType="EVENT"
                items={optionsItems}
                onItemClick={handleOptionsItemClick}
                menuButtonClassName="block w-full lg:inline-block lg:w-auto"
                customButtonContents={
                  <>
                    <Tooltip
                      content="Options"
                      triggerElement={
                        <button className="block w-6 text-center border border-transparent rounded-md hover:bg-slate-50 hover:border-slate-200">
                          <FontAwesomeIcon icon={faEllipsisVertical} />
                        </button>
                      }
                    />
                  </>
                }
              />
            </div>
          </div>
        </div>
        <div className="flex-grow p-4 pt-2">
          {category.description && (
            <p className="text-gray-500">{category.description}</p>
          )}
        </div>
        <div className="flex-initial">
          <div className="px-4 pb-2 flex flex-row">
            <div className="flex-grow">
              <Badge
                icon={faArrowProgress}
                hyperlink={categoryEditUrl}
                text={journeyCountText}
                backgroundColourClassName="bg-accent/50"
                marginClassName=""
                textSize="text-sm"
                classNames="!py-1 border-accent"
                textColourClassName="text-gray-500"
              />
            </div>
            <PrimaryLinkButton
              text="Edit"
              href={categoryEditUrl}
              size="SMALL"
              icon={faArrowRightLong}
              iconPlacement="RIGHT"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const EmptyState = ({
  onCreateNewCategoryButtonClick,
}: {
  onCreateNewCategoryButtonClick: () => void;
}) => {
  return (
    <div className="text-center px-4 py-2 rounded-md w-full lg:w-2/3 lg:mx-auto lg:mt-8">
      <div className="hidden lg:flex pb-4 justify-center">
        <EmptyStateImage />
      </div>
      <h3>There isn't anything in here yet</h3>
      <div className="text-gray-600 [&>*]:mb-2">
        <p>
          Before you get started, you need to create a category for your first
          journey.
        </p>
        <p>
          Categories are used to group journeys together, either for different
          parts of the business, or for different purposes. So, for example,
          your categories could be:
        </p>
        <ul className="list-disc list-inside">
          <li>"Leaders", "Managers" and "Core", or</li>
          <li>"Inductions", "Career" and "Exits"</li>
        </ul>
        <p>The possibilities are endless.</p>
      </div>
      <div className="text-center py-2">
        <PrimaryButton
          text="New category"
          onClick={onCreateNewCategoryButtonClick}
          icon={faPlus}
        />
      </div>
    </div>
  );
};

export default JourneyCategoryList;
