import { useEffect } from "react";
import { t } from "i18next";
import cx from "classnames";
import { useMatch, NavLink } from "react-router-dom";
import SubNavItem from "../../types/nav/SubNavItem";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, HighlightDot } from "../common";
import {
  faCircleMinus,
  faCirclePlus,
} from "@fortawesome/pro-regular-svg-icons";

interface LeftSubNavGroupProps {
  isExpanded: boolean;
  subNavId: string;
  baseRoute: string;
  baseLinkText: string;
  items: SubNavItem[];
  activeIcon: IconDefinition;
  inactiveIcon: IconDefinition;
  showRootLevelBadge?: boolean;
  rootLevelBadgeText?: string;
  rootLevelBadgeTooltip?: string;
  onToggleExpand: (navItemId: string) => void;
}

function LeftSubNavGroup({
  isExpanded,
  subNavId,
  baseRoute,
  baseLinkText,
  items,
  activeIcon,
  inactiveIcon,
  showRootLevelBadge,
  rootLevelBadgeText,
  rootLevelBadgeTooltip,
  onToggleExpand,
}: LeftSubNavGroupProps) {
  const isActive = useMatch({
    path: baseRoute,
    end: false,
  });

  const toggleSubNav = () => {
    onToggleExpand(subNavId);
  };

  return (
    <div
      className={cx("left-nav-item-subnav", isActive ? "active-subnav" : "")}
    >
      <div className="flex flex-row">
        <div className="flex-grow">
          <NavLink
            className={({ isActive }) =>
              `main-nav-link ${isActive ? "" : "in"}active-nav-link`
            }
            to={baseRoute}
          >
            <FontAwesomeIcon
              icon={activeIcon}
              size="lg"
              className="nav-icon-active fa-fw"
            />
            <FontAwesomeIcon
              icon={inactiveIcon}
              size="lg"
              className="nav-icon-inactive fa-fw"
            />

            <span className="px-2">{baseLinkText}</span>
            {showRootLevelBadge &&
              rootLevelBadgeText &&
              rootLevelBadgeTooltip && (
                <Badge
                  text={rootLevelBadgeText}
                  backgroundColourClassName="accent-bg"
                  textColourClassName="text-white"
                  marginClassName=""
                  tooltip={rootLevelBadgeTooltip}
                />
              )}
          </NavLink>
        </div>
        <div className="flex-initial">
          <button
            className={cx(
              "hover:pointer",
              isActive ? "" : "text-gray-400 hover:text-gray-600"
            )}
            onClick={toggleSubNav}
          >
            <FontAwesomeIcon
              size="1x"
              icon={isExpanded ? faCircleMinus : faCirclePlus}
            />
          </button>
        </div>
      </div>

      {isExpanded && (
        <div id={subNavId} className="left-subnav">
          {items.map((navItem) => (
            <NavLink
              className={({ isActive }) =>
                cx(
                  "",
                  isActive
                    ? "text-gray-500 font-semibold"
                    : "text-gray-400 hover:text-gray-500"
                )
              }
              to={navItem.url}
              key={navItem.title}
            >
              <span>
                {navItem.translateTitle ? t(navItem.title) : navItem.title}
              </span>
              {navItem.badgeCount !== undefined && navItem.badgeCount > 0 && (
                <HighlightDot containerClassNames="ml-1" />
              )}
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
}

export default LeftSubNavGroup;
