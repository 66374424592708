import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { MainContainer } from "../../../components/layout";
import AppContext from "../../../state/AppContext";
import UserContext from "../../../state/UserContext";
import adminHelper from "../../../helpers/adminHelper";
import AppRoutes from "../../AppRoutes";
import adminApi from "../../../api/dashboard/adminApi";
import UserDetailDto from "../../../types/dtos/admin/UserDetailDto";
import { Avatar } from "../../../components/common";
import {
  faExternalLink,
  faChevronLeft,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SmallLoader from "../../../components/loaders/SmallLoader";
import { KeyValuePair } from "../../../types/generic";
import UserDetailEditableFields from "../../../components/admin/client-journeys/UserDetail/UserDetailEditableFields";
import PaginationAndSearchCriteria from "../../../types/admin/client-journeys/PaginationAndSearchCriteria";
import UserDetailPageLoadApiResponseDto from "../../../types/dtos/admin/UserDetailPageLoadApiResponseDto";
import UserDetailJourneyApiResponseDto from "../../../types/dtos/admin/UserDetailJourneyApiResponseDto";
import UserDetailJourneySection from "../../../components/admin/client-journeys/UserDetail/UserDetailJourneySection";
import UserDetailSendNewJourneySection from "../../../components/admin/client-journeys/UserDetail/UserDetailSendNewJourneySection";
import { dateHelper } from "../../../helpers";
import UserDetailClientSentJourneyDto from "../../../types/dtos/admin/UserDetailClientSentJourneyDto";
import UserTagList from "../../../components/manager-dashboard/Tags/UserTagList";
import UserJourneyDetailDto from "../../../types/dtos/shared/UserJourneyDetailDto";
import UserDetailTaskSection from "../../../components/admin/client-journeys/UserDetail/UserDetailTaskSection";
import ToDoListTaskTypeDto from "../../../types/dtos/dashboards/right-bar/to-do-list/ToDoListTaskTypeDto";

function UserDetail() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const appContext = React.useContext(AppContext);
  const userContext = React.useContext(UserContext);
  const auth = useAuth();
  const admApi = new adminApi(auth.user?.access_token);

  const [isWaitingForApiResult, setIsWaitingForApiResult] =
    useState<boolean>(true);
  const [employeeStatuses, setEmployeeStatuses] = useState<
    KeyValuePair<number, string>[] | null
  >(null);
  const [userDetails, setUserDetails] = useState<UserDetailDto | null>(null);
  const [journeyHistory, setJourneyHistory] = useState<
    UserJourneyDetailDto[] | undefined
  >(undefined);
  const [journeyTotalCount, setJourneyTotalCount] = useState<
    number | undefined
  >(undefined);
  const [isLoadingAfterCriteriaChange, setIsLoadingAfterCriteriaChange] =
    useState<boolean>(false);
  const [clientSentJourneys, setClientSentJourneys] = useState<
    UserDetailClientSentJourneyDto[] | null
  >(null);
  const [taskTypeDtos, setTaskTypeDtos] = useState<ToDoListTaskTypeDto[] | null>(null);
  const [sendableClientJourneyOptions, setSendableClientJourneyOptions] =
    useState<KeyValuePair<string, string>[] | null>(null);
  const [selectedClientSentJourney, setSelectedClientSentJourney] =
    useState<KeyValuePair<string, string> | null>(null);
  const [alreadySentJourneyReferences, setAlreadySentJourneyReferences] =
    useState<string[]>([]);

  const [criteria, setCriteria] = useState<PaginationAndSearchCriteria>({
    pageNumber: 1,
    pageSize: 5,
    searchTerm: null,
  });

  const { userId } = useParams();
  let selectedUserId = 0;
  if (userId != null) {
    var parsedUserId = parseInt(userId);
    if (!isNaN(parsedUserId)) {
      selectedUserId = parseInt(userId);
    }
  }

  const managerAdminsTheirPeopleConfig =
    userContext.user.client.moduleConfigs.find((config) => {
      return config.key === "ManagersAdministerTheirEmployees";
    });

  const isNonAdminUserWithManagerConfigAccess =
    !userContext.user.isAdmin &&
    managerAdminsTheirPeopleConfig != undefined &&
    managerAdminsTheirPeopleConfig.value === "true" &&
    userContext.user.isManager;

  const allowCollabDocViewerConfig = userContext.user.client.moduleConfigs.find(
    (config) => {
      return config.key === "AllowAccessToCollabDocViewer";
    }
  );

  const doesClientAllowAccessToCollabDocViewer =
    allowCollabDocViewerConfig != undefined &&
    allowCollabDocViewerConfig.value === "true";

  const doesClientUseAdvancedTaskTypes = userContext.user.client.taskTypes.length > 0;

  useEffect(() => {
    adminHelper.checkAdminAccessAndRedirectWhenNoAccess(
      userContext,
      "USER-JOURNEY",
      navigate
    );
    appContext.setPageTitle(t("Pages.Admin.PageTitle.Journeys"));
    appContext.setShowPageTitleAccent(true);

    admApi.isAllowedToViewThisUser(
      selectedUserId,
      isNonAdminUserWithManagerConfigAccess,
      (isAllowed: boolean) => {
        if (isAllowed) {
          loadUserDetailPage();
        } else {
          navigate(AppRoutes.admin.clientJourneys.path);
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadUserDetailPage = () => {
    // Call the API to load the necessary state
    const successCallback = (data: UserDetailPageLoadApiResponseDto) => {
      setIsWaitingForApiResult(false);
      setEmployeeStatuses(data.employeeStatuses);
      setUserDetails(data.userDetails);
      setJourneyHistory(data.pagedJourneyHistory.pagedItems);
      setJourneyTotalCount(data.pagedJourneyHistory.totalItemCount);
      setAlreadySentJourneyReferences(data.alreadySentJourneyReferences);
      setClientSentJourneys(data.clientSentJourneys);
      setTaskTypeDtos(data.taskTypes);

      const keyValuePairs = data.clientSentJourneys.map((journey) => {
        let title = journey.journeyTitle;
        const localDateCreated = dateHelper.convertUtcDateToLocal(
          journey.dateCreated
        );
        title +=
          " (" +
          t("Pages.Admin.Common.CreatedOn").toLowerCase() +
          " " +
          localDateCreated.toLocaleDateString() +
          " " +
          localDateCreated
            .toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })
            .toLowerCase() +
          ")";

        if (journey.journeyDeadline) {
          const localJourneyDeadline = dateHelper.convertUtcDateToLocal(
            journey.journeyDeadline
          );
          title +=
            " (deadline: " + localJourneyDeadline.toLocaleDateString() + ")";
        }

        return { key: journey.journeyReference, value: title };
      });

      setSendableClientJourneyOptions(keyValuePairs);
    };

    const errorCallback = (error: any) => {
      console.error(error);
    };

    admApi.getUserPageInfo(
      selectedUserId,
      isNonAdminUserWithManagerConfigAccess,
      criteria.pageSize,
      criteria.pageNumber,
      successCallback,
      errorCallback,
      criteria.searchTerm
    );
  };

  // Trigger a reload of journeys if the pageNumber or searchTerm changes
  useEffect(() => {
    setIsLoadingAfterCriteriaChange(true);
    admApi.paginateHistoricalJourneys(
      selectedUserId,
      criteria.pageSize,
      criteria.pageNumber,
      reloadJourneyHistorySuccessCallback,
      reloadJourneyHistoryErrorCallback,
      criteria.searchTerm
    );
  }, [criteria]);

  const reloadJourneyHistorySuccessCallback = (
    data: UserDetailJourneyApiResponseDto
  ) => {
    setJourneyHistory(data.pagedItems);
    setJourneyTotalCount(data.totalItemCount);
    setIsLoadingAfterCriteriaChange(false);
  };

  const reloadJourneyHistoryErrorCallback = (error: any) => {
    console.error(error);
  };

  const handleEditDetailsClick = () => {
    const redirectUrl = AppRoutes.admin.classicUserDetailAdmin.generateRoute(
      selectedUserId.toString()
    );
    window.open(redirectUrl, "_blank");
  };

  const handleChangeLogClick = () => {
    navigate(
      AppRoutes.admin.clientJourneys.userDetail.generateAdminHistoryRoute(
        selectedUserId.toString()
      )
    );
  };

  const handleSearchChange = (newsearchTerm: string | null) => {
    setCriteria({ ...criteria, pageNumber: 1, searchTerm: newsearchTerm }); // Resets page number
  };

  const handlePageNumberChange = (pageNumber: number) => {
    setCriteria({ ...criteria, pageNumber });
  };

  const handleSelectedClientSentJourneyChange = (
    selectedValue: KeyValuePair<string, string> | null
  ) => {
    setSelectedClientSentJourney(selectedValue);
  };

  const handleUpdateCurrentJourneyDetails = (
    journeyReference: string | undefined,
    journeyName: string | undefined
  ) => {
    if (userDetails) {
      // Need to make sure that the alreadySentJourneyReferences is updated
      let newAlreadySentState = [...alreadySentJourneyReferences];

      // If the user has a currentJourneyReference we need to remove that from the list
      if (userDetails.currentJourneyReference) {
        const index = newAlreadySentState.indexOf(
          userDetails.currentJourneyReference
        );
        if (index > -1) {
          newAlreadySentState.splice(index, 1);
        }
      }

      // If a new currentJourneyReference is being sent then add it to the list
      if (journeyReference != undefined) {
        newAlreadySentState.push(journeyReference);
      }

      // Update the CurrentJourneyReference and CurrentJourneyTitle against userDetails in state
      setUserDetails({
        ...userDetails,
        currentJourneyReference: journeyReference,
        currentJourneyTitle: journeyName,
      });
      // Update the AlreadySentJourneyReferences in state
      setAlreadySentJourneyReferences(newAlreadySentState);
    }
  };

  const handleUpdateAlreadySentReferencesAfterCancelling = (
    journeyReference: string
  ) => {
    let newAlreadySentState = [...alreadySentJourneyReferences];

    const index = newAlreadySentState.indexOf(journeyReference);
    if (index > -1) {
      newAlreadySentState.splice(index, 1);
    }
    setAlreadySentJourneyReferences(newAlreadySentState);
  };

  return (
    <MainContainer>
      <div className="w-full border rounded p-6 mt-6">
        <div className="hover:underline">
          <Link to={AppRoutes.admin.clientJourneys.path}>
            <FontAwesomeIcon size="xs" icon={faChevronLeft} />
            <span className="pl-1.5">{t("Common.Back")}</span>
          </Link>
        </div>
        {isWaitingForApiResult && (
          <div className="mt-8 text-center">
            <SmallLoader colour={userContext.user.client.primaryHexColour} />
            <span className="block mt-2 text-gray-600 text-sm">
              {t("Common.Loading") + "..."}
            </span>
          </div>
        )}
        {userDetails && (
          <div className="md:px-14">
            <div className="grid grid-cols-1 gap-1 mt-2">
              <div className="flex flex-row">
                <div className="hidden md:block md:mr-4">
                  <Avatar
                    key={userId}
                    userFullName={userDetails.fullName}
                    userInitials={userDetails.initials}
                    initialsTextClassName="text-xl"
                    size={16}
                    imageUrl={
                      userDetails.profileImageUrl
                        ? userDetails.profileImageUrl
                        : undefined
                    }
                  />
                </div>
                <div className="grow pt-2">
                  <div className="flex flex-col md:flex-row">
                    <h3 className="coloured-heading !mb-0 !mt-0 basis-3/5">
                      {userDetails.fullName}
                    </h3>
                    <div className="basis-2/5 flex gap-x-2 text-gray-600 md:justify-end">
                      <button
                        className="primary-text text-sm font-normal hover:underline"
                        onClick={handleChangeLogClick}
                      >
                        <span className="ml-2 mr-1">
                          {t("Pages.Admin.UserDetails.AdminHistory")}
                        </span>
                      </button>
                      <button
                        className="btn-primary text-sm"
                        onClick={handleEditDetailsClick}
                      >
                        <FontAwesomeIcon icon={faExternalLink} />
                        <span className="ml-1">
                          {t("Pages.Admin.UserDetails.EditDetails")}
                        </span>
                      </button>
                    </div>
                  </div>
                  <div>
                    {/* Tags */}
                    <div className="mt-1">
                      <UserTagList
                        jobTitle={userDetails.jobTitle}
                        jobTitleTooltip={t("Pages.Admin.Common.JobTitle")}
                        appraisalLevel={userDetails.appraisalLevel}
                        appraisalLevelTooltip={t("Common.AppraisalLevel")}
                        location={userDetails.locationName}
                        locationTooltip={t("Pages.Admin.Common.Location")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="mt-5" />
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2">
              {employeeStatuses && (
                <UserDetailEditableFields
                  userDetails={userDetails}
                  employeeStatuses={employeeStatuses}
                  setUserDetails={setUserDetails}
                />
              )}
            </div>
            <hr className="mt-2" />
            <UserDetailJourneySection
              user={userDetails}
              journeyHistory={journeyHistory}
              pageSize={criteria.pageSize}
              pageNumber={criteria.pageNumber}
              searchTerm={criteria.searchTerm}
              totalItemCount={journeyTotalCount}
              isLoading={isLoadingAfterCriteriaChange}
              allowViewOption={doesClientAllowAccessToCollabDocViewer}
              onPageNumberChange={handlePageNumberChange}
              onSearchTermChange={handleSearchChange}
              criteriaForReload={criteria}
              reloadJourneyHistorySuccessCallback={
                reloadJourneyHistorySuccessCallback
              }
              onUpdateCurrentJourneyDetails={handleUpdateCurrentJourneyDetails}
              onUpdateAlreadySentJourneyReferences={
                handleUpdateAlreadySentReferencesAfterCancelling
              }
            />
            {clientSentJourneys &&
              sendableClientJourneyOptions &&
              sendableClientJourneyOptions.length > 0 && (
                <>
                  <UserDetailSendNewJourneySection
                    user={userDetails}
                    clientSentJourneys={clientSentJourneys}
                    sendableClientJourneyOptions={sendableClientJourneyOptions}
                    alreadySentJourneyReferences={alreadySentJourneyReferences}
                    selectedClientSentJourney={selectedClientSentJourney}
                    onClientSentJourneyDropdownChange={
                      handleSelectedClientSentJourneyChange
                    }
                    criteriaForReload={criteria}
                    reloadJourneyHistorySuccessCallback={
                      reloadJourneyHistorySuccessCallback
                    }
                    onUpdateCurrentJourneyDetails={
                      handleUpdateCurrentJourneyDetails
                    }
                  />
                </>
              )}
            {doesClientUseAdvancedTaskTypes && taskTypeDtos && (
              <>
                <hr className="my-5" />
                <UserDetailTaskSection
                  userId={selectedUserId}
                  taskTypeDtos={taskTypeDtos}
                />
              </>
            )}
          </div>
        )}
      </div>
    </MainContainer>
  );
}

export default UserDetail;
