import { useEffect, useState, ReactNode } from "react";
import * as TabsPrimitive from "@radix-ui/react-tabs";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MobileSummaryTabPopUp from "../form-summaries/MobileSummaryTabPopUp";
import Badge from "./Badge";

/** The type for Tabs, specifying the props required */
export interface TabDetails {
  /** The title for this tab */
  title: string;
  /** This type field can optionally be used to assist with finding tabs using a string, ideally it would be typescripted type */
  type?: string | null;
  /** The content to render when this tab is selected */
  content: ReactNode;
  /** The toggle whether or not the tab should display it has an update */
  displayAlertIcon?: boolean;
  /** A count to display alongside the tab name, e.g. "Tab Name (3)" */
  itemCount?: number | undefined;
  itemCountDisplayMode?: "BADGE" | "BRACKETS" | "NONE";
  /** An optional onClick event for the tab */
  onClickEvent?(): void | undefined;
}

interface TabsProps {
  tabs: TabDetails[];
  selectFirstTabByDefault?: boolean;
  selectedTabClassNames?: string;
  /** To enable responsive tabs with a drop down for further tabs, set a number here */
  maxTabsOnSmallScreens?: number | null;
  initialTabToOpenToUsingTabType?: string | null;
  tabTextClassNames?: string;
  contextClassNames?: string;
  tabListWidth?: string;
  hideTabs?: boolean;
  onActiveTabChangeEvent?(tabName: string): void | undefined;
  customComponentOnRightOfTabs?: ReactNode | undefined;
}

/** A tab component - just supply an array of Tabs (`title`, `content` as an element to render) */
const Tabs = ({
  tabs,
  selectFirstTabByDefault = true,
  hideTabs = false,
  selectedTabClassNames = "",
  maxTabsOnSmallScreens = null,
  initialTabToOpenToUsingTabType = null,
  tabTextClassNames = "",
  contextClassNames = "",
  tabListWidth = "w-full",
  onActiveTabChangeEvent,
  customComponentOnRightOfTabs = undefined,
}: TabsProps) => {
  const { t } = useTranslation();
  const [activeTabIndex, setActiveTabIndex] = useState<number | undefined>(
    selectFirstTabByDefault ? 0 : undefined
  );
  // If the user selects tabs from the "More" dropdown, change the displayed tabs to the left of that "More" tab
  const [mobileVisibleTabs, setMobileVisibleTabs] = useState<null | number[]>(
    null
  );
  const [showAlertIconOnMoreTab, setShowAlertIconOnMoreTab] =
    useState<boolean>(true);

  const [tabModalIsOpen, setTabModalIsOpen] = useState<boolean>(false);

  const condenseTabsForMobile: boolean =
    maxTabsOnSmallScreens !== null &&
    maxTabsOnSmallScreens > 0 &&
    tabs.length > maxTabsOnSmallScreens;

  const alertIcon = (
    <FontAwesomeIcon
      icon={faExclamationCircle}
      size="1x"
      className="text-[#62DBC6] ml-1"
    />
  );

  useEffect(() => {
    // On load, set the initial displayed mobile/condensed tabs
    if (maxTabsOnSmallScreens !== null && maxTabsOnSmallScreens > 0) {
      const displayedTabs = Array.from(Array(maxTabsOnSmallScreens).keys());
      setMobileVisibleTabs(displayedTabs);
    }
  }, []);

  useEffect(() => {
    // If initialTabToOpenToUsingTabType is specified then find that tab and open to it.
    if (initialTabToOpenToUsingTabType != null && tabs != null) {
      const newActiveIndex = tabs.findIndex(
        (x) => x.type === initialTabToOpenToUsingTabType
      );
      setActiveTabIndex(newActiveIndex);
    }
  }, [initialTabToOpenToUsingTabType]);

  /** Get the css class name for condensed tabs */
  const getCondensedTabClassName = (tabIndex: number) => {
    if (maxTabsOnSmallScreens !== null && maxTabsOnSmallScreens > 0) {
      const noDropDownMoreTabSelected =
        mobileVisibleTabs === null && tabIndex + 1 > maxTabsOnSmallScreens;
      const moreTabSelectedAndTabIndexLowerThanSelectedMoreTabIndex =
        mobileVisibleTabs && mobileVisibleTabs.indexOf(tabIndex) === -1;
      if (
        noDropDownMoreTabSelected ||
        moreTabSelectedAndTabIndexLowerThanSelectedMoreTabIndex
      ) {
        return "hidden md:block";
      }
    }
    return "";
  };

  /** Handle the tab change, with complex behaviour for mobile condensed tabs */
  const onActiveTabChange = (tabId: string) => {
    const newActiveTab = parseInt(tabId.replace("tab", ""));
    const mobileMoreTabId = "tab" + tabs.length;

    // If the "More" tab is clicked, don't change the value
    if (condenseTabsForMobile && tabId === mobileMoreTabId) {
      // Do nothing
    } else if (
      condenseTabsForMobile &&
      maxTabsOnSmallScreens &&
      maxTabsOnSmallScreens > 0 &&
      (mobileVisibleTabs === null ||
        mobileVisibleTabs.indexOf(newActiveTab) === -1)
    ) {
      // Display the selected tab, plus up to n more, when n is `maxTabsOnSmallScreens`
      const newMobileVisibleTabs = [newActiveTab];
      for (var i = 1; i < maxTabsOnSmallScreens!; i++) {
        if (tabs.length >= newActiveTab + i) {
          newMobileVisibleTabs.push(newActiveTab + i);
        }
      }

      // If any tab not displayed has an alert icon, make the "More" tab show that alert
      const anyNotVisibleTabHasAlertIcon =
        tabs.filter(
          (tab, tabIndex) =>
            newMobileVisibleTabs.indexOf(tabIndex) === -1 &&
            tab.displayAlertIcon
        ).length > 0;

      setMobileVisibleTabs(newMobileVisibleTabs);
      setShowAlertIconOnMoreTab(anyNotVisibleTabHasAlertIcon);
      setActiveTabIndex(newActiveTab);
    } else {
      // Non-condensed tabs, no complication
      setActiveTabIndex(newActiveTab);
    }

    if (onActiveTabChangeEvent) {
      const tab = tabs[newActiveTab];
      onActiveTabChangeEvent(tab.title);
    }
  };

  return (
    <TabsPrimitive.Root
      defaultValue={
        activeTabIndex !== undefined ? `tab${activeTabIndex}` : undefined
      }
      value={activeTabIndex !== undefined ? `tab${activeTabIndex}` : undefined}
      onValueChange={onActiveTabChange}
    >
      {!hideTabs && (
        <div className={customComponentOnRightOfTabs ? "flex flex-row" : ""}>
          <div className={customComponentOnRightOfTabs ? "flex-initial" : ""}>
            <TabsPrimitive.List
              className={cx("flex bg-white gap-x-1", tabListWidth)}
            >
              {tabs.map(
                (
                  {
                    title,
                    displayAlertIcon = false,
                    itemCount = undefined,
                    itemCountDisplayMode = "BRACKETS",
                    onClickEvent,
                  },
                  i
                ) => (
                  <TabsPrimitive.Trigger
                    key={`tab-trigger-tab${i}`}
                    value={`tab${i}`}
                    className={cx(
                      selectedTabClassNames,
                      "group",
                      "border-gray-300 text-gray-600",
                      "radix-state-inactive:bg-white",
                      "radix-state-inactive:text-gray-400",
                      "tracking-wide",
                      "flex-1 px-3 py-2.5",
                      "focus:radix-state-active:border-b-red",
                      "focus:z-10 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-0",
                      getCondensedTabClassName(i)
                    )}
                    onClick={onClickEvent ? () => onClickEvent() : undefined}
                  >
                    <span
                      className={cx("text-sm font-medium", tabTextClassNames)}
                    >
                      {title}
                      {displayAlertIcon && alertIcon}
                      {!displayAlertIcon && itemCount !== undefined && (
                        <>
                          {itemCountDisplayMode === "BADGE" && (
                            <Badge
                              text={itemCount.toString()}
                              backgroundColourClassName="bg-primary"
                              textColourClassName="text-white"
                              borderRadiusClassName="rounded"
                              marginClassName="ml-2"
                            />
                          )}
                          {itemCountDisplayMode === "BRACKETS" && (
                            <span className="ml-1 text-gray-700">
                              ({itemCount})
                            </span>
                          )}
                        </>
                      )}
                    </span>
                  </TabsPrimitive.Trigger>
                )
              )}
              {condenseTabsForMobile && (
                <TabsPrimitive.Trigger
                  value={`tab${tabs.length}`}
                  className={cx(
                    "md:hidden",
                    selectedTabClassNames,
                    "group",
                    "border-gray-300 text-[#5d5d5d]",
                    "radix-state-inactive:bg-white",
                    "flex-1 px-3 py-2.5",
                    "focus:radix-state-active:border-b-red",
                    "focus:z-10 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-0"
                  )}
                >
                  <span
                    onClick={() => {
                      setTabModalIsOpen(true);
                    }}
                  >
                    <span
                      className={cx("text-sm font-medium", tabTextClassNames)}
                    >
                      {t("Common.More")}
                      {showAlertIconOnMoreTab && alertIcon}
                    </span>
                  </span>
                  <MobileSummaryTabPopUp
                    tabModalIsOpen={tabModalIsOpen}
                    setTabModalIsOpen={setTabModalIsOpen}
                    tabs={tabs}
                    customIcon={alertIcon}
                    onItemClick={(
                      itemIndex: number,
                      tabIndex: any | undefined
                    ) => {
                      onActiveTabChange(("tab" + tabIndex) as string);
                    }}
                  />
                </TabsPrimitive.Trigger>
              )}
            </TabsPrimitive.List>
          </div>

          {customComponentOnRightOfTabs && (
            <div className="flex-grow hidden lg:flex justify-end">
              <div className="h-full flex flex-col justify-end">
                {customComponentOnRightOfTabs}
              </div>
            </div>
          )}
        </div>
      )}

      {tabs.map(({ content }, i) => (
        <TabsPrimitive.Content
          key={`tab-content-tab${i}`}
          value={`tab${i}`}
          className={cx("rounded-b-lg bg-white py-2", contextClassNames)}
        >
          {content}
        </TabsPrimitive.Content>
      ))}
    </TabsPrimitive.Root>
  );
};

export default Tabs;
