import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/pro-regular-svg-icons";
import { ClientTaskType } from "../../../../types/dtos/tasks/advanced-tasks/ClientTaskType";

interface AdvancedToDoListLockedStateProps {
  selectedTaskType: ClientTaskType | undefined;
  journeyTitle?: string | null;
}

function AdvancedToDoListLockedState({
  selectedTaskType,
  journeyTitle,
}: AdvancedToDoListLockedStateProps) {
  const { t } = useTranslation();
  const taskTypePluralTranslationKeyIdentifier =
    selectedTaskType != undefined
      ? selectedTaskType.pluralNameTranslationKeyIdentifier
      : "";

  const displayText = journeyTitle != null
    ? t("DashboardTaskBar.LockedState.TextJourneyNameProvided", {
      taskType: t(taskTypePluralTranslationKeyIdentifier),
      journeyName: journeyTitle != undefined ? journeyTitle : "",
    })
    : t("DashboardTaskBar.LockedState.TextWithNoJourneyName", {
      taskType: t(taskTypePluralTranslationKeyIdentifier),
    });
  return (
    <div>
      <div className="text-center my-2">
          <FontAwesomeIcon
            color={"#D1D5DB"}
            size="2x"
            icon={faLock}
          />
        </div>
        <div className="text-sm text-gray-400 text-center mb-2">
          <p>{displayText}</p>
        </div>
    </div>
  );
}

export default AdvancedToDoListLockedState;
