import { t } from "i18next";
import dateHelper from "../../../helpers/dateHelper";
import UserJourneyDetailDto from "../../../types/dtos/shared/UserJourneyDetailDto";
import { Badge, Tooltip } from "../../common";
import { JourneyFormButtonArg } from "../../../types/dashboard/JourneyFormDropDownItems";
import { faLoader, faPaperPlane } from "@fortawesome/pro-regular-svg-icons";
import { faTimer } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import journeyFormHelper from "../../../helpers/journeyFormHelper";
import { useState } from "react";
import { UserContextInterface } from "../../../state/UserContext";

interface JourneyFormItemProps {
  userContext: UserContextInterface;
  form: UserJourneyDetailDto;
  isCompletedTab: boolean;
  isYourPeoplePage: boolean;
  onUpdateClick(form: UserJourneyDetailDto, arg?: JourneyFormButtonArg): void;
  onViewClick(form: UserJourneyDetailDto, arg?: JourneyFormButtonArg): void;
  onDualPrepItemClick(
    form: UserJourneyDetailDto,
    arg: JourneyFormButtonArg
  ): void;
}

interface JourneyStatusSummary {
  text: string;
  colour: undefined | "GOOD" | "BAD" | "NEUTRAL";
}

function JourneyFormItem({
  userContext,
  form,
  isCompletedTab,
  isYourPeoplePage,
  onUpdateClick,
  onViewClick,
  onDualPrepItemClick,
}: JourneyFormItemProps) {
  const [hasButtonBeenClicked, setHasButtonBeenClicked] =
    useState<boolean>(false);
  const standardButtonText = isCompletedTab
    ? t("Common.View")
    : t("Common.Update");

  const journeyNotStartedOrPartiallyCompleteStatus =
    form.hasNotBeenStarted ||
    (form.statusEnum !== null && form.statusEnum === "PARTIALLY-SAVED-JOURNEY");

  const hasPreventJourneySwitchConfigEnabled =
    userContext.user.client.moduleConfigs.findIndex(
      (cfg) =>
        cfg.key === "PreventManualJourneySwitching" &&
        cfg.value.trim().toLowerCase() === "true"
    ) >= 0;

  const dualPrepDropDownButtonItems =
    journeyFormHelper.getDualPrepDropdownButtonItems(
      form,
      isCompletedTab,
      isYourPeoplePage,
      hasPreventJourneySwitchConfigEnabled
    );

  /** Handles the click event for non-dual prep journeys */
  const onStandardButtonClick = () => {
    setHasButtonBeenClicked(true);
    if (isCompletedTab) {
      onViewClick(form);
    } else {
      onUpdateClick(form);
    }
  };

  const onDualPrepButtonClick = (
    form: UserJourneyDetailDto,
    arg: JourneyFormButtonArg
  ) => {
    setHasButtonBeenClicked(true);
    onDualPrepItemClick(form, arg);
  };

  const disableStandardButton = // On the manager version if the user hasn't started the journey yet disable the update
    // button as the manager can't update until that user has done the journey
    (isYourPeoplePage && journeyNotStartedOrPartiallyCompleteStatus) ||
    // If the journey hasn't been started, and the prevent journey switch config is enabled
    // then disable the button as to prevent the user from switching to this journey
    (journeyNotStartedOrPartiallyCompleteStatus &&
      hasPreventJourneySwitchConfigEnabled);
  const disablePrimaryDualPrepButton =
    dualPrepDropDownButtonItems && dualPrepDropDownButtonItems.length > 0
      ? dualPrepDropDownButtonItems[0].disabled
      : true;

  const journeyStatusInfo = JourneyFormStatusInfo(
    form,
    journeyNotStartedOrPartiallyCompleteStatus
  );

  return (
    <div className="p-3 bg-white rounded-md mt-3 border border-gray-300">
      <div className="grid grid-cols-1 lg:grid-cols-6 gap-1">
        <div className="col-span-1 lg:col-span-3">
          <div className="flex flex-col items-start justify-center h-full gap-2">
            <div>
              <button
                className="font-bold text-gray-600 hover:cursor-pointer hover:underline disabled:cursor-not-allowed disabled:no-underline mr-2 leading-tight"
                disabled={
                  form.isDualPrepJourney
                    ? disablePrimaryDualPrepButton
                    : disableStandardButton
                }
                onClick={
                  form.isDualPrepJourney
                    ? () =>
                        onDualPrepButtonClick(
                          form,
                          dualPrepDropDownButtonItems[0].customArg
                        )
                    : onStandardButtonClick
                }
              >
                {form.title}
              </button>
              <JourneyInfoTags form={form} />
            </div>
            <div className="text-gray-500 text-sm">
              {journeyStatusInfo && (
                <>
                  <JourneyStatusBadge status={journeyStatusInfo} />
                  {!form.isHiddenToEmployeeFlow &&
                    !isCompletedTab &&
                    journeyStatusInfo.colour !== "BAD" && (
                      <span>{t(form.statusTranslationKeyIdentifier)}</span>
                    )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-span-1 lg:col-span-2 mt-2 lg:mt-0">
          <div className="text-gray-500 text-sm flex flex-col items-start lg:items-end justify-center h-full gap-1">
            {form.dateSent && (
              <div>
                <Tooltip
                  triggerElement={
                    <span>
                      <FontAwesomeIcon
                        icon={faPaperPlane}
                        size="sm"
                        className="text-gray-600"
                      />
                      <span className="ml-2">
                        {dateHelper
                          .convertUtcDateToLocal(form.dateSent)
                          .toLocaleDateString()}
                      </span>
                    </span>
                  }
                  content={t("Pages.Admin.Common.DateSent")}
                />
              </div>
            )}
            {form.dateLastUpdated && (
              <div>
                <Tooltip
                  triggerElement={
                    <span>
                      <FontAwesomeIcon
                        icon={faTimer}
                        size="sm"
                        className="text-gray-600"
                      />
                      <span className="ml-2">
                        {dateHelper
                          .convertUtcDateToLocal(form.dateLastUpdated)
                          .toLocaleDateString()}
                      </span>
                    </span>
                  }
                  content={t("Pages.Admin.Common.LastUpdated")}
                />
              </div>
            )}
          </div>
        </div>
        <div className="col-span-1 lg:col-span-1 mt-2 lg:mt-0">
          {/* Button container */}
          <div className="flex flex-col items-end justify-center h-full">
            <div className="text-center">
              {/* Standard */}
              {!form.isDualPrepJourney && (
                <JourneyFormItemButton
                  clickHandler={onStandardButtonClick}
                  isLoading={hasButtonBeenClicked}
                  isDisabled={disableStandardButton}
                  text={
                    hasButtonBeenClicked
                      ? t("Common.PleaseWait")
                      : standardButtonText
                  }
                />
              )}

              {/* Dual Prep */}
              {form.isDualPrepJourney && (
                <>
                  {dualPrepDropDownButtonItems.length >= 1 &&
                    dualPrepDropDownButtonItems[0] && (
                      <JourneyFormItemButton
                        text={standardButtonText}
                        clickHandler={() =>
                          onDualPrepButtonClick(
                            form,
                            dualPrepDropDownButtonItems[0].customArg
                          )
                        }
                        isDisabled={dualPrepDropDownButtonItems[0].disabled}
                        isLoading={hasButtonBeenClicked}
                      />
                    )}
                  {dualPrepDropDownButtonItems.length > 1 && (
                    <>
                      {dualPrepDropDownButtonItems.slice(1).map((item) => (
                        <div className="mt-1 text-gray-600">
                          <button
                            className="hover:underline text-sm disabled:cursor-not-allowed disabled:no-underline"
                            disabled={item.disabled}
                            onClick={() =>
                              onDualPrepButtonClick(form, item.customArg)
                            }
                          >
                            {item.text}
                          </button>
                        </div>
                      ))}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const JourneyFormItemButton = ({
  text,
  isDisabled,
  isLoading,
  clickHandler,
}: {
  text: string;
  isDisabled: boolean;
  isLoading: boolean;
  clickHandler: () => void;
}) => {
  return (
    <button
      disabled={isDisabled || isLoading}
      onClick={clickHandler}
      className="btn-primary block lg:inline-block w-36 max-w-none cursor-pointer disabled:cursor-not-allowed"
    >
      {isLoading && (
        <span className="mr-1">
          <FontAwesomeIcon icon={faLoader} spin size="xs" />
        </span>
      )}
      <span>{isLoading ? t("Common.PleaseWait") : text}</span>
    </button>
  );
};

const JourneyFormStatusInfo = (
  form: UserJourneyDetailDto,
  journeyNotStartedOrPartiallyCompleteStatus: boolean
): JourneyStatusSummary | undefined => {
  const getHiddenToEmployeeJourneyBadgeText = (dto: UserJourneyDetailDto) => {
    if (
      dto.approvalFlow === "HIDDEN-TO-EMPLOYEE" &&
      dto.statusEnum &&
      dto.statusEnum === "INITIAL-SAVE-FORM-INCOMPLETE"
    ) {
      return t("Pages.Admin.Common.NotStarted");
    }
    return t("Pages.Admin.Common.InProgress");
  };

  if (form.hasNotBeenStarted) {
    // Journey hasn't been started yet
    return {
      colour: "BAD",
      text: t("Pages.Admin.Common.NotStarted"),
    };
  }

  if (
    form.statusEnum !== null &&
    form.statusEnum === "PARTIALLY-SAVED-JOURNEY"
  ) {
    // Journey is partially saved
    return {
      colour: "NEUTRAL",
      text: t("Pages.Admin.Common.InProgress"),
    };
  }

  if (form.isCompleted) {
    // Journey is completed
    return {
      colour: "GOOD",
      text: t("Pages.Admin.Common.Completed"),
    };
  }

  if (form.isHiddenToEmployeeFlow && !form.isCompleted) {
    // Journey is hidden to employee approval flow
    return {
      colour: "NEUTRAL",
      text: getHiddenToEmployeeJourneyBadgeText(form),
    };
  }

  if (
    !form.isDualPrepJourney &&
    !journeyNotStartedOrPartiallyCompleteStatus &&
    !form.isCompleted &&
    !form.isHiddenToEmployeeFlow
  ) {
    // Non Dual Prep
    // If not a dual prep and it's been started and not completed
    return {
      colour: "NEUTRAL",
      text: t("Pages.Admin.Common.Meeting"),
    };
  }

  if (form.latestAnswerSet && form.isDualPrepJourney && !form.isCompleted) {
    if (
      form.latestAnswerSet.isDualPrepSubjectAnswerSet ||
      form.latestAnswerSet.isDualPrepOtherAnswerSet
    ) {
      // Dual Prep Planning Stage
      // If its a dual prep form that hasn't been completed, and the latest form is planning
      return {
        colour: "NEUTRAL",
        text: t("Pages.Admin.Common.Planning"),
      };
    } else if (form.latestAnswerSet.isDualPrepMeetingDocAnswerSet) {
      // Dual Prep Meeting Stage
      // If its a dual prep form that hasn't been completed, and latest form is the meeting doc
      return {
        colour: "NEUTRAL",
        text: t("Pages.Admin.Common.Meeting"),
      };
    }
  }

  return undefined;
};

const JourneyInfoTags = ({ form }: { form: UserJourneyDetailDto }) => {
  return (
    <>
      {/* Is Dual Prep Journey */}
      {form.isDualPrepJourney && (
        <Badge
          text={t("Pages.Admin.Common.DualPrepJourney")}
          textColourClassName="text-gray-400"
          marginClassName="mr-2"
        />
      )}

      {/* Is Manager Only */}
      {form.isHiddenToEmployeeFlow && (
        <Badge
          text={t("Pages.Admin.Common.ManagerOnly")}
          textColourClassName="text-gray-400"
          marginClassName="mr-2"
        />
      )}
    </>
  );
};

const JourneyStatusBadge = ({ status }: { status: JourneyStatusSummary }) => {
  const bgColour =
    status.colour === "GOOD"
      ? "bg-green-200/75"
      : status.colour === "BAD"
      ? "bg-rose-200/75"
      : "bg-blue-200/75";
  const textColour =
    status.colour === "GOOD"
      ? "text-green-600"
      : status.colour === "BAD"
      ? "text-rose-600"
      : "text-blue-600";
  return (
    <Badge
      text={status.text}
      textColourClassName={textColour}
      backgroundColourClassName={bgColour}
      marginClassName="mr-2"
      textSize="text-sm"
    />
  );
};

export default JourneyFormItem;
