import cx from "classnames";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import { Tooltip } from "../../common";

export const ConfigurationBlockCard = ({
  title,
  children,
  icon,
  helpTooltip,
  isInEditMode,
}: {
  title: string;
  children: React.ReactNode;
  icon: IconProp;
  helpTooltip?: string | undefined;
  isInEditMode: boolean;
}) => {
  return (
    <div
      className={cx(
        "bg-white border border-gray-200 rounded-md pt-1 pb-2 mt-2 shadow-md",
        isInEditMode ? "lg:col-span-3" : ""
      )}
    >
      <div className="border-b border-gray-200 pb-1 px-4 flex flex-row">
        <h5 className="font-semibold text-gray-400 flex-grow">
          <FontAwesomeIcon icon={icon} size="1x" className="mr-2" />
          {title}
        </h5>
        {helpTooltip && (
          <div className="flex flex-shrink items-center text-gray-400 hover:text-gray-500">
            <Tooltip
              content={helpTooltip}
              triggerElement={
                <span className="hover:cursor-help">
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </span>
              }
            />
          </div>
        )}
      </div>

      <div className="px-4 pb-2 pt-1">{children}</div>
    </div>
  );
};

export const ConfigurationBlockCardEmptyState = ({
  itemName,
  icon,
  onAddClick,
  additionalChildren = null,
}: {
  itemName: "step" | "condition" | "trigger";
  icon: IconProp;
  onAddClick: () => void;
  additionalChildren?: React.ReactNode | null;
}) => {
  return (
    <div className="flex flex-col items-center pt-4 pb-3 gap-3 group">
      <button
        className="relative flex items-center justify-center w-12 h-12 bg-gray-300 group-hover:bg-green-300 rounded-full"
        onClick={onAddClick}
      >
        {/* Bolt Icon */}
        <FontAwesomeIcon icon={icon} className="text-white text-2xl " />

        {/* Plus Icon */}
        <span className="absolute -top-1 -right-2">
          <FontAwesomeIcon
            icon={faPlus}
            className="text-green-300 text-sm hidden group-hover:block"
          />
        </span>
      </button>

      <button
        className="text-gray-400 group-hover:text-gray-500"
        onClick={onAddClick}
      >
        Add the first {itemName}
      </button>
      {additionalChildren ? additionalChildren : null}
    </div>
  );
};
