import React, { Reducer, useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMatch, useNavigate } from "react-router-dom";
import { MainContainer } from "../../../components/layout";
import AppContext from "../../../state/AppContext";
import AppRoutes from "../../AppRoutes";
import Tabs, { TabDetails } from "../../../components/common/Tabs";
import { AdminTabType } from "../../../types/admin/client-journeys/AdminTabType";
import JourneyConfigurationTab from "./JourneyConfigurationTab";
import UserContext from "../../../state/UserContext";
import adminHelper from "../../../helpers/adminHelper";
import JourneyConfigurationTabApiResponseDto, {
  ClientFormGroupDto,
} from "../../../types/dtos/admin/JourneyConfigurationTabApiResponseDto";
import { useAuth } from "react-oidc-context";
import adminApi from "../../../api/dashboard/adminApi";
import UserJourneyTab from "../users/UserJourneysTab";
import UserJourneysTabApiResponseDto, {
  UserDetailSearchDto,
} from "../../../types/dtos/admin/UserJourneysTabApiResponseDto";
import { EmployeeSearchSortColumn } from "../../../types/admin/client-journeys/EmployeeSearchSortColumn";
import { EmployeeSearchSortDirection } from "../../../types/admin/client-journeys/EmployeeSearchSortDirection";
import SearchFilterInputs from "../../../types/admin/client-journeys/SearchFilterInputs";
import PaginationSearchAndFilterCriteria from "../../../types/admin/client-journeys/PaginationSearchAndFilterCriteria";

interface JourneyPageState {
  userJourneyTab: {
    isLoading: boolean;
    pagedItems: UserDetailSearchDto[] | undefined;
    totalItemCount: number;
  };
  configurationTab: {
    isLoading: boolean;
    data: ClientFormGroupDto[] | undefined;
  };
}

function Journeys() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userContext = React.useContext(UserContext);
  const appContext = React.useContext(AppContext);
  const [initialTabToOpenTo, setInitialTabToOpenTo] =
    useState<AdminTabType>("USER-JOURNEY");
  const auth = useAuth();
  const admApi = new adminApi(auth.user?.access_token);

  const [state, setState] = useReducer<
    Reducer<JourneyPageState, Partial<JourneyPageState>>
  >((state, newState) => ({ ...state, ...newState }), {
    userJourneyTab: {
      isLoading: true,
      pagedItems: undefined,
      totalItemCount: 0,
    },
    configurationTab: {
      isLoading: true,
      data: undefined,
    },
  });

  const emptyFilterSelection: SearchFilterInputs = {
    locationIds: [],
    appraisalLevelIds: [],
    jobTitleIds: [],
    employeeStatusIds: [],
    activeUsersOnly: true,
  };

  const [isEditingFilters, setIsEditingFilters] = useState<boolean>(false);
  // These are the values selected in the filters UI, but not necessarily applied yet
  const [filterSelectedValues, setFilterSelectedValues] =
    useState<SearchFilterInputs>(emptyFilterSelection);

  const [criteria, setCriteria] = useState<PaginationSearchAndFilterCriteria>({
    pageNumber: 1,
    pageSize: 10,
    searchTerm: null,
    sortColumn: "NAME",
    sortDirection: "ASC",
    filters: emptyFilterSelection,
  });

  const redirectedBackFromConfigDrivenPage = useMatch({
    path: AppRoutes.admin.clientJourneys.pathOpenWithConfigTabSelected,
    end: true,
  });
  const redirectedFromSendSuccess = useMatch({
    path: AppRoutes.admin.clientJourneys.pathRedirectFromSendAfterSuccess,
    end: true,
  });

  const managerAdminsTheirPeopleConfig =
    userContext.user.client.moduleConfigs.find((config) => {
      return config.key === "ManagersAdministerTheirEmployees";
    });

  const isNonAdminUserWithManagerConfigAccess =
    !userContext.user.isAdmin &&
    managerAdminsTheirPeopleConfig != undefined &&
    managerAdminsTheirPeopleConfig.value === "true" &&
    userContext.user.isManager;

  useEffect(() => {
    adminHelper.checkAdminAccessAndRedirectWhenNoAccess(
      userContext,
      "USER-JOURNEY",
      navigate
    );
    appContext.setPageTitle(t("Pages.Admin.PageTitle.Journeys"));
    appContext.setShowPageTitleAccent(true);

    // If the user has come from the send now page, we want to open the configuration tab
    // and load the data for that page.
    if (redirectedBackFromConfigDrivenPage || redirectedFromSendSuccess) {
      loadConfigurationTabData();
      setInitialTabToOpenTo("JOURNEY-CONFIG");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Trigger a reload of data if the pageNumber or searchTerm changes
  useEffect(() => {
    loadUserJourneyTabData(true);
  }, [criteria]);

  const loadUserJourneyTabData = (forceReload: boolean) => {
    // Call the API to load the necessary state
    const successCallback = (data: UserJourneysTabApiResponseDto) => {
      setState({
        userJourneyTab: {
          ...state.userJourneyTab,
          isLoading: false,
          pagedItems: data.pagedItems,
          totalItemCount: data.totalItemCount,
        },
      });
    };

    const errorCallback = (error: any) => {
      console.error(error);
    };

    // Only load the data if it hasn't been loaded yet, or we force a reload due to pageNumber/searchTerm change
    if (state.userJourneyTab.pagedItems == undefined || forceReload) {
      setState({
        ...state,
        userJourneyTab: { ...state.userJourneyTab, isLoading: true },
      });
      admApi.getUsersForSearch(
        criteria,
        isNonAdminUserWithManagerConfigAccess,
        successCallback,
        errorCallback
      );
    }
  };

  const handleSearchChange = (newsearchTerm: string | null) => {
    setCriteria({ ...criteria, pageNumber: 1, searchTerm: newsearchTerm }); // Resets page number
  };

  const handlePageNumberChange = (pageNumber: number) => {
    setCriteria({ ...criteria, pageNumber });
  };

  const handlePageSizeChange = (pageSize: number) => {
    setCriteria({ ...criteria, pageNumber: 1, pageSize }); // Resets page number
  };

  const handleSortingChange = (sortColumn: EmployeeSearchSortColumn) => {
    let sortDirection: EmployeeSearchSortDirection = "ASC";

    if (criteria.sortColumn == sortColumn) {
      sortDirection = criteria.sortDirection == "ASC" ? "DESC" : "ASC";
    }

    setCriteria({ ...criteria, pageNumber: 1, sortColumn, sortDirection }); // Resets page number
  };

  const handleLocationPickerChange = (selectedLocationIds: number[]) => {
    const newValues: SearchFilterInputs = {
      ...filterSelectedValues,
      locationIds: selectedLocationIds,
    };
    setFilterSelectedValues(newValues);
  };

  const handleJobTitlePickerChange = (selectedJobTitleIds: number[]) => {
    const newValues: SearchFilterInputs = {
      ...filterSelectedValues,
      jobTitleIds: selectedJobTitleIds,
    };
    setFilterSelectedValues(newValues);
  };

  const handleAppraisalPickerChange = (selectedAppraisalIds: number[]) => {
    const newValues: SearchFilterInputs = {
      ...filterSelectedValues,
      appraisalLevelIds: selectedAppraisalIds,
    };
    setFilterSelectedValues(newValues);
  };

  const handleEmployeeStatusPickerChange = (
    selectedEmployeeStatusIds: number[]
  ) => {
    const newValues: SearchFilterInputs = {
      ...filterSelectedValues,
      employeeStatusIds: selectedEmployeeStatusIds,
    };
    setFilterSelectedValues(newValues);
  };

  const handleActiveOnlyChange = (isChecked: boolean) => {
    const newValues: SearchFilterInputs = {
      ...filterSelectedValues,
      activeUsersOnly: isChecked,
      // If active only is checked, clear the employee status filter
      employeeStatusIds: isChecked
        ? []
        : filterSelectedValues.employeeStatusIds,
    };
    setFilterSelectedValues(newValues);
  };

  const onApplyFilters = () => {
    setIsEditingFilters(false);
    setCriteria({ ...criteria, pageNumber: 1, filters: filterSelectedValues }); // Resets page number
  };

  const onResetFilters = () => {
    setIsEditingFilters(false);
    setCriteria({ ...criteria, pageNumber: 1, filters: emptyFilterSelection }); // Resets page number
    setFilterSelectedValues(emptyFilterSelection);
  };

  const loadConfigurationTabData = () => {
    // Call the API to load the necessary state
    const successCallback = (data: JourneyConfigurationTabApiResponseDto) => {
      setState({
        configurationTab: {
          isLoading: false,
          data: data.groupedClientForms,
        },
      });
    };

    const errorCallback = (error: any) => {
      console.error(error);
    };

    // Only attempt to load the configurationTab data if it hasn't already been loaded
    if (state.configurationTab.data == undefined) {
      admApi.getClientFormsByClientId(successCallback, errorCallback);
    }
  };

  const getTabContent = (tab: AdminTabType) => {
    switch (tab) {
      case "USER-JOURNEY":
        return (
          <div className="w-full border rounded p-6 !pt-1">
            <UserJourneyTab
              isLoading={state.userJourneyTab.isLoading}
              users={state.userJourneyTab.pagedItems}
              pageNumber={criteria.pageNumber}
              pageSize={criteria.pageSize}
              totalItems={state.userJourneyTab.totalItemCount}
              sortColumn={criteria.sortColumn}
              sortDirection={criteria.sortDirection}
              searchTerm={criteria.searchTerm}
              inEditMode={isEditingFilters}
              selectedValues={criteria.filters}
              handlePageNumberChange={handlePageNumberChange}
              handlePageSizeChange={handlePageSizeChange}
              handleSortColumnChange={handleSortingChange}
              handleSearchTermChange={handleSearchChange}
              onBeginEditMode={() => setIsEditingFilters(true)}
              onLocationPickerChange={handleLocationPickerChange}
              onJobTitlePickerChange={handleJobTitlePickerChange}
              onAppraisalLevelPickerChange={handleAppraisalPickerChange}
              onEmployeeStatusPickerChange={handleEmployeeStatusPickerChange}
              onActiveOnlyChange={handleActiveOnlyChange}
              onApplyFilters={onApplyFilters}
              onResetFilters={onResetFilters}
            />
          </div>
        );
      case "JOURNEY-CONFIG":
        return (
          <div className="w-full border rounded p-6 !pt-1">
            <JourneyConfigurationTab
              isLoading={state.configurationTab.isLoading}
              clientFormGroups={state.configurationTab.data}
            />
          </div>
        );
    }
  };

  const displayTabs: Array<TabDetails> = [
    {
      title: t("Pages.Admin.Tabs.Headings.UserJourneys"),
      type: "USER-JOURNEY",
      content: getTabContent("USER-JOURNEY"),
      displayAlertIcon: false,
      onClickEvent: () => loadUserJourneyTabData(false),
    },
  ];

  if (
    adminHelper.doesUserHaveAdminModuleAccess(userContext, "JOURNEY-CONFIG")
  ) {
    displayTabs.push({
      title: t("Pages.Admin.Tabs.Headings.JourneyConfiguration"),
      type: "JOURNEY-CONFIG",
      content: getTabContent("JOURNEY-CONFIG"),
      displayAlertIcon: false,
      onClickEvent: () => loadConfigurationTabData(),
    });
  }

  return (
    <MainContainer>
      <div className="mt-4">
        {displayTabs.length == 1 && (
          <UserJourneyTab
            isLoading={state.userJourneyTab.isLoading}
            users={state.userJourneyTab.pagedItems}
            pageNumber={criteria.pageNumber}
            pageSize={criteria.pageSize}
            totalItems={state.userJourneyTab.totalItemCount}
            sortColumn={criteria.sortColumn}
            sortDirection={criteria.sortDirection}
            searchTerm={criteria.searchTerm}
            inEditMode={isEditingFilters}
            selectedValues={criteria.filters}
            handlePageNumberChange={handlePageNumberChange}
            handlePageSizeChange={handlePageSizeChange}
            handleSortColumnChange={handleSortingChange}
            handleSearchTermChange={handleSearchChange}
            onBeginEditMode={() => setIsEditingFilters(true)}
            onLocationPickerChange={handleLocationPickerChange}
            onJobTitlePickerChange={handleJobTitlePickerChange}
            onAppraisalLevelPickerChange={handleAppraisalPickerChange}
            onEmployeeStatusPickerChange={handleEmployeeStatusPickerChange}
            onActiveOnlyChange={handleActiveOnlyChange}
            onApplyFilters={onApplyFilters}
            onResetFilters={onResetFilters}
          />
        )}
        {displayTabs.length > 1 && (
          <Tabs
            tabs={displayTabs}
            selectedTabClassNames="radix-state-active: bg-[#EFEFF0] hover:!bg-[#EFEFF0] rounded-md"
            initialTabToOpenToUsingTabType={initialTabToOpenTo}
          />
        )}
      </div>
    </MainContainer>
  );
}

export default Journeys;
