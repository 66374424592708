import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import cx from "classnames";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface PrimaryLinkButtonProps {
  text: string;
  href: string;
  icon?: IconProp | undefined;
  iconPlacement?: "LEFT" | "RIGHT";
  size?: "NORMAL" | "SMALL";
}

const PrimaryLinkButton = ({
  text,
  href,
  icon = undefined,
  iconPlacement = "LEFT",
  size = "NORMAL",
}: PrimaryLinkButtonProps) => {
  return (
    <Link
      className={cx(
        "btn-primary leading-loose font-semibold inline-flex items-center shadow",
        size === "SMALL" ? "btn-small" : ""
      )}
      to={href}
    >
      {icon && iconPlacement === "LEFT" && (
        <FontAwesomeIcon className="mr-2" icon={icon} size="xs" />
      )}
      <span>{text}</span>
      {icon && iconPlacement === "RIGHT" && (
        <FontAwesomeIcon className="ml-2" icon={icon} size="xs" />
      )}
    </Link>
  );
};

export default PrimaryLinkButton;
