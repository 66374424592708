import { useTranslation } from "react-i18next";
import UserTaskListItemDto from "../../types/dtos/tasks/advanced-tasks/UserTaskListItemDto";
import { ModalPopup } from "../common";
import taskRestrictionHelper from "../../helpers/taskRestrictionHelper";
import { CountRestrictionType } from "../../types/dtos/tasks/advanced-tasks/CountRestrictionType";
import { ClientTaskTypeCategory } from "../../types/dtos/tasks/advanced-tasks/ClientTaskTypeCategory";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClientTaskType } from "../../types/dtos/tasks/advanced-tasks/ClientTaskType";
import UserTaskListGroupDto from "../../types/dtos/tasks/advanced-tasks/UserTaskListGroupDto";
import { useState } from "react";
import parse from "html-react-parser";
import cx from "classnames";
import AdvancedTaskDto from "../../types/dtos/forms/AdvancedTaskDto";
import AdvancedToDoListItemDto from "../../types/dtos/dashboards/right-bar/to-do-list/AdvancedToDoListItemDto";

type OriginLocation = "JOURNEY" | "OUTSIDE-JOURNEY";

interface TaskRequirementBadgeProps {
  tasks:
  | UserTaskListItemDto[]
  | AdvancedToDoListItemDto[]
  | AdvancedTaskDto[];
  minTasks: number | null;
  maxTasks: number | null;
  countRestriction: CountRestrictionType;
  activeCategories: ClientTaskTypeCategory[] | null;
  taskType: ClientTaskType;
  isNonEmployeeViewer: boolean;
  userGroup?: UserTaskListGroupDto;
  showInfoIcon?: boolean;
  origin: OriginLocation;
  journeySubjectFirstName?: string | null;
  fullLengthMode?: boolean;
}

const TaskRequirementBadge = ({
  tasks,
  minTasks,
  maxTasks,
  countRestriction,
  activeCategories,
  taskType,
  isNonEmployeeViewer,
  userGroup,
  showInfoIcon = true,
  origin,
  journeySubjectFirstName,
  fullLengthMode = false,
}: TaskRequirementBadgeProps) => {
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  let personToken = isNonEmployeeViewer ? userGroup?.employeeFirstName! : null;

  if (origin == "JOURNEY" && journeySubjectFirstName) {
    personToken = journeySubjectFirstName;
  }

  const doesUserHaveTaskRestrictions =
    taskRestrictionHelper.doesUserHaveTasksThatSatisfyRestrictions(
      tasks,
      minTasks,
      maxTasks,
      countRestriction,
      activeCategories
    );

  const taskRestrictionText =
    taskRestrictionHelper.getRestrictionBannerTextHtmlForManagementPages(
      taskType,
      activeCategories,
      countRestriction,
      minTasks,
      maxTasks,
      personToken,
      t
    );

  const infoPopup = (
    <ModalPopup
      isOpen={modalIsOpen}
      onOpenChange={setModalIsOpen}
      onPrimaryButtonClick={() => setModalIsOpen(false)}
      primaryButtonText={t("Common.Close")}
      title={t("Pages.SendNow.Tooltips.Information")}
      showCloseIcon={false}
      width="SMALL"
    >
      <div className="styled-list">{parse(taskRestrictionText!)}</div>
    </ModalPopup>
  );

  const openModal = () => {
    setModalIsOpen(true);
  };

  return (
    <>
      <button
        onClick={openModal} className={cx(
          "hover:cursor-pointer",
          fullLengthMode ? "w-full" : ""
        )}>
        <span
          className={cx(
            "badge-label px-2 py-0.5 text-xs leading-tight select-none rounded-sm",
            doesUserHaveTaskRestrictions
              ? origin == "OUTSIDE-JOURNEY"
                ? "bg-green-200/75 rounded-full"
                : "bg-green-200 rounded-full"
              : origin == "OUTSIDE-JOURNEY"
                ? "bg-rose-200/75 rounded-full"
                : "bg-rose-200 rounded-full",
            doesUserHaveTaskRestrictions ? "text-green-600" : "text-rose-600",
            origin == "JOURNEY" ? "mr-2" : "",
            fullLengthMode 
              ? doesUserHaveTaskRestrictions 
                ? "!text-sm block !rounded border border-green-500" 
                : "!text-sm block !rounded border border-rose-500" 
              : ""
          )}
        >
          {showInfoIcon && (
            <FontAwesomeIcon icon={faInfoCircle} size="sm" className="mr-1" />
          )}
          {doesUserHaveTaskRestrictions
            ? t("TaskType.Restrictions.TaskRequirementsMet").replace(
              "#TASK_TYPE#",
              t(taskType.singularNameTranslationKeyIdentifier)
            )
            : t("TaskType.Restrictions.TaskRequirementsNotMet").replace(
              "#TASK_TYPE#",
              t(taskType.singularNameTranslationKeyIdentifier)
            )}
        </span>
      </button>
      {infoPopup}
    </>
  );
};

export default TaskRequirementBadge;
