import SubNavItem from "../../types/nav/SubNavItem";
import AppRoutes from "../AppRoutes";

const AdminSubNavItems = (navType: "LEFT-NAV" | "TOUCH"): SubNavItem[] => {
  const translationIdentifierPrefix = `Nav.${
    navType === "LEFT-NAV" ? "LargeScreen" : "Touch"
  }.Admin.`;

  const output = [
    {
      title: translationIdentifierPrefix + "Journeys",
      translateTitle: true,
      url: AppRoutes.admin.clientJourneys.path,
    },
    {
      title: translationIdentifierPrefix + "UserManagement",
      translateTitle: true,
      url: AppRoutes.admin.userManagement,
    },
  ];

  return output;
};

export default AdminSubNavItems;
