import { t } from "i18next";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { useEffect, useState } from "react";
import { OverrideDto } from "../../../../types/dtos/admin/OverrideDto";
import ClientFormModeDisplay from "../../../common/ClientFormModeDisplay";
import {
  AppraisalLevelDropDownList,
  FormattedDate,
  IconButton,
  Label,
  ModeDropDownList,
  Switch,
} from "../../../common";
import { dateHelper, journeyAutomationHelper } from "../../../../helpers";
import AutomationDisplay from "../../../common/AutomationDisplay";
import SuccessAlert from "../../../alerts/SuccessAlert";
import { ValidationResult } from "../../../../types/forms";
import { NewOverrideDto } from "../../../../types/dtos/admin/NewOverrideDto";
import { WindowDto } from "../../../../types/dtos/admin/WindowDto";
import WindowPicker from "../../../common/WindowPicker";
import overrideHelper from "../../../../helpers/overrideHelper";
import InductionDateRange from "../../../common/InductionDateRange";
import ClientFormModeType from "../../../../types/dtos/admin/ClientFormModeType";
import ClientFormAutomationUnitType from "../../../../types/dtos/admin/ClientFormAutomationUnitType";
import AutomationPicker from "../../../common/AutomationPicker";
import WindowDatabaseStatusEnum from "../../../../types/admin/client-journeys/WindowDatabaseStatus";
import { useAuth } from "react-oidc-context";
import adminApi from "../../../../api/dashboard/adminApi";
import { Overrides } from "../../../../state/admin/config/Overrides";
import SmallLoader from "../../../loaders/SmallLoader";
import MinimumAutomationValidationMessage from "./MinimumAutomationValidationMessage";
import AutomationWarningMessage from "./AutomationWarningMessage";

export type ConfigMode = "ADD" | "EDIT";

interface OverridesTabProps {
  clientFormId: number;
  newOverrideData: NewOverrideDto;
  existingOverrides?: OverrideDto[];
  onUpdateExistingOverrides(newState: OverrideDto[] | undefined): void;
  overrideSettings: Overrides;
  onOverrideSettingsChange(newState: Overrides): void;
  createBlankOverride(
    mode: ClientFormModeType,
    clientFormId: number,
    appLevelId: number,
    appLevelName: string,
    disableJourney: boolean,
    automationAmount?: number,
    automationUnit?: ClientFormAutomationUnitType,
    inductionDaysTo?: number,
    inductionDaysFrom?: number
  ): OverrideDto;
  setOverrideTitle(configMode: ConfigMode, appLevelName?: string): string;
  defaultAppraisalLevelId?: number;
  defaultAppraisalLevelName?: string;
  defaultMode: ClientFormModeType;
  defaultAutomationAmount?: number;
  defaultAutomationUnit?: ClientFormAutomationUnitType;
  defaultInductionDaysTo?: number;
  defaultInductionDaysFrom?: number;
  defaultDisableJourney?: boolean;
}

function OverridesTab({
  clientFormId,
  newOverrideData,
  existingOverrides,
  onUpdateExistingOverrides,
  overrideSettings,
  onOverrideSettingsChange,
  createBlankOverride,
  setOverrideTitle,
  defaultAppraisalLevelId = newOverrideData.appraisalLevels[0].key,
  defaultAppraisalLevelName = newOverrideData.appraisalLevels[0].value,
  defaultMode,
  defaultAutomationAmount = 3,
  defaultAutomationUnit = "MONTHS",
  defaultInductionDaysFrom = 25,
  defaultInductionDaysTo = 30,
  defaultDisableJourney = false,
}: OverridesTabProps) {
  const { t } = useTranslation();
  const auth = useAuth();
  const admApi = new adminApi(auth.user?.access_token);
  const appraisalLevelInputId = "override-appraisal-level-select";
  const modeInputId = "override-mode-select";
  const automationAmountInputId = "override-automation-amount-input";
  const windowsInputId = "override-windows";
  const inductionDateRangeInputId = "override-induction-date-range-input";

  const [isFormSaving, setIsFormSaving] = useState<boolean>(false);
  const [currentSavedMode, setCurrentSavedMode] = useState<
    ClientFormModeType | undefined
  >(undefined);

  useEffect(() => {
    // Set the mode on page load, so we can track when it's changed from its original value
    // and warn the user about the effects of setting a form up as automated
    setCurrentSavedMode(overrideSettings.overrideObject.mode);
  }, []);

  useEffect(() => {
    if (
      overrideSettings.overrideObject.automationAmount &&
      overrideSettings.overrideObject.automationUnit
    ) {
      onOverrideSettingsChange({
        ...overrideSettings,
        nextDate: dateHelper.getFromCurrentDateUtcs(
          overrideSettings.overrideObject.automationAmount,
          overrideSettings.overrideObject.automationUnit
        ),
      });
    }
  }, [
    overrideSettings.overrideObject.automationAmount,
    overrideSettings.overrideObject.automationUnit,
  ]);

  useEffect(() => {
    if (overrideSettings.overrideObject.inductionDaysFrom) {
      onOverrideSettingsChange({
        ...overrideSettings,
        nextDate: dateHelper.getFromCurrentDateUtcs(
          overrideSettings.overrideObject.inductionDaysFrom,
          "DAYS"
        ),
      });
    }
  }, [overrideSettings.overrideObject.inductionDaysFrom]);

  //  |------------- EVENTS -------------|
  const handleModeChange = (newMode: ClientFormModeType) => {
    if (newMode !== "AUTOMATED") {
      onOverrideSettingsChange({
        ...overrideSettings,
        formIsDirty: true,
        overrideObject: {
          ...overrideSettings.overrideObject,
          mode: newMode,
          automationAmount: undefined,
          automationUnit: undefined,
        },
      });
    } else {
      onOverrideSettingsChange({
        ...overrideSettings,
        formIsDirty: true,
        overrideObject: {
          ...overrideSettings.overrideObject,
          mode: newMode,
          automationAmount:
            defaultAutomationAmount != null ? defaultAutomationAmount : 3,
          automationUnit:
            defaultAutomationUnit != null ? defaultAutomationUnit : "MONTHS",
        },
      });
    }
  };

  const handleAppraisalLevelChange = (newAppLevelId: string) => {
    let validationResult;
    if (overrideSettings.showValidationErrors) {
      // Only perform validation if the user has tried to save already
      validationResult = validateAppraisalLevel(parseInt(newAppLevelId));
    }

    onOverrideSettingsChange({
      ...overrideSettings,
      formIsDirty: true,
      appraisalLevelValidation:
        validationResult != undefined ? validationResult : null,
      overrideObject: {
        ...overrideSettings.overrideObject,
        appraisalLevelName: newAppLevelId,
        appraisalLevelId: parseInt(newAppLevelId),
      },
    });
  };

  const handleAutomationAmountChange = (newAutomationAmount: number) => {
    if (newAutomationAmount !== null) {
      onOverrideSettingsChange({
        ...overrideSettings,
        formIsDirty: true,
        overrideObject: {
          ...overrideSettings.overrideObject,
          automationAmount: newAutomationAmount,
        },
      });
    }
  };

  const handleAutomationUnitChange = (
    newAutomationUnitString: string | null
  ) => {
    const newAutomationUnitType =
      newAutomationUnitString !== null
        ? (newAutomationUnitString as ClientFormAutomationUnitType)
        : undefined;

    onOverrideSettingsChange({
      ...overrideSettings,
      formIsDirty: true,
      overrideObject: {
        ...overrideSettings.overrideObject,
        automationUnit: newAutomationUnitType,
      },
    });
  };

  const handleInductionDaysToChange = (newInductionDaysTo: number) => {
    let validationResult;
    if (overrideSettings.showValidationErrors) {
      // Only perform validation if the user has tried to save already
      validationResult = validateInductionDateRange(
        overrideSettings.overrideObject.inductionDaysFrom,
        newInductionDaysTo
      );
    }

    if (newInductionDaysTo !== null) {
      onOverrideSettingsChange({
        ...overrideSettings,
        formIsDirty: true,
        inductionDateRangeValidation:
          validationResult != undefined ? validationResult : null,
        overrideObject: {
          ...overrideSettings.overrideObject,
          inductionDaysTo: newInductionDaysTo,
        },
      });
    }
  };

  const handleInductionDaysFromChange = (newInductionDaysFrom: number) => {
    let validationResult;
    if (overrideSettings.showValidationErrors) {
      // Only perform validation if the user has tried to save already
      validationResult = validateInductionDateRange(
        newInductionDaysFrom,
        overrideSettings.overrideObject.inductionDaysTo
      );
    }

    if (newInductionDaysFrom !== null) {
      onOverrideSettingsChange({
        ...overrideSettings,
        formIsDirty: true,
        inductionDateRangeValidation:
          validationResult != undefined ? validationResult : null,
        overrideObject: {
          ...overrideSettings.overrideObject,
          inductionDaysFrom: newInductionDaysFrom,
        },
      });
    }
  };

  const handleDisableJourneyChange = (disable: boolean) => {
    if (disable !== null) {
      if (
        overrideSettings.overrideObject.inductionDaysTo === null &&
        overrideSettings.overrideObject.inductionDaysFrom === null
      ) {
        onOverrideSettingsChange({
          ...overrideSettings,
          formIsDirty: true,
          overrideObject: {
            ...overrideSettings.overrideObject,
            disableJourney: disable,
            inductionDaysTo: defaultInductionDaysTo,
            inductionDaysFrom: defaultInductionDaysFrom,
          },
        });
      } else {
        onOverrideSettingsChange({
          ...overrideSettings,
          formIsDirty: true,
          overrideObject: {
            ...overrideSettings.overrideObject,
            disableJourney: disable,
          },
        });
      }
    }
  };

  const onAddWindow = (startDate: Date | null, endDate: Date | null) => {
    if (overrideSettings.overrideObject.windows && startDate && endDate) {
      const newWindowDto: WindowDto = {
        id: 0,
        startDate: startDate,
        endDate: endDate,
        isActive: false,
        status: WindowDatabaseStatusEnum.ToAdd,
      };

      // @ts-ignore
      const sorted = [...overrideObject.windows, newWindowDto].sort(
        (a, b) => a.startDate - b.startDate
      );
      onOverrideSettingsChange({
        ...overrideSettings,
        formIsDirty: true,
        overrideObject: {
          ...overrideSettings.overrideObject,
          windows: sorted,
        },
      });
    }
  };

  const onEditWindow = (window: WindowDto) => {
    if (overrideSettings.overrideObject.windows) {
      // 'Clone'
      let newState = [...overrideSettings.overrideObject.windows];

      // Create window object to represent the edited window
      // Note: If the EditedWindow is yet to be added to the database we need to keep the status as ToAdd
      const editedWindow: WindowDto = {
        id: window.id,
        startDate: window.startDate,
        endDate: window.endDate,
        isActive: window.isActive,
        status:
          window.status == WindowDatabaseStatusEnum.ToAdd
            ? window.status
            : WindowDatabaseStatusEnum.ToUpdate,
      };

      // Find index in the cloned list and replace it, before updating the overrideSettings.
      let index = newState.findIndex((x) => x.id == window.id);
      newState.splice(index, 1, editedWindow);

      onOverrideSettingsChange({
        ...overrideSettings,
        formIsDirty: true,
        overrideObject: {
          ...overrideSettings.overrideObject,
          windows: newState,
        },
      });
    }
  };

  const onDeleteWindow = (window: WindowDto) => {
    if (window.status == WindowDatabaseStatusEnum.Exists) {
      // Flag the window as needing as 'ToDelete' from the database when saved.
      window.status = WindowDatabaseStatusEnum.ToDelete;
    } else {
      // If the window being deleted doesn't already exist in terms of the database status
      // then it can be removed from the windows completely.
      var newState = overrideSettings.overrideObject.windows?.filter(function (
        w
      ) {
        return w !== window;
      });

      if (newState && newState?.length > 0) {
        onOverrideSettingsChange({
          ...overrideSettings,
          formIsDirty: true,
          overrideObject: {
            ...overrideSettings.overrideObject,
            windows: newState,
          },
        });
      } else {
        onOverrideSettingsChange({
          ...overrideSettings,
          formIsDirty: true,
          overrideObject: {
            ...overrideSettings.overrideObject,
            windows: [],
          },
        });
      }
    }
  };

  const onSaveChanges = () => {
    if (settingsAreValid()) {
      setIsFormSaving(true);
      var override: OverrideDto = overrideHelper.createCleanOverrideDto(
        overrideSettings.overrideObject.overrideId,
        overrideSettings.overrideObject.clientFormId,
        overrideSettings.overrideObject.appraisalLevelId,
        overrideSettings.overrideObject.appraisalLevelName,
        overrideSettings.overrideObject.mode,
        overrideSettings.overrideObject
      );

      admApi.saveOverride(
        clientFormId,
        override,
        (data: OverrideDto[]) => {
          // Turn dates into JS Date objects so they can be sorted.
          if (data) {
            data.forEach((override) => {
              if (override.windows != undefined) {
                override.windows.forEach((window) => {
                  window.startDate = new Date(window.startDate);
                  window.endDate = new Date(window.endDate);
                });
              }
            });
          }

          // Update the existing overrides with the fresh lot from the server post-save.
          onUpdateExistingOverrides(data);

          onOverrideSettingsChange({
            ...overrideSettings,
            formIsDirty: false,
            overrideObject: createBlankOverride(
              defaultMode,
              clientFormId,
              defaultAppraisalLevelId,
              defaultAppraisalLevelName,
              defaultDisableJourney,
              defaultAutomationAmount,
              defaultAutomationUnit,
              defaultInductionDaysTo,
              defaultInductionDaysFrom
            ),
            showValidationErrors: false,
            inEditMode: false,
            title: setOverrideTitle("ADD"),
            showSuccessAlert: true,
            successMessage: !overrideSettings.inEditMode
              ? "Common.Validation.Successful.SavedOverrides"
              : "Common.Validation.Successful.UpdatedOverrides",
          });
          setIsFormSaving(false);
          setCurrentSavedMode(overrideSettings.overrideObject.mode);
        },
        (error: any) => {
          console.error(error);
        }
      );
    } else {
      // Show errors
      onOverrideSettingsChange({
        ...overrideSettings,
        showValidationErrors: true,
        appraisalLevelValidation: validateAppraisalLevel(
          overrideSettings.overrideObject.appraisalLevelId
        ),
        inductionDateRangeValidation: validateInductionDateRange(
          overrideSettings.overrideObject.inductionDaysFrom,
          overrideSettings.overrideObject.inductionDaysTo
        ),
        showSuccessAlert: false,
        successMessage: "",
      });
      setIsFormSaving(false);
    }
  };

  const onEditOverride = (existingOverride: OverrideDto) => {
    onOverrideSettingsChange({
      ...overrideSettings,
      title: setOverrideTitle("EDIT", existingOverride.appraisalLevelName),
      inEditMode: true,
      overrideObject: existingOverride,
    });
    setCurrentSavedMode(existingOverride.mode);
  };

  const onDeleteOverride = (existingOverride: OverrideDto) => {
    admApi.deleteOverride(
      clientFormId,
      existingOverride,
      (data: OverrideDto[]) => {
        // Turn dates into JS Date objects so they can be sorted.
        if (data) {
          data.forEach((override) => {
            if (override.windows != undefined) {
              override.windows.forEach((window) => {
                window.startDate = new Date(window.startDate);
                window.endDate = new Date(window.endDate);
              });
            }
          });
        }

        // Update the existing overrides with the fresh lot from the server post-save.
        onUpdateExistingOverrides(data);

        onOverrideSettingsChange({
          ...overrideSettings,
          formIsDirty: false,
          overrideObject: createBlankOverride(
            defaultMode,
            clientFormId,
            defaultAppraisalLevelId,
            defaultAppraisalLevelName,
            defaultDisableJourney,
            defaultAutomationAmount,
            defaultAutomationUnit,
            defaultInductionDaysTo,
            defaultInductionDaysFrom
          ),
          showValidationErrors: false,
          inEditMode: false,
          title: setOverrideTitle("ADD"),
          showSuccessAlert: true,
          successMessage: "Common.Validation.Successful.DeletedOverride",
        });
      },
      (error: any) => {
        console.error(error);
      }
    );
  };

  const onResetChanges = () => {
    onOverrideSettingsChange({
      ...overrideSettings,
      formIsDirty: false,
      overrideObject: createBlankOverride(
        defaultMode,
        clientFormId,
        defaultAppraisalLevelId,
        defaultAppraisalLevelName,
        defaultDisableJourney,
        defaultAutomationAmount,
        defaultAutomationUnit,
        defaultInductionDaysTo,
        defaultInductionDaysFrom
      ),
      showValidationErrors: false,
      inEditMode: false,
      title: setOverrideTitle("ADD"),
      showSuccessAlert: false,
      successMessage: "",
    });
  };

  //  |------------- VALIDATION -------------|
  const settingsAreValid = (): boolean => {
    const appraisalLevelValidationResult = validateAppraisalLevel(
      overrideSettings.overrideObject.appraisalLevelId
    );
    const inductionDatRangeValidationResult = validateInductionDateRange(
      overrideSettings.overrideObject.inductionDaysFrom,
      overrideSettings.overrideObject.inductionDaysTo
    );

    if (overrideSettings.overrideObject.mode === "INDUCTION") {
      return (
        appraisalLevelValidationResult.isValid &&
        inductionDatRangeValidationResult.isValid
      );
    } else {
      const automationValidationResult = journeyAutomationHelper.isValid(
        overrideSettings.overrideObject.automationAmount,
        overrideSettings.overrideObject.automationUnit
      );
      return (
        appraisalLevelValidationResult.isValid &&
        !(
          overrideSettings.overrideObject.mode === "AUTOMATED" &&
          automationValidationResult !== "VALID"
        )
      );
    }
  };

  const validateAppraisalLevel = (
    appraisalLevelId: number
  ): ValidationResult => {
    let validationResult: ValidationResult;

    var selectedAppraisalLevelId = newOverrideData.appraisalLevels.find(
      (x) => x.key == appraisalLevelId
    )?.key;
    const doesAppLevelAlreadyHaveOverride = existingOverrides?.some(
      (x) => x.appraisalLevelId === selectedAppraisalLevelId
    );

    // If the app level already has an override and its not in edit mode then flag.
    if (doesAppLevelAlreadyHaveOverride && !overrideSettings.inEditMode) {
      validationResult = new ValidationResult(false, [
        { errorType: "NON-UNIQUE_APP_LEVEL_OVERRIDE" },
      ]);
    } else {
      validationResult = new ValidationResult(true);
    }

    return validationResult;
  };

  const validateInductionDateRange = (
    inductionDaysFrom?: number,
    inductionDaysTo?: number
  ): ValidationResult => {
    let validationResult: ValidationResult;

    if (
      inductionDaysTo !== undefined &&
      inductionDaysFrom !== undefined &&
      inductionDaysTo > inductionDaysFrom
    ) {
      validationResult = new ValidationResult(true);
    } else {
      validationResult = new ValidationResult(false, [
        { errorType: "NUMBER-MISMATCH" },
      ]);
    }

    return validationResult;
  };

  return (
    <>
      {overrideSettings.showSuccessAlert && overrideSettings.successMessage && (
        <SuccessAlert
          prefix={t("Common.Success")}
          message={t(overrideSettings.successMessage)}
        />
      )}
      <div className="grid grid-cols-1 md:grid-cols-12 gap-10">
        {existingOverrides && existingOverrides.length > 0 && (
          <div className="col-span-1 md:col-span-4">
            <h3>{t("Pages.Admin.Tabs.Titles.ExistingOverrides")}</h3>
            <div className="snap-y overflow-scroll overflow-x-hidden max-h-96 border border-gray-200">
              {existingOverrides.map((existing) => {
                return (
                  <div
                    key={`existing_${existing.overrideId}`}
                    className="grid grid-cols-8 border-4 border-[#F7F8FA] rounded mb-1 p-4"
                  >
                    <div className="text-sm col-span-6">
                      <div>
                        <label className="font-semibold underline">
                          {t("Common.AppraisalLevel")}
                        </label>
                        <p className="font-normal">
                          - {t(existing.appraisalLevelName)}
                        </p>
                      </div>
                      {existing.mode !== "WINDOWED" &&
                        existing.mode !== "INDUCTION" &&
                        existing.mode !== "EXIT" && (
                          <div className="pt-1">
                            <label className="font-semibold underline">
                              {t("Common.Override")}:{" "}
                            </label>
                            <p>
                              -{" "}
                              <ClientFormModeDisplay
                                className="font-normal"
                                value={existing.mode}
                              />
                              {existing.automationAmount &&
                                existing.automationUnit && (
                                  <span>
                                    {" "}
                                    (
                                    <AutomationDisplay
                                      value={existing.automationAmount}
                                      type={existing.automationUnit}
                                      className="font-normal"
                                    />
                                    )
                                  </span>
                                )}
                            </p>
                          </div>
                        )}
                      {existing.mode === "WINDOWED" && existing.windows && (
                        <div className="pt-1">
                          <label className="font-semibold underline">
                            {t("Common.Override")}:{" "}
                          </label>
                          {existing.windows.map((window) => (
                            <p key={"window_" + window.id}>
                              {window.startDate && window.endDate && (
                                <span className="font-normal">
                                  -{" "}
                                  <FormattedDate
                                    displayMode="DATE-ONLY"
                                    date={window.startDate}
                                  />{" "}
                                  -{" "}
                                  <FormattedDate
                                    displayMode="DATE-ONLY"
                                    date={window.endDate}
                                  />{" "}
                                </span>
                              )}
                            </p>
                          ))}
                        </div>
                      )}
                      {existing.mode === "INDUCTION" && (
                        <div className="pt-1">
                          <label className="font-semibold underline">
                            {t("Common.Override")}:{" "}
                          </label>
                          {existing.disableJourney !== undefined &&
                          existing.disableJourney === false ? (
                            <div>
                              {existing.inductionDaysTo &&
                                existing.inductionDaysFrom && (
                                  <span className="font-normal">
                                    - {t("Pages.Admin.Common.Between")}{" "}
                                    {existing.inductionDaysFrom}{" "}
                                    {t("Common.And_LowerCase")}{" "}
                                    {existing.inductionDaysTo}{" "}
                                    {t("Common.Days_LowerCase")}
                                  </span>
                                )}
                            </div>
                          ) : (
                            <div>
                              <span className="font-normal">
                                - {t("Pages.Admin.Common.Disabled")}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                      {existing.mode === "EXIT" && (
                        <div className="pt-1">
                          <label className="font-semibold underline">
                            {t("Common.Override")}:{" "}
                          </label>
                          {existing.disableJourney !== undefined &&
                          existing.disableJourney === false ? (
                            <div>
                              <span className="font-normal">
                                - {t("Pages.Admin.Common.WillSend")}
                              </span>
                            </div>
                          ) : (
                            <div>
                              <span className="font-normal">
                                - {t("Pages.Admin.Common.Disabled")}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="col-span-1 text-right">
                      <IconButton
                        buttonType="EDIT"
                        displayMode="ICON-ONLY"
                        iconClassName="text-yellow-700"
                        onClick={() => {
                          onEditOverride(existing);
                        }}
                      />
                    </div>
                    <div className="col-span-1 text-right">
                      <IconButton
                        buttonType="DELETE"
                        displayMode="ICON-ONLY"
                        iconClassName="text-rose-800"
                        onClick={() => {
                          onDeleteOverride(existing);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <div
          className={cx(
            existingOverrides && existingOverrides.length > 0
              ? "col-span-1 md:col-span-8"
              : "col-span-1 md:col-span-12"
          )}
        >
          <div className="grid grid-cols-10">
            <h3 className="col-span-9">{overrideSettings.title}</h3>
            {overrideSettings.inEditMode && (
              <div className="col-span-1">
                <IconButton
                  buttonType="NEW-OVERRIDE"
                  displayMode="ICON-ONLY"
                  onClick={() => {
                    onResetChanges();
                  }}
                />
              </div>
            )}
          </div>
          {!overrideSettings.inEditMode && (
            <div>
              <AppraisalLevelDropDownList
                inputId={appraisalLevelInputId}
                value={overrideSettings.overrideObject.appraisalLevelName}
                onChange={handleAppraisalLevelChange}
                options={newOverrideData.appraisalLevels}
                showValidationErrors={overrideSettings.showValidationErrors}
                validationResult={overrideSettings.appraisalLevelValidation}
              />
            </div>
          )}
          {overrideSettings.overrideObject.mode !== "WINDOWED" &&
            overrideSettings.overrideObject.mode !== "INDUCTION" &&
            overrideSettings.overrideObject.mode !== "EXIT" && (
              <div
                className={cx(overrideSettings.inEditMode ? "" : "mb-2 mt-6")}
              >
                <ModeDropDownList
                  inputId={modeInputId}
                  value={overrideSettings.overrideObject.mode}
                  onChange={handleModeChange}
                  modes={newOverrideData.modes}
                />
                <AutomationWarningMessage
                  originalMode={currentSavedMode}
                  selectedMode={overrideSettings.overrideObject.mode}
                  automationAmount={
                    overrideSettings.overrideObject.automationAmount
                  }
                  automationUnit={
                    overrideSettings.overrideObject.automationUnit
                  }
                />
              </div>
            )}
          {overrideSettings.overrideObject.mode === "AUTOMATED" &&
            newOverrideData.automationUnits &&
            overrideSettings.overrideObject.automationAmount &&
            overrideSettings.overrideObject.automationUnit && (
              <div className="mb-2 mt-6">
                <Label
                  htmlFor={automationAmountInputId}
                  text={t("Pages.Admin.Common.SendSectionToUsersEveryX")}
                />
                <MinimumAutomationValidationMessage
                  automationNumber={
                    overrideSettings.overrideObject.automationAmount
                  }
                  automationUnit={
                    overrideSettings.overrideObject.automationUnit
                  }
                />
                <AutomationPicker
                  numberInputId={automationAmountInputId}
                  dropdownInputId={automationAmountInputId}
                  onAutomationAmountChange={handleAutomationAmountChange}
                  onAutomationUnitChange={handleAutomationUnitChange}
                  value={overrideSettings.overrideObject.automationAmount}
                  selectedAutomationUnit={
                    overrideSettings.overrideObject.automationUnit
                  }
                  automationUnits={newOverrideData.automationUnits}
                  nextDate={overrideSettings.nextDate}
                />
              </div>
            )}
          {overrideSettings.overrideObject.mode === "WINDOWED" &&
            overrideSettings.overrideObject.windows && (
              <WindowPicker
                inputId={windowsInputId}
                onAdd={onAddWindow}
                onEdit={onEditWindow}
                onDelete={onDeleteWindow}
                windows={overrideSettings.overrideObject.windows}
              />
            )}
          {overrideSettings.overrideObject.mode === "INDUCTION" &&
            overrideSettings.overrideObject.disableJourney !== undefined && (
              <>
                <div className="mt-4 grid grid-cols-6 gap-4">
                  <Label
                    text={t("Pages.Admin.Common.DoYouWantToDisable")}
                    className="col-span-5"
                  />
                  <div className="col-span-1 text-right">
                    <Switch
                      checked={overrideSettings.overrideObject.disableJourney}
                      onChange={handleDisableJourneyChange}
                    />
                  </div>
                </div>
                {overrideSettings.overrideObject.disableJourney === false &&
                  overrideSettings.overrideObject.inductionDaysTo &&
                  overrideSettings.overrideObject.inductionDaysFrom && (
                    <InductionDateRange
                      inputId={inductionDateRangeInputId}
                      onDaysFromChange={handleInductionDaysFromChange}
                      onDaysToChange={handleInductionDaysToChange}
                      daysFrom={
                        overrideSettings.overrideObject.inductionDaysFrom
                      }
                      daysTo={overrideSettings.overrideObject.inductionDaysTo}
                      nextDate={overrideSettings.nextDate}
                      showValidationErrors={
                        overrideSettings.showValidationErrors
                      }
                      validationResult={
                        overrideSettings.inductionDateRangeValidation
                      }
                    />
                  )}
              </>
            )}
          {overrideSettings.overrideObject.mode === "EXIT" &&
            overrideSettings.overrideObject.disableJourney !== undefined && (
              <div className="mt-4 grid grid-cols-6 gap-4">
                <Label
                  text={t("Pages.Admin.Common.DoYouWantToDisable")}
                  className="col-span-5"
                />
                <div className="col-span-1 text-right">
                  <Switch
                    checked={overrideSettings.overrideObject.disableJourney}
                    onChange={handleDisableJourneyChange}
                  />
                </div>
              </div>
            )}
          <div className="flex flex-row py-3">
            {!isFormSaving && (
              <>
                <div className="grow">
                  <button
                    className="btn-primary"
                    disabled={!overrideSettings.formIsDirty}
                    onClick={onSaveChanges}
                  >
                    {t("Pages.Admin.Buttons.SaveChanges")}
                  </button>
                </div>
                {overrideSettings.formIsDirty &&
                  !overrideSettings.inEditMode && (
                    <div>
                      <button
                        className="btn-secondary"
                        onClick={onResetChanges}
                      >
                        {t("Pages.Admin.Buttons.ResetChanges")}
                      </button>
                    </div>
                  )}
              </>
            )}

            {isFormSaving && (
              <div className="grow">
                <div className="pt-2">
                  <SmallLoader />
                  <p className="text-center pt-1 text-[#959595] text-sm">
                    {t("Common.SavingYourSettings")}...
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default OverridesTab;
