import { useState } from "react";
import { faArrowProgress } from "@fortawesome/pro-regular-svg-icons";
import {
  ConfigurationBlockCard,
  ConfigurationBlockCardEmptyState,
} from "../ConfigurationBlocks";

interface StepsConfigurationBlockProps {
  journeyVersionId: string;
  isInEditMode: boolean;
  onTriggerEditMode: () => void;
}

export const StepsConfigurationBlock = ({
  journeyVersionId,
  isInEditMode,
  onTriggerEditMode,
}: StepsConfigurationBlockProps) => {
  const [stepCount, setStepCount] = useState<number>(0);

  const emptyStateAdditionalContent = isInEditMode ? (
    <p className="text-sm text-gray-400">
      Steps are required - without any steps, nothing can happen
    </p>
  ) : null;

  return (
    <ConfigurationBlockCard
      title="Steps"
      helpTooltip="'Steps' are the things which happen on this journey, e.g. emails, forms, delays. There must be at least one step."
      icon={faArrowProgress}
      isInEditMode={isInEditMode}
    >
      <div className="text-gray-500 italic">
        {stepCount > 0 ? (
          <div>
            <div>TODO: Listing/summary</div>
            <button onClick={onTriggerEditMode}>Edit</button>
          </div>
        ) : (
          <ConfigurationBlockCardEmptyState
            itemName="step"
            icon={faArrowProgress}
            onAddClick={() => {}}
            additionalChildren={emptyStateAdditionalContent}
          />
        )}
      </div>
    </ConfigurationBlockCard>
  );
};
