import { useContext, useEffect, useRef, useState } from "react";
import { ModalPopup, Sidebar } from "../../common";
import { useTranslation } from "react-i18next";
import { ClientTaskTypeCategory } from "../../../types/dtos/tasks/advanced-tasks/ClientTaskTypeCategory";
import { useAuth } from "react-oidc-context";
import advancedTasksApi from "../../../api/task/advancedTasksApi";
import { ClientTaskType } from "../../../types/dtos/tasks/advanced-tasks/ClientTaskType";
import { NewUserTaskDto } from "../../../types/dtos/tasks/advanced-tasks/add-popup/NewUserTaskDto";
import { UserTaskSaveResponse } from "../../../types/dtos/tasks/advanced-tasks/api-response/UserTaskSaveResponse";
import DangerAlert from "../../alerts/DangerAlert";
import taskRestrictionHelper from "../../../helpers/taskRestrictionHelper";
import UserTaskListItemDto from "../../../types/dtos/tasks/advanced-tasks/UserTaskListItemDto";
import AdvancedTaskCommonFieldSection from "./AdvancedTaskCommonFieldSection";
import taskValidationHelper from "../../../helpers/taskValidationHelper";
import SimpleForm from "../../simple-forms/editable/SimpleForm";
import UserContext from "../../../state/UserContext";
import {
  SimpleFormAnswer,
  SimpleFormContent,
  SimpleFormValidationError,
} from "../../../types/dtos/simple-forms";
import { advancedTaskHelper, simpleFormHelper } from "../../../helpers";
import { BaseUserDetailsDto } from "../../../types/dtos/generic";
import { AdvancedTaskMainDetailsValidationResult } from "../../../types/tasks/AdvancedTaskMainDetailsValidationResult";
import AdvancedTaskDto from "../../../types/dtos/forms/AdvancedTaskDto";
import { ValidationResult } from "../../../types/forms";
import LeaveTaskSummaryTable from "./LeaveTaskSummaryTable";
import { TaskEditPopupUsageScenario } from "../../../types/tasks/TaskEditPopupUsageScenario";
import AdvancedToDoListItemDto from "../../../types/dtos/dashboards/right-bar/to-do-list/AdvancedToDoListItemDto";

interface AddAdvancedTaskPopupProps {
  usageScenario: TaskEditPopupUsageScenario;
  taskType: ClientTaskType;
  activeTasks: UserTaskListItemDto[] | AdvancedTaskDto[] | AdvancedToDoListItemDto[];
  maxOverallTaskRestriction: number | null;
  categories: ClientTaskTypeCategory[];
  ownerEmployee: BaseUserDetailsDto;
  isOpen: boolean;
  createdInAnswerSetUniqueId?: string | null;
  formId?: number | null;
  onOpenChange(open: boolean): void;
  onSuccessfulCreation(userTaskId: string | null): void;
}

const AddAdvancedTaskPopup = ({
  usageScenario,
  taskType,
  activeTasks,
  maxOverallTaskRestriction,
  categories,
  ownerEmployee,
  isOpen,
  createdInAnswerSetUniqueId = null,
  formId = null,
  onOpenChange,
  onSuccessfulCreation,
}: AddAdvancedTaskPopupProps) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const userContext = useContext(UserContext);
  const tasksApi = new advancedTasksApi(auth.user?.access_token);
  const bodyRef = useRef<HTMLDivElement>(null);

  const [taskDetails, setTaskDetails] = useState<NewUserTaskDto | null>(null);
  const [bodyFormContent, setBodyFormContent] =
    useState<SimpleFormContent | null>(null);

  const [
    showErrorCreatingTaskNotification,
    setShowErrorCreatingTaskNotification,
  ] = useState<boolean>(false);
  const [
    showRestrictionErrorWhenCreatingTaskNotification,
    setShowRestrictionErrorWhenCreatingTaskNotification,
  ] = useState<boolean>(false);

  const [showValidationErrors, setShowValidationErrors] =
    useState<boolean>(false);
  const [mainDetailsValidationResult, setMainDetailsValidationResult] =
    useState<AdvancedTaskMainDetailsValidationResult>({
      title: null,
      category: null,
      targetDate: null,
    });
  const [bodyFormAnswers, setBodyFormAnswers] = useState<SimpleFormAnswer[]>(
    []
  );
  const [bodyFormValidationErrors, setBodyFormValidationErrors] = useState<
    SimpleFormValidationError[] | null
  >(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const [isUserTryingToLeave, setIsUserTryingToLeave] =
    useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      // When opened we need to reset the state
      setTaskDetails(blankTaskDetails());
      setShowValidationErrors(false);
      setMainDetailsValidationResult({
        category: null,
        targetDate: null,
        title: null,
      });
      setShowErrorCreatingTaskNotification(false);
      setShowRestrictionErrorWhenCreatingTaskNotification(false);
      setBodyFormAnswers([]);
      setBodyFormValidationErrors(null);
      setIsSaving(false);
      setIsFormDirty(false);
      setIsUserTryingToLeave(false);
    }
  }, [isOpen]);

  useEffect(() => {
    // Load the body form for the task type
    tasksApi.getBodyFormForTaskType(
      taskType.id,
      function (data) {
        setBodyFormContent(data);
      },
      function (error) {
        console.error(
          "Unable to load body form for taskType " + taskType.id,
          error
        );
      }
    );
  }, [taskType]);

  const blankTaskDetails = (): NewUserTaskDto => {
    return {
      employeeId: ownerEmployee.userId,
      taskTypeId: taskType.id,
      categoryId: null,
      targetDate: undefined,
      title: "",
      bodyFormResponse: null,
      createdInAnswerSetUniqueId: null,
      answerSetDetails: null
    };
  };

  const isValid = (): boolean => {
    const categoryValidity = taskValidationHelper.isCategoryValid(
      taskDetails?.categoryId,
      categories
    );
    const targetDateValidity = taskValidationHelper.isTargtDateValid(
      taskDetails?.targetDate
    );

    const titleWillBeSetAutomatically =
      bodyFormContent !== null &&
      advancedTaskHelper.getAutomaticTitleSettingQuestion(
        taskType,
        bodyFormContent
      );
    const titleValidationResult = taskValidationHelper.isTitleValid(
      taskDetails?.title
    );
    const titleValidity: ValidationResult = titleWillBeSetAutomatically
      ? { isValid: true, errors: [] }
      : titleValidationResult;

    setMainDetailsValidationResult({
      category: categoryValidity,
      targetDate: targetDateValidity,
      title: titleValidity,
    });

    if (taskDetails && titleWillBeSetAutomatically) {
      // If no title has been set yet, via the automatic title setting question,
      // we have to provide a temporary title for the validation to pass
      // otherwise the API call fails before it validates the body form
      if (!taskDetails.title || !(taskDetails.title.trim().length > 0)) {
        setTaskDetails({ ...taskDetails, title: "(Not set)" });
      }
    }

    let isValid = true;

    if (
      (categories.length > 0 && !categoryValidity.isValid) ||
      !targetDateValidity.isValid ||
      !titleValidity.isValid
    ) {
      isValid = false;
      setShowValidationErrors(true);
      scrollToTopOfPopup();
    } else {
      setShowValidationErrors(false);
    }

    return isValid;
  };

  const onCategoryChange = (value: string) => {
    if (taskDetails != null) {
      setTaskDetails({ ...taskDetails, categoryId: value });
      setIsFormDirty(true);
    }
  };

  const onTitleChange = (value: string) => {
    if (taskDetails != null) {
      setTaskDetails({ ...taskDetails, title: value });
      setIsFormDirty(true);
    }
  };

  const onTargetDateChange = (value: Date) => {
    if (taskDetails != null) {
      setTaskDetails({ ...taskDetails, targetDate: value });
      setIsFormDirty(true);
    }
  };

  const onBodyFormAnswerChange = (newAnswer: SimpleFormAnswer) => {
    const newAnswers = simpleFormHelper.updateAnswerState(
      newAnswer,
      bodyFormAnswers
    );
    setBodyFormAnswers(newAnswers);
    advancedTaskHelper.conditionallyAutomaticallySetTaskTitle(
      taskType,
      bodyFormContent!,
      newAnswers,
      taskDetails?.title,
      onTitleChange
    );
    setIsFormDirty(true);
  };

  const scrollToTopOfPopup = () => {
    if (!bodyRef?.current) return;
    bodyRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const createNewTask = () => {
    setShowRestrictionErrorWhenCreatingTaskNotification(false);
    if (isValid() && taskDetails != null) {
      const canUserCreateNewTask = taskRestrictionHelper.canUserCreateNewTask(
        taskDetails,
        activeTasks,
        maxOverallTaskRestriction,
        categories
      );

      const isTitleSetAutomatically =
        bodyFormContent !== null &&
        advancedTaskHelper.getAutomaticTitleSettingQuestion(
          taskType,
          bodyFormContent
        );

      // Assign the body form answers to the task details
      // so we can send everything we need to the API to save this
      // new UserTask down
      const newTaskDto: NewUserTaskDto = {
        ...taskDetails,
        // If no title has been set yet, via the automatic title setting question,
        // we have to provide a temporary title for the validation to pass
        // otherwise the API call fails before it validates the body form
        title: (taskDetails.title == null || taskDetails.title.length === 0)
          && isTitleSetAutomatically ? "(Not set)" : taskDetails.title,
        bodyFormResponse: {
          answers: bodyFormAnswers,
          formId: bodyFormContent!.formId,
          responseId: null,
        },
        createdInAnswerSetUniqueId: createdInAnswerSetUniqueId,
      };

      if (usageScenario === "JOURNEY-COLLAB-DOC") {
        newTaskDto.answerSetDetails = {
          answerSetGuidId: createdInAnswerSetUniqueId,
          clientFormId: formId,
        };
      }

      if (canUserCreateNewTask) {
        setIsSaving(true);
        tasksApi.createTask(
          newTaskDto,
          function (data: UserTaskSaveResponse) {
            setIsSaving(false);
            setBodyFormValidationErrors(null);
            if (data.savedSuccessfully) {
              onSuccessfulCreation(data.userTaskId);
            } else {
              if (data.validationErrors && data.validationErrors.length > 0) {
                setBodyFormValidationErrors(data.validationErrors);
                // Clear generic error flag if we have specific validation errors
                setShowErrorCreatingTaskNotification(false);
              } else if (
                data.errorReason !== null &&
                (data.errorReason === "OVERALL-MAX-LIMIT-REACHED" ||
                  data.errorReason === "CATEGORY-MAX-LIMIT-REACHED")
              ) {
                setShowRestrictionErrorWhenCreatingTaskNotification(true);
                scrollToTopOfPopup();
              } else {
                setShowErrorCreatingTaskNotification(true);
                scrollToTopOfPopup();
              }
            }
          },
          (error: any) => {
            setShowErrorCreatingTaskNotification(true);
            setIsSaving(false);
            scrollToTopOfPopup();
            console.error("Error creating task", error);
          }
        );
      } else {
        scrollToTopOfPopup();
        setShowRestrictionErrorWhenCreatingTaskNotification(true);
      }
    }
  };

  const checkFormIsDirtyBeforeClosing = (open: boolean) => {
    // If the form is dirty, we need to prompt the user to confirm before closing
    if (isFormDirty) {
      setIsUserTryingToLeave(true);
    } else {
      // Else we can just close the form
      onOpenChange(open);
    }
  };

  const singularTaskTypeName = advancedTaskHelper.ToLowerCase(
    taskType.singularNameTranslationKeyIdentifier
  );

  const modalTitle = !isUserTryingToLeave
    ? t("TaskType.Popup.AddTask.Title", {
      taskType: singularTaskTypeName,
    })
    : t("TaskType.Popup.UserLeavingConfirmation.AreYouSure");

  return (
    <Sidebar
      isOpen={isOpen}
      onOpenChange={() => { }}
      title={modalTitle}
      onAlternativeOpenChange={checkFormIsDirtyBeforeClosing}
      showCloseIcon={!isUserTryingToLeave}
      preventInitialAutoFocus
      width="MEDIUM"
    >
      {!isUserTryingToLeave && (
        <>
          {showErrorCreatingTaskNotification && (
            <DangerAlert
              prefix={t("Common.Validation.Oops")}
              message={t("TaskType.Popup.Validation.ErrorCreatingTask").replace(
                "#TASK_TYPE#",
                singularTaskTypeName
              )}
            />
          )}
          {showRestrictionErrorWhenCreatingTaskNotification && (
            <DangerAlert
              prefix={t("Common.Validation.Oops")}
              message={t(
                "TaskType.Popup.Validation.UnableToCreateDueToRestrictions"
              ).replace("#TASK_TYPE#", singularTaskTypeName)}
            />
          )}
          {taskDetails && (
            <div className="flex flex-col gap-4 py-2">
              <div
                className="overflow-y-auto pr-2"
                ref={bodyRef}
              >
                <AdvancedTaskCommonFieldSection
                  isReadOnly={false}
                  categories={categories}
                  categoryId={taskDetails?.categoryId}
                  setCategoryId={onCategoryChange}
                  targetDate={taskDetails.targetDate}
                  setTargetDate={onTargetDateChange}
                  title={taskDetails.title}
                  setTitle={onTitleChange}
                  showValidationErrors={showValidationErrors}
                  validationResults={mainDetailsValidationResult}
                  setValidationResults={setMainDetailsValidationResult}
                  taskType={taskType}
                  bodyFormContent={bodyFormContent}
                />
                {bodyFormContent && (
                  <SimpleForm
                    answers={bodyFormAnswers}
                    formContent={bodyFormContent}
                    loggedInUser={userContext.user}
                    subjectUser={ownerEmployee}
                    onChange={onBodyFormAnswerChange}
                    requiredBadgeMode="SHOW-OPTIONAL"
                    validationErrors={bodyFormValidationErrors}
                  />
                )}
              </div>
              <div className="flex flex-row justify-end">
                <button
                  className="btn-primary"
                  onClick={createNewTask}
                  disabled={isSaving}
                >
                  <span className="ml-1">
                    {t("TaskType.Button.SaveAndCreate")}
                  </span>
                </button>
              </div>
            </div>
          )}
        </>
      )}
      {isUserTryingToLeave && (
        <>
          <DangerAlert
            prefix=""
            message={t(
              "TaskType.Popup.UserLeavingConfirmation.EverythingWillBeLost"
            )}
          />
          <LeaveTaskSummaryTable
            taskType={taskType}
            categories={categories}
            taskDetails={taskDetails}
            formAnswers={bodyFormAnswers}
            formContent={bodyFormContent}
            ownerEmployee={ownerEmployee}
          />
          <div className="pt-2 pb-2">
            <div className="flex flex-row gap-2 justify-end">
              <button
                className="btn-secondary"
                onClick={() => setIsUserTryingToLeave(false)}
              >
                <span className="ml-1">
                  {t("TaskType.Popup.Buttons.NoAndGoBack")}
                </span>
              </button>

              <button
                className="btn-primary"
                onClick={() => onOpenChange(false)}
              >
                <span className="ml-1">
                  {t("TaskType.Popup.Buttons.YesImSure")}
                </span>
              </button>
            </div>
          </div>
        </>
      )}
    </Sidebar>
  );
};

export default AddAdvancedTaskPopup;
