import { useContext, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentPlus } from "@fortawesome/pro-regular-svg-icons";
import CommentsList from "./CommentsList";
import { NewCommentForm } from ".";
import { UserBasicDetailsDto } from "../../types/dtos/generic";
import { ReactComponent as NoCommentsImage } from "../../images/noComments.svg";
import UserContext from "../../state/UserContext";
import { commentsHelper, userDetailsHelper } from "../../helpers";
import { NewCommentDto, SavedCommentDto } from "../../types/dtos/forms";
import { t } from "i18next";

interface CommentsSideBarProps {
  activeQuestionId: string | null;
  comments: SavedCommentDto[] | null;
  participants: UserBasicDetailsDto[];
  isReadOnly: boolean;
  /** A method to call to mark the comments as seen */
  onCommentsSeen(questionId: string): void;
  /** A method to call to insert a new comment */
  onCommentAdd(
    newComment: NewCommentDto,
    successCallback: () => void,
    errorCallback: () => void
  ): void;
  /** A method to call to delete a comment */
  onCommentDelete(
    commentId: string,
    successCallback: () => void,
    errorCallback: () => void
  ): void;
}

function CommentsSideBar({
  activeQuestionId,
  comments,
  participants,
  isReadOnly,
  onCommentsSeen,
  onCommentAdd,
  onCommentDelete,
}: CommentsSideBarProps) {
  // Context
  const userContext = useContext(UserContext);

  // State
  const [replyToCommentId, setReplyToCommentId] = useState<string | null>(null);
  const [seenCommentsForQuestionIds, setSeenCommentsForQuestionIds] = useState<
    string[]
  >([]);

  // Refs
  const commentFormInputRef = useRef<HTMLTextAreaElement>(null);

  const otherParticipantName = userDetailsHelper.getOtherUsersDisplayName(
    userContext.user.id,
    participants
  );

  // Determine whether the sidebar is visible so we know whether or not to mark the
  // comments loaded in it as "seen" (as this component loads when the active question changes,
  // but is hidden with responsive css. So we can't just mark them as seen, as this will execute,
  // but the user may not have actually seen it)
  const sidebarElement = document.getElementById("comments-sidebar");
  let sidebarIsVisible = false;
  if (sidebarElement) {
    sidebarIsVisible =
      window.getComputedStyle(sidebarElement).display === "block";
  }

  // Events
  useEffect(() => {
    // Ensure `replyToCommentId` gets reset as the activeQuestion changes
    setReplyToCommentId(null);
    // Mark the comments as "seen" (unless on mobile, in which case mark as seen on comments modal load)
    const unseenCommentCount = commentsHelper.getUnseenCommentCount(
      comments,
      userContext.user.id
    );
    if (sidebarIsVisible && unseenCommentCount > 0 && activeQuestionId) {
      onCommentsSeen(activeQuestionId);
    }

    // Set the log of the previous active question, which is used to toggle
    // display of the help text on the comments sidebar
    if (
      activeQuestionId !== null &&
      seenCommentsForQuestionIds.indexOf(activeQuestionId) === -1
    ) {
      setSeenCommentsForQuestionIds([
        ...seenCommentsForQuestionIds,
        activeQuestionId,
      ]);
    }
  }, [activeQuestionId]);

  /** When a user clicks the "Reply" button on another comment */
  const onInitiateReply = (commentId: string) => {
    setReplyToCommentId(commentId);
    // Focus the textarea
    if (commentFormInputRef?.current) {
      commentFormInputRef.current.focus();
    }
  };

  return (
    <div id="comments-sidebar" className="hidden lg:block lg:overflow-auto">
      {!activeQuestionId && (
        <div className="text-center text-gray-400">
          <NoCommentsImage width={"18rem"} className="block m-auto mb-2" />
          <div>{t("Comments.SideBar.NoQuestionsSelected")}</div>
          <div>{t("Comments.SideBar.SelectAQuestion")}</div>
        </div>
      )}
      {activeQuestionId && (
        <>
          <div>
            <h3 className="!mb-0 font-semibold text-slate-600 float-left">
              {t("Comments.SideBar.Title")}
              <FontAwesomeIcon
                icon={faCommentPlus}
                size="1x"
                className="pl-1"
              />
            </h3>
            <div className="clear-both"></div>
          </div>
          <p className="text-sm mt-1 mb-4 text-slate-600">
            {t("Comments.SideBar.Intro")}
          </p>
          <hr className="mb-4" />

          <CommentsList
            isReadOnly={isReadOnly}
            comments={comments}
            participants={participants}
            onDeleteComment={onCommentDelete}
            onTargetCommentForReply={onInitiateReply}
          />
          {!isReadOnly && (
            <NewCommentForm
              questionId={activeQuestionId}
              replyToCommentId={replyToCommentId}
              onSubmit={onCommentAdd}
              inputRef={commentFormInputRef}
            />
          )}
        </>
      )}
    </div>
  );
}

export default CommentsSideBar;
