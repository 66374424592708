import { useContext, useEffect, useState } from "react";
import cx from "classnames";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/pro-solid-svg-icons";
import UserContext from "../../state/UserContext";
import { colourHelper, typeConversionHelper } from "../../helpers";
import { Tooltip } from "../common";
import AppRoutes from "../../routes/AppRoutes";
import UserJourneyDetailDto from "../../types/dtos/shared/UserJourneyDetailDto";

interface JourneySwitcherProps {
  /** The assigned journey id for the logged in user */
  currentJourneyRef: string | undefined;
  /** The other journeys available to be switched onto */
  otherJourneys: UserJourneyDetailDto[];
  /** A function to call when the user switches their journey */
  onJourneyChange: (
    journeyRef: string,
    successCallback: undefined | (() => void),
    errorCallback: undefined | (() => void)
  ) => void;
}

/** A control which allows the user to switch between their available journeys */
const JourneySwitcher = ({
  currentJourneyRef,
  otherJourneys,
  onJourneyChange,
}: JourneySwitcherProps) => {
  // Constants
  const userContext = useContext(UserContext);
  const themeColour = `#${userContext.user.client.primaryHexColour}`;
  const navigate = useNavigate();

  // State
  const [bgColourHex, setBgColourHex] = useState<string | undefined>(undefined);
  const [textColourClassName, setTextColourClassName] =
    useState<string>("text-gray-600");
  const [isChangingJourney, setIsChangingJourney] = useState<boolean>(false);

  // Effects
  useEffect(() => {
    const lightenedThemeColour = colourHelper.shadeColour(themeColour, 10);
    const contrastOkForWhite = colourHelper.doesColourPassContrastCheck(
      "#FFFFFF",
      lightenedThemeColour
    );
    setBgColourHex(lightenedThemeColour);
    setTextColourClassName(contrastOkForWhite ? "text-white" : "text-gray-600");
  }, [userContext.user.client.primaryHexColour]);

  // Events
  const handleJourneyChange = (journey: UserJourneyDetailDto) => {
    if (journey.journeyReference === currentJourneyRef) return;

    // Check whether this has a collab doc we can redirect to,
    // or if it's a normal journey change
    const collabDocGuid: string | undefined =
      getRedirectUrlForJourneyItem(journey);

    if (collabDocGuid) {
      // Redirect to the collaborative document
      navigate(
        AppRoutes.collaborativeDocument.generateRoute(
          collabDocGuid,
          false,
          null
        )
      );
    } else {
      // Change the journey
      setIsChangingJourney(true);

      const successCallback = () => {
        setIsChangingJourney(false);
      };

      const errorCallback = () => {
        setIsChangingJourney(false);
      };

      onJourneyChange(journey.journeyReference, successCallback, errorCallback);
    }
  };

  /** Check whether this has a collab doc we can redirect to, or if it's a normal journey change return `undefined` */
  function getRedirectUrlForJourneyItem(journey: UserJourneyDetailDto) {
    if (journey?.answerSets && journey.answerSets.length > 0) {
      if (journey.isDualPrepJourney) {
        // For dual prep, see if there's a meeting answerset first, if so, redirect to that
        const meetingAnswerSet = journey.answerSets.find(
          (x) => x.isDualPrepMeetingDocAnswerSet
        );
        if (meetingAnswerSet) {
          return meetingAnswerSet.guidId;
        } else {
          // If no meeting AnswerSet for dual prep, check whether emp planning is still in progress
          const empPlanningAnswerSet = journey.answerSets.find(
            (x) => x.isDualPrepSubjectAnswerSet
          );
          if (
            empPlanningAnswerSet &&
            empPlanningAnswerSet.statusEnum !== null &&
            empPlanningAnswerSet.statusEnum !== "PARTIALLY-SAVED-JOURNEY"
          ) {
            return empPlanningAnswerSet.guidId;
          }
        }
      } else {
        // Not dual prep
        if (journey.answerSets[0].statusEnum !== "PARTIALLY-SAVED-JOURNEY") {
          return journey.answerSets[0].guidId;
        }
      }
    }

    return undefined;
  }

  if (
    !otherJourneys ||
    otherJourneys.length === 0 ||
    // Check whether the only journey is the one the user is currently on
    (otherJourneys.length === 1 &&
      otherJourneys[0].journeyReference === currentJourneyRef) ||
    bgColourHex === undefined
  ) {
    // Return an empty state with the correct spacing between the top title bar and the journey widget if we're not displaying anything
    return <div className="pt-2 md:pt-4 lg:pt-6"></div>;
  }

  const activeJourney = otherJourneys.find(
    (j) => j.journeyReference === currentJourneyRef
  );

  return (
    <div className="mt-0 md:mt-2 mb-4 border-b border-slate-200 px-2 py-4">
      <div className="mb-2">
        <div className="flex flex-row items-center">
          <span className="font-semibold text-lg leading-tight flex-grow">
            Select a journey
          </span>
          <Link
            className="flex-initial text-sm hover:underline"
            to={AppRoutes.yourJourney.forms}
          >
            View all journeys
            <FontAwesomeIcon
              icon={faArrowRightLong}
              size="xs"
              className="ml-1"
            />
          </Link>
        </div>
        <p className="mt-2 mb-4 text-gray-600">
          You currently have{" "}
          <span>
            {typeConversionHelper.numberToWordsString(otherJourneys.length)}
            {` journey${otherJourneys.length === 1 ? "" : "s"}`}
          </span>{" "}
          to complete
        </p>
      </div>

      <div
        className={cx(
          "flex flex-row flex-wrap gap-2 text-sm font-medium",
          isChangingJourney ? "hover:!cursor-wait opacity-80 animate-pulse" : ""
        )}
      >
        {activeJourney ? (
          <Tooltip
            triggerElement={
              <span
                className={cx(
                  "px-4 py-2 font-semibold border border-gray-400 hover:cursor-default bg-gray-200  text-gray-600 p-2 rounded-md"
                )}
              >
                {activeJourney.title}
              </span>
            }
            content="You are currently on this journey"
          />
        ) : null}

        {otherJourneys.map((journey) => {
          const isActiveJourney =
            journey.journeyReference === currentJourneyRef;

          if (isActiveJourney) {
            // Don't render the active journey as a button
            return null;
          }

          return (
            <Tooltip
              key={journey.journeyReference}
              triggerElement={
                <button
                  className={cx(
                    "px-4 py-2 rounded-md border text-gray-600",
                    "hover:cursor-pointer disabled:cursor-not-allowed",
                    isChangingJourney
                      ? "bg-gray-400 border-gray-400 text-white animate-pulse"
                      : "bg-gray-200/60 border-gray-200/60 hover:text-gray-800 hover:opacity-100"
                  )}
                  onClick={() => handleJourneyChange(journey)}
                  disabled={isChangingJourney}
                >
                  <span>{journey.title}</span>
                </button>
              }
              content={
                journey.hasNotBeenStarted
                  ? "Switch to this journey"
                  : "Continue this journey"
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default JourneySwitcher;
