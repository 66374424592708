import ToDoListTaskTypeDto from "../../../../types/dtos/dashboards/right-bar/to-do-list/ToDoListTaskTypeDto";
import { useEffect, useState } from "react";
import StandardToDoListItemDto from "../../../../types/dtos/dashboards/right-bar/to-do-list/StandardToDoListItemDto";
import { EditableTask } from "../../../../types/tasks/EditableTasks";
import ManageTaskPopup, { TaskPopupMode } from "../../../tasks/ManageTaskPopup";
import ModifyTaskResponseDto from "../../../../types/dtos/tasks/ModifyTaskResponseDto";
import myDashboardApi from "../../../../api/dashboard/myDashboardApi";
import { useAuth } from "react-oidc-context";
import ToDoListEmptyState from "../shared/ToDoListEmptyState";
import { advancedTaskHelper, taskTypeHelper } from "../../../../helpers";
import ToDoListDisplay from "../shared/ToDoListDisplay";
import { faChevronDown, faChevronUp, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { Switch } from "../../../common";
import { ModifyStatus } from "../../../../types/generic";
import { LoadingPlaceholderItem } from "../shared/LoadingPlaceholderItem";

interface StandardToDoListProps {
  initialToDoItems: StandardToDoListItemDto[];
  toDoListTaskTypes: ToDoListTaskTypeDto[];
  selectedTaskTypeId: number | undefined;
  updateTaskTypeCount(taskTypeId: string | number, count: number): void;
  modifyTaskFromDashboard(task: any, onSuccess: (taskData: ModifyTaskResponseDto<number>) => void): void;
  onLoadTaskToEdit(taskId: number, taskType: string, successCallback: (responseData: any) => void): void;
}

function StandardToDoList({
  initialToDoItems,
  toDoListTaskTypes,
  selectedTaskTypeId,
  updateTaskTypeCount,
  modifyTaskFromDashboard,
  onLoadTaskToEdit,
}: StandardToDoListProps) {
  const auth = useAuth();
  const dashboardApi = new myDashboardApi(auth.user?.access_token);
  const maxItemsToDisplay = 4;

  // Flag used to avoid hitting the API on the first selectedTaskTypeId change to load data as 
  // the initial data comes from the dashboard load API
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tasks, setTasks] = useState<StandardToDoListItemDto[]>([]);
  const [displayTasks, setDisplayTasks] = useState<StandardToDoListItemDto[]>([]);
  const [selectedTaskType, setSelectedTaskType] = useState<ToDoListTaskTypeDto | undefined>(undefined);
  const [taskToEdit, setTaskToEdit] = useState<EditableTask<number> | null>(null);
  const [isReadOnlyPopup, setIsReadOnlyPopup] = useState<boolean>(false);
  const [taskLockedByJourney, setTaskLockedByJourney] = useState<string | null>(null);
  const [popupMode, setPopupMode] = useState<TaskPopupMode>("ADD");
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [viewAll, setViewAllClicked] = useState<boolean>(false);
  const [includeCompleted, setIncludeCompleted] = useState<boolean>(false);

  useEffect(() => {
    if (selectedTaskTypeId != undefined) {
      // If the selected task type is undefined or the selected task type id is different to the selected task type id
      if (selectedTaskType == undefined || selectedTaskType.standardTaskTypeId !== selectedTaskTypeId) {
        setSelectedTaskType(toDoListTaskTypes.find((x) => x.standardTaskTypeId === selectedTaskTypeId));

        if (isInitialLoad) {
          setIsInitialLoad(false);
          setTasks(initialToDoItems);
          setDisplayTasks(initialToDoItems.filter((x) => !x.isCompleted).slice(0, maxItemsToDisplay));
        } else {
          setIsLoading(true);
          dashboardApi.getStandardToDoListItems(
            selectedTaskTypeId,
            (data: StandardToDoListItemDto[]) => {
              setIsLoading(false);
              setViewAllClicked(false);
              setIncludeCompleted(false);
              setTasks(data);
              setDisplayTasks(data.filter((x) => !x.isCompleted).slice(0, maxItemsToDisplay));
            },
            (error: any) => {
              console.error(error.message);
              setIsLoading(false);
            }
          );
        }        
      }
    }
  }, [selectedTaskTypeId]);

  const onModalOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      setTaskToEdit(null);
      setIsReadOnlyPopup(false);
    }
    setModalIsOpen(isOpen);
  };

  // When the user clicks to add a new task
  const onAddTaskPopup = () => {
    setPopupMode("ADD");
    setModalIsOpen(true);
  };

  // When the user clicks to edit a task
  const onEditTaskPopup = (taskId: number) => {
    if (selectedTaskType != undefined) {
      const successCallback = (responseData: ModifyTaskResponseDto<number>) => {
        setPopupMode("EDIT");
        setModalIsOpen(true);
        setTaskToEdit(responseData.task);
        setTaskLockedByJourney(responseData.editLockedByJourney);

        if (
          responseData.task &&
          (responseData.task.status === "COMPLETED" ||
            responseData.task.status === "CANCELLED" ||
            responseData.editLockedByJourney)
        ) {
          setIsReadOnlyPopup(true);
        } else {
          setIsReadOnlyPopup(false);
        }
      };
      onLoadTaskToEdit(taskId, selectedTaskType.standardTaskType, successCallback);
    }
  };

  const handleTaskSave = (task: EditableTask<number>) => {
    modifyTaskFromDashboard(task, (returnedTask) => {
      // TaskId should come from the returnedTask, modifyStatus should come from the task
      if (returnedTask.task.taskId != null) {
        refreshTaskData(returnedTask.task.taskId, task.modifyStatus);
      }
    });
  };

  const refreshTaskData = (taskId: number, taskModifyStatus: ModifyStatus) => {
    if (selectedTaskTypeId != undefined) {
      dashboardApi.getStandardToDoListItems(
        selectedTaskTypeId,
        (data: StandardToDoListItemDto[]) => {
          data = updateDataWithRelevantFlags(taskId, taskModifyStatus, data);
          setIsLoading(false);
          setTasks(data);
          updateDisplayTasks(viewAll, includeCompleted, data);
          updateTaskTypeCount(selectedTaskTypeId, data.filter((x) => !x.isCompleted).length);
        },
        (error: any) => {
          console.error(error.message);
          setIsLoading(false);
        }
      );
    }
  }

  const updateDataWithRelevantFlags = (taskId: number, taskModifyStatus: ModifyStatus, data: StandardToDoListItemDto[]) => {
    let hasBeenAddedFlagSet = false;
    let hasUpdatedFlagBeenSet = false;

    // console.log("updateDataWithRelevantFlags > task", task);
    // console.log("is true", task.modifyStatus === "NEW");
    // console.log("data", data);

    // If the modifyStatus is 'NEW' then the task has been added and we need to set the flag
    if (taskModifyStatus === "NEW") {
      hasBeenAddedFlagSet = true;
      data = data.map((x) => {
        if (x.id === taskId) {
          x.wasAdded = true;
        }
        return x;
      });
    }

    // If the modifyStatus is anything but 'NEW' then the task has been updated and we need to set the flag
    if (taskModifyStatus !== "NEW") {
      hasUpdatedFlagBeenSet = true;
      data = data.map((x) => {
        if (x.id === taskId) {
          x.wasUpdated = true;
        }
        return x;
      });
    }

    // If either flag was set then create a timeout to revert the flag
    if (hasBeenAddedFlagSet || hasUpdatedFlagBeenSet) {
      setTimeout(() => {
        data = data.map((x) => {
          if (x.id === taskId) {
            x.wasAdded = false;
            x.wasUpdated = false;
          }
          return x;
        });
        updateDisplayTasks(viewAll, includeCompleted, data);
      }, 2000);
    }
    return data;
  }

  const manageTaskPopupRefresh = () => {
    if (taskToEdit != undefined && taskToEdit.taskId != null) {
      refreshTaskData(taskToEdit.taskId, taskToEdit.modifyStatus);
    }
  }

  const handleViewAllClick = (isToggled: boolean) => {
    if (!isToggled) {
      setViewAllClicked(isToggled);
      setIncludeCompleted(false);
      updateDisplayTasks(isToggled, false, tasks);
    } else {
      setViewAllClicked(isToggled);
      updateDisplayTasks(isToggled, includeCompleted, tasks);
    }
  }

  const handleSwitchToggle = (isChecked: boolean) => {
    setIncludeCompleted(isChecked);
    updateDisplayTasks(viewAll, isChecked, tasks);
  }

  const updateDisplayTasks = (viewAll: boolean, includeCompleted: boolean, items: StandardToDoListItemDto[]) => {
    let itemsForDisplay: StandardToDoListItemDto[] = includeCompleted ? items : items.filter((x) => !x.isCompleted);
    if (!viewAll) {
      itemsForDisplay = itemsForDisplay.slice(0, maxItemsToDisplay);
    }
    setDisplayTasks(itemsForDisplay);
  }

  return (
    <>
      <div className="flex flex-col h-full">
        {isLoading && (
          <>
            <LoadingPlaceholderItem />
            <LoadingPlaceholderItem />
            <LoadingPlaceholderItem />
            <LoadingPlaceholderItem />
          </>
        )}

        {!isLoading && selectedTaskType && (
          <>
            {viewAll && (
              <div className="mb-1 text-right pr-1">
                <span className="text-xs pr-1 text-gray-500 font-medium">
                  {t("Common.IncludeCompleted")}?
                </span>
                <Switch
                  checked={includeCompleted}
                  onChange={handleSwitchToggle}
                  size="sm"
                />
              </div>
            )}
            {displayTasks.length === 0 && (
              <ToDoListEmptyState
                iconType={taskTypeHelper.getTaskTypeIcon(selectedTaskType.standardTaskType)}
                pluralNameTranslationKeyIdentifier={selectedTaskType.pluralTranslationKeyIdentifier}
              />
            )}
            {displayTasks.length > 0 && (
              <ToDoListDisplay
                items={displayTasks}
                iconType={taskTypeHelper.getTaskTypeIcon(selectedTaskType.standardTaskType)}
                singularNameTranslationKeyIdentifier={selectedTaskType.singularTranslationKeyIdentifier}
                onItemClick={onEditTaskPopup}
              />
            )}
            {selectedTaskType && (
              <button
                className="btn-primary w-full !mt-2 !px-3 !py-2 disabled:cursor-not-allowed"
                onClick={onAddTaskPopup}
              >
                <FontAwesomeIcon icon={faPlus} />
                <span className="ml-2">
                  {t("TaskType.Button.AddNewX").replace(
                    "#TASK_TYPE#",
                    advancedTaskHelper.ToLowerCase(
                      selectedTaskType.singularTranslationKeyIdentifier
                    )
                  )}
                </span>
              </button>
            )}

            {/* If there are more tasks then we can display, or there are completed tasks */}
            {(tasks.length > maxItemsToDisplay || tasks.some((x) => x.isCompleted)) && (
              <div className="text-sm pt-1 cursor-pointer text-right">

                {/* Show less tasks */}
                {viewAll && (
                  <button onClick={() => handleViewAllClick(false)} className="hover:underline">
                    <span>{t("Common.ShowLessTasks", {
                      tasks: t(selectedTaskType.pluralTranslationKeyIdentifier)
                    })}</span>
                    <FontAwesomeIcon icon={faChevronUp} className="ml-1" />
                  </button>
                )}
                {/* View all tasks */}
                {!viewAll && (
                  <button onClick={() => handleViewAllClick(true)} className="hover:underline">
                    <span>{t("Common.ViewAllTasks", {
                      tasks: t(selectedTaskType.pluralTranslationKeyIdentifier)
                    })}</span>
                    <FontAwesomeIcon icon={faChevronDown} className="ml-1" />
                  </button>
                )}
              </div>
            )}
          </>
        )}
      </div>
      {selectedTaskType && (
        <ManageTaskPopup
          mode={popupMode}
          isOpen={modalIsOpen}
          onOpenChange={onModalOpenChange}
          onSaveClick={handleTaskSave}
          taskType={selectedTaskType.standardTaskType}
          editObject={taskToEdit}
          popupOrigin="DASHBOARD"
          refreshParentTaskList={manageTaskPopupRefresh}
          isReadOnly={isReadOnlyPopup}
          setIsReadOnly={setIsReadOnlyPopup}
          taskLockedByJourney={taskLockedByJourney}
        />
      )}
    </>
  );
}

export default StandardToDoList;
