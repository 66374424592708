import {
  NewBehaviourAnswerDto,
  NewGoalReviewAnswerDto,
  NewMultiChoiceAnswerDto,
  NewTextAnswerDto,
} from "../../types/dtos/collab-docs/answers";
import NewDevelopmentAnswerDto from "../../types/dtos/collab-docs/answers/NewDevelopmentAnswerDto";
import { NewCommentDto } from "../../types/dtos/forms";
import {
  JourneyAnswerSubmissionDto,
  JourneyObjectSubmissionDto,
  JourneySavedAnswerDto,
  JourneySavedObjectDto,
} from "../../types/dtos/journeys/answers";
import { QuestionTasks } from "../../types/forms";
import AnswerSetDto from "../../types/forms/AnswerSetDto";
import {
  JourneySubmission,
  JourneySubmissionResponse,
} from "../../types/journeys";
import { apiClient } from "../apiClient";

class journeyApi {
  constructor(authToken: string | undefined) {
    this.accessToken = authToken;
  }

  accessToken: string | undefined;

  /** Save a new comment against an AnswerSet */
  submitComment(
    /** The data being submitted to the server */
    comment: JourneyObjectSubmissionDto<NewCommentDto>,
    onSuccess: (saveResponse: JourneySavedObjectDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/journey/change-question-comment",
      {
        body: JSON.stringify(comment),
      }
    ).then(
      (data) => {
        onSuccess(data as JourneySavedObjectDto);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Save a new enforced comment (e.g. for behaviours) against an AnswerSet */
  submitEnforcedComment(
    /** The data being submitted to the server */
    comment: JourneyObjectSubmissionDto<NewCommentDto>,
    onSuccess: (saveResponse: JourneySavedObjectDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/journey/change-question-enforced-comment",
      {
        body: JSON.stringify(comment),
      }
    ).then(
      (data) => {
        onSuccess(data as JourneySavedObjectDto);
      },
      (error) => {
        onError(error);
      }
    );
  }

  saveTextAnswer(
    answerDto: JourneyAnswerSubmissionDto<NewTextAnswerDto>,
    onSuccess: (saveResponse: JourneySavedAnswerDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(this.accessToken, "dashboards/journey/save-text-answer", {
      body: JSON.stringify(answerDto),
    }).then(
      (data) => {
        onSuccess(data as JourneySavedAnswerDto);
      },
      (error) => {
        onError(error);
      }
    );
  }

  saveMultiChoiceAnswer(
    answerDto: JourneyAnswerSubmissionDto<NewMultiChoiceAnswerDto>,
    onSuccess: (saveResponse: JourneySavedAnswerDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/journey/save-multi-choice-answer",
      {
        body: JSON.stringify(answerDto),
      }
    ).then(
      (data) => {
        onSuccess(data as JourneySavedAnswerDto);
      },
      (error) => {
        onError(error);
      }
    );
  }

  saveBehaviourAnswer(
    answerDto: JourneyAnswerSubmissionDto<NewBehaviourAnswerDto>,
    onSuccess: (saveResponse: JourneySavedAnswerDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/journey/save-behaviour-answer",
      {
        body: JSON.stringify(answerDto),
      }
    ).then(
      (data) => {
        onSuccess(data as JourneySavedAnswerDto);
      },
      (error) => {
        onError(error);
      }
    );
  }

  saveGoalReviewAnswer(
    answerDto: JourneyAnswerSubmissionDto<NewGoalReviewAnswerDto>,
    onSuccess: (saveResponse: JourneySavedAnswerDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/journey/save-goal-review-answer",
      {
        body: JSON.stringify(answerDto),
      }
    ).then(
      (data) => {
        onSuccess(data as JourneySavedAnswerDto);
      },
      (error) => {
        onError(error);
      }
    );
  }

  saveDevelopmentAnswer(
    answerDto: JourneyAnswerSubmissionDto<NewDevelopmentAnswerDto>,
    onSuccess: (saveResponse: JourneySavedAnswerDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/journey/save-development-answer",
      {
        body: JSON.stringify(answerDto),
      }
    ).then(
      (data) => {
        onSuccess(data as JourneySavedAnswerDto);
      },
      (error) => {
        onError(error);
      }
    );
  }

  saveQuestionTasks(
    questionTasks: JourneyObjectSubmissionDto<QuestionTasks>,
    onSuccess: (data: JourneySavedObjectDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(this.accessToken, "dashboards/journey/save-tasks", {
      body: JSON.stringify(questionTasks),
    }).then(
      (data) => {
        onSuccess(data as JourneySavedObjectDto);
      },
      (error) => {
        onError(error);
      }
    );
  }

  getOrCreateAnswerSet(
    questionTasks: JourneyObjectSubmissionDto<AnswerSetDto>,
    onSuccess: (data: JourneySavedObjectDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(this.accessToken, "dashboards/journey/get-or-create-answer-set", {
      body: JSON.stringify(questionTasks),
    }).then(
      (data) => {
        onSuccess(data as JourneySavedObjectDto);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Save a journey response to the database via the API */
  submitResponse(
    /** The data being submitted to the server */
    model: JourneySubmission,
    onSuccess: (data: JourneySubmissionResponse) => void,
    onError: (error: any) => void
  ) {
    return apiClient(this.accessToken, "dashboards/journey/submit-journey", {
      body: JSON.stringify(model),
    }).then(
      (data) => {
        const responseData = data as JourneySubmissionResponse;
        onSuccess(responseData);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Extend the expiration date for a partially completed journey */
  snoozeExpiration(
    answerSetUniqueId: string,
    onSuccess: (newExpiryDate: Date) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      `dashboards/journey/snooze-expiration?answerSetUniqueId=${answerSetUniqueId}`
    ).then(
      (newExpirationDate: Date) => {
        onSuccess(newExpirationDate);
      },
      (error) => {
        onError(error);
      }
    );
  }
}

export default journeyApi;
