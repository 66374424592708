import { Routes, Route } from "react-router-dom";
import AppRoutes from "../AppRoutes";
import IndividualTaskManagement from "./IndividualTaskManagement";
import YourJourneyForms from "./YourJourneyForms";
import YourJourneyCatchUps from "./YourJourneyCatchUps";

function YourJourneyRoot() {
  return (
    <>
      <Routes>
        <Route
          path={AppRoutes.yourJourney.sub.forms.path}
          element={<YourJourneyForms />}
        />
        <Route
          path={AppRoutes.yourJourney.sub.catchUps.path}
          element={<YourJourneyCatchUps />}
        />
        <Route
          path={AppRoutes.yourJourney.sub.tasks.path}
          element={<IndividualTaskManagement />}
        />
        <Route
          path={AppRoutes.yourJourney.sub.tasks.pathWithTaskId}
          element={<IndividualTaskManagement />}
        />
      </Routes>
    </>
  );
}

export default YourJourneyRoot;
