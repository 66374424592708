import { Routes, Route } from "react-router-dom";
import AppRoutes from "../AppRoutes";
import TeamTaskManagement from "./TeamTaskManagement";
import YourPeopleForm from "./YourPeopleForm";

function YourPeopleRoot() {
  return (
    <>
      <Routes>
        <Route
          path={AppRoutes.yourPeople.sub.forms.path}
          element={<YourPeopleForm />}
        />
        <Route
          path={AppRoutes.yourPeople.sub.forms.pathWithPreselectedEmployeeId}
          element={<YourPeopleForm />}
        />
        <Route
          path={AppRoutes.yourPeople.sub.tasks.path}
          element={<TeamTaskManagement />}
        />
        <Route
          path={AppRoutes.yourPeople.sub.tasks.pathWithPreselectedEmployeeId}
          element={<TeamTaskManagement />}
        />
      </Routes>
    </>
  );
}

export default YourPeopleRoot;
