import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import AdvancedToDoListItemDto from "../../../../types/dtos/dashboards/right-bar/to-do-list/AdvancedToDoListItemDto";
import StandardToDoListItemDto from "../../../../types/dtos/dashboards/right-bar/to-do-list/StandardToDoListItemDto";
import ToDoListItem from "./ToDoListItem";

interface ToDoListDisplayProps {
  items: AdvancedToDoListItemDto[] | StandardToDoListItemDto[];
  iconType: IconDefinition;
  singularNameTranslationKeyIdentifier: string;
  onItemClick(id: string | number): void;
}

function ToDoListDisplay({
  items,
  iconType,
  singularNameTranslationKeyIdentifier,
  onItemClick,
}: ToDoListDisplayProps) {
  return (
    <div id="advanced-to-do-list-display">
      {items.map((item, index) => (
        <div
          className="snap-start my-2 first-of-type:mt-0 last-of-type:mb-0"
          key={`tr-advanced-to-do-list-item-${index}`}
        >
          <ToDoListItem
            id={item.id}
            title={item.title}
            dueDate={item.dueDate}
            isOverdue={item.isOverdue}
            isCompleted={item.isCompleted}
            singularNameTranslationKeyIdentifier={singularNameTranslationKeyIdentifier}
            iconType={iconType}
            wasAdded={item.wasAdded}
            wasUpdated={item.wasUpdated}
            onItemClick={onItemClick}
          />
        </div>
      ))}
    </div>
  );
}

export default ToDoListDisplay;
