const AppRoutes = {
  admin: {
    root: "/admin",
    /** "ClientJourneys" can be considered old/v1, before the admin UI was introduced for editing journey flows in 2024  */
    clientJourneys: {
      path: "/admin/client-journeys",
      pathOpenWithConfigTabSelected: "/admin/client-journeys/configuration",
      pathRedirectFromSendAfterSuccess:
        "/admin/client-journeys/configuration/send-complete",
      sendingHistory: "/admin/client-journeys/sending-history",
      config: {
        path: "/admin/client-journeys/config/:clientFormId",
        generateRoute: (clientFormId: string): string => {
          return AppRoutes.admin.clientJourneys.config.path.replace(
            ":clientFormId",
            clientFormId
          );
        },
      },
      send: {
        path: "/admin/client-journeys/send/:clientFormId",
        generateRoute: (clientFormId: string): string => {
          return AppRoutes.admin.clientJourneys.send.path.replace(
            ":clientFormId",
            clientFormId
          );
        },
      },
      userDetail: {
        path: "/admin/client-journeys/user/:userId",
        generateRoute: (userId: string): string => {
          return AppRoutes.admin.clientJourneys.userDetail.path.replace(
            ":userId",
            userId
          );
        },
        adminHistory: "/admin/client-journeys/user/:userId/admin-history",
        generateAdminHistoryRoute: (userId: string): string => {
          return AppRoutes.admin.clientJourneys.userDetail.adminHistory.replace(
            ":userId",
            userId
          );
        },
      },
    },
    journeys: {
      root: "/admin/journeys",
      category: {
        path: "/admin/journeys/:categoryId",
        generateRoute: (categoryId: string): string => {
          return AppRoutes.admin.journeys.category.path.replace(
            ":categoryId",
            categoryId
          );
        },
      },
      journey: {
        path: "/admin/journeys/:categoryId/:journeyId",
        generateRoute: (categoryId: string, journeyId: string): string => {
          return AppRoutes.admin.journeys.journey.path
            .replace(":categoryId", categoryId)
            .replace(":journeyId", journeyId);
        },
      },
    },
    sub: {
      clientJourneys: {
        path: "client-journeys",
        pathOpenWithConfigTabSelected: "client-journeys/configuration",
        pathRedirectFromSendNowAfterSuccess:
          "client-journeys/configuration/send-complete",
        sendingHistory: "client-journeys/sending-history",
        send: {
          path: "/client-journeys/send/:clientFormId",
          generateRoute: (clientFormId: string): string => {
            return AppRoutes.admin.sub.clientJourneys.send.path.replace(
              ":clientFormId",
              clientFormId
            );
          },
        },
        config: {
          path: "/client-journeys/config/:clientFormId",
          generateRoute: (clientFormId: string): string => {
            return AppRoutes.admin.clientJourneys.config.path.replace(
              ":clientFormId",
              clientFormId
            );
          },
        },
        userDetail: {
          path: "/client-journeys/user/:userId",
          generateRoute: (userId: string): string => {
            return AppRoutes.admin.clientJourneys.userDetail.path.replace(
              ":userId",
              userId
            );
          },
          adminHistory: "/client-journeys/user/:userId/admin-history",
          generateAdminHistoryRoute: (userId: string): string => {
            return AppRoutes.admin.clientJourneys.userDetail.adminHistory.replace(
              ":userId",
              userId
            );
          },
        },
      },
      journeys: {
        root: "journeys",
        category: "journeys/:categoryId",
        journey: "journeys/:categoryId/:journeyId",
      },
    },
    userManagement: "/og-app/admin",
    classicUserDetailAdmin: {
      path: "/og-app/admin/:userId",
      generateRoute: (userId: string): string => {
        return AppRoutes.admin.classicUserDetailAdmin.path.replace(
          ":userId",
          userId
        );
      },
    },
  },
  analytics: {
    root: "/analytics",
    classic: "/og-app/analytics",
    usage: "/analytics/usage",
    dynamicPage: {
      path: "/analytics/page/:pageId",
      generateRoute: (pageId: string): string => {
        return AppRoutes.analytics.dynamicPage.path.replace(":pageId", pageId);
      },
    },
    exports: "/analytics/exports",
    sub: {
      classic: "classic",
      dynamicPage: {
        path: "page/:pageId",
        generateRoute: (pageId: string): string => {
          return AppRoutes.analytics.sub.dynamicPage.path.replace(
            ":pageId",
            pageId
          );
        },
      },
      usage: "usage",
      exports: "exports",
    },
  },
  companyDashboard: "/og-app/dashboard",
  demo: {
    managerReset: "/demo/manager-reset",
  },
  reviewHistory: {
    path: "/og-app/review-history",
    search: "?displayType=1",
  },
  legacyTaskList: {
    path: "/og-app/tasks",
    search: "?displayType=1",
  },
  collaborativeDocument: {
    standardPath: "/collab-doc/:answerSetUniqueId",
    specificFormPath: "/collab-doc/:answerSetUniqueId/:singleFormId",
    viewCollabDoc: "/view-collab-doc/:answerSetUniqueId",
    generateRoute: (
      answerSetUniqueId: string,
      showPostJourneyModal: boolean,
      specificClientFormId: number | null
    ): string => {
      const hasClientFormId = specificClientFormId && specificClientFormId > 0;
      const path = hasClientFormId
        ? AppRoutes.collaborativeDocument.specificFormPath
        : AppRoutes.collaborativeDocument.standardPath;

      let output = path.replace(":answerSetUniqueId", answerSetUniqueId);

      if (hasClientFormId) {
        output = output.replace(
          ":singleFormId",
          specificClientFormId.toString()
        );
      }

      if (showPostJourneyModal) {
        output += "?pj=1";
      }

      return output;
    },
  },
  yourJourney: {
    root: "/me",
    forms: "/me/forms",
    catchUps: "/me/catch-ups",
    tasks: {
      path: "/me/tasks/:taskTypeId",
      generatePathRoute: (taskTypeId: string): string => {
        return AppRoutes.yourJourney.tasks.path.replace(
          ":taskTypeId",
          taskTypeId
        );
      },
      pathWithTaskId: "/me/tasks/:taskTypeId/:taskId",
      generatePathWithTaskIdRoute: (
        taskTypeId: string,
        taskId: string
      ): string => {
        return AppRoutes.yourJourney.tasks.pathWithTaskId
          .replace(":taskTypeId", taskTypeId)
          .replace(":taskId", taskId);
      },
    },
    sub: {
      catchUps: {
        path: "/catch-ups",
      },
      forms: {
        path: "/forms",
      },
      tasks: {
        path: "/tasks/:taskTypeId",
        pathWithTaskId: "/tasks/:taskTypeId/:taskId",
      },
    },
  },
  yourPeople: {
    root: "/team",
    forms: {
      path: "/team/forms",
      pathWithPreselectedEmployeeId:
        "/team/forms/:preselectedEmployeeId",
      generatePathWithPreselectedEmployeeIdRoute: (
        preselectedEmployeeId: number
      ): string => {
        return AppRoutes.yourPeople.forms.pathWithPreselectedEmployeeId
          .replace(":preselectedEmployeeId", preselectedEmployeeId.toString());
      },
    },
    tasks: {
      path: "/team/tasks/:taskTypeId",
      generateRoute: (taskTypeId: string): string => {
        return AppRoutes.yourPeople.tasks.path.replace(
          ":taskTypeId",
          taskTypeId
        );
      },
      pathWithPreselectedEmployeeId:
        "/team/tasks/:taskTypeId/:preselectedEmployeeId",
      generatePathWithPreselectedEmployeeIdRoute: (
        taskTypeId: string,
        preselectedEmployeeId: number
      ): string => {
        return AppRoutes.yourPeople.tasks.pathWithPreselectedEmployeeId
          .replace(":taskTypeId", taskTypeId)
          .replace(":preselectedEmployeeId", preselectedEmployeeId.toString());
      },
    },
    sub: {
      tasks: {
        path: "/tasks/:taskTypeId",
        pathWithPreselectedEmployeeId:
          "/tasks/:taskTypeId/:preselectedEmployeeId",
      },
      forms: {
        path: "/forms",        
        pathWithPreselectedEmployeeId:
          "/forms/:preselectedEmployeeId",
      },
    },
  },
  componentsDemo: "/components-demo",
  help: "https://help.talenttoolbox.com/",
  home: "/",
  auth: {
    callback: "/auth/callback",
    ssoCallback: "/auth/sso-callback",
    logout: "/auth/logout",
    remoteLogoutCallback: "/auth/session-ended",
    postLogoutRedirectUri: function (
      mvcAppRootUrl: string,
      triggeredByRemoteApp: boolean,
      clientId: number | undefined
    ) {
      let redirectUrl: string;
      if (triggeredByRemoteApp) {
        // Logout process has already been done in MVC TT, and now in the React app too, so pass back to TT to redirect appropriately
        redirectUrl = `${mvcAppRootUrl}${this.remoteLogoutCallback}`;

        // If there is external SSO involved, pass the client id back so MVC TT can handle that
        if (clientId) {
          redirectUrl += `?clientId=${clientId}`;
        }
      } else {
        // Logout has begun in the React app, so now we need to log out of TT as well
        redirectUrl = `${mvcAppRootUrl}/auth/logout?skipRemote=1`;
      }

      return redirectUrl;
    },
  },
  profile: "/og-app/profile",
  changePassword: "/og-app/changePassword",
  yourGoals: "/og-app/yourGoals",
  notificationPreferences: "/og-app/notificationPreferences",
  logout: "/og-app/logout",
};

export default AppRoutes;
